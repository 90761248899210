import React from 'react';
import { Navigate } from 'react-router-dom';
import { getSelectedChipset, getToken, getTypeOfSlidelide } from './Common';

export const PublicRoute = ({ component: Component }) => {

  if (getToken() && window.location.pathname === "/Login") {
    if (getTypeOfSlidelide()=="WSI"){
      return <Navigate to="/caselist" />
    } else if (getTypeOfSlidelide()==("GC" || "Grid Capture")){
      return <Navigate to="/gridCaptureList" />
    } else if (getTypeOfSlidelide()==("livecontrol")){
      return <Navigate to="/livecontrol" />
    }
  }
  else {
    return <Component />
  }
}