import React, { useContext, useState } from 'react';
import { PageContext } from "../../CaseViewer";
import { BiRadioCircle } from 'react-icons/bi';

const ColorButtons = ({ color,setSelectedColor,selectedColor }) => {
    const { pageDispatcher } = useContext(PageContext);

    const handleColorChange=(color)=>{
        setSelectedColor(color);
        pageDispatcher.set_Annotation_color(color);
        console.log("state.annotationColor:",color)
      }

  return (
    <button
      style={{
        border: 'none',
        background: 'none',
        paddingRight: '10px',
      }}
      onClick={() => handleColorChange(color)}
      title={color.charAt(0).toUpperCase() + color.slice(1)}
    >
      <BiRadioCircle
        style={{
          color: color,
          backgroundColor: selectedColor === color ? color : 'transparent',
          borderRadius: selectedColor === color ? '60%' : '0',
          fontSize: selectedColor === color ? '60%' : '100%',
        }}
      />
    </button>
  );
};

export default ColorButtons;