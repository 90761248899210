import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { environment } from "../../environment";
// import image from "./image.png";
import setting from "./setting.png";
import { getSelectedChipset, setAuthData, setCurrentModule } from "../Utils/Common";
import { NotificationContainer, NotificationManager } from "react-notifications";
import Loader from "./Loader";
import { Modal, Button } from "react-bootstrap";
import ChipButton from "./ChipButton";
import CardInfo from "./CardInfo";
const baseURL = environment.MICALYS_POSTGRES_API;
const baseURLV2 = environment.MICALYS_API_V2;

export const Login = () => {
  const initialState = {
    pageTitle: "MICALYS",
    email: "",
    password: "",
    isMaint: false,
  };
  const [state, dispatch] = useReducer(pageReducer, initialState);

  const pageDispatcher = {
    set_UserName: (value) => dispatch({ type: "set_UserName", payload: value }),
    set_Password: (value) => dispatch({ type: "set_Password", payload: value }),
    set_isMaint: (value) => dispatch({ type: "set_isMaint", payload: value }),
    set_clearFields: (value) => dispatch({ type: "set_clearFields", payload: value }),
  };

  return (
    <div>
      <PageContext.Provider value={{ state, pageDispatcher }}>
        <NotificationContainer />
        <Chipset />
      </PageContext.Provider>
    </div>
  );
};

const LoginformDesign = ({ selectedChipset, typeOfSlide, chipData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const { state, pageDispatcher } = useContext(PageContext);
  const navigate = useNavigate();

  const handleLoginChange = () => {
    pageDispatcher.set_isMaint(!state.isMaint);
    pageDispatcher.set_UserName("");
    pageDispatcher.set_Password("");
  };

  const handleLogin = (obj) => {
    return axios.post(baseURL + "login", obj);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // localStorage.clear();
    setIsLoading(true); // Show loader
    let ismaint;
    if (state.isMaint) {
      ismaint = 1;
    } else {
      ismaint = 0;
    }
    const obj = {
      username: state.email,
      password: state.password,
      isMaint: ismaint,
      selectedChipset: getSelectedChipset(),
    };
    try {
      const res = await handleLogin(obj);
      console.log("response 1", res);
      if (res && res.data.access_token) {
        if (state.isMaint == 1) {
          localStorage.setItem("isMaint", 1);
          localStorage.setItem("isNameMisMatched", res.data.device_name_mismatch);
          console.log("pageState typeOf:", typeof res.data.device_name_mismatch);
        }
        if (state.isMaint == 1 && res.data.device_name) {
          localStorage.setItem("device_name", res.data.device_name);
        }
        setTimeout(() => {
          setAuthData(res.data.access_token, res.data.username);
          let moduleData = res.data.modules;
          console.log("module data:", moduleData);

          if (typeOfSlide == "WSI") {
            navigate("/caselist", { state: { showMessage: true } });
          }
          if (typeOfSlide == "GC") {
            console.log("666 typeOfSlide==GC");
            navigate("/gridCaptureList", { state: { showMessage: true } });
          }
          if (typeOfSlide == "livecontrol") {
            console.log("666 typeOfSlide==livecontrol");
            navigate("/livecontrol", { state: { showMessage: true } });
          }
        }, 200);
      } else if (res && res.data.MSG) {
        setIsLoading(false); // Hide loader
        pageDispatcher.set_clearFields("");
        setLoginError(res.data.MSG);
        <Navigate to="/Login" />;
      } else if (res && res.data.Message) {
        setIsLoading(false); // Hide loader
        pageDispatcher.set_clearFields("");
        setLoginError(res.data.Message);
        <Navigate to="/Login" />;
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        setIsLoading(false); // Hide loader
        setLoginError(error.response.data.MSG);
      } else {
        setLoginError("Something Went Wrong");
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      {loginError && (
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          {loginError}
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => setLoginError("")}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
      {!state.isMaint ? (
        <form onSubmit={handleSubmit}>
          {chipData === "0" ? (
            <p>This module is not active</p>
          ) : (
            <>
              <div className="form-group text-left">
                <label>
                  <b>Username</b>
                </label>
                <input
                  type="text"
                  className="form-control login-input"
                  id="email"
                  value={state.email}
                  pattern="[^\s]+"
                  required
                  onChange={(e) => {
                    pageDispatcher.set_UserName(e.target.value);
                  }}
                  placeholder="Enter username"
                />
              </div>
              <div className="form-group text-left">
                <label>
                  <b>Password</b>
                </label>
                <input
                  type="password"
                  className="form-control login-input"
                  id="password"
                  value={state.password}
                  pattern="[^\s]+"
                  required
                  onChange={(e) => {
                    pageDispatcher.set_Password(e.target.value);
                  }}
                  placeholder="Enter password"
                />
              </div>
            </>
          )}

          <div className="d-flex justify-content-between mt-5">
            <a
              onClick={() => handleLoginChange()}
              style={{ fontSize: "12px", cursor: "pointer", color: "#3270cd" }}
            >
              Maintainer Login
            </a>
            {chipData !== "0" ? (
              <button
                type="submit"
                className=" loginBtn"
              >
                LOGIN
              </button>
            ) : null}
          </div>
        </form>
      ) : (
        <form
          style={{ marginTop: "5px" }}
          onSubmit={handleSubmit}
        >
          <div className="form-group text-left">
            <label>
              <b>Maintainer Id</b>
            </label>
            <input
              type="text"
              className="form-control login-input"
              id="maintainerId"
              value={state.email}
              pattern="[^\s]+"
              required
              onChange={(e) => {
                pageDispatcher.set_UserName(e.target.value);
              }}
              placeholder="Enter Email"
            />
          </div>
          <div className="form-group text-left">
            <label>
              <b>OTP</b>
            </label>
            <input
              type="text"
              className="form-control login-input"
              id="otp"
              value={state.password}
              pattern="[^\s]+"
              required
              onChange={(e) => {
                pageDispatcher.set_Password(e.target.value);
              }}
              placeholder="Enter OTP"
            />
          </div>
          <div className="d-flex justify-content-between mt-5">
            <a
              onClick={() => handleLoginChange()}
              style={{ fontSize: "12px", cursor: "pointer", color: "#3270cd" }}
            >
              User Login
            </a>
            <button
              type="submit"
              className=" loginBtn"
            >
              LOGIN
            </button>
          </div>
        </form>
      )}
    </>
  );
};
const Chipset = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedChipset, setSelectedChipset] = useState("");
  const [typeOfSlide, setTypeOfSlide] = useState("");
  const [chipsetData, setChipsetData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chipData, setChipData] = useState();
  const [live_run_status, set_live_run_status] = useState();

  useEffect(() => {
    const fetchChipset = async () => {
      try {
        const response = await axios.get(baseURLV2 + "get_additional_module_data"); // Replace with your backend API endpoint
        const data = response.data.result[0];
        const live_run_status = response.data.LC[0];
        set_live_run_status(live_run_status);
        const chipsetArray = Object.keys(data).map((key) => ({
          name: key,
          ...data[key],
        }));
        setChipsetData(chipsetArray);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchChipset();
  }, []);

  console.log("chipsetData:", chipsetData);

  const handleCardClick = (chip) => {
    setChipData(chip.active);
    localStorage.clear();
    localStorage.setItem("selectedChipset", chip.name);
    localStorage.setItem("type_of_slide", chip.type_of_slide);
    setSelectedChipset(chip.name);
    setTypeOfSlide(chip.type_of_slide);
    setShowModal(true);
  };

  const handleLCCardClick = (live_run_status) => {
    setChipData(live_run_status);
    localStorage.clear();
    localStorage.setItem("selectedChipset", "Live Control");
    localStorage.setItem("type_of_slide", "livecontrol");
    setSelectedChipset("Live Control");
    setTypeOfSlide("livecontrol");
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="main-container">
      <div className="logo-container">
        <img
          className="logo"
          height="57"
          // src={image}
          src={process.env.PUBLIC_URL+"/main_logo.png"}
          alt="logo"
        />
      </div>
      <div className="container mt-5">
        <div className="d-flex justify-content-around align-items-center">
          {chipsetData.map((chip, index) => (
            <div
              className=""
              key={index}
            >
              {console.log("chip:",chip)}
              {/* <ChipButton
                name={chip.name}
                handleClick={() => handleCardClick(chip)}
              /> */}
              <CardInfo
                name={chip.name}
                active={chip.active}
                handleClick={() => handleCardClick(chip)}
              />
            </div>
          ))}
          {live_run_status==1 && 
          <div
              className=""
            >
              {/* <ChipButton
                name={chip.name}
                handleClick={() => handleCardClick(chip)}
              /> */}
              <CardInfo
                name={"Live Control"}
                active={live_run_status}
                handleClick={() => handleLCCardClick(live_run_status)}
              />
            </div>
}
          
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        className="custom-modal"
        centered
      >
        <Modal.Header closeButton>{/* <Modal.Title>Login</Modal.Title> */}</Modal.Header>
        <Modal.Body>
          <LoginformDesign
            selectedChipset={selectedChipset}
            typeOfSlide={typeOfSlide}
            chipData={chipData}
          />{" "}
          {/* Render your login form component inside the modal */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

const pageReducer = (state, action) => {
  switch (action.type) {
    case "set_UserName":
      return {
        ...state,
        email: action.payload,
      };
    case "set_Password":
      return {
        ...state,
        password: action.payload,
      };
    case "set_isMaint":
      return {
        ...state,
        isMaint: action.payload,
      };

    case "set_clearFields":
      return {
        ...state,
        email: "",
        password: "",
      };

    default:
      return state;
  }
};

const PageContext = createContext(null);
