import { useContext } from "react";
import { Alert } from "react-bootstrap";
import { PageContext } from "../Remote";
import { handleAbortState} from "../Service";
import { Aborting } from "./Aborting";

export const DeviceErrorAbort = () => {
    const context = useContext(PageContext);

            return (
            <Alert variant="danger">
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <p>
                    {context.pageState.errText}
                </p>

                <br />
                {
                    (context.pageState.isDeviceError || context.pageState.isCloudIdMsgErr) &&
                    <div className="col-2" style={{ marginLeft: '42%' }}>

                        <button type="button" className="btn btn-danger btn-block"
                            onClick={() =>
                                handleAbortState()
                                    .then(res => {
                                        if (res && res.data.msg === 'ok') {

                                            context.pageDispatcher.componentToRender(<Aborting />)

                                            setTimeout(function () {
                                                // window.location.reload();
                                                context.pageDispatcher.set_timeInterval(100);

                                                context.pageDispatcher.set_isDeviceError(false);

                                                context.pageDispatcher.set_isCloudIdMsgErr(false);


                                            }, 100);
                                        }
                                    })
                            }
                        >Abort</button>
                    </div>
                }

            </Alert>
            )
}
