import React, { useReducer, useState, useEffect, createContext, useRef, useContext } from "react";
import "ol/ol.css";
import Map from "ol/Map";
// import TileLayer from 'ol/layer/Tile';
import TileLayer from "ol/layer/WebGLTile";
import View from "ol/View";
import Zoomify from "ol/source/Zoomify";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Button, Modal, Image, Container, Row, Form } from "react-bootstrap";
import axios from "axios";
import { environment } from "../../../environment";
import { getToken } from "../../Utils/Common";
import { PageTitle } from "../../shared/PageTitle";
import Cropper from "cropperjs";
import TileState from "ol/TileState";
import { OverviewMap } from "ol/control";
import FullScreen from "ol/control/FullScreen";
import Overlay from "ol/Overlay";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { LineString, Polygon } from "ol/geom";
import Draw from "ol/interaction/Draw";
import { unByKey } from "ol/Observable";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { ScaleLine } from "ol/control";
// import olMapScreenshot from 'ol-map-screenshot';
import Projection from "ol/proj/Projection";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { useParams } from "react-router-dom";
import Transform from "ol-ext/interaction/Transform";
import Feature from "ol/Feature";
import { fromExtent } from "ol/geom/Polygon";
import { getVectorContext } from "ol/render";
import { addupdatescale, getRequestForm } from "../Service";
import { FaArrowCircleDown, FaFilter, FaPaintBrush, FaTh } from "react-icons/fa";
// import * as olEvents from 'ol/events';

const baseURL = environment.MICALYS_POSTGRES_API;

const zoomifyURL = environment.MICALYS_POSTGRES_API + "slidedata/";

export const SlideViewer = (props) => {
  const imageElement = useRef(null);
  // const selectedElement = useRef(null);
  // const selectedAnnotationElement = useRef(null);
  // const zoomLevelElement = useRef(null);

  const prepareSlideViewerData = (slide_id, slide_access_token, scaleData) => {
    state.getSlideViewerData(slide_id, slide_access_token, scaleData);
  };

  const [scaleModalVisible, set_scaleModalVisible] = useState(false);
  const [scaleValue, set_ScaleValue] = useState();

  const initialState = {
    xInput: "",
    yInput: "",
    slideInfo: {
      slideName: "",
      createdDate: "",
      slideToken: "",
    },
    scaleData: "",
    disableTools: true,
    // slideToken: '',
    modalVisible: false,
    requestModalVisible: false,
    wsImage: "",
    requestFormImage: "",
    getSlideToken: (slide_id) =>
      getSlideToken(slide_id).then((res) => {
        if (res && res.data) {
          let slide_access_token = res.data.access_token;
          let scaleData = "";
          dispatch({ type: "set_slideInfo", payload: res.data });

          getScaleData(slide_id, res.data.access_token) //getScaleData
            .then((res) => {
              if (res && res.data && res.data.scale) {
                set_ScaleValue(res.data.scale);
                state.set_disableTools(false);
                scaleData = res.data.scale;
                prepareSlideViewerData(slide_id, slide_access_token, scaleData);
              }
            })
            .catch((err) => {
              console.log(err);
              state.set_disableTools(true);
              prepareSlideViewerData(slide_id, slide_access_token, scaleData);
            });
        }
      }),
    getannotated: (slideToken) =>
      getannotated(slideToken).then((res) => {
        if (res && res.data && res.data.byteLength > 0) {
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          let blobURL = URL.createObjectURL(blob);

          dispatch({ type: "set_wsImage", payload: blobURL });
          new Cropper(imageElement.current, {
            viewMode: 0,
            dragMode: "move",
            //strict: true,
            zoomable: true,
            background: true,
            autoCrop: false,
            toggleDragModeOnDblclick: false,
            // crop: function (e) {
            // },
          });
        }
        if (res && res.data.Message) {
          NotificationManager.error(res.data.Message, "", 2000);
        }
      }),
    getRequestForm: (slideToken) =>
      getRequestForm(slideToken)
        .then((res) => {
          if (res && res.data && res.data.byteLength > 0) {
            let blob = new Blob([res.data], {
              type: res.headers["content-type"],
            });
            let blobURL = URL.createObjectURL(blob);
            // dispatch({ type: "set_RequestForm", payload: blobURL})
            dispatch({ type: "set_wsImage", payload: blobURL });

            new Cropper(imageElement.current, {
              viewMode: 0,
              dragMode: "move",
              //strict: true,
              zoomable: true,
              background: true,
              autoCrop: false,
              toggleDragModeOnDblclick: false,
              // crop: function (e) {
              // },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          NotificationManager.info("No File to Show");
        }),

    set_modalVisible: (value) => dispatch({ type: "set_modalVisible", payload: value }),
    set_RequestModalVisible: (value) => dispatch({ type: "set_RequestModalVisible", payload: value }),
    set_disableTools: (value) => dispatch({ type: "set_disableTools", payload: value }),
    getSlideViewerData: (slide_id, slide_token, scaleData) =>
      getSlideViewerData(slide_id, slide_token)
        .then((res) => {
          if (res && res.data) {
            if (localStorage.getItem("x") == null && localStorage.getItem("y") == null) {
              localStorage.setItem("x", 100);
              localStorage.setItem("y", 100);
            }

            NotificationManager.success("success!", "", 2000);

            var parser = new DOMParser();
            var xmlDoc = parser.parseFromString(res.data, "text/xml");
            var elements = xmlDoc.getElementsByTagName("IMAGE_PROPERTIES");
            var tileSize = Number(elements[0].getAttribute("TILESIZE"));

            if (elements[0].getAttribute("WIDTH") != null && elements[0].getAttribute("HEIGHT") != null) {
              var imgWidth = Number(elements[0].getAttribute("WIDTH"));
              var imgHeight = Number(elements[0].getAttribute("HEIGHT"));
            }

            let draw, measureTooltipElement, measureTooltip, helpTooltipElement, helpTooltip, sketch;
            var typeSelect = document.getElementById("selectedElement");
            var typeSelectAnnotation = document.getElementById("selectedAnnotationElement");

            var source = new Zoomify({
              url: environment.MICALYS_POSTGRES_API + "slidedata/" + slide_id + "/",
              size: [imgWidth, imgHeight],
              crossOrigin: "anonymous",
              zDirection: -1,
              tileSize: tileSize / 1,
              tilePixelRatio: 1,
            });
            var extent = source.getTileGrid().getExtent();

            const variables = {
              exposure: 0,
              contrast: 0,
              saturation: 0,
              brightness: 0,
            };

            var layer = new TileLayer({
              style: {
                exposure: ["var", "exposure"],
                contrast: ["var", "contrast"],
                saturation: ["var", "saturation"],
                brightness: ["var", "brightness"],
                variables: variables,
              },
              source: source,
            });

            var vector_layer = new VectorLayer({
              name: "my_vectorlayer",
              source: new VectorSource({
                wrapX: false,
              }),
              style: new Style({
                fill: new Fill({
                  color: "rgba(255, 255, 255, 0.2)",
                }),
                stroke: new Stroke({
                  color: "#ff0000",
                  width: 2,
                }),
                image: new CircleStyle({
                  radius: 7,
                  fill: new Fill({
                    color: "#ff0000",
                  }),
                }),
              }),
            });

            var gridlayer = new VectorLayer({
              name: "my_gridlayer",
              source: new VectorSource({
                wrapX: false,
              }),
              style: new Style({
                stroke: new Stroke({
                  color: "#ffff00",
                  width: 2,
                  lineDash: [4, 8],
                }),
              }),
            });

            var boxlayer = new VectorLayer({
              name: "my_boxlayer",
              source: new VectorSource({
                wrapX: false,
              }),
              style: new Style({
                stroke: new Stroke({
                  color: "#ffffff",
                  width: 2,
                  lineDash: [4, 8],
                }),
              }),
            });

            var annotationBoxlayer = new VectorLayer({
              name: "my_annotationBoxlayer",
              source: new VectorSource({
                wrapX: false,
              }),
              style: new Style({
                stroke: new Stroke({
                  color: "#ff0000",
                  width: 2,
                }),
              }),
            });

            function createHelpTooltip() {
              if (helpTooltipElement) {
                helpTooltipElement.parentNode.removeChild(helpTooltipElement);
              }
              helpTooltipElement = document.createElement("div");
              helpTooltipElement.className = "ol-tooltip hidden";

              helpTooltip = new Overlay({
                element: helpTooltipElement,
                offset: [15, 0],
                positioning: "center-left",
              });
              initialMap.addOverlay(helpTooltip);
            }
            function createMeasureTooltip() {
              if (measureTooltipElement) {
                measureTooltipElement.parentNode.removeChild(measureTooltipElement);
              }
              measureTooltipElement = document.createElement("div");
              measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
              measureTooltip = new Overlay({
                element: measureTooltipElement,
                offset: [0, -15],
                positioning: "bottom-center",
                stopEvent: false,
                insertFirst: false,
              });
              initialMap.addOverlay(measureTooltip);
            }
            function addInteraction(selectedElement) {
              if (selectedElement && selectedElement.id) {
                if (selectedElement.id !== "none") {
                  const type = selectedElement.id === "area" ? "Polygon" : "LineString";

                  draw = new Draw({
                    source: vector_layer.getSource(),
                    type: type,
                    style: new Style({
                      fill: new Fill({
                        color: "rgba(255, 255, 255, 0.2)",
                      }),
                      stroke: new Stroke({
                        color: "red",
                        lineDash: [10, 10],
                        width: 2,
                      }),
                      image: new CircleStyle({
                        radius: 5,
                        stroke: new Stroke({
                          color: "yellow",
                        }),
                        fill: new Fill({
                          color: "#ffcc33",
                        }),
                      }),
                    }),
                  });

                  createMeasureTooltip();
                  createHelpTooltip();
                  let listener;

                  draw.on("drawstart", function (evt) {
                    // set sketch
                    sketch = evt.feature; //getDrawnFeatureObj i.e Feature{..}
                    listener = sketch.getGeometry().on("change", function (event) {});
                  });

                  draw.on("drawend", function (event) {
                    let geomType = event.feature.getGeometry();
                    const properties = event.feature.getProperties();
                    feature.set("name", "New Delhi");
                    console.log("console properties:", feature.getProperties());
                    let coordinates, output, tooltipCoord;
                    let arr = [];
                    let X, Y;
                    let Xarr = [],
                      Yarr = [];

                    if (geomType.getType() === "Polygon") {
                      coordinates = event.feature.getGeometry().getCoordinates();
                      coordinates = coordinates[0];
                      coordinates.forEach((el) => {
                        // pixelCoord = initialMap.getPixelFromCoordinate([el[0], el[1]]);
                        X = Math.round(el[0]);
                        Y = Math.round(el[1]);
                        arr.push([X, Y]);
                      });
                      // console.log("arr", arr)
                      arr.forEach((el) => {
                        Xarr.push(el[0]);
                        Yarr.push(el[1]);
                      });

                      output = polygonArea(Xarr, Yarr, Xarr.length);
                      tooltipCoord = geomType.getInteriorPoint().getCoordinates();

                      // console.log("output of Polygon", output)
                    } else if (geomType.getType() === "LineString") {
                      coordinates = event.feature.getGeometry().getCoordinates();
                      coordinates.forEach((el) => {
                        // pixelCoord = initialMap.getPixelFromCoordinate([el[0], el[1]]);

                        // X = Math.round(pixelCoord[0]);
                        // Y = Math.round(pixelCoord[1]);

                        X = Math.round(el[0]);
                        Y = Math.round(el[1]);

                        arr.push([X, Y]);
                      });
                      output = lineStringLength(arr);
                      // console.log("output of Linestring", output)
                      tooltipCoord = geomType.getLastCoordinate();
                    }
                    measureTooltipElement.innerHTML = output;
                    measureTooltip.setPosition(tooltipCoord);

                    measureTooltipElement.className = "ol-tooltip ol-tooltip-static";
                    measureTooltip.setOffset([0, -7]);
                    // unset sketch
                    sketch = null;
                    // unset tooltip so that a new one can be created
                    measureTooltipElement = null;
                    createMeasureTooltip();
                    unByKey(listener);
                  });
                  initialMap.addInteraction(draw);
                }
              }
            }

            // *******************************************************ScaleLine Feature Start*****************************************************************************************//

            const scaleControl = new ScaleLine({
              units: "metric",
              // className: "custom-scale-line",
            });

            let px_To_mmVal = 1 / scaleData;

            let proj = new Projection({
              //this step is needed to set micro scale on map.
              units: "pixels",
              extent: [0, 0, imgWidth, imgHeight],
              metersPerUnit: px_To_mmVal / 1000000, //(this is in micro unit)=>here converted px to mm & then mm to micro meter to get value
            });

            // *******************************************************ScaleLine Feature End*****************************************************************************************//

            // ******************************************************Overview Feature Start*******************************************************************************************//

            let overviewMapControl = new OverviewMap({
              className: "ol-overviewmap ol-slideViwer-overviewmap",
              layers: [
                new TileLayer({
                  source: source,
                }),
              ],
              collapseLabel: "\u00AB",
              label: "\u00BB",
              collapsed: true,
              collapsible: true,
              view: new View({
                resolutions: layer.getSource().getTileGrid().getResolutions(),
                // extent: extent,
                extent: [imgWidth, 0, 0, -imgHeight], //by adding this extent,overview map stopped shaking
                projection: scaleData ? proj : "",
                constrainOnlyCenter: true,
                maxZoom: 0,
                minZoom: 0,
                zoom: 0,
              }),
            });

            // if (!scaleData) {
            //     document.getElementsByClassName('ol-overviewmap ol-custom-overviewmap').style.disabled = 'not-allowed';

            //     document.getElementsByClassName('ol-overviewmap ol-custom-overviewmap').style.cursor = 'not-allowed';
            // }
            // cursor: context && context.disableTools ? 'not-allowed' : 'pointer'

            // ******************************************************Overview Feature End*****************************************************************************************//

            source.setTileLoadFunction(function (tile, src) {
              var xhr = new XMLHttpRequest();
              xhr.responseType = "blob";
              xhr.addEventListener("loadend", function (evt) {
                var data = this.response;
                if (data !== undefined) {
                  tile.getImage().src = URL.createObjectURL(data);
                } else {
                  tile.setState(TileState.ERROR);
                }
              });
              xhr.addEventListener("error", function () {
                tile.setState(TileState.ERROR);
              });
              xhr.open("GET", src);
              xhr.setRequestHeader("Authorization", "Bearer " + slide_token);

              xhr.send();
            });
            const continueLineMsg = "Click to continue drawing the line";
            const continuePolygonMsg = "Click to continue drawing the polygon";

            const pointerMoveHandler = function (evt) {
              createMeasureTooltip();
              createHelpTooltip();

              if (evt.dragging) {
                return;
              }

              let helpMsg = "Click to start drawing";

              if (sketch) {
                const geom = sketch.getGeometry();
                if (geom instanceof Polygon) {
                  helpMsg = continuePolygonMsg;
                } else if (geom instanceof LineString) {
                  helpMsg = continueLineMsg;
                }

                helpTooltipElement.innerHTML = helpMsg;
                helpTooltip.setPosition(evt.coordinate);
                helpTooltipElement.classList.remove("hidden");
              }
            };

            // *******************************************************Initialize Map Start*****************************************************************************************//
            var interaction = new Transform({
              enableRotatedTransform: false,
              translateFeature: false,
              scale: false,
              rotate: false,
              translate: true,
            });

            interaction.setStyle(
              "translate",
              new Style({
                text: new Text({
                  text: "\uf047",
                  font: "20px Fontawesome",
                  fill: new Fill({
                    color: [255, 255, 255, 0.8],
                  }),
                  stroke: new Stroke({
                    width: 2,
                    color: "red",
                  }),
                }),
              })
            );

            var initialMap = new Map({
              layers: [layer, vector_layer],
              loadTilesWhileAnimating: true,
              loadTilesWhileInteracting: true,
              target: mapElement.current,
              view: new View({
                resolutions: layer.getSource().getTileGrid().getResolutions(),
                extent: extent,
                projection: proj,
                zoom: 7,
                constrainOnlyCenter: true,
                // center: [0, 0],
                center: [500000, 6000000],
              }),
            });

            const rectangleBox = function (resolution, width, height) {
              var coordinate = initialMap.getView().getCenter();
              // console.log(coordinate, resolution, width, height);
              let lineStyle = new Style({
                stroke: new Stroke({
                  color: "red",
                  width: 2,
                  lineDash: [4, 8],
                }),
              });
              var draw = new Feature({
                geometry: new fromExtent([
                  coordinate[0] - (width * resolution) / 2,
                  coordinate[1] - (height * resolution) / 2,
                  coordinate[0] + (width * resolution) / 2,
                  coordinate[1] + (height * resolution) / 2,
                ]),
              });
              draw.setStyle(lineStyle);
              var features = annotationBoxlayer.getSource().getFeatures();
              features.forEach((feature) => {
                annotationBoxlayer.getSource().removeFeature(feature);
              });
              annotationBoxlayer.getSource().addFeature(draw);
              initialMap.removeLayer(annotationBoxlayer);
              // .addInteraction(interaction);
              initialMap.addLayer(annotationBoxlayer);
              initialMap.addInteraction(interaction);
            };

            let feature = new Feature({
              geometry: new Polygon([
                [
                  [extent[0], extent[1]],
                  [extent[0], extent[3]],
                  [extent[2], extent[3]],
                  [extent[2], extent[1]],
                  [extent[0], extent[1]],
                ],
              ]),
            });

            boxlayer.getSource().addFeature(feature);
            gridlayer.getSource().addFeature(feature);

            boxlayer.on("postrender", function (event) {
              var vectorContext = getVectorContext(event);
              var viewState = event.frameState.viewState;
              var style = new Style({
                stroke: new Stroke({
                  color: "black",
                  width: 2,
                  lineDash: [4, 8],
                }),
              });

              var center = viewState.center;

              const newCoordinates = [];
              var X = localStorage.getItem("x");
              var Y = localStorage.getItem("y");
              let offsetVX = (X * scaleData) / 2;
              let offsetVY = (Y * scaleData) / 2;
              newCoordinates.push([center[0] + offsetVX, center[1] + offsetVY]);

              newCoordinates.push([center[0] + offsetVX, center[1] - offsetVY]);

              newCoordinates.push([center[0] - offsetVX, center[1] - offsetVY]);

              newCoordinates.push([center[0] - offsetVX, center[1] + offsetVY]);
              newCoordinates.push([center[0] + offsetVX, center[1] + offsetVY]);

              var geometry = new Polygon([newCoordinates]);
              var feature = new Feature(geometry);
              vectorContext.drawFeature(feature, style);
            });

            const gridBox = (X, Y) => {
              let lineStyle = new Style({
                stroke: new Stroke({
                  color: "#aeaeae",
                  width: 2,
                  lineDash: [4, 8],
                  // lineDash: [10, 20, 10, 20]
                }),
              });

              let offsetVX = X * scaleData;
              let offsetVY = Y * scaleData;
              var [minx, miny, maxx, maxy] = extent;
              var X1 = minx;
              var Y1 = miny;

              var features = gridlayer.getSource().getFeatures();
              features.forEach((feature) => {
                gridlayer.getSource().removeFeature(feature);
              });

              while (X1 < maxx) {
                var draw = new Feature({
                  geometry: new LineString([
                    [X1, miny],
                    [X1, maxy],
                  ]),
                });
                draw.setStyle(lineStyle);
                gridlayer.getSource().addFeature(draw);
                X1 = X1 + offsetVX;
              }

              while (Y1 < maxy) {
                var draw1 = new Feature({
                  geometry: new LineString([
                    [minx, Y1],
                    [maxx, Y1],
                  ]),
                });
                draw1.setStyle(lineStyle);
                gridlayer.getSource().addFeature(draw1);
                Y1 = Y1 + offsetVY;
              }

              initialMap.removeLayer(gridlayer);
              initialMap.addLayer(gridlayer);
            };

            if (scaleData) {
              initialMap.addControl(scaleControl);
            }
            overviewMapControl.getOverviewMap().on("click", function (event) {
              initialMap.getView().setCenter(event.coordinate);
            });
            // var ovMap = overviewMapControl.getOverviewMap();
            // ovMap.setLayerGroup(new LayerGroup());
            initialMap.addControl(overviewMapControl);

            initialMap.addControl(
              new FullScreen({
                className: "slideViwer-fullscreen-button",
              })
            );
            initialMap.getView().fit(extent);

            overviewMapControl.getOverviewMap().on("click", function (e) {
              initialMap.getView().setCenter(e.coordinate);
            });

            const polygonArea = function (X, Y, n) {
              let area = 0.0;
              let j = n - 1;
              for (let i = 0; i < n; i++) {
                area += (X[j] + X[i]) * (Y[j] - Y[i]);
                j = i;
              }

              let val = Math.abs(area / 2.0);

              if (!Number.isInteger(val)) {
                val.toFixed(2);
              }

              let output;
              if (scaleData) {
                output = `${(val / Math.pow(scaleData, 2)).toFixed(2)} µm<sup>2</sup>`;
              } else {
                output = `${val} px<sup>2</sup>`;
              }

              return output;
            };

            const lineStringLength = function (lineCoords) {
              let output, data;
              let dataarr = [];

              for (let i = 0; i < lineCoords.length; i++) {
                let xn = lineCoords[i][0];
                let yn = lineCoords[i][1];

                if (lineCoords[i + 1] !== undefined) {
                  let xinc = lineCoords[i + 1][0];
                  let yinc = lineCoords[i + 1][1];

                  // console.log("(x(n+1)-xn):", (xinc - xn))
                  // console.log("(y(y+1)-yn):", (yinc - yn))

                  data = Math.sqrt(Math.pow(xinc - xn, 2) + Math.pow(yinc - yn, 2));
                  dataarr.push(data);
                }
              }

              let sum = dataarr.reduce(function (accumulator, currentValue) {
                return accumulator + currentValue;
              });

              let val = Math.round(sum);

              if (!Number.isInteger(val)) {
                val.toFixed(2);
              }

              if (scaleData) {
                output = `${(val / scaleData).toFixed(2)} µm`;
              } else {
                output = `${val} px`;
              }

              return output;
            };

            // *******************************************************Measure Feature Start*****************************************************************************************//
            if (typeSelect) {
              typeSelect.addEventListener("click", function (event) {
                initialMap.removeInteraction(draw);

                if (event.target.id !== "none") {
                  initialMap.on("pointermove", pointerMoveHandler);
                  createMeasureTooltip();
                  createHelpTooltip();
                  addInteraction(event.target);
                } else {
                  sketch = null;
                  initialMap.removeOverlay(helpTooltip);
                  initialMap.removeOverlay(measureTooltip);
                }
              });
            }

            if (typeSelectAnnotation) {
              typeSelectAnnotation.addEventListener("click", function (event) {
                // initialMap.removeInteraction(draw);

                if (event.target.id === "box") {
                  initialMap.removeLayer(boxlayer);
                  initialMap.addLayer(boxlayer);
                } else if (event.target.id === "grid") {
                  initialMap.removeLayer(gridlayer);
                  // initialMap.addLayer(gridlayer);
                  gridBox(localStorage.getItem("x"), localStorage.getItem("y"));
                } else if (event.target.id === "annotatedBox") {
                  initialMap.removeLayer(annotationBoxlayer);
                  initialMap.removeInteraction(interaction);
                  rectangleBox(scaleData, localStorage.getItem("x"), localStorage.getItem("y"));
                } else if (event.target.id === "none") {
                  initialMap.removeLayer(boxlayer);
                  initialMap.removeLayer(gridlayer);
                  initialMap.removeLayer(annotationBoxlayer);
                  initialMap.removeInteraction(interaction);
                }
              });
            }

            // *******************************************************Measure Feature End*****************************************************************************************//

            // *******************************************************Download View Feature Start*****************************************************************************************//

            let exp_btn = document.getElementById("export-png");

            if (exp_btn) {
              exp_btn.onclick = async () => {
                doDonwload("map.png");
              };
            }

            async function doDonwload(fileName) {
              const response = await doScreenshot(fileName);
            }

            async function doScreenshot(fileName) {
              var rtval;
              // document.getElementById('export-png').addEventListener('click', function () {
              initialMap.once("rendercomplete", function () {
                const mapCanvas = document.createElement("canvas");
                const size = initialMap.getSize();
                mapCanvas.width = size[0];
                mapCanvas.height = size[1];
                const mapContext = mapCanvas.getContext("2d");
                Array.prototype.forEach.call(initialMap.getViewport().querySelectorAll(".ol-layer canvas, canvas.ol-layer"), function (canvas) {
                  if (canvas.width > 0) {
                    const opacity = canvas.parentNode.style.opacity || canvas.style.opacity;
                    mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
                    let matrix;
                    const transform = canvas.style.transform;
                    if (transform) {
                      // Get the transform parameters from the style's transform matrix
                      matrix = transform
                        .match(/^matrix\(([^\(]*)\)$/)[1]
                        .split(",")
                        .map(Number);
                    } else {
                      matrix = [parseFloat(canvas.style.width) / canvas.width, 0, 0, parseFloat(canvas.style.height) / canvas.height, 0, 0];
                    }
                    // Apply the transform to the export map context
                    CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
                    const backgroundColor = canvas.parentNode.style.backgroundColor;
                    if (backgroundColor) {
                      mapContext.fillStyle = backgroundColor;
                      mapContext.fillRect(0, 0, canvas.width, canvas.height);
                    }
                    mapContext.drawImage(canvas, 0, 0);
                  }
                });

                mapContext.globalAlpha = 1;
                mapContext.setTransform(1, 0, 0, 1, 0, 0);
                rtval = mapCanvas.toDataURL();
                const element = document.createElement("a");
                element.setAttribute("href", mapCanvas.toDataURL());
                element.setAttribute("download", fileName);
                element.style.display = "none";
                document.body.appendChild(element);
                element.click();
              });
              initialMap.renderSync();

              //   }
              //   );
            }
            // *******************************************************Download View Feature End*****************************************************************************************//

            initialMap.on("moveend", () => {
              // var zoom = initialMap.getView().getZoom();
              // console.log("min zoom", zoom);
              // console.log("maxZoom", initialMap.getView().getMaxZoom()
              // )
              // var view = initialMap.getView();
              // // console.log("view", view);
              // var resolution = view.getResolution();
              // // alert(resolution)
              // console.log("resolution", resolution)
              // var resolutionss = view.getResolutions();
              // console.log("resolutionss", resolutionss)
            });

            let dynamicResolutionBtns = document.getElementById("dynamicResolution");

            if (dynamicResolutionBtns) {
              let resScale = scaleData / 5.275;
              var Resdict = {
                "80X": 1 * resScale,
                "40X": 1.7855350008023005 * resScale,
                "20X": 2.231796258402592 * resScale,
                "10X": 4.523187969088357 * resScale,
                "4X": 8.342052841421221 * resScale,
                fit: 106.34897959183674 * resScale,
              };

              dynamicResolutionBtns.addEventListener("click", (event) => {
                let resolutionVal = event.target.value;
                initialMap.getView().setResolution(Resdict[resolutionVal].toString());
              });
            }
            // *******************************************************Initialize Map End End*****************************************************************************************//
            // *******************************************************Initialize Map End End*****************************************************************************************//

            // *******************************************************Tile Styling(brightness,exposure etc start)*****************************************************************************************//

            let variable;
            const updates = {};

            function setStyleToTiles() {
              for (variable in variables) {
                const name = variable;
                const element = document.getElementById(name);

                const value = variables[name];
                element.value = value.toString();
                document.getElementById(name + "-value").innerText = value.toFixed(2);

                const listener = function (event) {
                  const value = parseFloat(event.target.value);
                  document.getElementById(name + "-value").innerText = value.toFixed(2);
                  updates[name] = value;
                  layer.updateStyleVariables(updates);
                };
                element.addEventListener("input", listener);
                element.addEventListener("change", listener);

                document.getElementById("resettilestyle").addEventListener("click", () => {
                  element.value = "0.00";
                  document.getElementById(name + "-value").innerText = "0.00";
                  updates[name] = 0;
                  layer.updateStyleVariables(updates);
                });
              }
            }

            setStyleToTiles(); //function for setting brighness,contrast etc to tiles

            // *******************************************************Tile Styling(brightness,exposure etc end)*****************************************************************************************//
          }
        })
        .catch((e) => {
          state.set_disableTools(true);
          NotificationManager.error("Error!", "", 2000);
        }),
  };

  const [state, dispatch] = useReducer(pageReducer, initialState);
  const mapElement = useRef(null);

  const { id } = useParams();

  const setXAndY = () => {
    // console.log("set X and Y called")
    localStorage.setItem("x", state.xInput);
    localStorage.setItem("y", state.yInput);
  };

  const updateXInputValue = (e) => {
    // console.log("set x input called")
    dispatch({ type: "set_xInput", payload: e.target.value });
  };
  const updateYInputValue = (e) => {
    // console.log("set y input called")
    dispatch({ type: "set_yInput", payload: e.target.value });
  };

  useEffect(() => {
    // const id = props.urlData.
    if (id) {
      state.getSlideToken(id);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container-fluid">
      <PageContext.Provider value={state}>
        <PageTitle
          title={state.slideInfo.slideName}
          backLink="/SlideList"
        ></PageTitle>

        <NotificationContainer />
        <br />
        <div className="row justify-content-end">
          <div className="col-md-5">
            <OverViewComponent imageElement={imageElement} />
          </div>
          {/* <div className="row justify-content-start col-md-7">
            <RequestFormComponent  />
          </div> */}

          <div className="col-md-6">
            <div style={{ textAlign: "right" }}>
              <a
                href="#!"
                style={{ color: "green" }}
                onClick={() => set_scaleModalVisible(true)}
              >
                + {scaleValue ? "Update" : "Add"} Scale
              </a>{" "}
              |&nbsp;
              <AddUpdateScaleComponent
                value={{ scaleModalVisible, set_scaleModalVisible }}
                scalVal={{ scaleValue, set_ScaleValue }}
                {...props}
              />
              <b>Created Date:</b>
              {state.slideInfo.createdDate}
            </div>
          </div>
        </div>
        <div className="row">
          <div
            ref={mapElement}
            className="map-container"
          >
            <DrawFeaturesComponent />
            <ZoomLevelsComponent />
            <ScreenshotSlideComponent />
            <TileLayerStyleControls />
            <div
              className=""
              style={{
                position: "absolute",
                right: "6px",
                top: "136px",
                height: "649px",
                width: "65px",
                display: "inline-block",
                backgroundColor: "transparent",
                zIndex: "10",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="btn-group dropleft">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        disabled={state.disableTools}
                        style={{
                          cursor: state && state.disableTools ? "not-allowed" : "pointer",
                          backgroundColor: "rgba(0,60,136,0.5)",
                          color: "white",
                          padding: "0",
                          fontSize: "1.14em",
                          fontWeight: "bold",
                          textAlign: "center",
                          height: "1.375em",
                          width: "1.375em",
                          border: "none",
                          borderRadius: "2px",
                        }}
                      >
                        <FaTh
                          aria-hidden="true"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Grid And Box"
                        />
                      </button>
                      <div
                        className="dropdown-menu"
                        id="selectedAnnotationElement"
                        // ref={props.selectedAnnotationElement}
                      >
                        <a
                          className="dropdown-item"
                          href="#!"
                          id="grid"
                        >
                          Grid
                        </a>
                        <a
                          className="dropdown-item"
                          href="#!"
                          id="box"
                        >
                          Box
                        </a>
                        <a
                          className="dropdown-item"
                          href="#!"
                          id="annotatedBox"
                        >
                          Annoted
                        </a>
                        <a
                          className="dropdown-item"
                          href="#!"
                          id="none"
                        >
                          None
                        </a>
                        <hr></hr>
                        <div style={{ width: "50px" }}>
                          <div className="d-flex">
                            <input
                              onChange={(e) => {
                                e.preventDefault();
                                updateXInputValue(e);
                              }}
                              className="ml-3 mb-2"
                              style={{
                                width: "50px",
                                marginRight: "10px",
                              }}
                              placeholder={localStorage.getItem("x")}
                            ></input>
                            µm
                          </div>
                          <div className="d-flex">
                            <input
                              onChange={(e) => {
                                e.preventDefault();
                                updateYInputValue(e);
                              }}
                              className="ml-3"
                              style={{
                                width: "50px",
                                marginRight: "10px",
                              }}
                              placeholder={localStorage.getItem("y")}
                            ></input>
                            µm
                          </div>
                        </div>
                        <button
                          className=" dropdown-item btn btn-primary"
                          onClick={setXAndY}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* box feature ui end */}
          </div>
        </div>
      </PageContext.Provider>
    </div>
  );
};

const AddUpdateScaleComponent = (props) => {
  const context = useContext(PageContext);

  const ScaleValueForm = useRef(null);
  // console.log("context",context)
  const [validated, setValidated] = useState(false);

  const { scaleModalVisible, set_scaleModalVisible } = props.value;

  const { scaleValue, set_ScaleValue } = props.scalVal;

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = ScaleValueForm.current;
    if (form.checkValidity()) {
      let obj = {
        scale_data: parseFloat(scaleValue),
      };

      addupdatescale(obj, context.slideInfo.slideToken).then((res) => {
        if (res) {
          NotificationManager.success(res.data.Message, "", 2000);
          set_scaleModalVisible(false);
        }
        if (res.data.Err) {
          NotificationManager.error(res.data.Err, "", 2000);
        }
      });
    } else {
      setValidated(true);
    }
  };

  return (
    <>
      <Modal
        show={scaleModalVisible}
        onHide={() => set_scaleModalVisible(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Update Scale</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            ref={ScaleValueForm}
            validated={validated}
          >
            <Form.Group controlId="scalevalue">
              {/* <Form.Label>Scale</Form.Label> */}
              <Form.Control
                type="text"
                placeholder="Enter scale value range 1 - 9"
                name="scaleval"
                value={scaleValue}
                onChange={(e) => set_ScaleValue(e.target.value)}
                required
              />

              <Form.Control.Feedback type="invalid">Please enter scale value.</Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => set_scaleModalVisible(false)}
          >
            Close
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={(e) => handleSubmit(e)}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const OverViewComponent = (props) => {
  const context = useContext(PageContext);
  return (
    <>
      <a
        className="ml-4 mt-0"
        href="#!"
        style={{ float: "right" }}
        onClick={(e) => {
          context.getRequestForm(context.slideInfo.slideToken);
          context.set_modalVisible(true);
        }}
      >
        <img
          src={window.location.origin + "/fileImage.png"}
          width="30px"
          alt="request file"
        ></img>
      </a>
      <a
        href="#!"
        style={{ float: "right" }}
        onClick={(e) => {
          context.getannotated(context.slideInfo.slideToken);
          context.set_modalVisible(true);
        }}
      >
        <h6>OverView</h6>
      </a>

      {context && context.wsImage && (
        <Modal
          show={context.modalVisible}
          onHide={() => context.set_modalVisible(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>WS Image</Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <Row>
                <Image
                  className="img-container"
                  src={context.wsImage}
                  alt=""
                  id="img"
                  ref={props.imageElement}
                />
              </Row>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => context.set_modalVisible(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

const DrawFeaturesComponent = (props) => {
  const context = useContext(PageContext);

  return (
    <div
      className=""
      style={{
        position: "absolute",
        right: "6px",
        top: "42px",
        height: "649px",
        width: "65px",
        display: "inline-block",
        backgroundColor: "transparent",
        zIndex: "10",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="btn-group dropleft">
              <a
                id="image-download"
                download="map.png"
              ></a>

              <button
                type="button"
                className="btn btn-secondary"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                disabled={context.disableTools}
                style={{
                  cursor: context && context.disableTools ? "not-allowed" : "pointer",
                  backgroundColor: "rgba(0,60,136,0.5)",
                  color: "white",
                  padding: "0",
                  fontSize: "1.14em",
                  fontWeight: "bold",
                  textAlign: "center",
                  height: "1.375em",
                  width: "1.375em",
                  border: "none",
                  borderRadius: "2px",
                }}
              >
                <FaPaintBrush
                  aria-hidden="true"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Measurement Type"
                />
              </button>
              <div
                className="dropdown-menu"
                id="selectedElement"
                // ref={props.selectedElement}
              >
                <a
                  className="dropdown-item"
                  href="#!"
                  id="length"
                >
                  Length (LineString)
                </a>
                <a
                  className="dropdown-item"
                  href="#!"
                  id="area"
                >
                  Area (Polygon)
                </a>
                <a
                  className="dropdown-item"
                  href="#!"
                  id="none"
                >
                  None
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ZoomLevelsComponent = (props) => {
  const context = useContext(PageContext);

  return (
    <div
      className=""
      style={{
        position: "absolute",
        right: "16px",
        top: "169px",
        margin: "0px",
        zIndex: "30",
        fontSize: "18px",
      }}
    >
      <ButtonGroup
        vertical
        size="sm"
        id="dynamicResolution"
        style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
      >
        <Button
          variant="outline-secondary"
          value="80X"
          id="80X"
          style={{
            cursor: context && context.disableTools ? "not-allowed" : "pointer",
          }}
          disabled={context.disableTools}
        >
          80X
        </Button>
        <Button
          variant="outline-secondary"
          value="40X"
          id="40X"
          style={{
            cursor: context && context.disableTools ? "not-allowed" : "pointer",
          }}
          disabled={context.disableTools}
        >
          {" "}
          40X
        </Button>
        <Button
          variant="outline-secondary"
          value="20X"
          id="20X"
          style={{
            cursor: context && context.disableTools ? "not-allowed" : "pointer",
          }}
          disabled={context.disableTools}
        >
          20X
        </Button>
        <Button
          variant="outline-secondary"
          value="10X"
          id="10X"
          style={{
            cursor: context && context.disableTools ? "not-allowed" : "pointer",
          }}
          disabled={context.disableTools}
        >
          10X
        </Button>
        <Button
          variant="outline-secondary"
          value="4X"
          id="4X"
          style={{
            cursor: context && context.disableTools ? "not-allowed" : "pointer",
          }}
          disabled={context.disableTools}
        >
          4X
        </Button>
        <Button
          variant="outline-secondary"
          value="fit"
          id="fit"
          style={{
            cursor: context && context.disableTools ? "not-allowed" : "pointer",
          }}
          disabled={context.disableTools}
        >
          Fit
        </Button>
      </ButtonGroup>
    </div>
  );
};

const ScreenshotSlideComponent = () => {
  const context = useContext(PageContext);

  return (
    <div
      className=""
      style={{
        position: "absolute",
        right: "6px",
        top: "74px",
        height: "649px",
        width: "65px",
        display: "inline-block",
        backgroundColor: "transparent",
        zIndex: "10",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="btn-group dropleft">
              <button
                type="button"
                className="btn btn-secondary"
                id="export-png"
                disabled={context.disableTools}
                style={{
                  cursor: context && context.disableTools ? "not-allowed" : "pointer",
                  backgroundColor: "rgba(0,60,136,0.5)",
                  color: "white",
                  padding: "0",
                  fontSize: "1.14em",
                  fontWeight: "bold",
                  textAlign: "center",
                  height: "1.375em",
                  width: "1.375em",
                  border: "none",
                  borderRadius: "2px",
                }}
              >
                <FaArrowCircleDown
                  aria-hidden="true"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Download current view"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TileLayerStyleControls = () => {
  const context = useContext(PageContext);

  return (
    <div
      className=""
      style={{
        position: "absolute",
        right: "6px",
        top: "106px",
        height: "649px",
        width: "65px",
        display: "inline-block",
        backgroundColor: "transparent",
        zIndex: "10",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="btn-group dropleft">
              <button
                type="button"
                className="btn btn-secondary"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                disabled={context.disableTools}
                style={{
                  cursor: context && context.disableTools ? "not-allowed" : "pointer",
                  backgroundColor: "rgba(0,60,136,0.5)",
                  color: "white",
                  padding: "0",
                  fontSize: "1.14em",
                  fontWeight: "bold",
                  textAlign: "center",
                  height: "1.375em",
                  width: "1.375em",
                  border: "none",
                  borderRadius: "2px",
                }}
              >
                <FaFilter
                  aria-hidden="true"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Filters"
                />
              </button>
              <div
                className="dropdown-menu row"
                id="tilestyles"
                style={{ textAlign: "center" }}
              >
                Brightness: <span id="brightness-value"></span>
                <br />
                <input
                  id="brightness"
                  type="range"
                  min="-0.5"
                  max="1"
                  step="0.01"
                />
                Exposure: <span id="exposure-value"></span>
                <br />
                <input
                  id="exposure"
                  type="range"
                  min="-0.5"
                  max="1"
                  step="0.01"
                />
                <br />
                Contrast: <span id="contrast-value"></span>
                <br />
                <input
                  id="contrast"
                  type="range"
                  min="-0.5"
                  max="1"
                  step="0.01"
                />
                <br />
                Saturation: <span id="saturation-value"></span>
                <br />
                <input
                  id="saturation"
                  type="range"
                  min="-0.5"
                  max="1"
                  step="0.01"
                />
                <div style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    id="resettilestyle"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getSlideToken = (slide_id) => {
  var data = {
    slide_id: slide_id,
  };
  return axios.post(baseURL + "get_slidetoken", data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });
};
const getSlideViewerData = (slide_id, slide_token) => {
  return axios.get(zoomifyURL + slide_id + "/ImageProperties.xml", {
    headers: { Authorization: `Bearer ${slide_token}` },
  });
};
const getScaleData = (slide_id, slide_token) => {
  return axios.get(zoomifyURL + slide_id + "/scale.json", {
    headers: { Authorization: `Bearer ${slide_token}` },
  });
};
const getannotated = (slide_token) => {
  return axios.get(baseURL + "getannotated", {
    headers: { Authorization: `Bearer ${slide_token}` },
    responseType: "arraybuffer",
  });
};
// const getRequestForm = (slide_token) => {
//   return axios.get(baseURL + "get_requestform", {
//     headers: { Authorization: `Bearer ${slide_token}` },
//     responseType: "arraybuffer",
//   });
// };
export const pageReducer = (state, action) => {
  switch (action.type) {
    case "set_slideInfo":
      state = {
        ...state,
        slideInfo: {
          ...state.slideInfo,
          slideName: action.payload.slide_name,
          createdDate: action.payload.created,
          slideToken: action.payload.access_token,
        },
      };
      return state;

    case "set_modalVisible":
      return {
        ...state,
        modalVisible: action.payload,
      };
    case "set_RequestModalVisible":
      return {
        ...state,
        requestModalVisible: action.payload,
      };
    case "set_scaleData":
      state = {
        ...state,
        scaleData: action.payload,
      };
      return state;

    case "set_slideToken":
      return {
        ...state,
        scaleData: action.payload,
      };
    case "set_wsImage":
      return {
        ...state,
        wsImage: action.payload,
      };
    case "set_RequestForm":
      return {
        ...state,
        requestFormImage: action.payload,
      };

    case "set_disableTools":
      return {
        ...state,
        disableTools: action.payload,
      };
    case "set_xInput":
      return {
        ...state,
        xInput: action.payload,
      };
    case "set_yInput":
      return {
        ...state,
        yInput: action.payload,
      };
    default:
      return state;
  }
};
export const PageContext = createContext(null);

//https://gis.stackexchange.com/questions/309387/detecting-zoom-change-in-openlayers
//https://gis.stackexchange.com/questions/274246/disable-pan-and-zoom-in-openlayers
