import React, { lazy, Suspense } from "react";
import { Card } from "react-bootstrap";
// import { LoaderComponent } from "../../../../GridCapture/Register/Components/Loader";
import ReportStatus from "../updateCase/ReportStatus"
import CaseName from "../updateCase/CaseName"
import CaseInfo from "../updateCase/CaseInfo"
import CaseFinding from "../updateCase/CaseFinding"
import PatientId from "../updateCase/PatientId"
import PatientGender from "../updateCase/PatientGender"
import PatientAge from "../updateCase/PatientAge"
import CaseExpiry from "../updateCase/CaseExpiry"
// const ReportStatus = lazy(() => import("../updateCase/ReportStatus"));
// const CaseName = lazy(() => import("../updateCase/CaseName"));
// const CaseInfo = lazy(() => import("../../CaseInfo/components/CaseInfo"));
// const CaseFinding = lazy(() => import("../../CaseFinding/components/CaseFinding"));
// const PatientId = lazy(() => import("../updateCase/PatientId"));
// const PatientGender = lazy(() => import("../updateCase/PatientGender"));
// const PatientAge = lazy(() => import("../updateCase/PatientAge"));
// const CaseExpiry = lazy(() => import("../updateCase/CaseExpiry"));

const CaseDetailCard = () => {
  return (
    <Card className="case-details">
      <Card.Header className="p-0">
        <strong>Case Details</strong>
      </Card.Header>
      <Card.Body className="case-detail-card-body">
        <Suspense>
          <ReportStatus/>
        </Suspense>
        {/* <Suspense fallback={<LoaderComponent />}> */}
          <CaseName />
        {/* </Suspense> */}
        <div className="d-flex justify-content-between mb-2">
          {/* <Suspense fallback={<LoaderComponent />}> */}
            <CaseInfo />
          {/* </Suspense> */}
          {/* <Suspense fallback={<LoaderComponent />}> */}
            <CaseFinding />
          {/* </Suspense> */}
        </div>
        {/* <Suspense fallback={<LoaderComponent />}> */}
          <PatientId />
        {/* </Suspense> */}
        {/* <Suspense fallback={<LoaderComponent />}> */}
          <PatientGender />
        {/* </Suspense> */}
        <Suspense >
          <PatientAge />
        </Suspense>
        {/* <Suspense fallback={<LoaderComponent />}> */}
          <CaseExpiry />
        {/* </Suspense> */}
      </Card.Body>
    </Card>
  );
};

export default CaseDetailCard;
