import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { create_new_diagnosis, delete_existing_diagnosis, get_all_existing_diagnosis } from "../Service";
import { JsonView, collapseAllNested, darkStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import { NotificationManager } from "react-notifications";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import jsonData from "./questionGraphTemplate.json";

const QuestionGraph = () => {
  const [graphs, setGraphs] = useState([]);
  const [newGraph, setNewGraph] = useState("");
  const [downloadData] = useState(jsonData);

  useEffect(() => {
    async function getAllExistingDiagnosis() {
      try {
        const res = await get_all_existing_diagnosis();
        // console.log('res.data',res.data.diagnoses)
        const jsonStringArray = res.data.diagnoses.map((obj) => JSON.stringify(obj));
        setGraphs(jsonStringArray);
      } catch (error) {
        console.log(error);
      }
    }
    getAllExistingDiagnosis();
  }, []);

  const handleAddQuestionGraph = async (event) => {
    event.preventDefault();
    try {
      const res = await create_new_diagnosis(newGraph);
      if(res && res?.status===200) {
        NotificationManager.success(res.data.Message, "", 2000);
      }
      if (res.data.Message) {
        setGraphs([...graphs, newGraph]);
        setNewGraph("");
      }
    }
    catch (error) {
      if (error.response && error.response.status === 401) {
        // console.error("Unauthorized error (401):", error.response);
        NotificationManager.error(error.response.data.Message, "", 2000);
      } else {
        console.error("Error sending data:", error);
      }
    }
    // setGraphs([...graphs, newGraph]);
    // setNewGraph("");
  };

  // const handleQuestionGraphStatus = async (event, i) => {
  //   event.preventDefault();
  //   try {
  //     const res = await create_new_diagnosis(graphs[i]);
  //     NotificationManager.success(res.data.Message, "", 2000);
  //   } catch (error) {
  //     console.error("Error sending data:", error);
  //   }
  // };

  const handleDeleteQuestionGraph = async (i) => {
    try {
      const graph = JSON.parse(graphs[i]);
      const obj = { diagnosis_name: graph.name };
      const res = await delete_existing_diagnosis(obj);
      NotificationManager.success(res.data.Message, "", 2000);

      // Remove the graph from the state
      if (res.data.Message) {
        const updatedGraphs = [...graphs];
        updatedGraphs.splice(i, 1);
        setGraphs(updatedGraphs);
      }
    } catch (error) {
      NotificationManager.error(error, "", 2000);
      console.error("Error sending data:", error);
    }
  };

  function onDownload() {
    if (downloadData) {
      const formattedJson = JSON.stringify(downloadData, null, 2);
      const blob = new Blob([formattedJson], { type: "application/json" });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "QuestionsGraphTemplate.json";
      a.click();
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <div className="d-flex justify-content-center align-items-center">
            <span style={{ fontSize: "15px", marginRight: "5px" }}>
              <h4>Question Graph List</h4>
            </span>
            <sup>
              <HiOutlineDocumentDownload
                onClick={onDownload}
                title="Question Template"
                style={{ fontSize: "25px", cursor: "pointer", color: "#2196f3" }}
              />
            </sup>
          </div>
          <div style={{ maxHeight: "750px", overflowY: "auto" }}>
            <ul style={{ listStyleType: "none" }}>
              {graphs.map((graph, i) => (
                <li
                  key={i}
                  className="d-flex justify-content-around align-items-center mb-1"
                >
                  <div style={{ maxHeight: "150px", overflowY: "auto" }}>
                    <JsonView
                      data={JSON.parse(graph)}
                      shouldInitiallyExpand={collapseAllNested}
                      style={darkStyles}
                    />
                  </div>
                  <Button
                    variant="danger"
                    onClick={() => handleDeleteQuestionGraph(i)}
                  >
                    Delete
                  </Button>
                  {/* <Button
                    variant="primary"
                    onClick={(e) => handleQuestionGraphStatus(e, i)}
                  >
                    Save
                  </Button> */}
                </li>
              ))}
            </ul>
          </div>
          <Form onSubmit={handleAddQuestionGraph}>
            <div className="d-flex justify-content-around align-items-center">
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Enter Question Graph List in json form"
                value={newGraph}
                onChange={(event) => setNewGraph(event.target.value)}
              />
              <Button
                type="submit"
                variant="primary"
              >
                Add
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default QuestionGraph;
