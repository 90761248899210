import React, { useContext, useRef, useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { PageContext } from "./CaseList";
import "../../../App.css";
import { addCase } from "../Service";
import { getSlideActiveDays } from "../../Settings/Service";
import { getSelectedChipset } from "../../Utils/Common";

export const AddCaseDetails = (props) => {
  const [validated, setValidated] = useState(false);
  const AddCaseForm = useRef(null);
  const [form, setForm] = useState({
    case_name: "",
    patient_id: "",
    // patient_name: "",
    patient_age: 0,
    patient_gender: "",
    slide_expiry_date: -1,
  });
  // const [showSlideExpiryDate, setShowSlideExpiryDate] = useState(true);
  const [showSlideExpiryDate, setShowSlideExpiryDate] = useState(false);
  const [slideExpiryDate, setSlideExpiryDate] = useState();
  const [value, setValue] = useState();
  const [submitValue, setSubmitValue] = useState();

  const { state: pageState, pageDispatcher } = useContext(PageContext);

  const handleClose = () => pageDispatcher.set_modalVisible(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSlideExpiry = (e) => {
    const { value } = e.target;
    // if (value > 0) setValue(value);
    value > 0 ? setValue(value) : setValue("");
    value > 0 ? setSubmitValue(value) : setSubmitValue("");
  };

  const fetchSlideActiveDaysInfo = async () => {
    const res = await getSlideActiveDays();
    setSlideExpiryDate(res.data.slide_active_days);
    setValue(res.data.slide_active_days);
    setSubmitValue(res.data.slide_active_days);
  };

  useEffect(() => {
    fetchSlideActiveDaysInfo();
  }, []);

  useEffect(() => {
    slideExpiryDate === -1 ? setShowSlideExpiryDate(true) : setShowSlideExpiryDate(false);
  }, [slideExpiryDate]);

  useEffect(() => {
    // showSlideExpiryDate === true ? setValue(-1) : setValue("");
    // showSlideExpiryDate === true ? setValue(-1) : setValue("");
    showSlideExpiryDate === true && setValue("");
    showSlideExpiryDate === true ? setSubmitValue(-1) : setSubmitValue("");
  }, [showSlideExpiryDate]);

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setShowSlideExpiryDate(checked);
    checked ? setSlideExpiryDate(-1) : setSlideExpiryDate(0);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const formValidate = AddCaseForm.current;
    if (formValidate.checkValidity()) {
      let obj = {
        case_name: form.case_name,
        patient_id: form.patient_id,
        patient_name: form.patient_name,
        patient_age: form.patient_age,
        patient_gender: form.patient_gender,
        patient_details:{patient_id:form.patient_id,patient_name: form.patient_name, patient_age: form.patient_age,patient_gender: form.patient_gender},
        // slide_active_days: value,
        slide_active_days: submitValue,
        selectedChipset: getSelectedChipset(),
      };

      try {
        const res = await addCase(obj);
        if (res.data.Message) NotificationManager.success(res.data.Message, "", 2000);
        let newCase = { ...obj };
        // let newCase = obj;
        newCase["case_id"] = res.data.case_id;
        newCase["created"] = res.data.created;
        newCase["expiry_date"] = res.data.expiry_date;
        newCase["assigned_to"] = [];
        newCase["report_status"] = 0;
        newCase["delete_status"] = 0;
        newCase["slide_count"] = 0;

        // console.log("newCase", newCase);
        const updatedCaseList = [newCase].concat(pageState.caseListTable.row);
        // console.log("updatedCaseList", updatedCaseList);
        pageDispatcher.set_caseDatalist({
          caselist: updatedCaseList,
        });
        setForm({ ...form, case_name: "", patient_id: "", patient_name: "", patient_age: 0, patient_gender: "" });
      } catch (error) {
        NotificationManager.error("error occurred", "", 2000);
      }
      pageDispatcher.set_modalVisible(false);
      let status = pageState.hideDeletedCaseStatus;
      if (status === true) {
        status = 1;
      } else if (status === false) {
        status = 0;
      }
      // setForm({ ...form, case_name: "", patient_id: "", patient_name: "", patient_age: 0, patient_gender: "" });
    } else {
      setValidated(true);
    }
  };

  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <NotificationContainer />
      <Modal
        show={pageState.modalVisible}
        onHide={() => pageDispatcher.set_modalVisible(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            validated={validated}
            ref={AddCaseForm}
          >
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Case Name</Form.Label>
              <Form.Control
                required
                type="text"
                value={form.case_name}
                onChange={handleInputChange}
                name="case_name"
                // placeholder="name@example.com"
                autoFocus
              />
              <Form.Control.Feedback type="invalid">Please provide a valid Case Name.</Form.Control.Feedback>
            </Form.Group>
            <div className="dropdown-divider"></div>
            <h5>Patient Details</h5>
            
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Age</Form.Label>
              <Form.Control
                type="number"
                value={form.patient_age}
                onChange={handleInputChange}
                name="patient_age"
                // placeholder="name@example.com"
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Gender</Form.Label>
              <Form.Control as="select" value={form.patient_gender} onChange={handleInputChange} name="patient_gender">
                <option value="" disabled selected>Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
                <option value="N/A">N/A</option>
              </Form.Control>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>ID</Form.Label>
              <Form.Control
                type="text"
                value={form.patient_id}
                onChange={handleInputChange}
                name="patient_id"
                // placeholder="name@example.com"
                autoFocus
              />
            </Form.Group>
            <div className="dropdown-divider"></div>
            <>Expires In</>

            <div className="d-flex justify-content-around align-items-center">
              <Form.Group controlId="exampleForm.Checkbox1">
                <Form.Check
                  type="checkbox"
                  label="never"
                  onChange={handleCheckboxChange}
                  checked={showSlideExpiryDate}
                />
              </Form.Group>

              <Form.Group
                controlId="exampleForm.ControlInput1"
                className="d-flex justify-content-around align-items-center"
              >
                <Form.Control
                  type="number"
                  // value={form.slide_expiry_date}
                  value={value}
                  onChange={handleSlideExpiry}
                  // name="slide_expiry_date"
                  disabled={slideExpiryDate === -1}
                  autoFocus
                />
              </Form.Group>
              <>Days</>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={handleSave}
          >
            Save Details
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
