import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { getAllTags, setAllTags } from "../Service";
import { NotificationContainer, NotificationManager } from "react-notifications";

const TagsList = () => {
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  // const [editTag, setEditTag] = useState("");
  // const [showEditModal, setShowEditModal] = useState(false);

  const fetchTags = async () => {
    const result = await getAllTags();
    setTags(result.data.tags);
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const handleAddTag = (event) => {
    event.preventDefault();
    const tagExists = tags.find((tag) => tag.toLowerCase() === newTag.toLowerCase());
    if (tagExists) {
      alert("Tag already exists!");
      return;
    }

    setTags([...tags, newTag]);
    setNewTag("");
  };

  const handleDeleteTag = (tagName) => {
    setTags(tags.filter((tag) => tag !== tagName));
  };

  const handleTagStatus = (e) => {
    e.preventDefault();
    let obj = {
      tags: tags,
    };
    setAllTags(obj)
      .then((res) => {
        // console.log(res);
        if (res?.status === "200") {
          NotificationManager.success(res.statusText, "", 2000);
        }
      })
      .catch((err) => {
        console.log("error msg", err);
      });
  };

  return (
    <Container>
      <Row>
        <Col>
          <div className="d-flex justify-content-center align-items-center">
            <h4>Tag List</h4>
            <button
              className="btn btn-primary"
              style={{ marginLeft: "10px", cursor: "pointer" }}
              onClick={handleTagStatus}
            >
              Save TagList
            </button>
         
          </div>
          <ul style={{ listStyleType: "none" }}>
            {tags.map((tag) => (
              <li
                key={tag}
                className="d-flex justify-content-between mb-1"
              >
                {tag}
                <Button
                  variant="danger"
                  onClick={() => handleDeleteTag(tag)}
                >
                  Delete
                </Button>
              </li>
            ))}
          </ul>
          <div className="d-flex flex-column align-items-center justify-content-center">
          <Form onSubmit={handleAddTag} className="w-20">
            <div className="d-flex justify-content-around align-items-center">
              <Form.Control
                type="text"
                placeholder="Enter tag name"
                value={newTag}
                onChange={(event) => setNewTag(event.target.value)}
              />

              <Button
                type="submit"
                variant="primary"
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
                Add
              </Button>
            </div>
          </Form>
          </div>
         
          {/* <Modal
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Tag</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group>
                  <Form.Label>Tag Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter tag name"
                    value={editTag}
                    onChange={(event) => setEditTag(event.target.value)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowEditModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleEditTag}
              >
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal> */}
        </Col>
      </Row>
    </Container>
  );
};

export default TagsList;
