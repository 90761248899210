import React, { useEffect, useState } from 'react'
import { Abort } from "./AbortStates";
import { getLiveImage, handleAbortState } from "../../Service";
import { NotificationContainer, NotificationManager } from 'react-notifications';


export const LiveImage = () => {
    const [liveImageData, setLiveImageData] = useState('')

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
    };

    useEffect(() => {
        const interval = setInterval(() => {
            getLiveImage()
                .then(async res => {
                    if (res && res.data && res.data.byteLength > 0) {
                        let blob = new Blob(
                            [res.data],
                            { type: res.headers['content-type'] }
                        )
                        let image = URL.createObjectURL(blob)
                        setLiveImageData(image)
                    }
                })
        }, 500);
        return () => clearInterval(interval);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="liveimgbody"
            style={{ background: 'linear-gradient(lightGray, white)' }}
        >
            <div className="liveimagebackground">
                {
                    liveImageData && Object.keys(liveImageData).length > 0 ?
                        <div>
                            <div className="row justify-content-around">

                                <div className="img-container col-md-4 col-sm-12" >

                                    <img src={liveImageData} alt="" id="liveimg"
                                        style={{ width: 'auto', height: '50vh', marginTop: '45px', border: '1px solid green' }}
                                    />
                                </div>
                                <div style={{ marginTop: '45px' }}>
                                    <h3>Device is running</h3>
                                    <Abort />
                                </div>
                            </div>
                        </div> : (<div  style={containerStyle}>
                        <h3   className='m-5'>Loading...</h3>
                        <button type="button" className="btn btn-danger btn-block mt-5"
                    onClick={() => handleAbortState()
                        .then(res => {
                            if (res.data && res.data.msg) {
                                NotificationManager.success(res.data.msg, '', 2000);
                            }
                        })}
                        style={{ width: "150px" }}
                >Abort</button>
                        </div>)
                }
            </div>
        </div>
    )
}