import {LoaderComponent} from './Loader'

export const RegisteringDevice = () => {
    return (
        <>
            <div className="col-md-4 offset-md-4" style={{ marginTop: '5px' }}>
                <h3>Registering Device...</h3>
                <LoaderComponent />
                {/* <br />
                <button type="button" className="btn btn-danger btn-block" onClick={() => handleAbortState().then(res => {
                    if (res.data && res.data.msg) {
                        NotificationManager.success(res.data.msg, '', 2000);
                    }
                })}
                >Abort</button> */}
            </div>
        </>
    )
}