import React, { useContext, useEffect } from "react";
import { getMainImage } from "../Service";
import { PageContext } from "./GridView";
import "./index.css";

export const ThumbnailItem = ({ imageUrl, accessToken, name,isActive,ithofTen,prevName,nextName }) => {
  const { state,pageDispatcher } = useContext(PageContext);

  const thumbnailClassName = isActive ? `thumbnail innerCard` : `thumbnail`
 
  const onClickThumbnail = async () => {
    const res = await getMainImage(name, accessToken,state.slide_id);
    if (res && res.data && res.data.byteLength > 0) {
      let blob = new Blob([res.data], { type: res.headers["content-type"] });
      let image = URL.createObjectURL(blob);
      pageDispatcher.set_main_img(image);
      pageDispatcher.set_selected_thumbnail_img(name);
      pageDispatcher.set_ithofTen_selected(ithofTen);
    }
  };

  useEffect(() => {
   
  },[]);

  return (
    <li className="thumbnail-list-item">
      <button
        type="button"
        className="thumbnail-button"
        onClick={onClickThumbnail}
      >
        <img
          src={imageUrl}
          alt={name}
          // style={{ border: "1px solid green" }}
          className={thumbnailClassName}
        />
      </button>
    </li>
  );
};

