import { useContext, useState } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { PageContext } from '../Remote';
import { start } from '../Service';
import { RegisteringDevice } from './RegisteringDevice';

export const RemoteComponent = () => {
    const context = useContext(PageContext);

    const [state, setState] = useState({
        email: '',
        isDisabled: true
    })

    return (
        <div className="col-md-4 offset-md-4">
            <form>
                <div className="form-group">
                    <label htmlFor="email">Email address</label>
                    <input type="email" id="email" value={state.email} className="form-control" aria-describedby="emailHelp" placeholder="Enter email"
                        onChange={(e) => {
                            setState({ ...state, email: e.target.value, isDisabled: false });
                            if (e.target.value.length <= 0) {
                                setState({ ...state, isDisabled: true, email: '' })
                            }
                        }

                        }
                    />
                </div>
                <button type="submit" className="btn btn-primary"
                    disabled={state.isDisabled}
                    onClick={(e) => {
                        e.preventDefault();
                        const obj = {
                            email: state.email
                        }

                        start(obj).then(res => {
                            // console.log("msg", res.data)
                            if (res && res.data && res.data.msg === "ok" && res.data.device_task === "7" && res.data.device_status === "0") {
                                context.pageDispatcher.componentToRender(< RegisteringDevice />)
                                NotificationManager.success('Email Submitted!', '', 2000);
                            }
                            else if (res && res.data && res.data.msg === "Cloud ID not found.") {
                                NotificationManager.info(`${res.data.msg}Please generate cloud id.`, '', 4000)
                            }
                        })
                    }}
                >Submit</button>
            </form>
        </div>
    )
}