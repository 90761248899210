import React, { useContext, useState, useRef, useEffect } from 'react'
import { PageContext } from "../Settings";
import { Button, Modal, Form } from 'react-bootstrap'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { getUser } from "../../Utils/Common";
import { submitCloudId, checkIfCloudIdExists, deleteCloudId } from "../Service";
import { FaCloudDownloadAlt } from 'react-icons/fa';
import { MdDelete } from "react-icons/md";

export const DeviceInfo = () => {
    const context = useContext(PageContext);

    const [isCloudIdExists, set_isCloudIdExists] = useState(false);

    const isCloudIDExists = () => {
        checkIfCloudIdExists().then(res => {
            if (res && res.data.isExists) {
                set_isCloudIdExists(res.data.isExists)
            }
        })
    }

    useEffect(() => {
        isCloudIDExists();
    }, [])
    return (
        <div>
            <NotificationContainer />

            {
                getUser() === 'Admin' &&

                <div>
                    <h4><b>Device Name:</b>{context && context.pageState.deviceName}</h4>
                </div>

            }

            <div style={{ float: 'center' }}>
                <a href="#!"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        context.pageDispatcher.set_generateCloudIdmodalVisible(true)
                    }}
                >
                    <FaCloudDownloadAlt aria-hidden="true" size={25}/> {isCloudIdExists ? "Re-new" : "Enter"} CloudID</a>
                {
                    context && context.pageState.generateCloudIdModalVisible && <GenerateCloudId />
                }
                <br />
                {
                    isCloudIdExists && <a href="#!" onClick={() => {
                        if (window.confirm('Sure want to delete?')) {
                            deleteCloudId().then(res => {
                                if (res && res.data.Message) {
                                    isCloudIDExists();
                                    NotificationManager.success(res.data.Message, '', 2000);
                                }
                            })
                        }

                    }}><MdDelete aria-hidden="true" size={25}/> Delete CloudID</a>
                }

            </div>
        </div>
    )
}

const GenerateCloudId = (props) => {
    const context = useContext(PageContext);
    const [validated, setValidated] = useState(false);

    const GenerateCloudIdForm = useRef(null)
    const handleSubmit = (e) => {
        e.preventDefault();
        const form = GenerateCloudIdForm.current

        if (form.checkValidity()) {
            let obj = {
                cloudid: form['cloudId'].value
            }
            submitCloudId(obj)
                .then(res => {
                    if (res && res.status === 200) {
                        NotificationManager.success(res.data.Message, '', 2000);
                        context.pageDispatcher.set_generateCloudIdmodalVisible(false)
                    }
                })
                .catch(e => {
                    if (e.response.status === 401 ){
                        NotificationManager.error(e.response.data.Message, '', 2000)
                    }
                })
        }
        else {
            setValidated(true);
        }
    }

    return (
        <>
            <Modal show={context.pageState.generateCloudIdModalVisible} onHide={() => context.pageDispatcher.set_generateCloudIdmodalVisible(false)} aria-labelledby="contained-modal-title-vcenter" size="sm"
                centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Cloud Id</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form noValidate validated={validated} ref={GenerateCloudIdForm}>
                        <Form.Group controlId="cloudId">
                            <Form.Label>Cloud Id:</Form.Label>
                            <Form.Control type="text" placeholder="Enter any text/number" name="cloudId" required />
                            <Form.Control.Feedback type="invalid">
                                Please enter Cloud ID
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary"
                        onClick={() => context.pageDispatcher.set_generateCloudIdmodalVisible(false)}
                    >
                        Close
                    </Button>
                    <Button variant="primary" type="submit"
                        onClick={(e) => handleSubmit(e)}
                    >
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}