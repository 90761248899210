import React from "react";
import { Button, Modal } from "react-bootstrap";
import { ChangePassword, DeleteUser } from "../Settings/Components";

const CommonModal = (props) => {
  // console.log(props.data)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        {/* <Modal.Title id="contained-modal-title-vcenter">{}</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>{props.option === "Delete" ? <DeleteUser data={props.data} hide={props.onHide}/> : <ChangePassword data={props.data} hide={props.onHide}/>}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={props.onHide}
        >
          Close
        </Button>
        {/* {props.option === "Delete" ? (
          <Button
            variant="danger"
            onClick={props.onDelete}
          >
            Delete
          </Button>
        ) : (
          ""
        )} */}
      </Modal.Footer>
    </Modal>
  );
};

export default CommonModal;
