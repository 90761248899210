// import React from "react";
// import { Accordion, Button, Card } from "react-bootstrap";
// import { MdKeyboardArrowUp } from "react-icons/md";

// const CommonAccordion = ({ title, displayItem, e, key, verticalScroll = "N" }) => {
//   const heightStyle = verticalScroll === "Y" ? "350px" : "1000px";
//   return (
//     <Accordion defaultActiveKey={key ? key : ""}>
//       <Accordion.Item eventKey={e}>
//         {/* <Card> */}
//           <Accordion.Header
//             // as={Button}
//             // variant="link"
//             // eventKey={e}
//             // size="sm"
//           >
//             {title}
//             {/* <MdKeyboardArrowUp /> */}
//           </Accordion.Header>
//           <Accordion.Collapse eventKey={e}>
//             <div style={{ maxHeight: `${heightStyle}`, overflowY: "auto" }}>{displayItem}</div>
//           </Accordion.Collapse>
//         {/* </Card> */}
//       </Accordion.Item>
//     </Accordion>
//   );
// };

// export default CommonAccordion;

import React from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { MdKeyboardArrowUp } from "react-icons/md";

const CommonAccordion = ({ title, displayItem, e, key, verticalScroll = "N" }) => {
  const heightStyle = verticalScroll === "Y" ? "350px" : "1000px";

  return (
    <Accordion
      defaultActiveKey={key ? key : ""}
      className="border-primary"
    >
      <Accordion.Item eventKey={e}>
        <Accordion.Header>{title}</Accordion.Header>
        <Accordion.Body>
          <div
            style={{
              maxHeight: `${heightStyle}`,
              overflowY: "auto",
              padding: "10px",
              borderTop: "1px solid #0d6efd",
            }}
          >
            {displayItem}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default CommonAccordion;
