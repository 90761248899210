import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { updateCaseData } from "../../../Service";
import { PageContext } from "../../CaseViewer";
import { SaveButton,CancelButton } from "../updateSlide/Buttons";

const CaseName = () => {
  const { state, pageDispatcher } = useContext(PageContext);
  const { case_owner, case_name, case_token } = state.case_info;

  const [isEditing, setIsEditing] = useState(false);
  const [caseName, setCaseName] = useState(case_name);
  const [originalCaseName, setOriginalCaseName] = useState(case_name);
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    setCaseName(case_name);
  }, [case_name]);

  const handleCaseNameChange = (event) => {
    setCaseName(event.target.value);
  };

  const handleEditButtonClick = () => {
    setOriginalCaseName(caseName);
    setIsEditing(true);
  };

  const handleSaveButtonClick = async () => {
    const obj = {
      case_name: caseName,
    };
    try {
      await updateCaseData(obj, case_token);
      setIsEditing(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelButtonClick = () => {
    setCaseName(originalCaseName);
    setIsEditing(false);
  };

  return (
    <div className="mb-1">
      {isEditing ? (
        <div>
          <input
            type="text"
            value={caseName}
            onChange={handleCaseNameChange}
          />
          <Button
            variant="outline-primary"
            size="sm"
            onClick={handleSaveButtonClick}
            className="mx-1"
          >
            <SaveButton/>
          </Button>
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={handleCancelButtonClick}
            className="mx-1"
          >
            <CancelButton/>
          </Button>
        </div>
      ) : (
        <div className="d-flex justify-content-start align-items-center">
          <span><strong>Case Name: </strong>{caseName}</span>
          {case_owner ? (
            <sup>
              <MdOutlineModeEditOutline
                size={15}
                className={isHovered ? "edit-icon hovered" : "edit-icon"}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleEditButtonClick}
              />
            </sup>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default CaseName;
