import React, { useState, useRef } from "react"
import { Row, Col, Form, Button } from 'react-bootstrap'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { changePassword } from "../Service";

export const ChangePassword = () => {
    const [validated, setValidated] = useState(false);
    const [disableButtons, setDisableButton] = useState(true);
    const [matchPassword, setMatchPassword] = useState(false);

    const ChangePasswordForm = useRef(null)

    const handleSubmit = (e) => {

        e.preventDefault();
        const form = ChangePasswordForm.current
        
        if (form.checkValidity()) {
            // if (form['old_password'].value === form['new_password'].value) {
            //     NotificationManager.warning("Old & New Passwords must contain different Values!");
            // }
            if (form['new_password'].value !== form['confirm_password'].value) {
                setMatchPassword(true)
            }
            else {
                setMatchPassword(false);
                let obj = {
                    old_password: form['old_password'].value,
                    new_password: form['new_password'].value
                }
                changePassword(obj).then(res => {
                    if (res && res.data.Message) {
                        NotificationManager.success(res.data.Message,'',2000);
                        form.reset();
                        setDisableButton(true)
                    }
                    else {
                        NotificationManager.error("Please enter correct old password!",'',2000);
                    }
                })
            }
        }
        else {
            setValidated(true);
        }
    }
    return (
        <div>
            <NotificationContainer />
            <div className="Login">
                <Form noValidate validated={validated} ref={ChangePasswordForm}>
                    <h5>Change Password</h5>
                    <Form.Group size="md" controlId="email">
                        <Form.Label>Enter Old Password</Form.Label>
                        <Form.Control
                            autoFocus
                            type="password" name="old_password" required onChange={(e) => {
                                setDisableButton(false)
                                const value = e.target.value;

                                if (value.length <= 0) {
                                    setDisableButton(true)
                                }
                                if (ChangePasswordForm.current['new_password'].value.length > 0 || ChangePasswordForm.current['confirm_password'].value.length > 0) {
                                    setDisableButton(false)
                                }
                            }
                            }
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide an old password.
                              </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group size="md" controlId="password">
                        <Form.Label>Enter New Password</Form.Label>
                        <Form.Control type="password" name="new_password" required
                            onChange={(e) => {
                                setDisableButton(false)
                                const value = e.target.value;
                                if (value.length <= 0) {
                                    setDisableButton(true)
                                }
                                if (ChangePasswordForm.current['old_password'].value.length > 0) {
                                    setDisableButton(false)
                                }
                            }
                            }
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a new password.
                              </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group size="md" controlId="confirm_password">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" name="confirm_password" required
                            onChange={(e) => {
                                setDisableButton(false)
                                const value = e.target.value;

                                if (value.length <= 0) {
                                    //setDisableButton(true)
                                    setMatchPassword(false);

                                }
                                if (ChangePasswordForm.current['confirm_password'].value.length > 0) {
                                    setDisableButton(false)
                                }
                            }
                            }
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a confirm password.
                              </Form.Control.Feedback>
                        {
                            matchPassword &&
                            <span className="text-danger">The passwords do not match</span>
                        }
                    </Form.Group>


                    <Row className="mt-3">
                        <Col xs={6}>
                            <Button block size="md" type="submit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    ChangePasswordForm.current.reset();
                                    setValidated(false);
                                    setDisableButton(true);
                                    setMatchPassword(false);

                                }}
                                disabled={disableButtons}>
                                clear
                            </Button>
                        </Col>
                        <Col xs={6}>
                            <Button block size="md" type="submit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleSubmit(e)
                                }
                                }
                                disabled={disableButtons}>
                                Change Password
                         </Button>
                        </Col>
                    </Row>

                </Form>
            </div>
        </div >
    )
}