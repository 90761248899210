import { useContext, useState } from "react";
import { PageContext } from "../Remote";

export const ConnectionStatus = ({ child1, child2, child3, setFps, fps }) => {
  const context = useContext(PageContext);
  const { email, deviceName } = context.pageState.data;

  return (
    <div>
      <div className="row liveControl ">
        <div className="col-12 col-md-4 border border-primary d-flex flex-column">
          <div className="border border-danger my-4">{child3}</div>
          <div className="border border-dark  my-4">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>Connected Status:</td>
                  <td>{context && context.pageState.device_substate}</td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>{context && email}</td>
                </tr>
                <tr>
                  <td>Device Name:</td>
                  <td>{context && deviceName}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="border border-warning  my-4">{child1}</div>
          <div className="dropdown  my-4">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              Live FPS
            </button>
            <div className="dropdown-menu">
              <a
                className="dropdown-item"
                href="#"
                onClick={() => context.pageDispatcher.set_live_fps(100)}
              >
                Fast
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => context.pageDispatcher.set_live_fps(500)}
              >
                Medium
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => context.pageDispatcher.set_live_fps(1000)}
              >
                Slow
              </a>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-8 border border-success">{child2}</div>
      </div>
    </div>
  );
};
