import { handleAbortState } from "../Service";
import { NotificationContainer, NotificationManager } from 'react-notifications';

export const RemoteUserConnected = () => {
    return (
        <div>
            <h3>Remote User Connected...</h3>
            <br />
            <button type="button" className="btn btn-danger btn-block"
                onClick={() => handleAbortState().then(res => {
                    if (res.data && res.data.msg) {
                        NotificationManager.success(res.data.msg, '', 2000);
                    }
                })}
            >Abort</button>
        </div>
    )
}