import React, { useState, useContext, lazy, Suspense, useEffect } from "react";

import CommonAccordion from "../../../../shared/Accordion";
import { PageContext } from "../../CaseViewer";
import { LoaderComponent } from "../../../../GridCapture/Register/Components/Loader";
import AnnotationList from "./AnnotationList";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { addComment, deleteComment, getComment } from "../../../Service";
import { NotificationManager } from "react-notifications";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineStop } from "react-icons/ai";
import MLcard from "./MLcard";
import AiRun from "./AiRun";

// const AnnotationList = lazy(() => import("./AnnotationList"));
const GridAnnotation = lazy(() => import("./GridAnnotation"));
const MultiChoiceQuestionAndAnswer = lazy(() => import("./MultiChoiceQuestionAndAnswer"));
const ScaleUpdate = lazy(() => import("./ScaleUpdate"));
const ScreenShotCaseSlide = lazy(() => import("./ScreenShotCaseSlide"));
const TileLayerStyleControls = lazy(() => import("./TileLayerStyleControls"));

const Setting = () => {
  return (
    <>
      <Card.Body style={{ maxHeight: "500px", overflowY: "auto", maxWidth: "350px" }}>
        <div id="tilestyles">
          <div className="d-flex justify-content-between align-items-center">
            <Suspense fallback={<LoaderComponent />}>
              <ScaleUpdate />
            </Suspense>
            <Suspense fallback={<LoaderComponent />}>
              <ScreenShotCaseSlide />
            </Suspense>
          </div>
          <hr className="m-1" />
          <CommonAccordion
            title="Color Correction"
            displayItem={
              <Suspense fallback={<LoaderComponent />}>
                <TileLayerStyleControls />
              </Suspense>
            }
            e="0"
          />
          <hr className="m-1" />
          <Suspense fallback={<LoaderComponent />}>
            <GridAnnotation />
          </Suspense>
        </div>
      </Card.Body>
    </>
  );
};

const CommonData = ({ handleShow }) => {
  const { state } = useContext(PageContext);
  const { id } = state.selected_slide;
  // const { annotationId } = state.annotation_list[0];

  return (
    <>
      <CommonAccordion
        title="Setting"
        displayItem={<Setting />}
        e="0"
      />
      <CommonAccordion
        title="Diagnosis"
        displayItem={
          id ? (
            <Suspense fallback={<LoaderComponent />}>
              <MultiChoiceQuestionAndAnswer />
            </Suspense>
          ) : (
            <>Click on slide to view Question and Options</>
          )
        }
        e="1"
      />
      <CommonAccordion
        title="Annotation"
        // displayItem={id ? <Suspense fallback={<LoaderComponent />}><AnnotationList /></Suspense> : <>Click on slide to view AnnotationList</>}
        displayItem={id ? <AnnotationList handleShow={handleShow} /> : <>Click on slide to view AnnotationList</>}
        e="2"
        // verticalScroll="Y"
      />
      {/* <CommonAccordion
        title="ML Card"
        displayItem={
          id ? (
            <Suspense fallback={<LoaderComponent />}>
              <MLcard />
            </Suspense>
          ) : (
            <>Click on slide to view data</>
          )
        }
        e="3"
      /> */}
      <CommonAccordion
        title="AI Run"
        displayItem={
          id ? (
            <Suspense fallback={<LoaderComponent />}>
              <AiRun />
            </Suspense>
          ) : (
            <>Click on slide to view data</>
          )
        }
        e="4"
      />
    </>
  );
};

const RightSideFeature = () => {
  const { pageDispatcher } = useContext(PageContext);
  const [showExportModal, setShow] = useState(false);
  const [annotationData, setAnnotationData] = useState({});
  const handleClose = () => {
    setShow(false);
    setAnnotationData({});
    pageDispatcher.set_comment_list([]);
  };
  const handleShow = (data) => {
    setAnnotationData(data);
    setShow(true);
  };

  const mediaQuery = window.matchMedia("(max-width: 460px)");
  let defaultActiveKeyValue;
  // Check if the media query matches
  if (mediaQuery.matches) {
    // Execute code for small screens
    defaultActiveKeyValue = "1";
  } else {
    // Execute code for larger screens
    defaultActiveKeyValue = "0";
  }

  return (
    <div className="right-panel-container">
      <CommonAccordion
        title="Tools"
        displayItem={<CommonData handleShow={handleShow} />}
        e="0"
        key={defaultActiveKeyValue}
      />
      <CommentModal
        showExportModal={showExportModal}
        handleClose={handleClose}
        handleShow={handleShow}
        annotationData={annotationData}
      />
    </div>
  );
};

const CommentModal = ({ showExportModal, handleClose, annotationData }) => {
  const [commentText, setCommentText] = useState();
  const { state, pageDispatcher } = useContext(PageContext);
  const slide_token = state.selected_slide.token;

  const handleCommentSubmit = async () => {
    const obj = {
      annotation_id: annotationData.annotationId,
      type_of: "text",
      content: commentText,
    };
    try {
      const res = await addComment(slide_token, obj);
      if (res.data.Message) NotificationManager.success(res.data.Message, "", 2000);
      let newComment = obj;
      newComment["comment_id"] = res.data.comment_id;
      const created = convertEpochTime(res.data.created);
      newComment["created"] = created;
      newComment["username"] = res.data.username;
      const updatedCommentList = [...state.comment_list, newComment];
      pageDispatcher.set_comment_list(updatedCommentList);
      setCommentText("");
    } catch (error) {
      console.log(error);
    }
  };

  const formattedDate = convertEpochTime(annotationData.created);

  const fetchCommentList = async (token) => {
    let obj = {
      annotation_id: annotationData.annotationId,
    };
    try {
      const result = await getComment(token, obj);
      let CommentList = result.data.comment_list;
      CommentList.sort((a, b) => a.created - b.created);
      const transformedComments = CommentList.map((each) => {
        const comment_id = each.comment_id;
        const annotation_id = each.annotation_id;
        const type_of = each.type_of;
        const content = each.content;
        const username = each.username;
        const created = convertEpochTime(each.created);

        return {
          comment_id,
          annotation_id,
          type_of,
          content,
          username,
          created,
        };
      });
      pageDispatcher.set_comment_list(transformedComments);
      // setDataLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (annotationData && Object.keys(annotationData).length > 0) {
      fetchCommentList(slide_token);
    }
  }, [annotationData]);

  return (
    <>
      <Modal
        show={showExportModal}
        onHide={handleClose}
        size="xl"
      >
        <Modal.Body>
          <div>
            <div className="d-flex justify-content-between">
              <p style={{ fontStyle: "italic" }}>
                Created by: <span style={{ fontWeight: "bold" }}>{annotationData.username}</span>
              </p>
              <p style={{ fontWeight: "bold" }}>{annotationData.label}</p>
              <p style={{ fontStyle: "italic" }}>Date: {formattedDate}</p>
            </div>
            <Form.Control
              as="textarea"
              placeholder="Your Comment"
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              className="mb-2"
              style={{ height: "150px" }}
            />
            <div className="text-right">
              <Button
                variant="primary"
                size="sm"
                onClick={handleCommentSubmit}
              >
                Add Comment
              </Button>
            </div>

            <p className="mb-0 mt-2">{state.comment_list.length} Comments:</p>
            <hr className="mb-1 mt-0" />
            <div style={{ maxHeight: "600px", overflowY: "auto" }}>
              {state.comment_list?.map((comment) =>
                comment.content === "comment deleted" ? (
                  <DeleteCard
                    key={comment.comment_id}
                    data={comment}
                  />
                ) : (
                  <CommentCard
                    key={comment.comment_id}
                    data={comment}
                  />
                )
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const CommentCard = ({ data }) => {
  const { state, pageDispatcher } = useContext(PageContext);
  const { token } = state.selected_slide;

  const onDeleteComment = async () => {
    const obj = {
      comment_id: data.comment_id,
    };
    try {
      await deleteComment(token, obj);
      const updatedComments = state.comment_list.map((comment) => {
        if (comment.comment_id === data.comment_id) {
          return { ...comment, content: "comment deleted" };
        }
        return comment;
      });
      pageDispatcher.set_comment_list(updatedComments);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Card
        className="mb-2 p-1"
        key={data.comment_id}
        style={{ border: "2px solid #ccc" }}
      >
        <div className="d-flex justify-content-between">
          <p
            className="mb-0"
            style={{ fontWeight: "bold" }}
          >
            {data.username}
          </p>
          <p
            className="mb-0"
            style={{ fontSize: "12px", color: "#757575" }}
          >
            {data.created}
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="mb-0">{data.content}</p>
          {(state.username_cookie == data.username || state.username_cookie == "Admin") && (
            <button
              onClick={() => onDeleteComment(data.comment_id)}
              className="align-self-end m-1"
              style={{ cursor: "pointer", minWidth: "20px", border: "none", background: "none" }}
            >
              <RiDeleteBin6Line />
            </button>
          )}
        </div>
      </Card>
    </div>
  );
};

const DeleteCard = ({ data }) => {
  return (
    <div>
      <Card
        className="mb-2 p-1"
        key={data.comment_id}
        style={{ border: "2px solid #ccc" }}
      >
        <div className="d-flex justify-content-between">
          <p
            className="mb-0"
            style={{ fontStyle: "italic", color: "#757575" }}
          >
            <AiOutlineStop /> {data.content}
          </p>
          <p
            className="mb-0"
            style={{ fontSize: "12px", color: "#757575" }}
          >
            <span>{data.username}, </span>
            {data.created}
          </p>
        </div>
      </Card>
    </div>
  );
};

const convertEpochTime = (epoch) => {
  const secondsSince1970 = epoch * 1000;
  const date = new Date(secondsSince1970);
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return date.toLocaleString(undefined, options);
};

export default RightSideFeature;
