import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { PageContext } from "../../CaseViewer";
import { CancelButton, SaveButton } from "../updateSlide/Buttons";

const GridWidth = () => {
  const { state, pageDispatcher } = useContext(PageContext);
  const { xInput } = state;

  const [isEditing, setIsEditing] = useState(false);
//   const [gridWidth, setGridWidth] = useState(xInput);
  const [gridWidth, setGridWidth] = useState(localStorage.getItem("x"));
  const [originalGridWidth, setOriginalGridWidth] = useState(localStorage.getItem("x"));
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    setGridWidth(localStorage.getItem("x"));
  }, [localStorage.getItem("x")]);

  const handleGridWidthChange = (event) => {
    setGridWidth(event.target.value);
  };

  const handleEditButtonClick = () => {
    setOriginalGridWidth(gridWidth);
    setIsEditing(true);
  };

  const handleSaveButtonClick = async () => {
    pageDispatcher.set_xInput(gridWidth);
    localStorage.setItem("x", gridWidth);
    setIsEditing(false);
  };

  const handleCancelButtonClick = () => {
    setGridWidth(originalGridWidth);
    setIsEditing(false);
  };

  return (
    <div className="mb-1">
      {isEditing ? (
        <div>
          <input
            type="number"
            value={gridWidth}
            // value={localStorage.getItem("x")}
            onChange={handleGridWidthChange}
          />
          <Button
            variant="outline-primary"
            size="sm"
            onClick={handleSaveButtonClick}
            className="mx-1"
          >
            <SaveButton />
          </Button>
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={handleCancelButtonClick}
            className="mx-1"
          >
            <CancelButton />
          </Button>
        </div>
      ) : (
        <div className="d-flex justify-content-start align-items-center">
          {/* <span>width: {gridWidth} µm</span> */}
          <span>width: {localStorage.getItem("x")}µm</span>
          <sup>
            <MdOutlineModeEditOutline
              size={15}
              className={isHovered ? "edit-icon hovered" : "edit-icon"}
              onMouseEnter={handleHover}
              onMouseLeave={handleMouseLeave}
              onClick={handleEditButtonClick}
            />
          </sup>
        </div>
      )}
    </div>
  );
};

export default GridWidth;
