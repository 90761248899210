import React, { useContext, useEffect, useState } from "react";
import { checkRegistrationProcess, get_cam_status, initializeRegistration, restart_cam } from "../Service";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { PageContext } from "../Settings";
import { Button } from "react-bootstrap";
import { Organiz_name_input } from "./Organiz_name_input";

export const DeviceRegistration = () => {
  const [registrationStatus, setRegistrationStatus] = useState(false);
  const [deviceNameStatus, setdeviceNameStatus] = useState("");
  const [organiz_name, setOrganiz_name] = useState("");
  const { pageState, pageDispatcher } = useContext(PageContext);

  const organiz_name_callback = (data) => {
    setOrganiz_name(data);
  };

  const handleRegistration = async () => {
    setdeviceNameStatus("");
    setRegistrationStatus(false);
    try {
      NotificationManager.info("Sending Request....", "Notification", 2000);
      let object = {
        organiz_name: organiz_name,
      };
      const res = await initializeRegistration(object);
      NotificationManager.success("Request Sent", "Notification", 2000);
      setRegistrationStatus(true);
    } catch (error) {
      NotificationManager.error("Something went wrong ", "Notification", 2000);
      console.log(error);
    }
  };

  const checkRegistrationStatus = async () => {
    try {
      const res = await checkRegistrationProcess();
      NotificationManager.success("Name Updated Successfully", "Notification", 2000);
      localStorage.setItem("device_name", res.data.device_name);
      localStorage.setItem("isNameMisMatched", false);
      pageDispatcher.set_Device_Name(res.data.device_name);
      pageDispatcher.set_is_Device_name_misMatched(false);
      setdeviceNameStatus(res.data.device_name);
    } catch (error) {
      NotificationManager.error("Something went wrong ", "Notification", 2000);
      setdeviceNameStatus("");
      console.log(error);
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <NotificationContainer />
      <div
        className="d-flex flex-column "
        style={{ display: "table", borderCollapse: "collapse", width: "60%" }}
      >
        {pageState.registered_device_Name && !pageState.is_Device_name_misMatched && (
          <div
            className="d-flex flex-column"
            style={{ display: "table-row", backgroundColor: "lightgray" }}
          >
            <div
              className="d-flex align-items-start"
              style={{ display: "table-cell", border: "1px solid #000", padding: "8px" }}
            >
              <h3 className="p-3">
                Device Name: <span style={{ color: "green" }}>{pageState.registered_device_Name}</span>
              </h3>
            </div>
            <div
              className="d-flex "
              style={{ display: "table-cell", border: "1px solid #000", padding: "8px" }}
            >
              <h3 className="p-3">
                To Rename your Device Name:{" "}
                <span>
                  <Button onClick={handleRegistration}>click</Button>
                </span>
              </h3>
              <div className="d-flex align-items-center">
                <Organiz_name_input organiz_name_callback={organiz_name_callback} />
              </div>
            </div>
          </div>
        )}

        {pageState.is_Device_name_misMatched && (
          <div
            className="d-flex flex-column"
            style={{ display: "table-row", backgroundColor: "lightgray" }}
          >
            <div
              className="d-flex align-items-start"
              style={{ display: "table-cell", border: "1px solid #000", padding: "8px" }}
            >
              <h3 className="p-3">
                Device Name <span style={{ color: "red" }}>Mismatched</span>{" "}
              </h3>
            </div>
            <div
              className="d-flex "
              style={{ display: "table-cell", border: "1px solid #000", padding: "8px" }}
            >
              <h3 className="p-3">
                To Rename your Device Name:{" "}
                <span>
                  <Button onClick={handleRegistration}>click</Button>
                </span>
              </h3>
              <div className="d-flex align-items-center">
                <Organiz_name_input organiz_name_callback={organiz_name_callback} />
              </div>
            </div>
          </div>
        )}

        {!pageState.registered_device_Name && !pageState.is_Device_name_misMatched && (
          <div
            className="d-flex flex-column"
            style={{ display: "table-row", backgroundColor: "lightgray" }}
          >
            <div style={{ display: "table-cell", border: "1px solid #000", padding: "8px", color: "red" }}>
              <h3 className="p-3">Your Device is not Registered</h3>
            </div>
            <div style={{ display: "table-cell", border: "1px solid #000", padding: "8px" }}>
              <h3 className="p-3">
                To Register your Device Name:{" "}
                <span>
                  <Button onClick={handleRegistration}>click</Button>
                </span>
                <div className="d-flex align-items-center">
                  <Organiz_name_input organiz_name_callback={organiz_name_callback} />
                </div>
              </h3>
            </div>
          </div>
        )}

        {registrationStatus && (
          <div
            className="d-flex flex-column"
            style={{ display: "table-row", backgroundColor: "lightgray" }}
          >
            <div
              className="d-flex align-items-start"
              style={{ display: "table-cell", border: "1px solid #000", padding: "8px" }}
            >
              <h3 className="p-3">
                Get Registration Status:{" "}
                <span>
                  <Button onClick={checkRegistrationStatus}>click</Button>
                </span>
              </h3>
            </div>
          </div>
        )}

        {deviceNameStatus && (
          <div
            className="d-flex flex-column"
            style={{ display: "table-row", backgroundColor: "lightgray" }}
          >
            <div
              className="d-flex align-items-start"
              style={{ display: "table-cell", border: "1px solid #000", padding: "8px", color: "green" }}
            >
              <h3 className="p-3">Your Device's Name Updated Successfully</h3>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
