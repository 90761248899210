import React, { useContext } from "react";
import { PageContext } from "./GridView";

const MainImage = () => {
  const { state } = useContext(PageContext);
  const { main_img } = state;

  return (
    <>
      {main_img ? (
        <div
          id="zoom"
          className=" mr-5"
        >
          <img
            src={main_img}
            className="selected-image"
            alt="main_img"
          />
        </div>
      ) : (
        <div
          id="zoom"
          className="mr-5"
        >
          <h6>click on Thumbnail-Image to display main image</h6>
        </div>
      )}
    </>
  );
};

export default MainImage;
