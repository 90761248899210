import "ol/ol.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { getCanvasData, handleAbortedState, handleplaceSlideState } from "../../Service";
import { Abort } from "./AbortStates";
import SingleSlot from "./SingleSlot";
import { PageContext } from "../StitchSlide";
import { FcProcess } from "react-icons/fc";
import { GoCheck } from "react-icons/go";
import { BiErrorCircle } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
import { FaPauseCircle } from "react-icons/fa";

export const Canvas = ({ device_task }) => {
  const [key, setKey] = useState("slot1");
  const [totalSlot, setTotalSlot] = useState(1);
  const [ui, setUI] = useState(true);

  const { pageState: StitchSlideState, pageDispatcher: StitchSlidePageDispatcher } = useContext(PageContext);
  let slot_status_Array = StitchSlideState?.slot_status;
  let slot_data_Array = StitchSlideState?.slot_data;
  let current_active_slot_status = StitchSlideState?.current_active_slot_status;
  const shouldRenderButton = slot_status_Array.every((value) => [3, 7, -1].includes(value));

  const slotArray = [...Array(totalSlot).keys()].map((num) => num + 1);

  const fetchData = async () => {
    try {
      const canvasDataTotalSlot = await getCanvasData();
      const { total_slot } = canvasDataTotalSlot?.data;
      setTotalSlot(total_slot);
      setUI(false);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.log("error.response:", error.response.data.existing_module_type);
        StitchSlidePageDispatcher.set_running_module_name(error.response.data.existing_module_type);
        setUI(true);
      } else {
        console.error("Error fetching canvas data:", error);
      }
    }
  };

  const audioElement1 = useRef(null);
  const audioElement2 = useRef(null);

  useEffect(() => {
    if (shouldRenderButton) {
      const audio1 = audioElement1.current;
      if (audio1) {
        audio1.play().catch((err) => {});

        const handleAudioEnded = () => {
          const audio2 = audioElement2.current;
          if (audio2) {
            audio2.play().catch((err) => {});
          }
        };

        audio1.addEventListener("ended", handleAudioEnded);
        return () => {
          audio1.removeEventListener("ended", handleAudioEnded);
        };
      }
    }
  }, [shouldRenderButton]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div style={{ marginTop: "12px" }}>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            {slotArray.map((slot, index) => (
              <Tab
                eventKey={`slot${slot}`}
                title={
                  <div
                    key={index}
                    className="d-flex align-items-center"
                  >
                    {`Slot_${slot}`}
                    <div className="ml-2">
                      {(slot_status_Array[index] === 0 || slot_status_Array[index] === 1) && <FaPauseCircle />}
                      {slot_status_Array[index] === 2 && <FcProcess />}
                      {slot_status_Array[index] === 3 && <GoCheck />}
                      {slot_status_Array[index] === -1 && <BiErrorCircle />}
                      {slot_status_Array[index] === 7 && <MdCancel />}
                    </div>
                  </div>
                }
                key={index}
              >
                <SingleSlot
                  slot_title={`slot${slot}`}
                  slot_number={slot}
                  slot_status={slot_status_Array[index]}
                  slot_data={slot_data_Array[index]}
                />
              </Tab>
            ))}
          </Tabs>
        </div>

        {!shouldRenderButton && (
          <div className="d-flex flex-row justify-content-center align-items-center">
            <div
              className="me-5 shift-left"
              style={{ marginTop: "10px" }}
            >
              <Abort />
            </div>

            <div
              className="ms-5 shift-left"
              style={{ marginTop: "10px" }}
            >
              {device_task == 2 ? (
                <button
                  type="button"
                  className="btn btn-success btn-block me-2"
                  onClick={() =>
                    handleplaceSlideState().then((res) => {
                      if (res.data.msg) {
                        NotificationManager.success(res.data.msg, "", 2000);
                      }
                    })
                  }
                  style={{ width: "150px" }}
                >
                  Retake
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        )}

        {shouldRenderButton && (
          <div>
            <audio
              id="audio1"
              ref={audioElement2}
              src="/sound.mp3"
            />
            <audio
              id="audio2"
              ref={audioElement1}
              src="/notification.mp3"
            />
            <h3>Capture Over.</h3>
            <h3>Please take the slide</h3>
            <div
              className="col-md-4 offset-md-4"
              style={{ marginTop: "45px" }}
            >
              <button
                className="btn btn-primary btn-block"
                onClick={() => handleAbortedState()}
              >
                Ok
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
