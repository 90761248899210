import { createContext } from 'react'
import axios from 'axios';
import { environment } from "../../environment";
import { getToken } from ".././Utils/Common";
const baseURL = environment.MICALYS_POSTGRES_API

export const pageReducer = (state, action) => {
    switch (action.type) {

        case 'set_componentToRender':
            return {
                ...state, componentToRender: action.payload
            }
        case 'set_componentToRenderInImagePages':
            return {
                ...state, componentToRenderInImagePages: action.payload
            }
        case 'set_showAbortWithLoader':
            return {
                ...state, showAbortWithLoader: action.payload
            }
        case 'set_timeInterval':
            return {
                ...state, timeInterval: action.payload
            }
        case 'set_isDeviceError':
            return {
                ...state, isDeviceError: action.payload
            }
        case 'set_errorMSG':
            return {
                ...state, errText: action.payload
            }
        default:
            return state
    }
}

export const handleInitialState = () => {
    return axios.get(baseURL + 'get_device_state', { headers: { Authorization: `Bearer ${getToken()}` } })
}
export const handleMaintenanceState = () => {
    return axios.get(baseURL + '107', { headers: { Authorization: `Bearer ${getToken()}` } })
}
export const handleCommand = async (obj) => {
    return await axios.post(baseURL + 'maint_cmd', obj, { headers: { Authorization: `Bearer ${getToken()}` } })
}
export const handleAbortState = () => {
    return axios.get(baseURL + '503', { headers: { Authorization: `Bearer ${getToken()}` } })
}
export const handleDeviceLog = () => {
    return axios.get(baseURL + 'get_device_log', { headers: { Authorization: `Bearer ${getToken()}` } })
}

export const PageContext = createContext(null)
