import { NotificationContainer, NotificationManager } from "react-notifications";
import { handleMaintenanceState } from "../Service";
import { set } from "ol/transform";
import { setCustomRoute } from "../../Utils/Common";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

export const EnterMaintenance = () => {
  return (
    <>
      <div className="d-flex justify-content-start">
        <Nav.Link
          as={Link}
          to="/settings"
        >
          <div className="back-button mt-3">
            <BiArrowBack size={30} />
          </div>
        </Nav.Link>
      </div>

      <div
        className="col-md-4 offset-md-4 text-center"
        style={{ marginTop: "10px" }}
      >
        <button
          className="btn btn-primary btn-block"
          onClick={() => {
            handleMaintenanceState().then((res) => {
              setCustomRoute("Maintenance Mode");
              if (res.data.msg) {
                NotificationManager.success(res.data.msg, "", 2000);
              }
            });
          }}
        >
          Enter
        </button>
      </div>
    </>
  );
};
