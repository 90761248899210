import { useContext } from "react";
import { getSelectedChipset } from "../../../Utils/Common";
import { PageContext } from "../SlideGridCapture";

export const GridModuleMismatchWarning = ({running_module_type}) => {
  console.log("GridModuleMismatchWarning:",GridModuleMismatchWarning)
  const { pageState, pageDispatcher } = useContext(PageContext);


  return (
    <div className="parentContainer">
      <div className="boxStyle">
        <h1>The {running_module_type} module is currently active.</h1>
        <br />
        <p>
          Please stop/Abort the {running_module_type} module before switching to the {getSelectedChipset()} module.
        </p>
      </div>
    </div>
  );
};
