import React from 'react'

const Captured = () => {

  return (
    <div className='boxStyle'>
      <h1>Captured</h1>
    </div>
  )
}

export default Captured
