import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Accordion, Button, Card, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";
import { TbCapture, TbFileImport } from "react-icons/tb";
import { NotificationManager } from "react-notifications";
import { Link, useParams } from "react-router-dom";
import { environment } from "../../../../../environment";
import { getToken, getUser } from "../../../../Utils/Common";
import { PageContext } from "../../CaseViewer";
import { AddUploadCaseSlide } from "./AddUploadCaseSlide";
import CaseDetailCard from "./CaseDetailCard";
import DeletedSlideCard from "./DeletedSlideCard";
import SlideInfoCard from "./SlideInfoCard";

const baseURL = environment.MICALYS_POSTGRES_API;

const LeftSideFeature = () => {
  const { state, pageDispatcher } = useContext(PageContext);
  const [showExportModal, setShow] = useState(false);
  const [exportSlideId, setExportSlideId] = useState("");
  const [exportSlideToken, setExportSlideToken] = useState("");
  const [selectedFormat, setFormat] = useState("tif");
  const [hideDeletedSlide, setHideDeletedSlide] = useState(true);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [slideList, setSlideList] = useState();
  // {console.log("slideList:",slideList)}
  const changeFormat = (value) => {
    setFormat(value);
  };

  const { case_name, case_owner } = state.case_info;

  const handleClose = () => {
    exportSlide(exportSlideId, selectedFormat, exportSlideToken);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  // console.log('case_owner',case_owner)

  const [showUploadComponent, setShowUploadComponent] = useState(false);
  const { id } = useParams();

  const selectedSlideView = state.selected_slide.id;

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setHideDeletedSlide(checked);
  };

  useEffect(() => {
    setSlideList(state?.slide_list);
  }, [state?.slide_list]);

  const filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = state?.slide_list?.filter((list) => {
        return list.slide_name.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setSlideList(results);
    } else {
      setSlideList(state?.slide_list);
    }

    setSearchKeyWord(keyword);
  };

  return (
    <div className="left-panel-container">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Card>
            <div className="left-panel-card">
              {case_owner && (
                <div className="capure-container">
                  <OverlayTrigger
                    key={`capture-slide-${id}`} 
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-capture-${id}`}>
                        <strong>Capture Slide</strong>
                      </Tooltip>
                    }
                  >
                    <Link
                      to={`/slidestitch/${id}/${case_name}`}
                      style={{ textDecoration: "none", paddingBottom: "30%" }}
                    >
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="mr-1"
                      >
                        <TbCapture />
                      </Button>
                    </Link>
                  </OverlayTrigger>
                  {state.modalVisible && <AddUploadCaseSlide uploadShow={{ showUploadComponent, setShowUploadComponent }} />}
                </div>
              )}

              {case_owner && (
                <div className="import-container">
                  <OverlayTrigger
                    key={`import-slide-${id}`} 
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-import-${id}`}>
                        <strong>Import Slide</strong>
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => {
                        setShowUploadComponent(false);
                        pageDispatcher.set_modalVisible(true);
                      }}
                      className="mx-1"
                    >
                      <TbFileImport />
                    </Button>
                  </OverlayTrigger>
                </div>
              )}

              <Accordion.Header>{case_name.length > 8 ? case_name.slice(0, 8) + "..." : case_name}</Accordion.Header>
            </div>

            <Accordion.Collapse eventKey="0">
              <Card.Body className="case-card-container">
                <CaseDetailCard />
                <>
                  <Form>
                    <Row className="d-flex align-items-center justify-content-between">
                      <Col>
                        <Form.Control
                          placeholder="search slide name"
                          value={searchKeyWord}
                          onChange={filter} 
                        />
                      </Col>
                      <Col>
                        {getUser() === "Admin" ? (
                          <Form.Check
                            type="checkbox"
                            onChange={handleCheckboxChange}
                            checked={hideDeletedSlide}
                            label="Hide Deleted Slide"
                            style={{ display: "flex", alignItems: "center" }}
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </Form>
                </>
                <div style={{ maxHeight: "510px", overflowY: "auto" }}>
                  {hideDeletedSlide
                    ? slideList
                        ?.filter((list) => list.delete_slide !== 2)
                        .map((list) => (
                          <SlideInfoCard
                            key={list.slide_id}
                            list={list}
                            handleShow={handleShow}
                            setExportSlideId={setExportSlideId}
                            setExportSlideToken={setExportSlideToken}
                          />
                        ))
                    : slideList?.map((list) =>
                        list.delete_slide !== 2 ? (
                          <SlideInfoCard
                            key={list.slide_id}
                            list={list}
                            handleShow={handleShow}
                            setExportSlideId={setExportSlideId}
                            setExportSlideToken={setExportSlideToken}
                          />
                        ) : (
                          <DeletedSlideCard
                            key={list.slide_id}
                            list={list}
                            selectedSlideView={selectedSlideView}
                          />
                        )
                      )}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion.Item>
      </Accordion>
      <ExportModal
        exportSlideId={{ exportSlideId, setExportSlideId }}
        selectedValue={selectedFormat}
        changeFormat={changeFormat}
        showExportModal={showExportModal}
        handleClose={handleClose}
        handleShow={handleShow}
      />
    </div>
  );
};

const ExportModal = (props) => {
  const context = useContext(PageContext);
  const handleChange = (e) => {
    props.changeFormat(e.target.value);
  };

  // .exportSlideId

  const handleSubmit = () => {
    // let currentIndex = parseInt(localStorage.currentIndex);
    // console.log('state',context.state)
    // console.log(props.exportSlideId, props.selectedValue)
    // context.state.export_Slide(props.exportSlideId.exportSlideId, props.selectedValue);
    // context.state.getSlideRecords(context.slide_name, currentIndex, context.number_of_items, props.statusExists);
    props.handleClose();
  };

  return (
    <>
      <Modal
        show={props.showExportModal}
        onHide={props.handleClose}
      >
        <Modal.Header>
          <Modal.Title>Select a Format</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <div>
              <label>
                <input
                  checked={props.selectedValue == "tif"}
                  onChange={handleChange}
                  value="tif"
                  name="format"
                  type="radio"
                />{" "}
                Tiff{" "}
              </label>
            </div>
            <div>
              <label>
                <input
                  checked={props.selectedValue == "zip"}
                  onChange={handleChange}
                  value="zip"
                  name="format"
                  type="radio"
                />{" "}
                Zoomify{" "}
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleSubmit}
          >
            Select
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const exportSlide = (slide_id, exportType, exportSlideToken) => {
  let obj = {
    // slide_id: slide_id.exportSlideId,
    slide_id: slide_id,
    export_type: exportType,
  };
  axios
    .post(baseURL + "export_this_slide", obj, { headers: { Authorization: `Bearer ${exportSlideToken}` } })
    .then(async (res) => {
      if (res && res.data.Message) {
        NotificationManager.success(res.data.Message, "", 2000);
      }
    })
    .catch((e) => {
      alert("Not Authorised");
    });
};

export default LeftSideFeature;

{
  /* {hideDeletedSlide
                  ? state?.slide_list
                      ?.filter((list) => list.delete_slide !== 2)
                      .map((list) => (
                        <SlideInfoCard
                          list={list}
                          handleShow={handleShow}
                          setExportSlideId={setExportSlideId}
                        />
                      ))
                  : state?.slide_list?.map((list) =>
                      list.delete_slide !== 2 ? (
                        <SlideInfoCard
                          list={list}
                          handleShow={handleShow}
                          setExportSlideId={setExportSlideId}
                        />
                      ) : (
                        <DeletedSlideCard
                          list={list}
                          selectedSlideView={selectedSlideView}
                        />
                      )
                    )} */
}
{
  /* {state?.slide_list?.map((list) =>
                  list.delete_slide !== 2 ? (
                    <SlideInfoCard
                      list={list}
                      handleShow={handleShow}
                      setExportSlideId={setExportSlideId}
                    />
                  ) : (
                    <DeletedSlideCard
                      list={list}
                      selectedSlideView={selectedSlideView}
                    />
                  )
                )} */
}
