import React from 'react';
import { Navigate } from 'react-router-dom';
import { getToken, getSelectedChipset, getTypeOfSlidelide, getCustomRoute } from './Common';
import { PageNotFound } from './PageNotFound';

export const PrivateRoute = ({ component: Component }) => {
  return getToken() ? <Component /> : <Navigate to="/Login" />
}

export const HistoRoute = ({ component: Component }) => {
  // return getToken() && getCurrentModule() === "Histo" ? <Component /> : <Navigate to="/Login" />
console.log("HistoRoute::")
  if (getToken()) {
    if ((getTypeOfSlidelide() === ( "WSI" ||'Histo' ))) {
      return <Component />
    }
    else {
      // setCurrentModule("WSI");
      // return <Component />
      return <PageNotFound />
    }
  }
  else {
    return <Navigate to="/Login" />
  }
}

export const PBSRoute = ({ component: Component }) => {
  // return getToken() && getCurrentModule() === "PBS" ? <Component /> : <Navigate to="/Login" />
  if (getToken()) {
    if (getTypeOfSlidelide() === "PBS") {
      return <Component />
    }
  }
  else {
    return <Navigate to="/Login" />
  }
}
export const GridCaptureRoute = ({ component: Component }) => {
  console.log("666 GridCaptureRoute")

  // return getToken() && getCurrentModule() === "PBS" ? <Component /> : <Navigate to="/Login" />
  if (getToken()) {
    if ((getTypeOfSlidelide() === ("GC" || "Grid Capture"))) {
  console.log("666 had token GridCaptureRoute:",getTypeOfSlidelide())

      return <Component />
    }
    else {
      // setCurrentModule("Grid Capture");
      // return <Component />
      return <PageNotFound />
    }
  }
  else {
    return <Navigate to="/Login" />
  }
}

export const LiveControlRoute = ({ component: Component }) => {
  // return getToken() && getCurrentModule() === "Live Control" ? <Component /> : <Navigate to="/Login" />
  if (getToken()) {
    if ((getTypeOfSlidelide() === "livecontrol") ) {
      return <Component />
    }
    else {
      return <PageNotFound />
    }
  }
  else {
    return <Navigate to="/Login" />
  }

}
export const MaintenanceRoute = ({ component: Component }) => {
  // return getToken() && getCurrentModule() === "Live Control" ? <Component /> : <Navigate to="/Login" />
  if (getToken()) {
    // if (getCustomRoute() === "Maintenance Mode") {
      return <Component />
    // }
    // else {
      // setCurrentModule("Live Control");
      // return <Component />
      // return <PageNotFound />
    // }
  }
  else {
    return <Navigate to="/Login" />
  }

}



