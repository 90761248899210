import React, { memo, useContext, useEffect, useState } from "react";
import { PageContext } from "../Remote";
// import { getStreamStatus } from "../Service";
import { getFrame } from "../Service";

export const OvmLiveVedio = () => {
  const context = useContext(PageContext);
  const [streamStatus, setstreamStatus] = useState(true);
  const [currentTime, setcurrentTime] = useState({ ct: 0 });
  const [frame, setFrame] = useState({ frameImage: "" });

  useEffect(() => {
    const timeNow = new Date().getTime();

    console.log("fps:", context.pageState.liveFps);

    if (timeNow - currentTime.ct >= context.pageState.liveFps) {
      getFrame(timeNow)
        .then((res) => {
          if (res && res.data && res.data.byteLength > 0) {
            let blob = new Blob([res.data], {
              type: res.headers["content-type"],
            });
            let blobURL = URL.createObjectURL(blob);
            var img = new Image();
            img.src = blobURL;
            setFrame({ ...frame, frameImage: blobURL });
            setcurrentTime({ ...currentTime, ct: timeNow });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const interval = setInterval(() => {
        console.log("fps:", context.pageState.liveFps);
        getFrame(timeNow)
          .then((res) => {
            if (res && res.data && res.data.byteLength > 0) {
              let blob = new Blob([res.data], {
                type: res.headers["content-type"],
              });
              let blobURL = URL.createObjectURL(blob);
              var img = new Image();
              img.src = blobURL;
              setFrame({ ...frame, frameImage: blobURL });
              setcurrentTime({ ...currentTime, ct: timeNow });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, context.pageState.liveFps - (timeNow - currentTime.ct));
      return () => clearInterval(interval);
    }
  }, [frame, context.pageState.liveFps]);

  return (
    <>
      {streamStatus === true ? (
        <img
          className="p-0 m-0"
          height="75%"
          src={frame.frameImage}
          alt="frame"
        />
      ) : (
        <h3>Live Stream Connecting...</h3>
      )}
    </>
  );
};
