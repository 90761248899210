import React from "react";
import { FaArrowCircleDown } from "react-icons/fa";

const ScreenShotCaseSlide = () => {
  return (
    <div className="mr-2">
      <button
        type="button"
        className="btn btn-secondary"
        id="export-png"
        // onClick={handleScreenshot}
        // disabled={context.disableTools}
        style={{
          //   cursor: context && context.disableTools ? "not-allowed" : "pointer",
          backgroundColor: "rgba(0,60,136,0.5)",
          color: "white",
          padding: "0",
          fontSize: "1.14em",
          fontWeight: "bold",
          textAlign: "center",
          height: "1.375em",
          width: "1.375em",
          border: "none",
          borderRadius: "2px",
        }}
      >
        <FaArrowCircleDown
          aria-hidden="true"
          data-toggle="tooltip"
          data-placement="top"
          title="Download current view"
        />
      </button>
    </div>
  );
};

export default ScreenShotCaseSlide;
