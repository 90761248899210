import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getToken, getTypeOfSlidelide } from "../Utils/Common";

const InitialPath = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (getToken()) {
     if (
        window.location.pathname === "/" &&
        getTypeOfSlidelide() &&
        getTypeOfSlidelide() === ( "WSI" ||'Histo' )
      ) {
        navigate("/caselist");
      } else if (
        window.location.pathname === "/" &&
        getTypeOfSlidelide() &&
        getTypeOfSlidelide() === "GC"
      ) {
        navigate("/gridCaptureList");
      }
    }
    if (!getToken()) {
      navigate("/Login");
    }
  }, []);

  return null;
};

export default InitialPath;
