import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ImageCarousel from "./ImageCarousel";
import { AiOutlineDelete, AiOutlinePlus} from "react-icons/ai";
import { addGrossImage, getGrossImageList } from "../../../Service";
import { NotificationManager } from "react-notifications";

const ImageModal = ({ show, setShow, slide_token, ImageNameList ,setImageNameList, fetchGrossImageName }) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [fileCount, setFileCount] = useState(0);
  // const [uploadStatus, setUploadStatus] = useState("Choose files"); // Track upload status

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages(files);
    setFileCount(files.length);
    // setUploadStatus(`${files.length} file(s) selected`);
  };

  const handleUpload = async () => {
    if (selectedImages.length === 0) {
      alert("Please select at least one image to upload.");
      return;
    }

    const formData = new FormData();
    selectedImages.forEach((image) => {
      // Create a unique name by appending Date.now() to the file name
      const uniqueFileName = `${Date.now()}_${image.name}`;
      formData.append("image", image, uniqueFileName); // Pass the unique name here
    });

    try {
      const response = await addGrossImage(formData, slide_token);
      // console.log("Images uploaded successfully:", response.data);
      NotificationManager.success("Image uploaded successfully", "", 2000);
      // setUploadStatus("Image uploaded");
      // Reset selected images after successful upload
      setSelectedImages([]);
      // fetchGrossImageName(slide_token);
      setShow(false);
      setFileCount(0)
    } catch (error) {
      console.error("Error uploading images:", error.response ? error.response.data : error.message);
    }
  };

  const handleCloseModal = () => {
    setShow(false);
    setSelectedImages([]);
    setFileCount(0);
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Gross Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="file-upload-container mb-2">
          <label
            htmlFor="file-input"
            className="file-upload-label"
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            <AiOutlinePlus size={24} /> {/* Plus Icon */}
            {fileCount > 0 ? (
              <span style={{ marginLeft: "10px" }}>{fileCount} files selected</span>
            ) : (
              <span style={{ marginLeft: "10px" }}>Select Files</span>
            )}
          </label>
          <input
            id="file-input"
            type="file"
            multiple
            accept=".jpg,.png,.jpeg"
            onChange={handleImageChange}
            style={{ display: "none" }} // Hides the input
          />
        </div>
        {ImageNameList?.length > 0 ? (
          <ImageCarousel
            arrImage={ImageNameList}
            slide_token={slide_token}
            fetchGrossImageName={fetchGrossImageName}
            setImageNameList={setImageNameList}
            setShow={setShow}
          />
        ) : (
          ""
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleCloseModal}
        >
          Close
        </Button>
        <Button
          onClick={handleUpload}
          variant="primary"
        >
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageModal;
