import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { PageContext } from "../../CaseViewer";
import { CancelButton, SaveButton } from "../updateSlide/Buttons";

const GridHeight = () => {
  const { state, pageDispatcher } = useContext(PageContext);
  const { yInput } = state;

  const [isEditing, setIsEditing] = useState(false);
  const [gridHeight, setGridHeight] = useState(localStorage.getItem("y"));
  const [originalGridHeight, setOriginalGridHeight] = useState(localStorage.getItem("y"));
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    setGridHeight(localStorage.getItem("y"));
  }, [localStorage.getItem("y")]);

  const handleGridHeightChange = (event) => {
    setGridHeight(event.target.value);
  };

  const handleEditButtonClick = () => {
    setOriginalGridHeight(gridHeight);
    setIsEditing(true);
  };

  const handleSaveButtonClick = async () => {
    pageDispatcher.set_yInput(gridHeight);
    localStorage.setItem("y", gridHeight);
    setIsEditing(false);
  };

  const handleCancelButtonClick = () => {
    setGridHeight(originalGridHeight);
    setIsEditing(false);
  };

  return (
    <div className="mb-1">
      {isEditing ? (
        <div>
          <input
            type="number"
            value={gridHeight}
            // value={localStorage.getItem("y")}
            onChange={handleGridHeightChange}
          />
          <Button
            variant="outline-primary"
            size="sm"
            onClick={handleSaveButtonClick}
            className="mx-1"
          >
            <SaveButton />
          </Button>
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={handleCancelButtonClick}
            className="mx-1"
          >
            <CancelButton />
          </Button>
        </div>
      ) : (
        <div className="d-flex justify-content-start align-items-center">
          {/* <span>height: {gridHeight}µm</span> */}
          <span>height: {localStorage.getItem("y")}µm</span>
          <sup>
            <MdOutlineModeEditOutline
              size={15}
              className={isHovered ? "edit-icon hovered" : "edit-icon"}
              onMouseEnter={handleHover}
              onMouseLeave={handleMouseLeave}
              onClick={handleEditButtonClick}
            />
          </sup>
        </div>
      )}
    </div>
  );
};

export default GridHeight
