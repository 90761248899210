import React, { useEffect, useRef } from "react";
import { Abort } from "./AbortStates";
import { handleAbortedState } from "../../Service";
export const Capture = () => {
  return (
    <div>
      <h3>Please wait. Capturing overview.</h3>
      <br />
      <div
      className="d-flex justify-content-center"
        style={{ marginTop: "10px" }}
      >
        <Abort />
      </div>
    </div>
  );
};

export const CaptureOver = () => {
  const audioElement1 = useRef(null);
  const audioElement2 = useRef(null);

  useEffect(() => {
    var audio1 = audioElement1.current;
    audio1.play().catch((err) => {});

    audio1.addEventListener("ended", () => {
      setTimeout(function () {
        var audio2 = audioElement2.current;
        audio2.play().catch((err) => {});
      }, 10);
    });
  }, []);

  return (
    <div>
      <audio
        id="audio"
        ref={audioElement2}
        src="/sound.mp3"
      />
      <audio
        id="audio"
        ref={audioElement1}
        src="/notification.mp3"
      />
      <h3>Capture Over.</h3>
      <h3>Please take the slide</h3>
      <div
        className="col-md-4 offset-md-4"
        style={{ marginTop: "45px" }}
      >
        <button
          className="btn btn-primary btn-block"
          onClick={() => handleAbortedState()}
        >
          Ok
        </button>
      </div>
    </div>
  );
};
