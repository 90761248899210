import React, { lazy, Suspense } from "react";
import { Card } from "react-bootstrap";
import { LoaderComponent } from "../../../../GridCapture/Register/Components/Loader";

const SlideName = lazy(() => import("../updateSlide/SlideName"));
const StatusSymbol = lazy(() => import("./StatusSymbol"));

const DeletedSlideCard = ({ list, selectedSlideView }) => {
  return (
    <Card
      border={list.slide_id === selectedSlideView ? "dark" : "none"}
      bg={list.slide_id === selectedSlideView ? "light" : "none"}
      key={list.slide_id}
      className="caseViewCard  p-1"
    >
      <Card.Body style={{ padding: 10 }}>
        <Card.Text>
          <div className="d-flex justify-content-between align-items-center mt-1">
            <Suspense fallback={<LoaderComponent />}>
              <SlideName list={list} />
            </Suspense>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p style={{ fontSize: "12px" }}>{list.created}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center">
            <Suspense fallback={<LoaderComponent />}>
              <StatusSymbol list={list} />
            </Suspense>
            </div>
          </div>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default DeletedSlideCard;
