import React from "react";

export const Organiz_name_input = (props) => {
    const handleOnChangeInput=(event)=>{
        const trimmedValue = event.target.value.trim();
        props.organiz_name_callback(trimmedValue)
    }

  return (
    <div>
      <input
        type="text"
        value={props.organiz_name}
        onChange={handleOnChangeInput}
        placeholder="Enter organization name" 
      />
    </div>
  );
};
