import React, { useContext, useState } from "react";
import { Card } from "react-bootstrap";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { AiOutlineEye,AiOutlineEyeInvisible,AiOutlineDelete } from "react-icons/ai";
// import {  } from "react-icons/ai";
// import { BiComment,FiFileText } from "react-icons/fi";
import { BiCommentDetail, BiPaint,BiComment } from "react-icons/bi";
// import {  } from "react-icons/ai";
import { deleteAnnotation, editAnnotionProfile } from "../../../Service";
import { PageContext } from "../../CaseViewer";

const AnnotationCard = ({ data,handleShow }) => {
  const { state, pageDispatcher } = useContext(PageContext);
  const { token } = state.selected_slide;
  const [isAnnoHidden, setIsAnnoHidden] = useState(data.profileContent?.annotation_hide || "0");
  const [isCommHidden, setIsCommHidden] = useState(data.profileContent?.annotation_comment || "0");
  const [isMeasureHidden, setIsMeasureHidden] = useState(data.profileContent?.annotation_measurement || "0");
  const [isFileHidden, setIsFileHidden] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [lastClickTime, setLastClickTime] = useState(0);
  const [name, setName] = useState(data.label);
  const [modalIsOpen, setModalIsOpen] = useState(false);


  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleClick = (key, cordinate) => {
    const currentTime = new Date().getTime();
    const timeSinceLastClick = currentTime - lastClickTime;
    setLastClickTime(currentTime);

    if (timeSinceLastClick < 300) { // Adjust the time threshold (in milliseconds) to define what you consider a double click
      handleDoubleClick();
    } else {
      if (data.rawData.raw_data.type=="Circle"){
        pageDispatcher.set_selected_annotation_slide({ primarykey: key, cordinate: cordinate, clicked: true, radius: data.rawData.raw_data.radius});
      }
      pageDispatcher.set_selected_annotation_slide({ primarykey: key, cordinate: cordinate, clicked: true, radius: null });
    }
  };

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleNameSave =async () => {
    const obj = {
      annotation_id: data.annotationId,
      label: name
    };
    try {
      await editAnnotionProfile(token, obj);
      setIsEditing(false);
      const updatedAnnotations = state.annotation_list.map((annotation) => {
        if (annotation.annotationId === data.annotationId) {
          return { ...annotation, label: name };
        }
        return annotation;
      });
      pageDispatcher.set_annotation_list(updatedAnnotations);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleNameSave();
    }
  };

  const handleColorChange = async (color) => {
    console.group("data:",data)

    let obj={
      annotation_id: data.annotationId,
      color: state.annotationColor,
    };
    try {
      await editAnnotionProfile(token, obj);
      const updatedAnnotations = state.annotation_list.map((annotation) => {
        if (annotation.annotationId === data.annotationId) {
          return { ...annotation, color: obj.color };
        }
        return annotation;
      });
      pageDispatcher.set_annotation_list(updatedAnnotations);
    } catch (error) {
      console.log(error);
    }
  }

  const deleteAnnot = async () => {
    const obj = {
      annotation_id: data.annotationId,
    };
    try {
      await deleteAnnotation(token, obj);
      const filteredAnnotations = state.annotation_list.filter((annotation) => annotation.annotationId !== data.annotationId);
      // pageDispatcher.set_annotation_list([]);
      pageDispatcher.set_annotation_list(filteredAnnotations);
    } catch (error) {
      console.log(error);
    }
  };

  const hideAnnotation = async () => {
    let annotationStatus;
    if (isAnnoHidden === "0") {
      annotationStatus = "1";
    } else {
      annotationStatus = "0";
    }
    let obj={
      annotation_id: data.annotationId,
      profile_content: { annotation_hide: annotationStatus, annotation_comment: "1", annotation_measurement: "1" },
    };
    
    try {
      await editAnnotionProfile(token, obj);
      setIsAnnoHidden(annotationStatus);
      setIsCommHidden("1")
      setIsMeasureHidden("1")
   
      const updatedAnnotations = state.annotation_list.map((annotation) => {
        if (annotation.annotationId === data.annotationId) {
          return { ...annotation, profileContent: obj.profile_content };
        }
        return annotation;
      });
      pageDispatcher.set_annotation_list(updatedAnnotations);
    } catch (error) {
      console.log(error);
    }
  };
  let toggleButtonStatusAnnotation;
  if (isAnnoHidden === "0") {
    toggleButtonStatusAnnotation = false;
  } else {
    toggleButtonStatusAnnotation = true;
  }

  const hideComment = async () => {
    if (isAnnoHidden==="0"){
      let commentStatus;
      if (isCommHidden === "0") {
        commentStatus = "1";
      } else {
        commentStatus = "0";
      }
      const obj = {
        annotation_id: data.annotationId,
        profile_content: { annotation_hide: isAnnoHidden, annotation_comment: commentStatus, annotation_measurement: isMeasureHidden },
      };
      try {
        await editAnnotionProfile(token, obj);
        setIsCommHidden(commentStatus);
        const updatedAnnotations = state.annotation_list.map((annotation) => {
          if (annotation.annotationId === data.annotationId) {
            return { ...annotation, profileContent: { annotation_hide: isAnnoHidden, annotation_comment: commentStatus, annotation_measurement: isMeasureHidden } };
          }
          return annotation;
        });
        pageDispatcher.set_annotation_list(updatedAnnotations);
      } catch (error) {
        console.log(error);
      }

    }

  };
  let toggleButtonStatusComment;
  if (isCommHidden === "0") {
    toggleButtonStatusComment = false;
  } else {
    toggleButtonStatusComment = true;
  }

  const hideMeasurement = async () => {
    if (isAnnoHidden==="0"){
    let measureStatus;
    if (isMeasureHidden === "0") {
      measureStatus = "1";
    } else {
      measureStatus = "0";
    }
    const obj = {
      annotation_id: data.annotationId,
      profile_content: { annotation_hide: isAnnoHidden, annotation_comment: isCommHidden, annotation_measurement: measureStatus },
    };
    try {
      await editAnnotionProfile(token, obj);
      setIsMeasureHidden(measureStatus);
      const updatedAnnotations = state.annotation_list.map((annotation) => {
        if (annotation.annotationId === data.annotationId) {
          return { ...annotation, profileContent: { annotation_hide: isAnnoHidden, annotation_comment: isCommHidden, annotation_measurement: measureStatus } };
        }
        return annotation;
      });
      pageDispatcher.set_annotation_list(updatedAnnotations);
      
    } catch (error) {
      console.log(error);
    }
  }

  };
  let toggleButtonStatusMeasurement;
  if (isMeasureHidden === "0") {
    toggleButtonStatusMeasurement = false;
  } else {
    toggleButtonStatusMeasurement = true;
  }

  let toggleButtonFile;
  if (isFileHidden){
    toggleButtonFile=true
  } else {
    toggleButtonFile=false
  }

  return (
    <div>
      <Card
        className="mb-0"
        key={data.primarykey}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        {isEditing ? (
        <input
        type="text"
        value={name}
        onChange={handleNameChange}
        onBlur={handleNameSave}
        onKeyDown={handleEnterKeyPress}
        autoFocus
        style={{ width: "150px" }}
        />
      ) : (
          <button
            style={{ border: "none", background: "none", paddingRight: "10px",cursor: "pointer", }}
            onClick={() => handleClick(data.annotationId, data.rawData.raw_data)}
          >
            {name}
          </button>
          )}
          {((state.username_cookie==data.username) || (state.username_cookie=="Admin")) &&
            <button
              style={{ border: "none", background: "none", paddingRight: "10px" }}
              title="Delete"
              onDoubleClick={handleDoubleClick}
              onClick={() => deleteAnnot(data.profileContent)}
            >
              <AiOutlineDelete />
            </button>
          }
        </div>
        <div className="button-container" style={{ display: "flex", justifyContent: "start" }}>
          {toggleButtonStatusAnnotation ? (
            <button
              style={{ border: "none", background: "none", paddingRight: "10px" }}
              onClick={() => hideAnnotation()}
              title="unHide Annotation"
            >
              <AiOutlineEyeInvisible />
            </button>
          ) : (
            <button
              style={{ border: "none", background: "none", paddingRight: "10px" }}
              onClick={() => hideAnnotation()}
              title="Hide Annotation"
            >
              <AiOutlineEye />
            </button>
          )}
          {toggleButtonStatusComment ? (
            <button
              style={{ border: "none", background: "none", paddingRight: "10px" }}
              onClick={() => hideComment()}
              title="unHide Comment"
            >
              <BsToggleOn />
            </button>
          ) : (
            <button
              style={{ border: "none", background: "none", paddingRight: "10px" }}
              onClick={() => hideComment()}
              title="Hide Comment"
            >
              <BsToggleOff />
            </button>
          )}
          {toggleButtonStatusMeasurement ? (
            <button
              style={{ border: "none", background: "none", paddingRight: "10px" }}
              onClick={() => hideMeasurement()}
              title="UnHide Measurement"
            >
              <BsToggleOn />
            </button>
          ) : (
            <button
              style={{ border: "none", background: "none", paddingRight: "10px" }}
              onClick={() => hideMeasurement()}
              title="Hide Measurement"
            >
              <BsToggleOff />
            </button>
          )}
            <button
              style={{ border: "none", background: "none", paddingRight: "10px" }}
              onClick={()=>handleShow(data)}
              title="Show Comment"
            >
              <BiCommentDetail />
            </button>
            <button
              style={{ border: "none", background: "none", paddingRight: "10px" }}
              onClick={() => handleColorChange()}
              title="Change Color"
            >
              <BiPaint style={{ color:`${data.color}`}}/>
            </button>
        </div>
      </Card>
    </div>
  );
};

export default AnnotationCard;
