import React, { useEffect, useState } from "react";
import { setSlideActiveDays, getSlideActiveDays } from "../Service";
import { NotificationContainer, NotificationManager } from "react-notifications";

export const SlideActiveDays = () => {
  const [activeDays, setActiveDays] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    getSlideActiveDays().then((res) => {
      setActiveDays(res.data.slide_active_days);
    });
  }, []);

  return (
    <div>
      <NotificationContainer />
      <div className="d-flex justify-content-center">
        <form
          onSubmit={handleSubmit}
          className="w-50"
        >
          <div className="form-group row justify-content-around">
            <div className="col-md-10">
                <p>Slide Active Days:</p>
              <input
                id="activeDays"
                type="number"
                value={activeDays}
                onChange={(e) => {
                  setActiveDays(e.target.value);
                }}
                className="form-control"
                placeholder="Active Days"
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="">
              <button
                type="button"
                size="lg"
                id="btn"
                onClick={() => {
                  let obj = {
                    slide_active_days: parseInt(activeDays),
                  };
                  setSlideActiveDays(obj);
                }}
                className="btn btn-primary btn-block mt-3"
              >
                Ok
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
