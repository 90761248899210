import React, { useContext } from "react";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { LoaderComponent } from "./Loader";
import { PageContext } from "../StitchSlide";
import { handleAbortState, handleAbortedState } from "../../Service";
export const Abort = (props) => {
  const context = useContext(PageContext);
  // console.log("context", context.pageState.showAbortWithLoader)

  return (
    <div>
      {/* <NotificationContainer /> */}

      {context && context.pageState.showAbortWithLoader ? (
        <>
          <LoaderComponent />
          {/* <div className="col-md-4 offset-md-4" style={{ marginTop: '10px' }}>
                    <button type="button" className="btn btn-danger btn-block"
                        onClick={() => handleAbortState()
                            .then(res => {
                                if (res.data && res.data.msg) {
                                    NotificationManager.success(res.data.msg, '', 2000);
                                }
                            })
                        }
                    >Abort</button>
                </div> */}
        </>
      ) : (
        <button
          type="button"
          className="btn btn-danger btn-block"
          onClick={() =>
            handleAbortState().then((res) => {
              if (res.data && res.data.msg) {
                NotificationManager.success(res.data.msg, "", 2000);
              }
            })
          }
          style={{ width: "150px" }}
        >
          Abort
        </button>
      )}
    </div>
  );
};

export const Aborting = () => {
  return (
    <div>
      <h3>Aborting...</h3>
      <br />
      <button
        type="button"
        className="btn btn-danger btn-block"
        onClick={() =>
          handleAbortState().then((res) => {
            if (res.data && res.data.msg) {
              NotificationManager.success(res.data.msg, "", 2000);
            }
          })
        }
      >
        Abort
      </button>
    </div>
  );
};

export const Aborted = () => {
  // const context = useContext(PageContext);
  return (
    <div>
      <NotificationContainer />

      <h3>Operation Aborted.</h3>
      {/* <h3>Please take the slide.</h3><br /> */}
      <div
        className="col-md-4 offset-md-4 text-center"
        style={{ marginTop: "10px" }}
      >
        <button
          className="btn btn-primary btn-block"
          onClick={() => {
            handleAbortedState();
          }}
        >
          Ok
        </button>
      </div>
    </div>
  );
};
