import React, { useContext, useEffect, useState } from "react";
import { updateSlideData } from "../../../Service";
import { PageContext } from "../../CaseViewer";

const Lens = ({ list }) => {
  const { state, pageDispatcher } = useContext(PageContext);
  const { lens, slide_token } = list;
  // console.log("lens:",lens, typeof lens, list
  const [selectedLens, setSelectedLens] = useState();

  const options = state?.zoomLensCapture;
  const handleChange = async (event) => {
    const lensValue = event.target.value.replace("X", "");
    setSelectedLens(event.target.value);
    const obj = {
      lens: lensValue,
    };
    try {
      await updateSlideData(obj, slide_token);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (lens !== undefined && lens !== -1) {
      setSelectedLens(`${lens}X`);
    } else if (lens === 240) {
      setSelectedLens(`40X(NC)`);
    } else {
      setSelectedLens("-1");
    }
  }, [lens]);

  return (
    <div className="d-flex justify-content-start align-items-center">
      <select
        className="custom-select custom-select-sm ml-2"
        id="lens"
        value={selectedLens}
        onChange={handleChange}
      >
        {options?.map((option) => (
          <option
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Lens;
