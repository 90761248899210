import React, { useReducer, createContext, useContext, useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { Pagination } from "../../shared/Pagination";
import { QuickshareCopyLink } from "../../shared/QuickshareCopyLink";
import { environment } from "../../../environment";
import { getToken, checkIfModuleActive, getUser, getTypeOfSlidelide, getSelectedChipset } from "../../Utils/Common";
import { AgGridReact } from "ag-grid-react";
import { ShowModuleStatusComponent } from "../../shared/ShowModuleStatus";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Modal, Button, Form, ProgressBar, Dropdown } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown";
import { AddUploadSlide } from "./AddUploadSlide";
import { getAllTags, setSlideTags, updateReportingStatus, uploadRequestForm } from "../Service";
import { start } from "../../Live Control/Service";
import { Navigation } from "../../shared/Navigation";
import {
  FaDownload,
  FaEllipsisH,
  FaExchangeAlt,
  FaExclamationTriangle,
  FaPlusCircle,
  FaRegCheckSquare,
  FaRegEdit,
  FaRegFileAlt,
  FaRegShareSquare,
  FaSearch,
  FaSpinner,
  FaTrash,
  FaUpload,
} from "react-icons/fa";
const baseURL = environment.MICALYS_POSTGRES_API;

export const SlideList = (props) => {
  const location = useLocation();

  const initialState = {
    modalVisible: false,
    modalRequestVisible: false,
    showmsg: location.state && location.state.showMessage ? true : false,
    allowPaging: true,
    totalRecords: 0,
    last_change: 0.0,
    eta_stitch: "",
    eta_capture: "",
    number_of_items: 10,
    index: 0,
    slide_name: "",
    tag_search: "all",
    slideListTable: {
      row: [],
    },
    hideDeletedSlideStatus: true,
    getSlideRecords: (slide_name, index, number_of_items, status, tag_search) =>
      getSlideRecords(slide_name, index, number_of_items, status, tag_search).then((res) => {
        if (res && res.data) {
          dispatch({
            type: "set_slideDatalist",
            payload: { slidelist: res.data.slidelist, totalRecords: res.data.counter, index: index, last_change: parseFloat(res.data.last_change) },
          });
          state.last_change = res.data.last_change;
          state.slide_name = slide_name;
          state.index = index;
          state.number_of_items = number_of_items;
          state.tag_search = tag_search;
        }
      }),
    updateIndex: function (indexValue) {
      dispatch({ type: "set_Index", payload: indexValue });
    },
    setSearch: (searchText) => dispatch({ type: "set_search", payload: searchText }),
    clearData: () => {
      dispatch({ type: "set_clearData", payload: { slide_name: "", index: 0, number_of_items: state.number_of_items, tag_search: state.tag_search } });
    },
    export_Slide: (slide_id, exportType) => exportSlide(slide_id, exportType),
    uploadSlide: (slide_id) => uploadSlide(slide_id),
    cancelSlide: (slide_id) => cancelSlide(slide_id),
    getExportedFile: (slide_id) =>
      getExportedFile(slide_id)
        .then(async (res) => {
          if (res && res.data && res.data.downloadKey) {
            let url = [`${baseURL}download_file`, `downloadKey=${res.data.downloadKey}`].join("?");

            window.open(url, "_blank").focus();
          }
        })
        .catch((e) => {
          console.log(e);
        }),
    deleteStatus: (slide_id) => deleteStatus(slide_id),
    hasDataUpdated: () =>
      hasDataUpdated().then((res) => {
        if (res && res.data) {
          // console.log("res.data", res.data)
          if (res.data.last_change !== state.last_change) {
            dispatch({ type: "set_etaForStatus", payload: res.data });
            let currentIndex = parseInt(localStorage.currentIndex);
            state.getSlideRecords(state.slide_name, currentIndex, state.number_of_items, true, state.tag_search);
          }
          state.last_change = res.data.last_change;
        }
      }),
    setHiddenDeletedSlideStatus: (value) => dispatch({ type: "set_hiddenDeletedSlide", payload: value }),
  };

  const [state, dispatch] = useReducer(pageReducer, initialState);
  // params.data
  // console.log('params.data',params.data)
  const pageDispatcher = {
    set_modalVisible: (value) => dispatch({ type: "set_modalVisible", payload: value }),
    set_modalRequestFormVisible: (value) => dispatch({ type: "set_modalRequestFormVisible", payload: value }),
  };

  useEffect(() => {
    dispatch({ type: "set_Index", payload: 0 });
    state.getSlideRecords(state.slide_name, state.index, state.number_of_items, state.hideDeletedSlideStatus, state.tag_search);
    const interval = setInterval(() => {
      state.hasDataUpdated();
    }, 5000);

    return () => clearInterval(interval);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Navigation />
      <div className="container-fluid">
        <PageContext.Provider value={{ state, pageDispatcher }}>
          <NotificationContainer />
          <SlideListComponent />
        </PageContext.Provider>
      </div>
    </>
  );
};

const SlideListComponent = () => {
  const { state, pageDispatcher } = useContext(PageContext);
  const [showUploadComponent, setShowUploadComponent] = useState(false);

  localStorage.setItem("currentIndex", state.index);
  const [tempSearch, setTempSearch] = useState("");

  const [isSlideNameEditable, setIsSlideNameEditable] = useState(false);
  let statusExists;
  statusExists = state.hideDeletedSlideStatus;
  const multiselectElement = useRef(null);

  // const onSelectionChanged = (event) => {
  //     event.api.deselectAll();
  //     document.getElementsByClassName('ag-input-field-input ag-checkbox-input')[0].checked=true
  // }
  const [showExportModal, setShow] = useState(false);
  const [showRequestFormModal, setRequestModal] = useState(false);
  const [initialTagList, setinitialTagList] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleRequestFormClose = () => setRequestModal(false);
  const handleRequestFormShow = () => setRequestModal(true);

  const [selectedFormat, setFormat] = useState("tif");

  const changeFormat = (value) => {
    setFormat(value);
  };

  const [exportSlideId, setSlideId] = useState("");
  const fetchTags = async () => {
    const result = await getAllTags();
    console.log("fetchTags", result.data);
    setinitialTagList(result.data.tags);
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const handleReportingStatus = (val, slide_id) => {
    let obj = {
      slideid: slide_id,
      report_status: val,
    };
    updateReportingStatus(obj).then((res) => {
      if (res && res.data.Message) {
        NotificationManager.success(res.data.Message, "", 2000);
      }
    });
  };

  const columnDefs = [
    // {
    //     headerName: "ID",
    //     field: "slide_id",
    //     sortable: true,
    //     suppressMovable: true,
    //     cellRendererFramework: (params) => {
    //         return (
    //             <>{params.data.slide_id}</>
    //         )
    //     }
    // },
    {
      headerName: "Slides",
      wrapText: true,
      autoHeight: true,
      children: [
        {
          headerName: "Name",
          field: "slide_name",
          width: 250,
          sortable: true,
          filter: true,
          wrapText: true,
          editable: isSlideNameEditable,
          autoHeight: true,
          cellRenderer: (params) => (
            <div>
              <button
                onClick={() => checkStatusForSlideView(params)}
                style={{ color: "#008cff", border: "none", backgroundColor: "white" }}
              >
                {params.data.slide_name}
              </button>

              <div>
                <FaRegEdit
                  aria-hidden="true"
                  style={{ top: "16px", position: "absolute", right: "0px", cursor: "pointer" }}
                  onClick={() => setIsSlideNameEditable(!isSlideNameEditable)}
                />
              </div>
              <div>
                <FaRegFileAlt
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Request Form"
                  aria-hidden="true"
                  style={
                    params.data.hasrequestform
                      ? { color: "green", top: "16px", position: "absolute", right: "0px", cursor: "pointer" }
                      : { top: "16px", position: "absolute", right: "0px", cursor: "pointer" }
                  }
                  onClick={() => {
                    setSlideId(params.data.slide_id);
                    handleRequestFormShow();
                    pageDispatcher.set_modalRequestFormVisible(true);
                  }}
                />
              </div>
            </div>
          ),
        },
        {
          headerName: "Image",
          field: "slide_name",
          width: 273,
          // headerCheckboxSelection: true,
          wrapText: true,
          height: 250,
          cellRenderer: (params) => (
            <button style={{ color: "#008cff", border: "none", backgroundColor: "white" }}>
              <img
                src={`data:image/png;base64,${params.data.imagedata}`}
                alt="ResponsiveImage"
                className="img-fluid"
                onClick={() => checkStatusForSlideView(params)}
              />
            </button>
          ),
        },
      ],
    },
    {
      headerName: "Created",
      field: "created",
      sortable: true,
      width: 200,
      wrapText: true,
      autoHeight: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        let date = params.data.created;
        let dateArray = date.split(" ");
        return (
          <>
            <p
              className="m-0"
              style={{ lineHeight: "1.5" }}
            >
              {dateArray[0]}
            </p>
            <p
              className="m-0"
              style={{ lineHeight: "1.5" }}
            >
              {dateArray[1]}
            </p>
          </>
        );
      },
    },
    {
      headerName: "Reporting",
      field: "report_status",
      // sortable: true,
      width: 200,
      // wrapText: true,
      // autoHeight: true,
      // suppressMovable: true,
      cellRenderer: (params) => {
        return (
          <div>
            {/* {console.log("params:", params)} */}
            {console.log("params-data:", params.data)}
            <select
              className="custom-select custom-select-sm"
              onChange={(e) => {
                handleReportingStatus(e.target.value, params.data.slide_id);
              }}
            >
              {params.data.report_status === 0 ? (
                <>
                  <option
                    value="0"
                    selected
                  >
                    Not Reported
                  </option>{" "}
                  <option value="1">Reporting</option>
                  <option value="2">Reported</option>
                  <option value="-1">Flag Issue</option>
                </>
              ) : params.data.report_status === 1 ? (
                <>
                  <option
                    value="1"
                    selected
                  >
                    Reporting
                  </option>{" "}
                  <option value="0">Not Reported</option>
                  <option value="2">Reported</option>
                  <option value="-1">Flag Issue</option>
                </>
              ) : params.data.report_status === 2 ? (
                <>
                  <option value="2">Reported</option> <option value="0">Not Reported</option>
                  <option value="1">Reporting</option>
                  <option value="-1">Flag Issue</option>
                </>
              ) : params.data.report_status === -1 ? (
                <>
                  <option value="-1">Flag Issue</option> <option value="0">Not Reported</option>
                  <option value="1">Reporting</option>
                  <option value="2">Reported</option>
                </>
              ) : (
                params.data.report_status
              )}
            </select>
          </div>
        );
      },
    },
    {
      headerName: "Tags",
      field: "tagsarr",
      width: 270,
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return (
          <TagsInput
            // taglistdata={params.data.tagsarr}
            taglistdata={params.data.tag_status}
            slide_id={params.data.slide_id}
          />
        );
      },
    },
    {
      headerName: "Status",
      field: "capture_status",
      sortable: true,
      suppressMovable: true,
      width: 120,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params) => StatusIcon(params),
    },
    {
      headerName: "Upload",
      field: "upload_status",
      sortable: true,
      suppressMovable: true,
      width: 120,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params) => StatusIcon(params),
    },
    {
      headerName: "Export",
      field: "export_slide",
      sortable: true,
      suppressMovable: true,
      wrapText: true,
      width: 140,
      autoHeight: true,
      cellRenderer: (params) => {
        return (
          <div>
            {params.data.export_slide === 0 ? (
              <>
                <a
                  href="#!"
                  onClick={() => {
                    setSlideId(params.data.slide_id);
                    // console.log('setSlideId:->',params.data.slide_id)
                    handleShow();
                  }}

                  // let currentIndex = parseInt(localStorage.currentIndex);
                  // state.exportSlide(params.data.slide_id);
                  // state.getSlideRecords(state.slide_name, currentIndex, state.number_of_items, statusExists)
                >
                  <FaRegShareSquare aria-hidden="true" />
                  <br />
                  Export Slide
                </a>
              </>
            ) : params.data.export_slide === 1 ? (
              <>
                &nbsp;
                <FaExchangeAlt aria-hidden="true" />
                <br />
                <label>Converting</label>
              </>
            ) : params.data.export_slide === 2 ? (
              <>
                <a
                  href="#!"
                  onClick={() => {
                    state.getExportedFile(params.data.slide_id);
                  }}
                >
                  <FaDownload aria-hidden="true" />
                  <br />
                  Download
                </a>
                <br />
                <a
                  href="#!"
                  style={{ color: "#1a0dab" }}
                  onClick={() => {
                    setSlideId(params.data.slide_id);
                    // console.log('setSlideId:->',params.data.slide_id)
                    handleShow();
                  }}
                >
                  <small>Re-Export</small>
                </a>
              </>
            ) : params.data.export_slide === -1 ? (
              <>
                &nbsp;
                <FaExclamationTriangle aria-hidden="true" />
                <br />
                <label>Error</label>
              </>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      headerName: "Delete",
      field: "delete_slide",
      sortable: true,
      width: 140,
      suppressMovable: true,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params) => {
        return (
          <div>
            {params.data.delete_slide === 0 ? (
              <>
                <a
                  href="#!"
                  onClick={() => {
                    let currentIndex = parseInt(localStorage.currentIndex);
                    state.deleteStatus(params.data.slide_id);
                    state.getSlideRecords(state.slide_name, currentIndex, state.number_of_items, statusExists, state.tag_search);
                  }}
                >
                  Delete
                </a>
              </>
            ) : params.data.delete_slide === 1 ? (
              <>
                &nbsp;
                <FaSpinner aria-hidden="true" />
              </>
            ) : params.data.delete_slide === 2 ? (
              <>
                &nbsp;
                <FaRegCheckSquare aria-hidden="true" />
              </>
            ) : params.data.delete_slide === -1 ? (
              <>
                &nbsp;
                <FaExclamationTriangle aria-hidden="true" />
              </>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      headerName: `Quickshare`,
      field: "",
      sortable: true,
      width: 145,
      // hide: checkIfModuleActive() && checkIfModuleActive().qs === 1 ? false : true,
      suppressMovable: true,
      cellRenderer: (params) => <QuickshareCopyLink slideid={params.data.slide_id} />,
    },
  ];
  const onGridReady = (params) => {
    // params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  };
  const checkStatusForSlideView = (params) => {
    let slide_stitch_status = params.data.stitch_status;
    if ((slide_stitch_status === 2 && params.data.delete_slide === 0) || params.data.import_slide === 4) {
      const win = window.open("/SlideViewer/" + params.data.slide_id, "_blank");
      win.focus();
    }
  };

  const StatusIcon = (params) => {
    const state = useContext(PageContext);
    switch (params.colDef.headerName) {
      case "Status":
        return (
          <div>
            {params.data.import_slide === 7 ? (
              params.data.capture_status === 0 ? (
                <>
                  <FaEllipsisH aria-hidden="true" />
                  <br />
                  <label>Initialized</label>
                </>
              ) : params.data.capture_status === 1 ? (
                <>
                  <div className="d-flex flex-column   ">
                    <p
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Capture"
                      className="orange d-flex justify-content-center align-items-center align-self-center  "
                    >
                      C
                    </p>

                    <p>Capturing</p>
                  </div>
                  <br />
                  {state.eta_capture !== -1 && <>{state.eta_capture}</>}
                </>
              ) : params.data.stitch_status === 0 && params.data.capture_status === 2 ? (
                <div className="d-flex flex-column">
                  <p
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Stitch"
                    className="orange d-flex justify-content-center align-items-center align-self-center"
                  >
                    S
                  </p>

                  <p>Initialized</p>
                </div>
              ) : params.data.stitch_status === 1 ? (
                <div className="d-flex flex-column   ">
                  <p
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Stitch"
                    className="orange d-flex justify-content-center align-items-center align-self-center"
                  >
                    S
                  </p>

                  <p>Stitching</p>
                </div>
              ) : params.data.stitch_status === 2 ? (
                <>
                  <FaRegCheckSquare
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Captured"
                    aria-hidden="true"
                  />
                  <br />
                  <label>Ready</label>
                </>
              ) : params.data.capture_status === -1 || params.data.stitch_status ? (
                <>
                  <FaExclamationTriangle aria-hidden="true" />
                  <br />
                  <label>Error</label>
                </>
              ) : (
                params.data.capture_status
              )
            ) : params.data.import_slide === 0 ? (
              <>
                <FaEllipsisH
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Import"
                  aria-hidden="true"
                />
                <br />
                <label>Initialize</label>
              </>
            ) : params.data.import_slide === 2 ? (
              <div className="d-flex flex-column   ">
                <p
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Import"
                  className="green d-flex justify-content-center align-items-center align-self-center"
                >
                  I
                </p>

                <p>Uploaded</p>
              </div>
            ) : params.data.import_slide === 3 ? (
              <div className="d-flex flex-column   ">
                <p
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Import"
                  className="orange d-flex justify-content-center align-items-center align-self-center"
                >
                  I
                </p>

                <p>Processing</p>
              </div>
            ) : params.data.import_slide === 4 ? (
              <>
                <FaRegCheckSquare
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Imported"
                  aria-hidden="true"
                />
                <br />
                <label>Ready</label>
              </>
            ) : (
              params.data.import_slide === -1 && (
                <>
                  <FaExclamationTriangle aria-hidden="true" />
                  <br />
                  <label>Error</label>
                </>
              )
            )}
          </div>
        );

      case "Upload":
        return (
          <div>
            {params.data.upload_status === 0 ? (
              <>
                <a
                  href="#!"
                  onClick={() => {
                    let currentIndex = parseInt(localStorage.currentIndex);
                    state.state.uploadSlide(params.data.slide_id);
                    // state.state.getSlideRecords(state.slide_name, currentIndex, state.number_of_items, statusExists);
                  }}
                >
                  <FaUpload aria-hidden="true" />
                  <br />
                  Upload
                </a>

                <br />
              </>
            ) : params.data.upload_status === 1 || params.data.upload_status === 2 || params.data.upload_status === 3 || params.data.upload_status === 4 ? (
              <>
                {" "}
                <FaUpload aria-hidden="true" /> <br />
                <label>Uploading</label>
                <br />
                <a
                  href="#!"
                  style={{ color: "red" }}
                  onClick={() => {
                    if (window.confirm("Sure wants to cancel upload?")) {
                      let currentIndex = parseInt(localStorage.currentIndex);
                      state
                        .cancelSlide(params.data.slide_id)
                        .then((res) => {
                          if (res && res.data.Message) {
                            state.getSlideRecords(state.slide_name, currentIndex, state.number_of_items, statusExists, state.tag_search);
                            NotificationManager.success(res.data.Message, "", 2000);
                          }
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    }
                  }}
                >
                  <small>Cancel</small>
                </a>
              </>
            ) : params.data.upload_status === 5 ? (
              <>
                <FaRegCheckSquare aria-hidden="true" />
                <br />
                <label>Done</label>
                <br />
                <a
                  href="#!"
                  style={{ color: "#1a0dab" }}
                  onClick={() => {
                    if (window.confirm("Sure wants to reupload?")) {
                      let currentIndex = parseInt(localStorage.currentIndex);
                      state.state.uploadSlide(params.data.slide_id);
                      // state.getSlideRecords(state.slide_name, currentIndex, state.number_of_items, statusExists);
                    }
                  }}
                >
                  <small>Re-Upload</small>
                </a>
              </>
            ) : params.data.upload_status === -1 ? (
              <>
                <FaExclamationTriangle aria-hidden="true" />
                <br />
                <label>Error</label>
                <br />
                <a
                  href="#!"
                  style={{ color: "#1a0dab" }}
                  onClick={() => {
                    if (window.confirm("Sure wants to reupload?")) {
                      let currentIndex = parseInt(localStorage.currentIndex);
                      state.state.uploadSlide(params.data.slide_id);
                      // state.getSlideRecords(state.slide_name, currentIndex, state.number_of_items, statusExists);
                    }
                  }}
                >
                  <small>Re-Upload</small>
                </a>
              </>
            ) : (
              params.data.upload_status
            )}
          </div>
        );

      default:
        return "";
    }
  };

  const handleKeyDown = (e) => {
    // if (e.key === " ") {
    //    e.preventDefault();
    // }
    //else if (e.key === 'Enter') {
    if (e.key === "Enter") {
      state.setSearch(tempSearch);
      state.updateIndex(0);
      state.getSlideRecords(tempSearch, state.index, state.number_of_items, statusExists, state.tag_search);
    }
    // }
  };

  const onCellValueChanged = (event) => {
    let obj = {
      slide_id: event.data.slide_id,
      slide_name: event.data.slide_name,
    };
    let currentIdx = parseInt(localStorage.currentIndex);

    updateSlidename(obj).then((res) => {
      if (res && res.data.Message) {
        state.getSlideRecords(state.slide_name, currentIdx, state.number_of_items, statusExists, state.tag_search);
        NotificationManager.success(res.data.Message, "", 2000);
      }
    });
  };

  let arr = [];
  function getTagsOptions() {
    initialTagList.map((el) => {
      // console.log("el", el);
      if (el !== null) {
        arr.push(el);
      }
      return 0;
    });
    arr = arr.flat();
    let uniqueChars = [...new Set(arr)];
    // console.log("uniqueChars", uniqueChars);
    return uniqueChars;
  }

  return (
    <div className="rtable">
      <ul
        className="navbar-nav"
        style={{
          backgroundColor: "transparent",
          textTransform: "upperCase",
          fontSize: "13px",
          textAlign: "start",
        }}
      >
        <li className="nav-item">
          {getTypeOfSlidelide() === ("WSI" || "Histo") && <Link to="/slidestitch">Scan Slide</Link>}
          {getTypeOfSlidelide() === "PBS" && <Link to="/registerslide">PBS Capture</Link>}
          {getTypeOfSlidelide() === "Live Control" && <Link to="/livecontrol">Live Control</Link>}
        </li>
      </ul>
      {state && state.slideListTable && state.slideListTable.row && (
        <>
          <div className="row d-flex align-item-center justify-content-between">
            <div className="col-md-6 d-flex align-item-center justify-content-between">
              <div className=" d-flex align-item-center justify-content-between mr-1">
                <input
                  type="search"
                  className="form-control rounded"
                  value={tempSearch}
                  onChange={(e) => {
                    if (!e.target.value) {
                      setTempSearch("");
                      state.clearData();
                      state.getSlideRecords("", 0, state.number_of_items, true, state.tag_search);
                      state.setHiddenDeletedSlideStatus(true);
                    } else {
                      state.updateIndex(0);
                      setTempSearch(e.target.value);
                    }
                  }}
                  onKeyDown={handleKeyDown}
                  placeholder="Search by SlideName"
                />
                <span
                  className="input-group-text border-0 "
                  id="search-addon"
                >
                  <FaSearch
                    aria-hidden="true"
                    onClick={(e) => {
                      state.updateIndex(0);
                      // setTempSearch(e.target.value)
                      state.setSearch(tempSearch);
                      state.getSlideRecords(tempSearch, state.index, state.number_of_items, statusExists, state.tag_search);
                    }}
                  />
                </span>
              </div>
              <div className="">
                <div style={{ lineHeight: "3px" }}>
                  <Multiselect
                    selectionLimit={1}
                    placeholder="search by tags"
                    id="tagnamelist"
                    ref={multiselectElement}
                    showArrow
                    options={getTagsOptions()}
                    isObject={false}
                    onSelect={(value) => {
                      console.log("add-value", value[0]);
                      if (value[0] !== "undefined") {
                        state.getSlideRecords(state.slide_name, state.index, state.number_of_items, state.hideDeletedSlideStatus, value[0]);
                      }
                    }}
                    onRemove={(value) => {
                      console.log("remove-value", value[0]);
                      if (value[0] === "undefined") {
                        state.getSlideRecords(state.slide_name, state.index, state.number_of_items, state.hideDeletedSlideStatus, "all");
                      }
                    }}
                  />
                </div>
              </div>
              <div className=" ">
                <button
                  type="button"
                  className="btn btn-link btn-sm"
                  onClick={() => {
                    setShowUploadComponent(false);
                    pageDispatcher.set_modalVisible(true);
                  }}
                >
                  <FaPlusCircle
                    className="mr-1"
                    aria-hidden="true"
                  />
                  Import Slide
                </button>
              </div>
              {state.modalVisible && <AddUploadSlide uploadShow={{ showUploadComponent, setShowUploadComponent }} />}
            </div>

            <div className="col-md-6">
              <Pagination
                initialState={state}
                refreshListwithSearch={() => {
                  setTempSearch("");
                  state.updateIndex(0);
                  state.clearData();
                  state.setHiddenDeletedSlideStatus(true);
                  state.getSlideRecords("", 0, state.number_of_items, true, state.tag_search);
                }}
                setIndex={(indexValue) => {
                  // state.updateIndex(indexValue)
                  state.getSlideRecords(state.slide_name, indexValue, state.number_of_items, statusExists, state.tag_search);
                }}
              />
            </div>
          </div>

          <div
            className="form-check d-flex align-items-center"
            style={{ justifyContent: "flex-end" }}
          >
            <input
              type="checkbox"
              className="form-check-input"
              checked={state.hideDeletedSlideStatus}
              onChange={(e) => {
                state.setHiddenDeletedSlideStatus(e.target.checked);
                let currentIndex = parseInt(localStorage.currentIndex);
                //Not using this stored value "hideDeletedSlideStatus" anywhere
                localStorage.setItem("hideDeletedSlideStatus", e.target.checked);
                state.getSlideRecords(state.slide_name, currentIndex, state.number_of_items, e.target.checked, state.tag_search);
              }}
            />
            <label className="form-check-label">Hide Deleted Slide</label>
          </div>

          <div className="ag-theme-alpine">
            <AgGridReact
              colResizeDefault="shift"
              domLayout="autoHeight"
              // onSelectionChanged={onSelectionChanged}
              columnDefs={columnDefs}
              headerHeight="32"
              rowData={state.slideListTable.row}
              getRowHeight={() => {
                return 130;
              }}
              onGridReady={onGridReady}
              onCellValueChanged={onCellValueChanged}
            ></AgGridReact>
          </div>
          <ExportModal
            statusExists={statusExists}
            exportSlideId={{ exportSlideId, setSlideId }}
            selectedValue={selectedFormat}
            changeFormat={changeFormat}
            showExportModal={showExportModal}
            handleClose={handleClose}
            handleShow={handleShow}
          />
          <ExportRequestFormModal
            statusExists={statusExists}
            showRequestFormModal={showRequestFormModal}
            handleRequestFormClose={handleRequestFormClose}
            handleRequestFormShow={handleRequestFormShow}
            exportSlideId={exportSlideId}
          />
        </>
      )}
    </div>
  );
};

const TagsInput = ({ taglistdata, slide_id }) => {
  const [initialTagList, setinitialTagList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedResults, setSelectedResults] = useState(taglistdata);

  const handleSearchInputChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    const filteredResults = initialTagList.filter(
      (item) => item.toLowerCase().includes(searchTerm.toLowerCase()) // Case-insensitive search
    );
    setSearchResults(filteredResults);
    if (searchTerm === "") setSearchResults([]);
  };

  const handleResultSelection = (result) => {
    setSelectedResults(result);
    setSearchTerm("");
    setSearchResults([]);
  };

  const handleTagStatus = (e) => {
    e.preventDefault();
    let obj = {
      slideid: slide_id,
      tags: selectedResults,
    };
    setSlideTags(obj)
      .then((res) => {
        if (res && res.data.Message) {
          NotificationManager.success(res.data.Message, "", 2000);
        }
      })
      .catch((err) => {
        console.log("error msg", err);
      });
  };

  const fetchTags = async () => {
    const result = await getAllTags();
    setinitialTagList(result.data.tags);
  };

  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <div style={{ position: "relative", zIndex: "1" }}>
      {/* search input and save button -> start */}
      <div className="d-flex justify-content-between align-items-center">
        <Form.Control
          type="text"
          placeholder="Search tags"
          value={searchTerm}
          onChange={handleSearchInputChange}
        />
        <button
          className="btn btn-primary"
          style={{ marginLeft: "5px", cursor: "pointer" }}
          onClick={handleTagStatus}
        >
          Save
        </button>
      </div>
      {/* search input and save button -> end */}

      {/* drop-down ui start*/}
      {searchResults.length > 0 && (
        <div
          style={{
            position: "absolute",
            top: "85%",
            left: 0,
            width: "100%",
            backgroundColor: "white",
            border: "1px solid lightgray",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            zIndex: "1000",
            maxHeight: "200px",
            overflowY: "auto",
          }}
        >
          {searchResults.map((result) => (
            <div
              key={result}
              style={{ padding: "5px" }}
              onClick={() => handleResultSelection(result)}
            >
              {result}
            </div>
          ))}
        </div>
      )}
      {/* drop-down ui end*/}

      {/* tag chip with delete button -> start */}
      <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px", position: "absolute", zIndex: "-1000" }}>
        <div
          style={{
            backgroundColor: "#e9ecef",
            color: "black",
            borderRadius: "15px",
            padding: "3px",
            marginRight: "5px",
            marginBottom: "5px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>{selectedResults}</span>
          {selectedResults !== "" ? (
            <button
              className="badge badge-danger"
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => setSelectedResults("")}
            >
              <span>
                <FaTrash aria-hidden="true" />
              </span>
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* tag chip with delete button -> end */}
    </div>
  );
};

const ExportModal = (props) => {
  const context = useContext(PageContext);
  const handleChange = (e) => {
    props.changeFormat(e.target.value);
  };

  // .exportSlideId

  const handleSubmit = () => {
    let currentIndex = parseInt(localStorage.currentIndex);
    // console.log('state',context.state)
    // console.log(props.exportSlideId, props.selectedValue)
    context.state.export_Slide(props.exportSlideId.exportSlideId, props.selectedValue);
    // context.state.getSlideRecords(context.slide_name, currentIndex, context.number_of_items, props.statusExists);
    props.handleClose();
  };

  return (
    <>
      <Modal
        show={props.showExportModal}
        onHide={props.handleClose}
      >
        <Modal.Header>
          <Modal.Title>Select a Format</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <div>
              <label>
                <input
                  checked={props.selectedValue == "tif"}
                  onChange={handleChange}
                  value="tif"
                  name="format"
                  type="radio"
                />{" "}
                Tiff{" "}
              </label>
            </div>
            <div>
              <label>
                <input
                  checked={props.selectedValue == "zip"}
                  onChange={handleChange}
                  value="zip"
                  name="format"
                  type="radio"
                />{" "}
                Zoomify{" "}
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleSubmit}
          >
            Select
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const ExportRequestFormModal = (props) => {
  const { state, pageDispatcher } = useContext(PageContext);

  const [step, setStep] = useState(1);
  const [fileObj, setFileObj] = useState("");
  const [disableSubmitAfterInit, setDisableSubmitAfterInit] = useState(true);

  const [validateFileFormat, setValidateFileFormat] = useState(false);
  let currentIndex = parseInt(localStorage.currentIndex);

  const formData = new FormData();

  const handleOk = (event) => {
    event.preventDefault();
    setStep(2);

    formData.append("file", fileObj);
    formData.append("slide_id", props.exportSlideId);

    uploadRequestForm(formData)
      .then(async (res) => {
        if (res) {
          setStep(3);
        }
      })
      .catch((e) => {
        setStep(4);
      });
  };

  const handleCancel = (event) => {
    state.getSlideRecords(state.slide_name, currentIndex, state.number_of_items, props.statusExists, state.tag_search);

    setDisableSubmitAfterInit(true);
    props.handleRequestFormClose();
    setStep(1);
  };

  return (
    <>
      <Modal
        show={props.showRequestFormModal}
        onHide={handleCancel}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload a File</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            {
              {
                1: (
                  <FileUploadComponent
                    fileState={{ fileObj, setFileObj }}
                    disableAfterInit={{ disableSubmitAfterInit, setDisableSubmitAfterInit }}
                    checkFileFormat={{ validateFileFormat, setValidateFileFormat }}
                  />
                ),
                2: <ProgressComponent />,
                3: <UploadCompleted />,
                4: <UploadFailed />,
              }[step] //switch case
            }
          </div>
        </Modal.Body>

        <Modal.Footer>
          {step === 1 && (
            <button
              className="btn btn-primary"
              disabled={disableSubmitAfterInit}
              onClick={(e) => handleOk(e)}
            >
              Upload
            </button>
          )}

          {step === 2 && (
            <button
              className="btn btn-primary"
              onClick={(e) => handleCancel(e)}
            >
              Cancel
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

const FileUploadComponent = (props) => {
  const { validateFileFormat, setValidateFileFormat } = props.checkFileFormat;
  const { setDisableSubmitAfterInit } = props.disableAfterInit;

  return (
    <>
      <Form ref={props.FileUploadForm}>
        <Form.Group className="mb-3">
          <Form.Label>Upload jpeg files only:</Form.Label>
          <Form.Control
            type="file"
            required
            // name="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={(e) => {
              setDisableSubmitAfterInit(false);
              props.fileState.setFileObj(e.target.files[0]);
            }}
          />
          {validateFileFormat ? <span style={{ color: "red" }}>Please Upload .jpeg files only!</span> : <span></span>}
          <Form.Control.Feedback type="invalid">please select a file</Form.Control.Feedback>
        </Form.Group>
      </Form>
    </>
  );
};

const ProgressComponent = () => {
  return (
    <div>
      <b>File is Uploading...</b>
      <br />
      <br />
      {
        <ProgressBar
          variant="success"
          animated
        />
      }
    </div>
  );
};

const UploadCompleted = (props) => {
  return (
    <div>
      <p>File Uploaded Successfully</p>
    </div>
  );
};
const UploadFailed = (props) => {
  return (
    <div>
      <p>File Upload Failed</p>
    </div>
  );
};

const getSlideRecords = (slide_name, index, number_of_items, status, tag_search) => {
  if (status === true) {
    status = 1;
  } else if (status === false) {
    status = 0;
  }

  const obj = {
    slide_name: slide_name,
    index: index,
    number_of_items: number_of_items,
    delete_slide: status,
    tag_search: tag_search,
  };
  // console.log("get slide records");
  return axios.post(baseURL + "get_slidelist_with_pagination", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
  // return axios.get("http://127.0.0.1:8080/info.json");
};

const exportSlide = (slide_id, exportType) => {
  let obj = {
    // slide_id: slide_id.exportSlideId,
    slide_id: slide_id,
    export_type: exportType,
  };
  axios
    .post(baseURL + "export_this_slide", obj, { headers: { Authorization: `Bearer ${getToken()}` } })
    .then(async (res) => {
      if (res && res.data.Message) {
        NotificationManager.success(res.data.Message, "", 2000);
      }
    })
    .catch((e) => {});
};

const getExportedFile = async (slide_id) => {
  let obj = {
    slide_id: slide_id,
  };

  return await axios.post(baseURL + "get_exported_file", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};
const deleteStatus = (slide_id) => {
  if (window.confirm("Sure want to delete?")) {
    let obj = {
      slide_id: slide_id,
    };
    axios
      .post(baseURL + "delete_this_slide", obj, { headers: { Authorization: `Bearer ${getToken()}` } })
      .then(async (res) => {
        if (res && res.data.Message) {
          NotificationManager.success(res.data.Message, "", 2000);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
};

const uploadSlide = (slide_id) => {
  let obj = {
    slide_id: slide_id,
  };
  axios
    .post(baseURL + "upload_this_slide", obj, { headers: { Authorization: `Bearer ${getToken()}` } })
    .then(async (res) => {
      if (res && res.data.Message) {
        NotificationManager.success(res.data.Message, "", 2000);
      }
    })
    .catch((e) => {
      console.log(e);
    });
};

const cancelSlide = (slide_id) => {
  let obj = {
    slide_id: slide_id,
  };
  return axios.post(baseURL + "cancel_slide_upload", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

const hasDataUpdated = async () => {
  return await axios.get(baseURL + "time_last_update", { headers: { Authorization: `Bearer ${getToken()}` } });
};

const updateSlidename = (obj) => {
  return axios.post(baseURL + "update_slidename", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const pageReducer = (state, action) => {
  switch (action.type) {
    case "set_slideDatalist":
      return {
        ...state,
        totalRecords: action.payload.totalRecords,
        index: action.payload.index,
        last_change: action.payload.last_change,
        slideListTable: {
          ...state.slideListTable,
          row: action.payload.slidelist,
        },
      };

    case "set_Index":
      return {
        ...state,
        index: action.payload,
      };

    case "set_search":
      return {
        ...state,
        slide_name: action.payload,
      };

    case "set_clearData":
      return {
        ...state,
        slide_name: action.payload.slide_name,
        index: action.payload.index,
        number_of_items: action.payload.number_of_items,
      };

    case "set_hiddenDeletedSlide":
      state = {
        ...state,
        hideDeletedSlideStatus: action.payload,
      };
      return state;

    case "set_etaForStatus":
      return {
        ...state,
        eta_capture: parseInt(action.payload.eta_capture),
        eta_stitch: parseInt(action.payload.eta_stitch),
      };
    case "set_modalVisible":
      return {
        ...state,
        modalVisible: action.payload,
      };
    case "set_modalRequestFormVisible":
      return {
        ...state,
        modalRequestVisible: action.payload,
      };

    default:
      return state;
  }
};
export const PageContext = createContext(null);
