import React, { useContext, useEffect, useState } from "react";
import { Button, Card, FormControl, InputGroup, ListGroup, Modal } from "react-bootstrap";
import { GoSearch } from "react-icons/go";
// import { PageContext } from "../../CaseViewer";
import { addCase, addCaseForDifferentSlot, getCaselistBySearchKeywords } from "../../Service";
import { NotificationContainer, NotificationManager } from "react-notifications";

const StickyModal = ({ show, setShow, case_name, setUserProvidedCaseId, setCurrentCaseName, currentCaseName }) => {
  const [search, setSearch] = useState("");
  const [filteredTitle, setFilteredTitle] = useState([]);
  const [UpdatedCaseName, setUpdatedCaseName] = useState("");
  const [UpdatedCaseId, setUpdatedCaseId] = useState("");
  //   const { state, pageDispatcher } = useContext(PageContext);
  //   const { case_name, case_id } = state.case_info;
  const [showAddCaseInput, setShowAddCaseInput] = useState(false);
  const [newCaseName, setNewCaseName] = useState("");

  const handleSearch = async (e) => {
    setSearch(e.target.value);
    setShowAddCaseInput(false);
    try {
      const obj = { case_name: e.target.value };
      const res = await getCaselistBySearchKeywords(obj);
      setFilteredTitle(res.data.filtered_caselist);
      setUpdatedCaseName("");
      setUpdatedCaseId("");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchResult = async () => {
    try {
      const obj = { case_name: search };
      const res = await getCaselistBySearchKeywords(obj);
      setFilteredTitle(res.data.filtered_caselist);
      setUpdatedCaseName("");
      setUpdatedCaseId("");
    } catch (error) {
      console.log(error);
    }
  };
  const handleAddCase = () => {
    setShowAddCaseInput(true);
    setSearch("");
  };
  const handleSaveData = async (e) => {
    e.preventDefault();
    try {
      //   const obj = { case_id: UpdatedCaseId, slide_id: slide_id };
      //   const res = await addCaseForDifferentSlot(obj);
      setCurrentCaseName(UpdatedCaseName);
      setUpdatedCaseName("");
      setUpdatedCaseId("");
      setFilteredTitle([]);
      setSearch("");
      setShow(false);
      //   pageDispatcher.set_fresh_case_list(!state.toggleNewCase);
    } catch (error) {
      NotificationManager.error("Something went wrong ", "Notification", 2000);
      console.log(error);
    }
  };
  const handleAddNewCase = async (e) => {
    e.preventDefault();
    if (newCaseName) {
      try {
        const obj = { case_name: newCaseName };
        const res = await addCase(obj);
        if (res.data.case_id) {
          setUserProvidedCaseId(res.data.case_id);
          setCurrentCaseName(newCaseName);
          NotificationManager.success(newCaseName + " Case Added Successfully", "Notification", 2000);
        }
        setShowAddCaseInput(false);
        setNewCaseName("");
        setShow(false);
        // pageDispatcher.set_fresh_case_list(!state.toggleNewCase);
      } catch (error) {
        console.log(error);
        NotificationManager.error("Something went wrong ", "Notification", 2000);
      }
    } else {
      alert("Please enter a case name");
    }
  };
  const handleCloseModal = () => {
    setFilteredTitle([]);
    setSearch("");
    setNewCaseName("");
    setShowAddCaseInput(false);
    setUpdatedCaseName("");
    setUpdatedCaseId("");
    setShow(false);
  };

  const cardHeight = filteredTitle.length > 0 ? "140px" : "0px";

  return (
    <div>
      {/* <NotificationContainer /> */}
      <Modal
        show={show}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {/* <div>
              <p1>
                Slide name: <span style={{ fontWeight: "bold" }}>{slide_name}</span>
              </p1>
            </div> */}
            <div>
              <p1>
                Current case: <span style={{ fontWeight: "bold" }}>{currentCaseName}</span>
              </p1>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup>
            <FormControl
              placeholder="Search"
              aria-label="Search"
              value={search || ""}
              onChange={handleSearch}
            />
            <InputGroup.Append>
              <Button
                variant="outline-secondary"
                onClick={handleSearchResult}
              >
                <GoSearch />
              </Button>
              <InputGroup.Text className="mx-2">or</InputGroup.Text>
              <Button
                variant="outline-secondary"
                onClick={handleAddCase}
              >
                Add Case
              </Button>
            </InputGroup.Append>
          </InputGroup>

          {showAddCaseInput ? (
            <>
              <div style={{ margin: "10px 0" }}>{/* Add space above the FormControl */}</div>
              <FormControl
                placeholder="Enter new case name"
                aria-label="New Case Name"
                value={newCaseName}
                onChange={(e) => setNewCaseName(e.target.value)}
              />
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  variant="secondary"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={handleAddNewCase}
                >
                  Add
                </Button>
              </Modal.Footer>
            </>
          ) : (
            <div>
              {!UpdatedCaseName && (
                <Card
                  className="auto-suggestion-card"
                  style={{ height: cardHeight, width: "430px", overflowY: "scroll", zIndex: 1000, position: "absolute" }}
                >
                  <ListGroup variant="flush">
                    {filteredTitle.map((f) => (
                      <ListGroup.Item
                        key={f.case_id}
                        onClick={() => {
                          setUpdatedCaseName(f.case_name);
                          setUserProvidedCaseId(f.case_id);
                          setSearch(f.case_name);
                        }}
                        className="listItem"
                      >
                        {f.case_name}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Card>
              )}
              {UpdatedCaseName && (
                <h4 className="mt-4">
                  New case: <span style={{ fontWeight: "bold" }}>{UpdatedCaseName}</span>
                </h4>
              )}
            </div>
          )}
        </Modal.Body>
        {!showAddCaseInput && (
          <Modal.Footer className="d-flex justify-content-between">
            <Button
              variant="secondary"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            {UpdatedCaseName && (
              <Button
                variant="primary"
                onClick={handleSaveData}
              >
                Save
              </Button>
            )}
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

export default StickyModal;
