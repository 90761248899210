import React, { useContext, useState } from "react";
import { PageContext } from "../../CaseViewer";
import { Button } from "react-bootstrap";
import { HiOutlineMagnifyingGlassPlus } from "react-icons/hi2";
import { SaveButton } from "../updateSlide/Buttons";

const ZoomoffSet = () => {
  const { state, pageDispatcher } = useContext(PageContext);
  const [value, setValue] = useState(0);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleClick = () => {
    pageDispatcher.set_zoomOffSetValue(value);
    // console.log('zoomOffSetValue',typeof state.zoomOffSetValue)
  };

  const selectedMagnifyOnClick = () => {
    if (state.selectedMagnifyVisibility) {
      // console.log("selectedAnnotationBoxOnClick-false", state.selectedMagnifyVisibility);
      pageDispatcher.set_selectedMagnifyVisibility(false);
    } else {
      // console.log("selectedAnnotationBoxOnClick-true", state.selectedMagnifyVisibility);
      pageDispatcher.set_selectedMagnifyVisibility(true);
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-center align-items-center">
        <>Magnify:</>
        <button
          type="button"
          className={`btn ${!state.selectedMagnifyVisibility ? "btn-outline-secondary" : "btn-secondary"} btn-sm m-1`}
          id="selectedMagnify"
          onClick={selectedMagnifyOnClick}
          title="Magnify"
        >
          <HiOutlineMagnifyingGlassPlus />
        </button>
        <input
          placeholder="0.2"
          onChange={handleChange}
          value={value}
          style={{ width: "50px" }}
        />
        <Button
          variant="outline-primary"
          size="sm"
          onClick={handleClick}
          className="mx-1"
        >
          <SaveButton />
        </Button>
      </div>
    </div>
  );
};

export default ZoomoffSet;
