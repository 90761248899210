import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { PageContext } from "../../CaseViewer";
import { NotificationContainer, NotificationManager } from "react-notifications";
import MapContext from "../Map/MapContext";


const ZstackZoom = () => {
  const { map } = useContext(MapContext);
  const { state, pageDispatcher } = useContext(PageContext);
  const numLayers = state.selected_slide.numOfLayer;
  const max = +Number(Math.floor(numLayers / 2));
  const min = -Number(Math.floor(numLayers / 2));
  const { scale_data } = state.selected_slide;

  //   const isMapAtMaxZoom = () => {
  //   const view = map?.getView();
  //   const maxZoom = view.getMaxZoom();
  //   const currentZoom = view.getZoom();
  //   const resolution = map.getView().getResolution();
  //   let resScale = scale_data / 5.275;
  //   if (resolution<=Math.round(2.75*resScale)){
  //     pageDispatcher.set_Layer_Mode(false);
  //     return true
  //   } else {
  //     pageDispatcher.set_Layer_Mode(true);
  //     return false
  //   }
  // };


  const handleButtonClick = (increment) => {
    // pageDispatcher.set_incDecZStackValue(increment);
    // if (isMapAtMaxZoom()) {
    pageDispatcher.set_zStackValue(state.zStackValue + (increment ? 1 : -1));
  // } else {
  //   NotificationManager.info("Increase Zoom Level", "", 1000);
  // }

  };

  return (
    <div
      className='zStackZoom'
      style={{
        position: "absolute",
        right: "16px",
        top: "619px",
        margin: "0px",
        zIndex: "30",
        fontSize: "18px",
        backgroundColor: "rgba(255,255,255,0.7)",
      }}
    >
      <div className="d-flex flex-column">
        <Button
          variant="outline-secondary"
          onClick={() => handleButtonClick(true)}
          style={{
            cursor: "pointer",
          }}
          disabled={max === state.zStackValue}
        >
          +
        </Button>
        {state.zStackValue}
        <Button
          variant="outline-secondary"
          onClick={() => handleButtonClick(false)}
          style={{
            cursor: "pointer",
          }}
          disabled={min === state.zStackValue}
        >
          -
        </Button>
      </div>
    </div>
  );
};

export default ZstackZoom;
