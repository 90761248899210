import React, { useContext, useRef, useState, useEffect } from "react";
// import { PageContext } from "../List/Slides";
import { Button, Modal, Form,ProgressBar } from "react-bootstrap";
import { getGeneratedSignedURL, importSlideURL } from "../Service";
import { NotificationContainer } from "react-notifications";
import { PageContext } from "./SlideList";
import axios from "axios";
import "../../../App.css";

const chunkSize = 1000 * 1024;

export const AddUploadSlide = (props) => {
  const { state, pageDispatcher } = useContext(PageContext);

  // for cannot be empty validation
  const [AddSlideFormValidated, setAddSlideFormValidated] = useState(false);

  // for rendering ui
  const [step, setStep] = useState(1);

  //for show entered slidename "xyz" is getting uploaded.
  const [slidename, set_slidename] = useState("");
  const [jwtToken, set_jwtToken] = useState("");
  const AddSlideForm = useRef(null);
  const FileUploadForm = useRef(null);
  const [disableSubmitAfterInit, setDisableSubmitAfterInit] = useState(true);
  const [files, setFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null);


  function handleUpload(e) {
    e.preventDefault();
    setCurrentFileIndex(lastUploadedFileIndex === null ? 0 : lastUploadedFileIndex + 1);
    setStep(3);
  }

  function readAndUploadCurrentChunk() {
    const reader = new FileReader();
    const file = files[currentFileIndex];
    if (!file) {
      return;
    }
    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = file.slice(from, to);
    reader.onload = (e) => uploadChunk(e);
    reader.readAsArrayBuffer(blob);
  }

  function uploadChunk(readerEvent) {
    const file = files[currentFileIndex];
    const data = readerEvent.target.result;
    const params = new URLSearchParams();
    params.set("name", file.name);
    params.set("size", file.size);
    params.set("currentChunkIndex", currentChunkIndex);
    params.set("totalChunks", Math.ceil(file.size / chunkSize));
    params.set("chunkSize", chunkSize);
    importSlideURL(data, jwtToken, params.toString()).then((response) => {
      const file = files[currentFileIndex];
      const filesize = files[currentFileIndex].size;
      const chunks = Math.ceil(filesize / chunkSize) - 1;
      const isLastChunk = currentChunkIndex === chunks;
      if (isLastChunk) {
        file.finalFilename = response.data.finalFilename;
        setLastUploadedFileIndex(currentFileIndex);
        setCurrentChunkIndex(null);
        setStep(4);
      } else {
        setCurrentChunkIndex(currentChunkIndex + 1);
      }
    });
  }

  useEffect(() => {
    if (lastUploadedFileIndex === null) {
      return;
    }
    const isLastFile = lastUploadedFileIndex === files.length - 1;
    const nextFileIndex = isLastFile ? null : currentFileIndex + 1;
    setCurrentFileIndex(nextFileIndex);
  }, [lastUploadedFileIndex]);

  useEffect(() => {
    if (currentFileIndex !== null) {
      setCurrentChunkIndex(0);
    }
  }, [currentFileIndex]);

  useEffect(() => {
    if (currentChunkIndex !== null) {
      readAndUploadCurrentChunk();
    }
  }, [currentChunkIndex]);

  useEffect(() => {
    if (props.uploadShow.showUploadComponent) {
      setStep(1);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNext = (event) => {
    event.preventDefault();
    const form = AddSlideForm.current;

    if (props && !props.uploadShow.showUploadComponent) {
      // for generateSignedURL
      if (form.checkValidity()) {
        set_slidename(form["slidename"].value); //set slidename to showin ui
        let obj = {
          slidename: form["slidename"].value,
        };
        getGeneratedSignedURL(obj).then((res) => {
          set_jwtToken(res.data.access_token);
          setStep(step + 1);
        });
      } else {
        setAddSlideFormValidated(true); // When form is empty
      }
    }
  };

  return (
    <div>
      <NotificationContainer />
      <Modal
        show={state.modalVisible}
        onHide={() => pageDispatcher.set_modalVisible(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add / Upload Slide</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {
              {
                1: (
                  <AddSlideFormComponent
                    AddSlideFormValidated={AddSlideFormValidated}
                    AddSlideForm={AddSlideForm}
                  />
                ),
                2: (
                  <FileUploadComponent
                    files={files}
                    setFiles={setFiles}
                    FileUploadForm={FileUploadForm}
                    disableAfterInit={{ disableSubmitAfterInit, setDisableSubmitAfterInit }}
                  />
                ),
                3: (
                  <ProgressComponent
                    slideName={{ slidename, set_slidename }}
                    files={files}
                    currentFileIndex={currentFileIndex}
                    currentChunkIndex={currentChunkIndex}
                  />
                ),
                4: (
                  <UploadCompleted
                    setStep={setStep}
                    pageDispatcher={pageDispatcher}
                  />
                ),
              }[step] //switch case
            }
          </div>
        </Modal.Body>

        <Modal.Footer>
          {step === 1 && (
            <button
              className="btn btn-primary"
              onClick={(e) => handleNext(e)}
            >
              Next
            </button>
          )}

          {step === 2 && (
            <button
              className="btn btn-primary"
              disabled={disableSubmitAfterInit}
              onClick={(e) => handleUpload(e)}
            >
              Upload
            </button>
          )}
          {step === 4 && (
            <button
              className="btn btn-primary"
              disabled={disableSubmitAfterInit}
              onClick={() => {
                setLastUploadedFileIndex(null)
                setStep(1);
              }}
            >
              New Upload
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const AddSlideFormComponent = (props) => {
  return (
    <Form
      noValidate
      validated={props.AddSlideFormValidated}
      ref={props.AddSlideForm}
    >
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Slide Name:</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter slidename"
          name="slidename"
          required
        />
        <Form.Control.Feedback type="invalid">Slidename cannot be empty!</Form.Control.Feedback>
      </Form.Group>
    </Form>
  );
};
const FileUploadComponent = (props) => {
  const { setDisableSubmitAfterInit } = props.disableAfterInit;
  return (
    <>
      <Form
        noValidate
        ref={props.FileUploadForm}
      >
        <Form.Group
          controlId="formFileSm"
          className="mb-3"
        >
          <Form.Label>Upload zip/tiff files only:</Form.Label>
          <Form.Control
            type="file"
            required
            onChange={(e) => {
              setDisableSubmitAfterInit(false);
              props.setFiles([...e.target.files]);
            }}
          />
        </Form.Group>
      </Form>
    </>
  );
};
const ProgressComponent = (props) => {
  const { slidename } = props.slideName;
  return (
    <div>
      <div className="files">
        {props.files.map((file, fileIndex) => {
          const chunks = Math.ceil(file.size / chunkSize);
          let progress = Math.round((props.currentChunkIndex / chunks) * 100);
          return (
            <div key={fileIndex}>
      <b>Slide {slidename} is Uploading...</b>
      <br />
      <br />
      {progress > 0 && (
        <ProgressBar
          now={progress}
          variant="success"
          animated
          label={`${progress}%`}
        />
      )}
    </div>
          );
        })}
      </div>
    </div>
  );
};

const UploadCompleted = (props) => {
  return (
    <div>
      <p>File Uploaded Successfully</p>
    </div>
  );
};
