import React from 'react'

const ErrorSlot = () => {
  return (
    <div className='boxStyle'>
      <h1>Error in Slot</h1>
    </div>
  )
}

export default ErrorSlot
