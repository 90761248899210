import { Button, Modal, Form } from 'react-bootstrap'
import { PageContext } from "../Settings";
import { addUser, getUserList } from "../Service";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import React, { useContext, useState, useRef } from 'react'

const AddUser = () => {
    const context = useContext(PageContext);
    const openModal = () => {
        context.pageDispatcher.set_modalVisible(true)
    }

    const closeModal = () => {
        context.pageDispatcher.set_modalVisible(false)
    }

    return (
        <>
            <NotificationContainer />
            
            <a href="#!"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    openModal()
                }} >+ Add User</a>

            {
                context.pageState.modalVisible && <AddUserModal closeModal={() => closeModal()} />
            }

        </>
    )
}

export const AddUserModal = (props) => {
    const context = useContext(PageContext);
    const [validated, setValidated] = useState(false);
    const [matchPassword, setMatchPassword] = useState(false);

    const AddUserForm = useRef(null)

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = AddUserForm.current

        if (form.checkValidity()) {

            if (form['password'].value !== form['confirm_password'].value) {
                setMatchPassword(true)
            }
            else {
                let obj = {
                    username: form['username'].value,
                    password: form['password'].value
                }

                addUser(obj).then(res => {
                    if (res && res.data) {
                        getUserList(context.dispatch)
                        NotificationManager.success(res.data.Response,'',2000);
                        props.closeModal(false)
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data && error.response.data.Response) {
                        alert(`Error: ${error.response.data.Response}`);
                    } else {
                        alert('Error: Something went wrong!');
                    }
                });
            
            }


        }
        else {
            setValidated(true);
        }
    }

    return (
        <>
            <Modal show={context.pageState.modalVisible} onHide={() => props.closeModal(false)} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form noValidate validated={validated} ref={AddUserForm}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>User Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter username" name="username" required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a user name.
                              </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" name="password" required />
                            <Form.Control.Feedback type="invalid">
                                New password is required.
                             </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="confirmPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="password" placeholder="Confirm Password" name="confirm_password" required />
                            <Form.Control.Feedback type="invalid">
                                Confirm password is required.
                             </Form.Control.Feedback>
                            {
                                matchPassword &&
                                <span className="text-danger">The passwords do not match</span>
                            }
                        </Form.Group>

                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.closeModal(false)}>
                        Close
                 </Button>
                    <Button variant="primary" type="submit" onClick={(e) => handleSubmit(e)}>
                        Ok
                 </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddUser