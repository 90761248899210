import { handleStartState } from "../../Service";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useParams, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const Start = () => {
 
  const { id } = useParams();
  const { case_name } = useParams();
  const navigate = useNavigate();

  const renderTooltip = (props) => (
    <Tooltip id="back-tooltip" {...props}>
      Back
    </Tooltip>
  );

  const handleGoBack = () => {
    const currentModule = localStorage.getItem("micalys_module_v2");
    console.log("currentModule:", currentModule);
    const newUrl = `/caseview/${id}`;
    navigate(newUrl);
  };

  return (
    <>
      <div
      className="d-flex flex-column align-items-center"
        style={{ marginTop: "10px" }}
      >
        <h2>Case Name: {case_name}</h2>
        <button
          className="btn btn-primary btn-block mt-3"
          style={{width:"20%"}}
          onClick={() => {
            handleStartState().then((res) => {
              if (res.data.msg) {
                NotificationManager.success(res.data.msg, "", 2000);
              }
            });
          }}
        >
          Start Scan
        </button>
      </div>
      <div
    //   className="back-button"
      >
          <OverlayTrigger placement="bottom" overlay={renderTooltip}>
        <button className="back-button" onClick={handleGoBack}>
          <BiArrowBack size={30} />
        </button>
      </OverlayTrigger>
          {/* <BiArrowBack size={30} onClick={() => {
            backTo();
          }}/> */}
      </div>
    </>
  );

};
