import React from 'react'
import { FaCaretLeft, FaCaretRight, FaStepBackward, FaStepForward, FaSync } from 'react-icons/fa';

export const Pagination = (props) => {
    const state = props.initialState
    const totalPages = Math.ceil(state.totalRecords / state.number_of_items).toFixed()
    let showCurrentPage;
    if (state.index === 0) {
        showCurrentPage = 1
    }
    else {
        showCurrentPage = state.index + 1
    }
    return (
        <div style={{ float: 'right' }}>
            {
                state.allowPaging === true && (
                    <div className="pagination " style={{ display: 'inline-block' }}>
                        <button className="btn btn-link col-xs-3" onClick={() => props.refreshListwithSearch()} >
                        <FaSync aria-hidden="true"  />


                        </button>

                        <span className="col-xs-6">
                            |
                            <strong>
                                {showCurrentPage} of {state.totalRecords <= state.index ? 0 : totalPages}
                            </strong>| Total{' '} <strong>{state.totalRecords}</strong>
                        </span>

                        <button className="btn btn-link col-xs-3" onClick={() => props.setIndex(0)} disabled={state.index === 0} >
                        <FaStepBackward aria-hidden="true"  />

                        </button>

                        <button className="btn btn-link col-xs-3" onClick={() => props.setIndex(state.index - 1)}
                            disabled={state.index === 0}
                        >
                            <FaCaretLeft aria-hidden="true" />
                        </button>


                        <button className="btn btn-link col-xs-3" onClick={() => props.setIndex(state.index + 1)}

                            disabled={totalPages <= 0 || state.index >= totalPages - 1}
                        >
                            <FaCaretRight aria-hidden="true"  />

                        </button>

                        <button className="btn btn-link col-xs-3" onClick={() => props.setIndex(totalPages - 1)}
                            disabled={totalPages <= 0 || state.index >= totalPages - 1}
                        >
                            <FaStepForward aria-hidden="true"  />
                        </button>
                    </div>
                )

            }

        </div>
    )
}