import React, { useContext } from "react";
import { PageContext } from "../../CaseViewer";

const SelectedSlideName = () => {
  const { pageDispatcher, state } = useContext(PageContext);
  const { name } = state.selected_slide;

  return (
    <div
      style={{
        position: "absolute",
        right: "406px",
        top: "1px",
        zIndex: "30",
        fontSize: "18px",
        fontSize: "18px",
        backgroundColor: "rgba(255, 255, 255, 0.7)", 
        padding: "5px",
        borderRadius: "4px", 
      }}
    >
      <>SlideName:{name}</>
    </div>
  );
};

export default SelectedSlideName;
