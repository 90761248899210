import React, { createContext, useEffect, useReducer, useRef, useState, useContext } from "react";
import "./index.css";
import { PageTitle } from "../../shared/PageTitle";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useParams } from "react-router-dom";
import { getannotated, getListTumbnailName, getMainImage, getSlideToken, getTumbnailImage, getUploadStatus, setReupload } from "../Service";
import Thumbnail from "./Thumbnail";
import MainImage from "./MainImage";
import { Button, Modal, Image, Container, Row } from "react-bootstrap";
import Cropper from "cropperjs";
import { Pagination } from "react-bootstrap";
import { Navigation } from "../../shared/Navigation";

export const GridView = () => {
  const [accessToken, setAccessToken] = useState("");
  const [pageCountState, setPageCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const imageElement = useRef(null);

  const initialState = {
    list_name: [],
    thumbnail_img: [],
    selectedThumbnailImg: "",
    main_img: "",
    slide_token: "",
    slide_id: "",
    slideName: "",
    wsImage: "",
    modalVisible: false,
    ithofTenSelected: "",
    prevNameThumbnail: "",
    nextNameThumbnail: "",
    uploadStatus: "",
    getSlideToken: (slide_id) =>
      getSlideToken(slide_id).then((res) => {
        if (res && res.data) {
          pageDispatcher.set_slide_token(res.data.access_token);
          pageDispatcher.set_slide_name(res.data.slide_name);
          setAccessToken(res.data.access_token);
          getListName(res.data.access_token);
        }
      }),

    getannotated: (slide_token) =>
      getannotated(slide_token).then((res) => {
        if (res && res.data && res.data.byteLength > 0) {
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          let blobURL = URL.createObjectURL(blob);
          pageDispatcher.set_wsImage(blobURL);
          // dispatch({ type: "set_wsImage", payload: blobURL });
          // new Cropper(imageElement.current, {
          //   viewMode: 0,
          //   dragMode: "move",
          //   //strict: true,
          //   zoomable: true,
          //   background: true,
          //   autoCrop: false,
          //   toggleDragModeOnDblclick: false,
          //   // crop: function (e) {
          //   // },
          // });
        }
        if (res && res.data.Message) {
          NotificationManager.error(res.data.Message, "", 2000);
        }
      }),
  };

  const [state, dispatch] = useReducer(pageReducer, initialState);

  const pageDispatcher = {
    set_list_name: (value) => dispatch({ type: "set_list_name", payload: value }),
    set_thumbnail_img: (value) => dispatch({ type: "set_thumbnail_img", payload: value }),
    set_selected_thumbnail_img: (value) => dispatch({ type: "set_selected_thumbnail_img", payload: value }),
    set_main_img: (value) => dispatch({ type: "set_main_img", payload: value }),
    set_slide_token: (value) => dispatch({ type: "set_slide_token", payload: value }),
    set_slide_id: (value) => dispatch({ type: "set_slide_id", payload: value }),
    set_slide_name: (value) => dispatch({ type: "set_slide_name", payload: value }),
    set_wsImage: (value) => dispatch({ type: "set_wsImage", payload: value }),
    set_modalVisible: (value) => dispatch({ type: "set_modalVisible", payload: value }),
    set_ithofTen_selected: (value) => dispatch({ type: "set_ithofTen_selected", payload: value }),
    set_prevName: (value) => dispatch({ type: "set_prevName", payload: value }),
    set_nextName: (value) => dispatch({ type: "set_nextName", payload: value }),
    set_uploadStatus: (value) => dispatch({ type: "set_uploadStatus", payload: value }),
  };
  const { id } = useParams();
  const containerRef = useRef(null);
  let pageCount;

  const getListName = async (token) => {
    try {
      const res = await getListTumbnailName(token);
      pageDispatcher.set_list_name([res.data.filelist]);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const getThumbnailChunk = async (token, pageThumbnails, id) => {
    try {
      const images = await Promise.all(
        pageThumbnails.map((name) =>
          getTumbnailImage(name, token, id).then(async (res) => {
            // getTumbnailImage(name, token).then(async (res) => {
            let image;
            if (res && res.data && res.data.byteLength > 0) {
              let blob = new Blob([res.data], { type: res.headers["content-type"] });
              image = URL.createObjectURL(blob);
            }
            return image;
          })
        )
      );
      pageDispatcher.set_thumbnail_img(images.map((img) => img));
    } catch (err) {
      console.log(err);
    }
  };
  let pageSize = 10;
  const [activePage, setActivePage] = useState(1);
  const [inputPage, setInputPage] = useState("");
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handlePageNumberChange = (event) => {
    setInputPage(event.target.value);
  };

  const handleGoToPage = (event) => {
    event.preventDefault();
    const newPage = parseInt(inputPage);
    if (newPage >= 1 && newPage <= pageCountState) {
      setActivePage(newPage);
      setInputPage("");
    }
  };

  // click -> id nahi mil raha
  // keypress -> name nahi mil raha

  const handleKeyDown = async (event) => {
    const totalPages = Math.ceil(state.list_name[0].length / pageSize);
    const RemainderOfPages = state.list_name[0].length % pageSize;
    const { slide_id } = state;
    // console.log("totalPages:",totalPages)
    // console.log("RemainderOfPages:",RemainderOfPages)
    if (event.key === "ArrowUp") {
      if (state.ithofTenSelected > 0) {
        const res = await getMainImage(state.list_name[0][state.ithofTenSelected - 1 + 10 * activePage - 10], state.slide_token, slide_id);
        // const res = await getMainImage(state.list_name[0][state.ithofTenSelected - 1 + 10 * activePage - 10], state.slide_token);
        if (res && res.data && res.data.byteLength > 0) {
          let blob = new Blob([res.data], { type: res.headers["content-type"] });
          let image = URL.createObjectURL(blob);
          pageDispatcher.set_main_img(image);
          pageDispatcher.set_selected_thumbnail_img(state.list_name[0][state.ithofTenSelected - 1 + 10 * activePage - 10]);
          pageDispatcher.set_ithofTen_selected(state.ithofTenSelected - 1);
        }
      } else {
        if (activePage > 1) {
          const res = await getMainImage(state.list_name[0][state.ithofTenSelected - 1 + 10 * activePage - 10], state.slide_token, slide_id);
          // const res = await getMainImage(state.list_name[0][state.ithofTenSelected - 1 + 10 * activePage - 10], state.slide_token);
          if (res && res.data && res.data.byteLength > 0) {
            let blob = new Blob([res.data], { type: res.headers["content-type"] });
            let image = URL.createObjectURL(blob);
            pageDispatcher.set_main_img(image);
            pageDispatcher.set_selected_thumbnail_img(state.list_name[0][state.ithofTenSelected - 1 + 10 * activePage - 10]);
            pageDispatcher.set_ithofTen_selected(9);
            setActivePage(activePage - 1);
          }
        }
      }
    } else if (event.key === "ArrowDown") {
      if (state.ithofTenSelected < 9) {
        if (activePage < totalPages) {
          const res = await getMainImage(state.list_name[0][state.ithofTenSelected + 1 + 10 * activePage - 10], state.slide_token, slide_id);
          // const res = await getMainImage(state.list_name[0][state.ithofTenSelected + 1 + 10 * activePage - 10], state.slide_token);
          if (res && res.data && res.data.byteLength > 0) {
            let blob = new Blob([res.data], { type: res.headers["content-type"] });
            let image = URL.createObjectURL(blob);
            pageDispatcher.set_main_img(image);
            pageDispatcher.set_selected_thumbnail_img(state.list_name[0][state.ithofTenSelected + 1 + 10 * activePage - 10]);
            pageDispatcher.set_ithofTen_selected(state.ithofTenSelected + 1);
          }
        } else if (state.ithofTenSelected < RemainderOfPages - 1 && activePage === totalPages) {
          const res = await getMainImage(state.list_name[0][state.ithofTenSelected + 1 + 10 * activePage - 10], state.slide_token, slide_id);
          // const res = await getMainImage(state.list_name[0][state.ithofTenSelected + 1 + 10 * activePage - 10], state.slide_token);
          if (res && res.data && res.data.byteLength > 0) {
            let blob = new Blob([res.data], { type: res.headers["content-type"] });
            let image = URL.createObjectURL(blob);
            pageDispatcher.set_main_img(image);
            pageDispatcher.set_selected_thumbnail_img(state.list_name[0][state.ithofTenSelected + 1 + 10 * activePage - 10]);
            pageDispatcher.set_ithofTen_selected(state.ithofTenSelected + 1);
          }
        }
      } else {
        if (activePage < totalPages) {
          const res = await getMainImage(state.list_name[0][state.ithofTenSelected + 1 + 10 * activePage - 10], state.slide_token, slide_id);
          // const res = await getMainImage(state.list_name[0][state.ithofTenSelected + 1 + 10 * activePage - 10], state.slide_token);
          if (res && res.data && res.data.byteLength > 0) {
            let blob = new Blob([res.data], { type: res.headers["content-type"] });
            let image = URL.createObjectURL(blob);
            pageDispatcher.set_main_img(image);
            pageDispatcher.set_selected_thumbnail_img(state.list_name[0][state.ithofTenSelected + 1 + 10 * activePage - 10]);
            pageDispatcher.set_ithofTen_selected(0);
            setActivePage(activePage + 1);
          }
        }
      }
    } else if (event.key === "ArrowRight") {
      if (activePage < totalPages) {
        const res = await getMainImage(state.list_name[0][state.ithofTenSelected + 10 * activePage], state.slide_token, state.slide_id);
        // const res = await getMainImage(state.list_name[0][state.ithofTenSelected + 10 * activePage], state.slide_token);
        if (res && res.data && res.data.byteLength > 0) {
          let blob = new Blob([res.data], { type: res.headers["content-type"] });
          let image = URL.createObjectURL(blob);
          pageDispatcher.set_main_img(image);
          pageDispatcher.set_selected_thumbnail_img(state.list_name[0][state.ithofTenSelected + 10 * activePage]);
          pageDispatcher.set_ithofTen_selected(state.ithofTenSelected);
          if (activePage !== totalPages) {
            setActivePage(activePage + 1);
          }
        }
      }
    } else if (event.key === "ArrowLeft") {
      if (activePage > 1) {
        const res = await getMainImage(state.list_name[0][state.ithofTenSelected + 10 * activePage - 10 - 10], state.slide_token, state.slide_id);
        // const res = await getMainImage(state.list_name[0][state.ithofTenSelected + 10 * activePage - 10 - 10], state.slide_token);
        if (res && res.data && res.data.byteLength > 0) {
          let blob = new Blob([res.data], { type: res.headers["content-type"] });
          let image = URL.createObjectURL(blob);
          pageDispatcher.set_main_img(image);
          pageDispatcher.set_selected_thumbnail_img(state.list_name[0][state.ithofTenSelected + 10 * activePage - 10 - 10]);
          pageDispatcher.set_ithofTen_selected(state.ithofTenSelected);
          setActivePage(activePage - 1);
        }
      }
    }
  };

  const handleUploadStatus = async (event) => {
    // event.preventDefault();
    try {
      const res = await getUploadStatus(state.slide_token);
      // console.log(res.data.status);
      pageDispatcher.set_uploadStatus(res.data.status);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReupload = async (event) => {
    // event.preventDefault();
    try {
      const res = await setReupload(state.slide_token);
      NotificationManager.success(res.data.msg, "", 2000);
      // pageDispatcher.set_uploadStatus(res.data.status);
    } catch (error) {
      NotificationManager.error("error occured", "", 2000);
    }
  };

  const handleFocus = () => {
    containerRef.current.focus();
  };

  useEffect(() => {
    if (state.main_img !== "") {
      var scale = 1,
        panning = false,
        pointX = 0,
        pointY = 0,
        start = { x: 0, y: 0 },
        zoom = document.getElementById("zoom");

      function setTransform() {
        zoom.style.transform = "translate(" + pointX + "px, " + pointY + "px) scale(" + scale + ")";
      }

      zoom.onmousedown = function (e) {
        e.preventDefault();
        start = { x: e.clientX - pointX, y: e.clientY - pointY };
        panning = true;
      };

      zoom.onmouseup = function (e) {
        panning = false;
      };

      zoom.onmousemove = function (e) {
        e.preventDefault();
        if (!panning) {
          return;
        }
        pointX = e.clientX - start.x;
        pointY = e.clientY - start.y;
        setTransform();
      };

      zoom.onwheel = function (e) {
        e.preventDefault();
        var xs = (e.clientX - pointX) / scale,
          ys = (e.clientY - pointY) / scale,
          delta = e.wheelDelta ? e.wheelDelta : -e.deltaY;
        delta > 0 ? (scale *= 1.1) : (scale /= 1.1);
        pointX = e.clientX - xs * scale;
        pointY = e.clientY - ys * scale;

        setTransform();
      };
    }
  }, [state.main_img]);

  useEffect(() => {
    if (id) {
      state.getSlideToken(id);
      pageDispatcher.set_slide_id(id);
    }
  }, [id]);

  useEffect(() => {
    if (!state.wsImage) return;
    new Cropper(imageElement.current, {
      viewMode: 0,
      dragMode: "move",
      //strict: true,
      zoomable: true,
      background: true,
      autoCrop: false,
      toggleDragModeOnDblclick: false,
      // crop: function (e) {
      // },
    });
  }, [state.wsImage]);

  useEffect(() => {
    if (state.list_name[0]) {
      pageCount = Math.ceil(state.list_name[0].length / pageSize);
      setPageCount(pageCount);
      const startIndex = (activePage - 1) * pageSize;
      const endIndex = startIndex + pageSize - 1;
      const pageThumbnails = state.list_name[0].slice(startIndex, endIndex + 1);
      getThumbnailChunk(state.slide_token, pageThumbnails, state.slide_id);
    }
    // setActivePage(1);
  }, [state.list_name, activePage, state.slide_id]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    handleUploadStatus();
  }, [show]);

  return (
    <>
      <Navigation />
      {loading && <div className="loader"></div>}
      {!loading && (
        <PageContext.Provider value={{ state, pageDispatcher }}>
          <NotificationContainer />
          <div>
            <div className="container-fluid">
              <PageTitle
                title={state.slideName}
                backLink="/gridCaptureList"
              ></PageTitle>
            </div>

            <div className="d-flex justify-content-center align-items-center gap-3">
              <OverViewComponent imageElement={imageElement} />
              <>
                &nbsp;&nbsp;&nbsp;
                <Button
                  size="sm"
                  // onClick={handleUploadStatus}
                  onClick={handleShow}
                >
                  Upload Status
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  className="d-flex gap-2"
                  size="sm"
                  onClick={handleReupload}
                  // onClick={handleShow}
                >
                  Re-Upload
                </Button>
                <Modal
                  show={show}
                  onHide={handleClose}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Upload Status</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{state.uploadStatus}</Modal.Body>
                </Modal>
              </>
            </div>
          </div>
          {/* state.slide_id */}
          <div className=" app-container">
            <div className="d-flex ">
              <div className="gallery-container">
                <MainImage />
              </div>
              {state.list_name[0].length !== 0 && (
                <div className="d-flex flex-column">
                  <div
                    className="thumbnail-container m-3 p-0"
                    onKeyDown={handleKeyDown}
                    onFocus={handleFocus}
                    ref={containerRef}
                    tabIndex={0}
                  >
                    <Thumbnail
                      accessToken={accessToken}
                      activePage={activePage}
                    />
                  </div>

                  <div className="d-flex justify-content-center">
                    <Pagination>
                      <Pagination.First
                        disabled={activePage === 1}
                        onClick={() => handlePageChange(1)}
                      />
                      <Pagination.Prev
                        disabled={activePage === 1}
                        onClick={() => handlePageChange(activePage - 1)}
                      />

                      {[...Array(pageCountState)].map((_, index) => {
                        const pageNumber = index + 1;
                        return (
                          activePage - 2 < index &&
                          index < activePage + 5 && (
                            <Pagination.Item
                              key={pageNumber}
                              active={pageNumber === activePage}
                              onClick={() => handlePageChange(pageNumber)}
                            >
                              {pageNumber}
                            </Pagination.Item>
                          )
                        );
                      })}

                      <Pagination.Next
                        disabled={activePage === pageCountState}
                        onClick={() => handlePageChange(activePage + 1)}
                      />
                      <Pagination.Last
                        disabled={activePage === pageCountState}
                        onClick={() => handlePageChange(pageCountState)}
                      />
                    </Pagination>
                  </div>

                  <div>
                    <form onSubmit={handleGoToPage}>
                      <label htmlFor="page-number">Page Number:</label>
                      <input
                        type="number"
                        id="page-number"
                        name="page-number"
                        min="1"
                        max={pageCountState}
                        value={inputPage}
                        onChange={handlePageNumberChange}
                      />
                    </form>
                    {state.selectedThumbnailImg && <p>Image: {state.selectedThumbnailImg}</p>}
                  </div>
                </div>
              )}
              {state.list_name[0].length === 0 && (
                <div className="thumbnails-list scroll mx-5">
                  <h5>Sorry, No thumbnails to show</h5>
                </div>
              )}
            </div>
          </div>
        </PageContext.Provider>
      )}
    </>
  );
};

export const pageReducer = (state, action) => {
  switch (action.type) {
    case "set_list_name":
      return {
        ...state,
        list_name: action.payload,
      };
    case "set_thumbnail_img":
      return {
        ...state,
        thumbnail_img: action.payload,
      };
    case "set_selected_thumbnail_img":
      return {
        ...state,
        selectedThumbnailImg: action.payload,
      };
    case "set_main_img":
      return {
        ...state,
        main_img: action.payload,
      };
    case "set_slide_token":
      return {
        ...state,
        slide_token: action.payload,
      };
    case "set_slide_id":
      return {
        ...state,
        slide_id: action.payload,
      };
    case "set_slide_name":
      return {
        ...state,
        slideName: action.payload,
      };

    case "set_wsImage":
      return {
        ...state,
        wsImage: action.payload,
      };
    case "set_modalVisible":
      return {
        ...state,
        modalVisible: action.payload,
      };
    case "set_ithofTen_selected":
      return {
        ...state,
        ithofTenSelected: action.payload,
      };
    case "set_prevName":
      return {
        ...state,
        prevNameThumbnail: action.payload,
      };
    case "set_nextName":
      return {
        ...state,
        nextNameThumbnail: action.payload,
      };
    case "set_uploadStatus":
      return {
        ...state,
        uploadStatus: action.payload,
      };
    default:
      return state;
  }
};

const OverViewComponent = (props) => {
  const context = useContext(PageContext);
  return (
    <>
      <a
        href="#!"
        style={{ float: "right" }}
        onClick={(e) => {
          context.state.getannotated(context.state.slide_token);
          context.pageDispatcher.set_modalVisible(true);
        }}
      >
        <h6>OverView</h6>
      </a>

      {context && context.state.wsImage && (
        <Modal
          show={context.state.modalVisible}
          onHide={() => context.pageDispatcher.set_modalVisible(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>WS Image</Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <Row>
                <Image
                  className="img-container"
                  src={context.state.wsImage}
                  alt=""
                  id="img"
                  ref={props.imageElement}
                />
              </Row>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => context.pageDispatcher.set_modalVisible(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export const PageContext = createContext(null);
