import React, { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { AiOutlineDelete, AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { PageContext } from "../../CaseViewer";
import { deleteViewTracking } from "../../../Service";

const OverviewMapAreaTracker = () => {
  const { state, pageDispatcher } = useContext(PageContext);
  // const [toggleAnnotation, setToggleAnnotation] = useState(state.colorBox.hide);
  // const [toggleSwitch, setToggleSwitch] = useState(false);
  // const [togglePopup, setTogglePopup] = useState(false);
  const { token } = state.selected_slide;

  const hideAnnotation = () => {
    // setToggleAnnotation((previoustoggleAnnotation) => !previoustoggleAnnotation);
    pageDispatcher.set_colorBox({ hide: !state.colorBox.hide, start: state.colorBox.start, delete: false, togglePopup: state.colorBox.togglePopup });
  };
  const StartAnnotation = () => {
    // setToggleSwitch((previousttoggleSwitch) => !previousttoggleSwitch);
    pageDispatcher.set_colorBox({ start: !state.colorBox.start, hide: state.colorBox.hide, delete: false, togglePopup: state.colorBox.togglePopup });
  };
  const handlePopup = () => {
    // setTogglePopup((previoustogglePopup) => !previoustogglePopup);
    pageDispatcher.set_colorBox({ start: state.colorBox.start, hide: state.colorBox.hide, delete: false, togglePopup: state.colorBox.togglePopup });
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this View Tracked?")) {
      try {
        const res = await deleteViewTracking(token);
        if (res.status === 200) {
          pageDispatcher.set_colorBox({ start: state.colorBox.start, hide: state.colorBox.hide, delete: true,togglePopup: state.colorBox.togglePopup });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const titleHide = state.colorBox.hide ? "unhide" : "hide";
  const titleStart = state.colorBox.start ? "stop" : "start";

  // console.log("rerender toggleAnnotation:",state.colorBox.hide)

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <div
          className="button-container"
          style={{ display: "flex", justifyContent: "start" }}
        >
          <button
            style={{ border: "none", background: "none", paddingRight: "10px" }}
            onClick={hideAnnotation}
            title={titleHide}
          >
            {state.colorBox.hide ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
          </button>
          <button
            style={{ border: "none", background: "none", paddingRight: "10px" }}
            onClick={StartAnnotation}
            title={titleStart}
          >
            {state.colorBox.start ? <BsToggleOn /> : <BsToggleOff />}
          </button>
          <button
            style={{ border: "none", background: "none", paddingRight: "10px" }}
            title="Delete"
            onClick={handleDelete}
          >
            <AiOutlineDelete />
          </button>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="overviewMapAreaTracker">
      <OverlayTrigger
        trigger="click"
        placement="left"
        overlay={popover}
      >
        <button
          style={{
            position: "absolute",
            right: "16px",
            // top: "949px",
            bottom: "5px",
            margin: "0px",
            zIndex: "30",
            border: "none",
            background: "none",
          }}
          onClick={handlePopup}
        >
          {state.colorBox.togglePopup ? <MdOutlineKeyboardDoubleArrowRight /> : <MdOutlineKeyboardDoubleArrowLeft />}
        </button>
      </OverlayTrigger>
    </div>
  );
};

export default OverviewMapAreaTracker;
