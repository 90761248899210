import React from "react";
import { add_admin_device_config } from "../Service";
import { NotificationManager } from "react-notifications";

const AutoCaptureAdminSettings = ({
  lens,
  quality,
  noOfLayers,
  isNameRequired,
  setIsNameRequired,
  setNoOfLayers,
  setQuality,
  setLens,
}) => {
  const handleOkClick = async () => {
    const obj = {
      lens: parseInt(lens),
      q: parseInt(quality),
      z_stack: parseInt(noOfLayers || 1),
      is_name_required: isNameRequired ? parseInt(1) : parseInt(0),
      
    };
    try {
      const res = await add_admin_device_config(obj);
      if (res.status === 200) NotificationManager.success("updated the setting", "", 2000);
    } catch (error) {
      NotificationManager.error("error occurred", "", 2000);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleZStackChange = (e) => {
    setNoOfLayers(e.target.value);
  };
  const options = [1, 3, 5, 7, 9, 11];

  const handleCheckboxChange = (e) => {
    setIsNameRequired(e.target.checked);
  };

  return (
    <div>
      <div className="row d-flex justify-content-center">
        <div className="col-md-5 col-sm-11 offset-sm-1">
          <form onSubmit={handleSubmit}>
            <div className="">
               
              <div className="d-flex light-gray-strip mb-3">
                <label
                  htmlFor="inputPassword3"
                  className="col-md-4 col-lg-3 col-form-label"
                  style={{fontWeight: "bold"}}
                >
                  Choose Quality:
                </label>

                <div className="d-flex justify-content-between row col-md-8 col-lg-7 mt-2">

                  <div className="col-md-4 col-lg-4 col-sm-12 ">
                    <div className="form-check pull-left">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="quality"
                        id="check1"
                        value="1"
                        style={{ marginRight: '5px' }}
                        checked={quality === "1"}
                        onChange={(e) => {
                          setQuality(e.target.value);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="check1"
                      >
                        Standard
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="quality"
                        id="check2"
                        value="2"
                        style={{ marginRight: '5px' }}
                        checked={quality === "2"}
                        onChange={(e) => {
                          setQuality(e.target.value);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="check2"
                      >
                        High
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="quality"
                        id="check3"
                        value="3"
                        style={{ marginRight: '5px' }}
                        checked={quality === "3"}
                        onChange={(e) => {
                          setQuality(e.target.value);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="check3"
                      >
                        Best
                      </label>
                    </div>
                  </div>
                </div>
              </div>



              <div className="d-flex light-gray-strip mb-3">
                <label
                  htmlFor="inputPassword3"
                  className="col-md-4 col-lg-3 col-form-label"
                  style={{ fontWeight: "bold" }}
                >
                  Lens:
                </label>
                <div className="d-flex justify-content-between row col-md-8 col-lg-7 mt-2">
                  <div className="col-md-5 col-lg-4 col-sm-12 ">
                    <div className="form-check pull-left">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="lens"
                        id="check1"
                        value="10"
                        checked={lens === "10"}
                        onChange={(e) => {
                          setLens(e.target.value);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="check1"
                      >
                        10X
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3 col-sm-12">
                    <div className="form-check pull-left">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="lens"
                        id="check2"
                        value="20"
                        checked={lens === "20"}
                        onChange={(e) => {
                          setLens(e.target.value);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="check2"
                      >
                        20X
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3 col-sm-12">
                    <div className="form-check pull-left">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="lens"
                        id="check3"
                        value="40"
                        checked={lens === "40"}
                        onChange={(e) => {
                          setLens(e.target.value);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="check3"
                      >
                        40X
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3 col-sm-12">
                    <div className="form-check pull-left">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="lens"
                        id="check3"
                        value="100"
                        checked={lens === "100"}
                        onChange={(e) => {
                          setLens(e.target.value);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="check3"
                      >
                        100X
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3 col-sm-12">
                    <div className="form-check pull-left">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="lens"
                        id="check3"
                        value="240"
                        checked={lens === "240"}
                        onChange={(e) => {
                          setLens(e.target.value);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="check3"
                      >
                        40X(NC)
                      </label>
                    </div>
                  </div>
                </div>
              </div>



              <div className="d-flex align-items-center light-gray-strip mb-3">
                <p style={{ fontWeight: "bold" }} className="mb-2 mt-2 col-md-4 col-lg-3 mb-0">zstack:</p>
                <select
                  className="custom-select custom-select-sm ml-3 col-md-8 col-lg-6"
                  style={{ width: "30%" }}
                  value={noOfLayers}
                  onChange={handleZStackChange}
                >
                  {options.map((option, index) => (
                    <option
                      key={index}
                      value={option}
                    >
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              <div className=" d-flex light-gray-strip mb-3">
                <input
                  type="checkbox"
                  checked={isNameRequired}
                  onChange={handleCheckboxChange}
                  style={{ marginRight: '5px' }}
                />
                <span className="ml-2">Name Required</span>
              </div>
            </div>
          </form>

          <div className="row justify-content-center mt-3">
            <div className="col-2">
              {/* className="col-md-6 col-sm-4" */}
              <button
                type="button"
                size="lg"
                id="btn"
                className="btn btn-primary btn-block"
                onClick={handleOkClick}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoCaptureAdminSettings;
