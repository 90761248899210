import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { NotificationManager } from "react-notifications";
import { updateCaseData } from "../../../Service";
import { PageContext } from "../../CaseViewer";
import { CancelButton, SaveButton } from "../updateSlide/Buttons";

const CaseFinding = () => {
  const { state, pageDispatcher } = useContext(PageContext);
  const { case_findings, case_token } = state.case_info;

  const [isEditing, setIsEditing] = useState(false);
  const [caseFinding, setCaseFinding] = useState(case_findings);
  const [originalCaseFinding, setOriginalCaseFinding] = useState(case_findings);
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    setCaseFinding(case_findings);
  }, [case_findings]);

  const handleCaseFindingChange = (event) => {
    setCaseFinding(event.target.value);
  };

  const handleEditButtonClick = () => {
    setOriginalCaseFinding(caseFinding);
    setIsEditing(true);
  };

  const handleSaveButtonClick = async () => {
    const obj = {
      case_findings: caseFinding,
    };
    try {
      const res = await updateCaseData(obj, case_token);
      setIsEditing(false);
      if (res && res.data.Message) {
        NotificationManager.success(res.data.Message, "", 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelButtonClick = () => {
    setCaseFinding(originalCaseFinding);
    setIsEditing(false);
  };

  return (
    <div className="mb-1">
      {isEditing ? (
        <div>
          <textarea
            type="text"
            value={caseFinding}
            onChange={handleCaseFindingChange}
          />
          <Button
            variant="outline-primary"
            size="sm"
            onClick={handleSaveButtonClick}
            className="mx-1"
          >
            <SaveButton/>
          </Button>
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={handleCancelButtonClick}
            className="mx-1"
          >
            <CancelButton/>
          </Button>
        </div>
      ) : (
        <div className="d-flex justify-content-start align-items-center">
          <span>Case Findings: {caseFinding.length > 25 ? caseFinding.slice(0, 25) + "..." : caseFinding}</span>
          <sup>
            <MdOutlineModeEditOutline
              size={15}
              className={isHovered ? "edit-icon hovered" : "edit-icon"}
              onMouseEnter={handleHover}
              onMouseLeave={handleMouseLeave}
              onClick={handleEditButtonClick}
            />
          </sup>
        </div>
      )}
    </div>
  );
};

export default CaseFinding;
