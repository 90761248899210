import React, { useReducer, createContext, useContext, useEffect, useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { useLocation } from "react-router-dom";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Multiselect } from "multiselect-react-dropdown";
import { environment } from "../../../environment";
import { getSelectedChipset, getToken } from "../../Utils/Common";
import axios from "axios";
import { AddCaseDetails } from "./AddCaseDetails";
import { getAllTags, setAssignedTo, setDeassignTo, updateCaseReportingStatus, updateSlideActiveDays } from "../Service";
import { PaginationCaselist } from "../../shared/PaginationCaselist";
import { Navigation } from "../../shared/Navigation";
// import CustomDropDown from "../../shared/DropDown";
import { Button, Dropdown, DropdownButton, Form, Modal } from "react-bootstrap";
import { FaBriefcaseMedical, FaExclamationTriangle, FaPlusCircle, FaRegCheckSquare, FaRegEdit, FaSearch, FaSpinner } from "react-icons/fa";
import { BsPeopleFill } from "react-icons/bs";
import { BiShow } from "react-icons/bi";
import { getSlideActiveDays } from "../../Settings/Service";

const baseURL = environment.MICALYS_POSTGRES_API;
const baseURLV2 = environment.MICALYS_API_V2;

export const CaseList = () => {
  // const location = useLocation();

  const initialState = {
    // showmsg: location.state && location.state.showMessage ? true : false,
    allowPaging: true,
    ifOwner: false,
    totalRecords: 0,
    last_change: 0.0,
    disk_use: 0,
    index: 0,
    userList: [],
    case_name: "",
    patient_id: "",
    number_of_items: 10,
    assigned_to: "all",
    modalVisible: false,
    caseListTable: {
      row: [],
    },
    hideDeletedCaseStatus: true,

    getCaseRecords: (case_name, patient_id, index, number_of_items, status, assigned_to) =>
      getCaseRecords(case_name, patient_id, index, number_of_items, status, assigned_to).then((res) => {
        if (res && res.data) {
          // dispatch({
          //   type: "set_caseDatalist",
          //   payload: {
          //     ifOwner: res.data.owner,
          //     caselist: res.data.caselist,
          //     totalRecords: res.data.total_records,
          //     index: index,
          //     last_change: parseFloat(res.data.last_change),
          //   },
          // });

          pageDispatcher.set_caseDatalist({
            caselist: res.data.caselist,
            // ifOwner: res.data.owner,
            // totalRecords: res.data.total_records,
            // index: index,
            // last_change: parseFloat(res.data.last_change),
          });
          pageDispatcher.set_caseData({
            ifOwner: res.data.owner,
            totalRecords: res.data.total_records,
            index: index,
            last_change: parseFloat(res.data.last_change),
          });
          state.last_change = res.data.last_change;
          state.case_name = case_name;
          state.patient_id = patient_id;
          state.index = index;
          state.number_of_items = number_of_items;
          state.assigned_to = assigned_to;
          state.totalRecords = res.data.total_records;
          state.ifOwner = res.data.owner;
        }
      }),
    updateIndex: function (indexValue) {
      dispatch({ type: "set_Index", payload: indexValue });
    },
    setSearch: (searchText) => dispatch({ type: "set_search", payload: searchText }),
    clearData: () => {
      dispatch({
        type: "set_clearData",
        payload: { case_name: "", patient_id: "", index: 0, number_of_items: state.number_of_items, assigned_to: state.assigned_to },
      });
    },
    deleteStatus: (slide_id) => deleteStatus(slide_id),
    hasDataUpdated: () =>
      hasDataUpdated().then((res) => {
        if (res && res.data) {
          if (res.data.last_change !== state.last_change) {
            dispatch({ type: "set_etaForStatus", payload: res.data });
            // console.log("postMessage:",res.data.last_change)

            broadcastChannel.postMessage(res.data);

            let currentIndex = parseInt(localStorage.currentIndex);
            state.getCaseRecords(state.case_name, state.patient_id, currentIndex, state.number_of_items, true, state.assigned_to);
          }
          state.last_change = res.data.last_change;
          state.disk_use = res.data.disk_use;
        }
      }),
    setHiddenDeletedCaseStatus: (value) => dispatch({ type: "set_hiddenDeletedSlide", payload: value }),
  };

  const [state, dispatch] = useReducer(pageReducer, initialState);
  // console.log("state.caseListTable.row", state.totalRecords);

  const pageDispatcher = {
    set_modalVisible: (value) => dispatch({ type: "set_modalVisible", payload: value }),
    set_user_list: (value) => dispatch({ type: "set_user_list", payload: value }),
    set_caseDatalist: (value) => dispatch({ type: "set_caseDatalist", payload: value }),
    set_caseData: (value) => dispatch({ type: "set_caseData", payload: value }),
    // set_modalRequestFormVisible: (value) => dispatch({ type: "set_modalRequestFormVisible", payload: value }),
  };

  const broadcastChannel = new BroadcastChannel("my_broadcast_channel");

  useEffect(() => {
    dispatch({ type: "set_Index", payload: 0 });
    state.getCaseRecords(state.case_name, state.patient_id, state.index, state.number_of_items, state.hideDeletedSlideStatus, state.assigned_to);
    // state.hasDataUpdated();
    // const interval = setInterval(() => {
    //   if (state.ifOwner) {
    //     state.hasDataUpdated();
    //   }
    // }, 5000);

    // return () => clearInterval(interval);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Navigation />
      <div className="container-fluid">
        <PageContext.Provider value={{ state, pageDispatcher }}>
          <NotificationContainer />
          <CaseListComponent />
        </PageContext.Provider>
      </div>
    </>
  );
};

const CaseListComponent = () => {
  const { state, pageDispatcher } = useContext(PageContext);
  // const { case_id } = state.case_info;
  localStorage.setItem("currentIndexCaseList", state.index);
  const [caseNameSearch, setCaseNameSearch] = useState("");
  const [pateintIdSearch, setPateintIdSearch] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  let statusExists;
  statusExists = state.hideDeletedCaseStatus;
  const multiselectElement = useRef(null);
  const [initialTagList, setinitialTagList] = useState([]);
  const [isCaseNameEditable, setIsCaseNameEditable] = useState(false);
  const [selectedOption, setSelectedOption] = useState("CaseName");

  const handleDropdownChange = (event) => {
    setSelectedOption(event);
  };

  const fetchTagsAndUerList = async () => {
    axios.get(baseURL + "list_users", { headers: { Authorization: `Bearer ${getToken()}` } }).then((res) => {
      const userListArray = res.data;
      const changeToArray = userListArray.map((a) => a.username);
      pageDispatcher.set_user_list(changeToArray);
    });
    const result = await getAllTags();
    setinitialTagList(result.data.tags);
  };

  const handleReportingStatus = (val, case_id) => {
    let obj = {
      case_id: case_id,
      report_status: val,
    };
    updateCaseReportingStatus(obj).then((res) => {
      if (res && res.data.Message) {
        NotificationManager.success(res.data.Message, "", 2000);
      }
    });
  };

  const CustomCellRenderer = (params) => {
    const [showModal, setShowModal] = useState(false);
    const [slideExpiryDate, setSlideExpiryDate] = useState("");
    const [showSlideExpiryDate, setShowSlideExpiryDate] = useState(false);
    const [value, setValue] = useState();
    const [activeDays, setActiveDays] = useState("");
    const [expiryDate, setExpiryDate] = useState("");

    // let date = params.data.expiry_date;

    // console.log('params.rowIndex, params.data.fieldName, params.value',params.rowIndex, params.data.fieldName, params.value)

    const handleInputChange = (e) => {
      const { value } = e.target;
      value > 0 ? setValue(value) : setValue("");
    };

    const fetchSlideActiveDaysInfo = async () => {
      const res = await getSlideActiveDays();
      // console.log('res.data.slide_active_days',res.data.slide_active_days)
      setSlideExpiryDate(res.data.slide_active_days);
      setValue(res.data.slide_active_days);
    };

    useEffect(() => {
      if (showModal === true) fetchSlideActiveDaysInfo();
    }, []);

    useEffect(() => {
      slideExpiryDate === -1 ? setShowSlideExpiryDate(true) : setShowSlideExpiryDate(false);
    }, [slideExpiryDate]);

    useEffect(() => {
      showSlideExpiryDate === true ? setValue(-1) : setValue("");
    }, [showSlideExpiryDate]);

    // useEffect(() => {
    //   showSlideExpiryDate === true ? setValue(-1) : setValue(value);
    // }, [showSlideExpiryDate, value]);

    const handleCheckboxChange = (e) => {
      const { checked } = e.target;
      setShowSlideExpiryDate(checked);
      checked ? setSlideExpiryDate(-1) : setSlideExpiryDate(0);
    };

    const handleUpdateSlideActiveDays = async () => {
      const obj = {
        case_id: params.data.case_id,
        slide_active_days: value,
      };
      try {
        // console.log('obj-ACTIVEDAYS',obj)
        const res = await updateSlideActiveDays(obj);
        setExpiryDate(res.data.expiry_time);
        setActiveDays(value);
        if (res.data.Message) NotificationManager.success(res.data.Message, "", 2000);
      } catch (error) {
        console.log(error);
      }
      setShowModal(false);
    };

    const handleCloseModal = () => {
      setShowModal(false);
      // setValue("")
    };

    const handleOpenModal = () => {
      setShowModal(true);
      setValue(params.data.slide_active_days);
    };

    let date = expiryDate !== "" ? expiryDate : params.data.expiry_date;

    return (
      <>
        <div
          className=""
          onClick={handleOpenModal}
        >
          <>
            {date === -1 ? (
              <></>
            ) : (
              <>
                <p
                  className="m-0"
                  style={{ lineHeight: "1.5" }}
                >
                  {date.split(" ")[0]}
                </p>
                <p
                  className="m-0"
                  style={{ lineHeight: "1.5" }}
                >
                  {date.split(" ")[1]}
                </p>
              </>
            )}
          </>
          {params.data.slide_active_days === -1 ? (
            <>NA</>
          ) : (
            <>{activeDays ? activeDays === -1 ? <>NA</> : `${activeDays} Days` : `${params.data.slide_active_days} Days`}</>
          )}
        </div>

        <Modal
          show={showModal}
          onHide={handleCloseModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Slide Active Days</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="d-flex justify-content-around align-items-center">
                <Form.Group controlId="exampleForm.Checkbox1">
                  <Form.Check
                    type="checkbox"
                    label="never"
                    onChange={handleCheckboxChange}
                    checked={showSlideExpiryDate}
                  />
                </Form.Group>

                <Form.Group
                  controlId="exampleForm.ControlInput1"
                  className="d-flex justify-content-around align-items-center"
                >
                  <Form.Control
                    type="number"
                    value={value}
                    onChange={handleInputChange}
                    name="slide_expiry_date"
                    disabled={slideExpiryDate === -1}
                    autoFocus
                  />
                </Form.Group>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={handleUpdateSlideActiveDays}
              // disabled={value <= 0}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  useEffect(() => {
    fetchTagsAndUerList();
  }, []);

  const columnDefs = [
    {
      headerName: "Case Name",
      field: "case_name",
      sortable: true,
      width: 200,
      wrapText: true,
      editable: isCaseNameEditable,
      autoHeight: true,
      suppressMovable: true,
      cellRenderer: (params) => (
        <div className="d-flex justify-content-between align-items-center">
          <button
            onClick={() => checkStatusForCaseView(params)}
            style={{ color: "#008cff", border: "none", backgroundColor: "white" }}
          >
            {params.data.case_name}
          </button>
          {state.ifOwner && (
            <div>
              <FaRegEdit
                aria-hidden="true"
                style={{ cursor: "pointer" }}
                onClick={() => setIsCaseNameEditable(!isCaseNameEditable)}
              />
            </div>
          )}
        </div>
      ),
    },
    {
      headerName: "Created Date",
      field: "created",
      sortable: true,
      width: 150,
      wrapText: true,
      autoHeight: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        let date = params.data.created;
        // console.log("date", params.data);
        let dateArray = date.split(" ");

        return (
          <>
            <p
              className="m-0"
              style={{ lineHeight: "1.5" }}
            >
              {dateArray[0]}
            </p>
            <p
              className="m-0"
              style={{ lineHeight: "1.5" }}
            >
              {dateArray[1]}
            </p>
          </>
        );
      },
    },
    {
      headerName: "Expiry Date",
      field: "expiry_date",
      sortable: true,
      width: 130,
      wrapText: true,
      autoHeight: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        let date = params.data.expiry_date !== -1 ? params.data.expiry_date : "N/A";
        let active_days = params.data.slide_active_days;

        return (
          <div className="">
            <>
              {date === -1 ? (
                <></>
              ) : (
                <>
                  <p
                    className="m-0"
                    style={{ lineHeight: "1.5" }}
                  >
                    {date.split(" ")[0]}
                  </p>
                  <p
                    className="m-0"
                    style={{ lineHeight: "1.5" }}
                  >
                    {date.split(" ")[1]}
                  </p>
                </>
              )}
              {active_days != -1 ? <p style={{ marginBottom: "0px" }}>{active_days} Days</p> : ""}
            </>
          </div>
        );
      },
    },
    {
      headerName: "Slides",
      field: "slide_count",
      sortable: true,
      width: 90,
      wrapText: true,
      autoHeight: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        if (params.hasOwnProperty("data") && params.data.hasOwnProperty("slide_count")) {
          return (
            <>
              <p>{params.data.slide_count}</p>
            </>
          );
        } else {
          return (
            <>
              <p>!</p>
            </>
          );
        }
      },
    },
    {
      headerName: "Reporting",
      field: "report_status",
      width: 200,
      cellRenderer: (params) => {
        return (
          <div>
            <select
              className="custom-select custom-select-sm"
              onChange={(e) => {
                handleReportingStatus(e.target.value, params.data.case_id);
              }}
            >
              {params.data.report_status === 0 ? (
                <>
                  <option
                    value="0"
                    defaultValue={0}
                  >
                    Not Reported
                  </option>{" "}
                  <option value="1">Reporting</option>
                  <option value="2">Reported</option>
                  <option value="-1">Flag Issue</option>
                </>
              ) : params.data.report_status === 1 ? (
                <>
                  <option
                    value="1"
                    defaultValue={0}
                  >
                    Reporting
                  </option>{" "}
                  <option value="0">Not Reported</option>
                  <option value="2">Reported</option>
                  <option value="-1">Flag Issue</option>
                </>
              ) : params.data.report_status === 2 ? (
                <>
                  <option value="2">Reported</option> <option value="0">Not Reported</option>
                  <option value="1">Reporting</option>
                  <option value="-1">Flag Issue</option>
                </>
              ) : params.data.report_status === -1 ? (
                <>
                  <option value="-1">Flag Issue</option> <option value="0">Not Reported</option>
                  <option value="1">Reporting</option>
                  <option value="2">Reported</option>
                </>
              ) : (
                params.data.report_status
              )}
            </select>
          </div>
        );
      },
    },
    {
      headerName: "Assigned To",
      field: "assigned_to",
      // sortable: true,
      width: 250,
      wrapText: true,
      // hide:!state.ifOwner,
      // filter: true,
      // suppressMovable: true,
      cellRenderer: (params) => {
        return (
          <AssignedTagInput
            case_id={params.data.case_id}
            assigned_to={params.data.assigned_to}
          />
        );
      },
    },
    {
      headerName: "Patient Details",
      field: "patient_details",
      sortable: true,
      width: 200,
      wrapText: true,
      autoHeight: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return (
          <div className="">
            {/* <p
              className="m-0"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              Name: {params.data.patient_name}
            </p> */}
            <p
              className="m-0"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              Age: {params.data.patient_details?.patient_age || params.data.patient_age}
            </p>
            <p
              className="m-0"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              Gender: {params.data.patient_details?.patient_gender || params.data.patient_gender}
            </p>
            <p
              className="m-0"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              Patient ID: {params.data.patient_details?.patient_id || params.data.patient_id}
            </p>
          </div>
        );
      },
    },
    {
      headerName: "Delete Status",
      field: "delete_status",
      sortable: true,
      width: 140,
      suppressMovable: true,
      wrapText: true,
      autoHeight: true,
      hide: !state.ifOwner,
      cellRenderer: (params) => {
        return (
          <div>
            {params.data.delete_status === 0 ? (
              <>
                <a
                  href="#!"
                  onClick={() => {
                    let currentIndex = parseInt(localStorage.currentIndexCaseList);
                    state.deleteStatus(params.data.case_id);
                    state.getCaseRecords(state.case_name, state.patient_id, currentIndex, state.number_of_items, statusExists, state.assigned_to);
                  }}
                >
                  Delete
                </a>
              </>
            ) : params.data.delete_status === 1 ? (
              <>
                &nbsp;
                <FaSpinner aria-hidden="true" />
              </>
            ) : params.data.delete_status === 2 ? (
              <>
                &nbsp;
                <FaRegCheckSquare aria-hidden="true" />
              </>
            ) : params.data.delete_status === -1 ? (
              <>
                &nbsp;
                <FaExclamationTriangle aria-hidden="true" />
              </>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  const onGridReady = (params) => {
    // params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  };

  const checkStatusForCaseView = (params) => {
    // let slide_stitch_status = params.data.stitch_status;
    // if ((slide_stitch_status === 2 && params.data.delete_slide === 0) || params.data.import_slide === 4) {
    // const win = window.open("/CaseViewer/" + params.data.case_id, "_blank");
    // setCurrentModule("WSI");
    const win = window.open("/caseview/" + params.data.case_id, "_blank");
    win.focus();
    // }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && pateintIdSearch !== "") {
      const search = pateintIdSearch;
      state.setSearch(search);
      state.updateIndex(0);
      state.getCaseRecords("", pateintIdSearch, state.index, state.number_of_items, statusExists, selectedTag[0]);
    } else if (e.key === "Enter" && caseNameSearch !== "") {
      const search = caseNameSearch;
      state.setSearch(search);
      state.updateIndex(0);
      state.getCaseRecords(caseNameSearch, "", state.index, state.number_of_items, statusExists, selectedTag[0]);
    }
  };

  const onCellValueChanged = (event) => {
    let obj = {
      case_id: event.data.case_id,
      case_name: event.data.case_name,
    };
    let currentIdx = parseInt(localStorage.currentIndex);

    updateCasename(obj).then((res) => {
      if (res && res.data.Message) {
        state.getCaseRecords(state.case_name, state.patient_id, currentIdx, state.number_of_items, statusExists, state.assigned_to);
        NotificationManager.success(res.data.Message, "", 2000);
      }
    });
  };

  let arr = [];
  function getTagsOptions() {
    state.userList.map((el) => {
      if (el !== null) {
        arr.push(el);
      }
      return 0;
    });
    arr = arr.flat();
    let uniqueChars = [...new Set(arr)];
    return uniqueChars;
  }

  return (
    <div className="rtable">
      {state && state.caseListTable && state.caseListTable.row && (
        <>
          <div className="row">
            <div className="col-lg-8 d-flex align-item-center justify-content-between">
              <div className="d-flex align-item-center">
                <Dropdown>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={
                      selectedOption === "CaseName" ? (
                        <FaBriefcaseMedical
                          // size={15}
                          className="mr-2"
                        />
                      ) : (
                        <BsPeopleFill
                          // size={15}
                          className="mr-2"
                        />
                      )
                    }
                  >
                    <Dropdown.Item
                      onSelect={handleDropdownChange}
                      eventKey="PatientId"
                    >
                      <BsPeopleFill
                        // size={15}
                        className="mr-2"
                      />
                      PatientId
                    </Dropdown.Item>
                    <Dropdown.Item
                      onSelect={handleDropdownChange}
                      eventKey="CaseName"
                    >
                      <FaBriefcaseMedical
                        // size={15}
                        className="mr-2"
                      />
                      CaseName
                    </Dropdown.Item>
                  </DropdownButton>
                </Dropdown>
                {selectedOption === "CaseName" ? (
                  <>
                    <input
                      type="search"
                      className="form-control rounded"
                      value={caseNameSearch}
                      onChange={(e) => {
                        if (!e.target.value) {
                          setCaseNameSearch("");
                          state.clearData();
                          state.getCaseRecords("", "", 0, state.number_of_items, true, selectedTag[0]);
                          state.setHiddenDeletedCaseStatus(true);
                        } else {
                          setPateintIdSearch("");
                          state.updateIndex(0);
                          setCaseNameSearch(e.target.value);
                        }
                      }}
                      onKeyDown={handleKeyDown}
                      placeholder="Search by Case Name"
                    />
                    <span
                      className="input-group-text"
                      id="search-addon"
                    >
                      <FaSearch
                        aria-hidden="true"
                        onClick={(e) => {
                          state.updateIndex(0);
                          state.setSearch(caseNameSearch);
                          state.getCaseRecords(caseNameSearch, "", state.index, state.number_of_items, statusExists, selectedTag[0]);
                        }}
                      />
                    </span>
                  </>
                ) : (
                  <>
                    <input
                      type="search"
                      className="form-control rounded"
                      value={pateintIdSearch}
                      onChange={(e) => {
                        if (!e.target.value) {
                          setPateintIdSearch("");
                          state.clearData();
                          state.getCaseRecords("", "", 0, state.number_of_items, true, selectedTag[0]);
                          state.setHiddenDeletedCaseStatus(true);
                        } else {
                          setCaseNameSearch("");
                          state.updateIndex(0);
                          setPateintIdSearch(e.target.value);
                        }
                      }}
                      onKeyDown={handleKeyDown}
                      placeholder="Search by Pateint Id"
                    />
                    <span
                      className="input-group-text mb-2"
                      id="search-addon"
                    >
                      <FaSearch
                        aria-hidden="true"
                        onClick={(e) => {
                          state.updateIndex(0);
                          state.setSearch(pateintIdSearch);
                          state.getCaseRecords("", pateintIdSearch, state.index, state.number_of_items, statusExists, selectedTag[0]);
                        }}
                      />
                    </span>
                  </>
                )}
              </div>

              <div style={{ lineHeight: "3px" }}>
                <Multiselect
                  selectionLimit={1}
                  placeholder="Assigned to"
                  id="tagnamelist"
                  // displayValue={taglistdata}
                  ref={multiselectElement}
                  showArrow
                  keepSearchTerm={true}
                  options={getTagsOptions()}
                  isObject={false}
                  onSelect={(value) => {
                    setSelectedTag(value);
                    if (value[0] !== undefined) {
                      state.getCaseRecords(state.case_name, state.patient_id, state.index, state.number_of_items, state.hideDeletedSlideStatus, value[0]);
                    }
                  }}
                  onRemove={(value) => {
                    if (value[0] === undefined) {
                      state.getCaseRecords(state.case_name, state.patient_id, state.index, state.number_of_items, state.hideDeletedSlideStatus, "all");
                    }
                  }}
                />
              </div>

              {state.ifOwner && (
                <div>
                  <button
                    type="button"
                    className="btn btn-link "
                    onClick={() => {
                      // setShowUploadComponent(false);
                      pageDispatcher.set_modalVisible(true);
                    }}
                  >
                    <FaPlusCircle
                      aria-hidden="true"
                      style={{ marginRight: "0.25rem" }} // Equivalent to `mr-1` in Bootstrap
                    />
                    Add Case
                  </button>
                </div>
              )}
              {state.modalVisible && <AddCaseDetails />}
            </div>

            <div className="col-lg-4">
              <PaginationCaselist
                initialState={state}
                refreshListwithSearch={() => {
                  setCaseNameSearch("");
                  setPateintIdSearch("");
                  state.updateIndex(0);
                  state.clearData();
                  state.setHiddenDeletedCaseStatus(true);
                  state.getCaseRecords("", "", 0, state.number_of_items, true, state.assigned_to);
                }}
                setIndex={(indexValue) => {
                  // state.updateIndex(indexValue)
                  state.getCaseRecords(state.case_name, state.patient_id, indexValue, state.number_of_items, statusExists, state.assigned_to);
                }}
              />
            </div>
          </div>

          {state.ifOwner && (
            <Form.Group className="d-flex justify-content-end align-items-center">
              <Form.Check
                required
                name="terms"
                label="Hide Deleted Case"
                onChange={(e) => {
                  state.setHiddenDeletedCaseStatus(e.target.checked);
                  let currentIndex = parseInt(localStorage.currentIndexCaseList);
                  localStorage.setItem("hideDeletedCaseStatus", e.target.checked);
                  state.getCaseRecords(state.case_name, state.patient_id, currentIndex, state.number_of_items, e.target.checked, state.assigned_to);
                }}
                checked={state.hideDeletedCaseStatus}
              />
            </Form.Group>
          )}

          <div className="ag-theme-alpine">
            <AgGridReact
              colResizeDefault="shift"
              domLayout="autoHeight"
              // onSelectionChanged={onSelectionChanged}
              columnDefs={columnDefs}
              headerHeight="32"
              rowData={state.caseListTable.row}
              getRowHeight={() => {
                return 130;
              }}
              onGridReady={onGridReady}
              onCellValueChanged={onCellValueChanged}
            />
          </div>
        </>
      )}
    </div>
  );
};

const AssignedTagInput = ({ case_id, assigned_to }) => {
  const { state, pageDispatcher } = useContext(PageContext);
  const userList = state.userList;
  const assignselectElement = useRef(null);

  const nonClickableValue = !state.ifOwner;

  const handleAssignCase = (assignValue) => {
    // e.preventDefault();
    let obj = {
      user_id: assignValue,
      case_id: case_id,
    };
    setAssignedTo(obj)
      .then((res) => {
        if (res && res.data.Message) {
          NotificationManager.success(res.data.Message, "", 2000);
        }
      })
      .catch((err) => {
        console.log("error msg", err);
      });
  };

  const handleDeassignCase = (deassignValue) => {
    let obj = {
      user_id: deassignValue,
      case_id: case_id,
    };
    setDeassignTo(obj)
      .then((res) => {
        if (res && res.data.Message) {
          NotificationManager.success(res.data.Message, "", 2000);
        }
      })
      .catch((err) => {
        console.log("error msg", err);
      });
  };

  return (
    <div
      className={nonClickableValue ? "non-clickable" : ""}
      style={{ lineHeight: "3px" }}
    >
      <Multiselect
        hidePlaceholder={true}
        selectionLimit={3}
        placeholder="Assign To"
        id="assignnamelist"
        ref={assignselectElement}
        // showArrow
        keepSearchTerm={true}
        selectedValues={[...assigned_to]}
        options={userList}
        isObject={false}
        onSelect={(value) => {
          if (value[0] !== undefined) {
            let assignValue = value.slice(-1);
            handleAssignCase(assignValue[0]);
          }
        }}
        onRemove={(userList, value) => {
          // if (value[0] == undefined) {
          let deassignValue = value;
          handleDeassignCase(deassignValue);
          // }
        }}
      />
    </div>
  );
};

const deleteStatus = (case_id) => {
  if (window.confirm("Sure want to delete?")) {
    let obj = {
      case_id: case_id,
    };
    axios
      .post(baseURLV2 + "delete_case", obj, { headers: { Authorization: `Bearer ${getToken()}` } })
      .then(async (res) => {
        if (res && res.data.Message) {
          NotificationManager.success(res.data.Message, "", 2000);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
};

const getCaseRecords = (case_name, patient_id, index, number_of_items, status = 1, assigned_to) => {
  if (status === true) {
    status = 1;
  } else if (status === false) {
    status = 0;
  }

  const obj = {
    case_name: case_name,
    patient_id: patient_id,
    index: index,
    number_of_items: number_of_items,
    hide_delete_case: status,
    assigned_to: assigned_to,
    module_type: getSelectedChipset(),
  };

  return axios.post(baseURLV2 + "get_caselist_with_pagination", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

const updateCasename = (obj) => {
  return axios.post(baseURLV2 + "update_casename", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

const hasDataUpdated = async () => {
  return await axios.get(baseURL + "time_last_update", { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const pageReducer = (state, action) => {
  switch (action.type) {
    case "set_caseDatalist":
      return {
        ...state,
        // ifOwner: action.payload.ifOwner,
        // totalRecords: action.payload.totalRecords,
        // index: action.payload.index,
        // last_change: action.payload.last_change,
        caseListTable: {
          ...state.caseListTable,
          row: action.payload.caselist,
        },
      };
    case "set_caseData":
      return {
        ...state,
        ifOwner: action.payload.ifOwner,
        totalRecords: action.payload.totalRecords,
        index: action.payload.index,
        last_change: action.payload.last_change,
        // caseListTable: {
        //   ...state.caseListTable,
        //   row: action.payload.caselist,
        // },
      };
    case "set_userList":
      return {
        ...state,
        userList: action.payload,
      };
    case "set_Index":
      return {
        ...state,
        index: action.payload,
      };

    case "set_search":
      return {
        ...state,
        case_name: action.payload,
      };
    case "set_clearData":
      return {
        ...state,
        case_name: action.payload.case_name,
        index: action.payload.index,
        number_of_items: action.payload.number_of_items,
      };
    case "set_hiddenDeletedSlide":
      state = {
        ...state,
        hideDeletedCaseStatus: action.payload,
      };
      return state;
    case "set_modalVisible":
      return {
        ...state,
        modalVisible: action.payload,
      };
    case "set_user_list":
      return {
        ...state,
        userList: action.payload,
      };

    default:
      return state;
  }
};

export const PageContext = createContext(null);
