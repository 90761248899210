import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { updateCaseData } from "../../../Service";
import { PageContext } from "../../CaseViewer";
import { SaveButton,CancelButton } from "../updateSlide/Buttons";

const PatientId = () => {
  const { state, pageDispatcher } = useContext(PageContext);
  const { case_owner, patient_id, case_token } = state.case_info;

  const [isEditing, setIsEditing] = useState(false);
  const [patientId, setPatientId] = useState(patient_id);
  const [originalPatientId, setOriginalPatientId] = useState(patient_id);
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  }

  useEffect(() => {
    setPatientId(patient_id);
  }, [patient_id]);

  const handlePatientIdChange = (event) => {
    setPatientId(event.target.value);
  };

  const handleEditButtonClick = () => {
    setOriginalPatientId(patientId);
    setIsEditing(true);
  };

  const handleSaveButtonClick = async () => {
    const obj = {
      patient_id: patientId,
    };
    setIsEditing(false);
    try {
      await updateCaseData(obj, case_token);
      console.log("isEditing:",isEditing)
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelButtonClick = () => {
    setPatientId(originalPatientId);
    setIsEditing(false);
  };

  // <Button
  //   variant="outline-success"
  //   size="sm"
  //   onClick={handleEditButtonClick}
  //   className="mx-1"
  // >
  //   Edit <MdOutlineModeEditOutline />
  // </Button>
  return (
    <div className="mb-1">
      {isEditing ? (
        <div>
          <input
            type="text"
            value={patientId}
            onChange={handlePatientIdChange}
          />
          <Button
            variant="outline-primary"
            size="sm"
            onClick={handleSaveButtonClick}
            className="mx-1"
          >
            <SaveButton/>
          </Button>
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={handleCancelButtonClick}
            className="mx-1"
          >
            <CancelButton/>
          </Button>
        </div>
      ) : (
        <div className="d-flex justify-content-start align-items-center">
          <span>Patient Id: {patientId}</span>
          {case_owner ? (
            <sup>
              <MdOutlineModeEditOutline
                size={15}
                className={isHovered ? 'edit-icon hovered' : 'edit-icon'}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleEditButtonClick}
              />
            </sup>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default PatientId;
