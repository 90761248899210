import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { NotificationContainer } from "react-notifications";
import { Navigation } from "../../shared/Navigation";
import { RunningStatusMSGComponent } from "../../shared/RunningStatusMSGComponent";
import { handleAbortState, handleInitialState, pageReducer } from "../Service";
import { Abort, Aborted, Aborting } from "./Components/AbortStates";
import { Canvas } from "./Components/Canvas";
import { Capture } from "./Components/CaptureStates";
import { LoaderComponent } from "./Components/Loader";
import { PlaceSlide } from "./Components/PlaceSlide";
import { LiveImage } from "./Components/LiveImage";
import { Start } from "./Components/Start";
import { Module_Type_mismatch } from "./Components/Module_Type_mismatch";
import { getSelectedChipset } from "../../Utils/Common";

export const StitchSlide = () => {
  const [apiResponse, setApiResponse] = useState(null);
  const initialState = {
    timeInterval: 500,
    slot_status: [0],
    slot_data: [],
    current_active_slot_status: 0,
    // total_slots: 1,
    device_task: 0,
    device_status: 0,
    showAbortWithLoader: false,
    isDeviceError: false,
    errText: "",
    componentToRender: <LoaderComponent />,
    componentToRenderInImagePages: null,
    runningModule: "",
    hideMlUi: false,
  };
  const [pageState, dispatch] = useReducer(pageReducer, initialState);
  let intervalId;
  const fetchData = async () => {
    try {
      const res = await handleInitialState();
      const data = res;
      // Compare the new response with the previous one
      if (responseDiffers(apiResponse, res)) {
        setApiResponse(data);
        if (!res.data.device_error_msg) {
          pageDispatcher.set_slot_status(res.data.slot_status);
          const { device_task, device_status, running_module_type, previous_module_status, slot_status,previous_module_status_time } = res.data;
          console.log("running_module_type:",running_module_type)
          const moduleMatched = running_module_type == getSelectedChipset() || running_module_type == "";
          if (device_task === "0" && device_status === "1") {
            pageDispatcher.componentToRender(<Start />);
          } else if (
            (device_task === "1" || device_task === "23") &&
            device_status === "1" &&
            (running_module_type == getSelectedChipset() || running_module_type == "")
          ) {
            pageDispatcher.set_showAbortWithLoader(false);
            pageDispatcher.componentToRender(<PlaceSlide previous_module_status={previous_module_status} previous_module_status_time={previous_module_status_time} />);
          } else if (device_task === "2" && device_status === "0" && moduleMatched) {

            pageDispatcher.componentToRender(<Capture />);
          } else if (((device_task === "2" && device_status === "1") || (device_task === "3" && device_status === "0") || (device_task === "3" && device_status === "1")) && moduleMatched) {
            pageDispatcher.componentToRender(
              <Canvas
                key={true}
                device_task={device_task}
              />
            );
          } else if (device_task === "3" && device_status === "1") {
            pageDispatcher.componentToRender(<PlaceSlide />);
          } else if (device_task === "1" && device_status === "0" && moduleMatched) {
            pageDispatcher.set_showAbortWithLoader(true);
            pageDispatcher.componentToRender(<Abort />);
          } else if (device_task === "503" && device_status === "0" && moduleMatched) {
            pageDispatcher.componentToRender(<Aborting />);
          } else if (device_task === "0" && device_status === "0") {
            pageDispatcher.componentToRender(<LoaderComponent />);
          } else if (device_task === "503" && device_status === "1" && moduleMatched) {
            pageDispatcher.componentToRender(<Aborted />);
          } else if (
            device_task === "21" ||
            device_task === "22" ||
            device_task === "23" ||
            (running_module_type != getSelectedChipset() && running_module_type != "")
          ) {
            pageDispatcher.componentToRender(<Module_Type_mismatch running_module_type={running_module_type} />);
          } else if (device_task === "7") {
            pageDispatcher.componentToRender(<Module_Type_mismatch running_module_type={running_module_type} />);
          } else if (device_task === "107") {
            pageDispatcher.componentToRender(<Module_Type_mismatch running_module_type={"Maintenance"} />);
          }
        } else {
          console.log("ERR", res.data.device_error_msg);
          pageDispatcher.set_isDeviceError(true);
          pageDispatcher.set_errorMSG(res.data.device_error_msg);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Custom comparison function
  const responseDiffers = (prevResponse, newResponse) => {
    return JSON.stringify(prevResponse) !== JSON.stringify(newResponse);
  };

  useEffect(() => {
    fetchData();
    intervalId = setInterval(fetchData, 3000);
    return () => clearInterval(intervalId);
  }, [apiResponse]);

  const pageDispatcher = {
    componentToRender: (component) => dispatch({ type: "set_componentToRender", payload: component }),
    set_slot_status: (value) => dispatch({ type: "set_slot_status", payload: value }),
    set_slot_data: (value) => dispatch({ type: "set_slot_data", payload: value }),
    set_current_active_slot_status: (value) => dispatch({ type: "set_current_active_slot_status", payload: value }),
    set_device_task: (value) => dispatch({ type: "set_device_task", payload: value }),
    set_device_status: (value) => dispatch({ type: "set_device_status", payload: value }),
    componentToRenderInImagePages: (component) => dispatch({ type: "set_componentToRenderInImagePages", payload: component }),
    set_showAbortWithLoader: (value) => dispatch({ type: "set_showAbortWithLoader", payload: value }),
    set_timeInterval: (value) => dispatch({ type: "set_timeInterval", payload: value }),
    set_isDeviceError: (value) => dispatch({ type: "set_isDeviceError", payload: value }),
    set_errorMSG: (val) => dispatch({ type: "set_errorMSG", payload: val }),
    set_current_active_slot_status: (val) => dispatch({ type: "set_current_active_slot_status", payload: val }),
    set_running_module_name: (val) => dispatch({ type: "set_running_module_name", payload: val }),
  };

  return (
    <>
      <Navigation />
      <div>
        <PageContext.Provider value={{ pageState, pageDispatcher }}>
          <NotificationContainer />

          {pageState.isDeviceError && (
            <div
              className="container p-0"
              style={{ marginTop: "14%" }}
            >
              <DeviceErrorAbortComponent />
            </div>
          )}
          {pageState && !pageState.componentToRender.key && !pageState.isDeviceError && (
            <div className="p-0 m-0">
              <div className="text-center m-0">
                <div className="card-body p-0">{pageState.componentToRender}</div>
              </div>
            </div>
          )}

          {pageState && pageState.componentToRender.key && !pageState.isDeviceError && <div className="imagepages">{pageState.componentToRender}</div>}
        </PageContext.Provider>
      </div>
    </>
  );
};

const DeviceErrorAbortComponent = () => {
  const context = useContext(PageContext);

  return (
    <Alert variant="danger">
      <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
      <p>{context.pageState.errText}</p>
      <br />
      {context.pageState.isDeviceError && (
        <div
          className="col-2"
          style={{ marginLeft: "42%" }}
        >
          <button
            type="button"
            className="btn btn-danger btn-block"
            onClick={() =>
              handleAbortState().then((res) => {
                if (res && res.data.msg === "ok") {
                  context.pageDispatcher.componentToRender(<Aborting />);

                  setTimeout(function () {
                    // window.location.reload();
                    context.pageDispatcher.set_timeInterval(100);
                    context.pageDispatcher.set_isDeviceError(false);
                  }, 100);
                }
              })
            }
          >
            Abort
          </button>
        </div>
      )}
    </Alert>
  );
};

export const PageContext = createContext(null);
