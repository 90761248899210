import React, { useContext } from "react";
import { ProgressBar } from "react-bootstrap";
import { PageContext } from "../../CaseViewer";

const MLcard = ({ handleShow }) => {
  const { state } = useContext(PageContext);
  // const { id } = state.selected_slide;
  const { job_id } = state.ml_job_list;

  // Determine if progress bar should be shown
  const showProgressBar = ["ML Model Running"].includes(state.ml_status["task_type"]);
  const showExportingBar = ["Exporting to Tiff"].includes(state.ml_status["task_type"]);
  // console.log("showProgressBar:",showProgressBar)

  // Modify task_type based on the number of objects detected
  let no_of_object;
  if (state.ml_status["task_type"].includes("Object detected")) {
    no_of_object = state.ml_status["percent"];
  }

  return (
    <div className="">
      {/* {job_id === state.ml_status["job_id"] ? ( */}
        <div>
          {showProgressBar && (
            <>
              <span>{state.ml_status["task_type"]}</span>
              <ProgressBar
                now={state.ml_status["percent"]}
                variant="success"
                animated
                label={`${state.ml_status["percent"]}%`}
              />
            </>
          )}
          {showExportingBar && (
            <>
              <span>{state.ml_status["task_type"]}</span>
              <ProgressBar
                now={100}
                variant="success"
                animated
                label={`${state.ml_status["percent"]}`}
              />
            </>
          )}
          {no_of_object > 1 && <span>{no_of_object} Object detected</span>}
          {no_of_object == 0 && <span>{no_of_object} Object detected</span>}
        </div>
      {/* ) : (
        // null
        <LoaderComponent />
      )} */}
    </div>
  );
};

export default MLcard;
