
// ********************************************* set authData in cookie start ************************************************//

export const setAuthData = (userToken, userName) => {
  try {
    var now = new Date();
    var expire = new Date();

    expire.setDate(now.getDate() + 2);

    var expires = "expires=" + expire.toGMTString();

    document.cookie = "token=" + userToken + "; " + expires + "; path=/";

    document.cookie = "username=" + userName + "; " + expires + "; path=/";

  }
  catch (err) {
    console.log(err)
  }

}
export const removeCookie = () => {
  try {
    var now = new Date();
    var expire = new Date();
    expire.setDate(now.getDate() - 1);

    var expires = "expires=" + expire.toGMTString();

    document.cookie = "token=; " + expires + "; path=/";
    document.cookie = "username=; " + expires + "; path=/";
  }
  catch (err) {
    console.log(err)
  }
}

export const getToken = () => {
  try {
    const tokenKey = "token=";

    const decodedValue = decodeURIComponent(document.cookie); //to be careful

    const arrVal = decodedValue.split('; ');

    let res;
    arrVal.forEach(val => {
      if (val.indexOf(tokenKey) === 0) res = val.substring(tokenKey.length);
    })

    return res
  }
  catch (err) {
    console.log(err)
  }

}

export const getUser = () => {
  try {
    const unameKey = "username=";
    const decodedValue = decodeURIComponent(document.cookie); //to be careful
    const arrVal = decodedValue.split('; ');

    let res;
    arrVal.forEach(val => {
      if (val.indexOf(unameKey) === 0) res = val.substring(unameKey.length);
    })
    return res
  }

  catch (err) {
    console.log(err)
  }

}

// ********************************************* set authData in cookie end ************************************************//


// **********************************************set currentModule & ModuleList Start****************************************//
export const getSelectedChipset = () => {
  //get dropdown selected module from navbar and return which module to display
  return localStorage.getItem('selectedChipset') || null
}
export const getTypeOfSlidelide = () => {
  //get dropdown selected module from navbar and return which module to display
  return localStorage.getItem('type_of_slide') || null
}
export const getCustomRoute = () => {
  //get dropdown selected module from navbar and return which module to display
  return localStorage.getItem('customRoute') || null
}
export const setCustomRoute = (modulename) => {
  localStorage.setItem('customRoute', modulename);
}

// *********************************************set currentModule & ModuleList End**********************************************//

// **********************************************set PBS ROW LIMIT Start*******************************************************//

export const setRowLimit = (val) => {
  localStorage.setItem('rowlimit', val);
}

export const getRowLimit = () => {
  return parseInt(localStorage.getItem('rowlimit'));
}
// **********************************************set PBS ROW LIMIT End******************************************************//
// **********************************************set Lens Start*******************************************************//

export const setDefaultLens = (val) => {
  localStorage.setItem('lens', val);
}

export const getDefaultLens = () => {
  return parseInt(localStorage.getItem('lens') || 40);
}
// **********************************************set Lens End******************************************************//

export const isQSLive=()=>{
  return JSON.parse(localStorage.getItem('isQSLive'));
}

export const setQSExpiry = (expiryHrs) => {
  localStorage.setItem('qs_expiryhrs', expiryHrs)
}

export const getQSExpiry = () => {
  return localStorage.getItem('qs_expiryhrs')
}

export const delQSExpiry=()=>{
  localStorage.removeItem("qs_expiryhrs");
}
