import { createContext } from "react";
import axios from "axios";
import { environment } from "../../environment";
import { getToken } from "../Utils/Common";
const baseURL = environment.MICALYS_POSTGRES_API;
const baseURL_V2 = environment.MICALYS_API_V2;

export const pageReducer = (state, action) => {
  switch (action.type) {
    case "set_componentToRender":
      return {
        ...state,
        componentToRender: action.payload,
      };

    case "set_data":
      // console.log("payload", action.payload.data)
      return {
        ...state,
        data: {
          ...state.data,
          email: action.payload.data.remote_email,
          deviceName: action.payload.data.DeviceName,
          // connectionStatus: action.payload.register_msg,
          connectionStatus: action.payload.status || "",
          message: action.payload.register_msg,
        },
      };
    case "set_position":
      // console.log("payload", action.payload.data)
      return {
        ...state,
        // positionData: [
        //     {
        //         ...state.positionData[0],
        //         'px-x': action.payload[0]['px-x'],
        //         'px-y': action.payload[0]['px-y'],
        //         x: action.payload[0].x,
        //         y: action.payload[0].y,
        //         z: action.payload[0].z,
        //         last_ws: action.payload.last_ws,
        //         // connectionStatus: action.payload.register_msg,
        //     },
        // ],
        positionData: [{ ...state.positionData[0], ...action.payload }],
      };

    case "set_timeInterval":
      return {
        ...state,
        timeInterval: action.payload,
      };
    case "set_modalVisible":
      return {
        ...state,
        modalVisible: action.payload,
      };

    case "set_deviceName":
      return {
        ...state,
        data: { ...state.data, deviceName: action.payload },
      };

    case "set_isDeviceError":
      return {
        ...state,
        isDeviceError: action.payload,
      };
    case "set_errorMSG":
      return {
        ...state,
        errText: action.payload,
      };

    case "set_isCloudIdMsgErr":
      return {
        ...state,
        isCloudIdMsgErr: action.payload,
      };

    case "set_device_substate":
      return {
        ...state,
        device_substate: action.payload,
      };
    case "set_stream_status":
      return {
        ...state,
        streamStatus: action.payload,
      };
    case "set_live_fps":
      return {
        ...state,
        liveFps: action.payload,
      };

    default:
      return state;
  }
};

export const start = (data) => {
  return axios.post(baseURL + "task_7", data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });
};

export const handleInitialState = () => {
  return axios.get(baseURL + "get_device_state", {
    headers: { Authorization: `Bearer ${getToken()}` },
  });
};

export const handleAbortState = () => {
  return axios.get(baseURL + "503", {
    headers: { Authorization: `Bearer ${getToken()}` },
  });
};

export const handleAbortedState = () => {
  return axios.get(baseURL + "0", {
    headers: { Authorization: `Bearer ${getToken()}` },
  });
};

export const remoteStatus = () => {
  return axios.get(baseURL + "remote_status", {
    headers: { Authorization: `Bearer ${getToken()}` },
  });
};
export const takeControlState = () => {
  return axios.get(baseURL + "take_control", {
    headers: { Authorization: `Bearer ${getToken()}` },
  });
};
export const placeSlideState = () => {
  return axios.get(baseURL + "place_slide_ok", {
    headers: { Authorization: `Bearer ${getToken()}` },
  });
};
export const getWsImage = () => {
  return axios.get(baseURL + "get_ws_image", {
    headers: { Authorization: `Bearer ${getToken()}` },
    responseType: "arraybuffer",
  });
};
export const getStreamStatus = () => {
  return axios.get(baseURL + "streamstatus", {
    headers: { Authorization: `Bearer ${getToken()}` },
  });
};
export const getFrame = (time) => {
  return axios.get(baseURL_V2 + "getframe?" + time, {
    headers: { Authorization: `Bearer ${getToken()}` },
    responseType: "arraybuffer",
  });
};

export const PageContext = createContext(null);
