import { NotificationContainer, NotificationManager } from 'react-notifications';
import { handleAbortState } from '../Service';
export const DeviceRegistering = () => {
    return (
        <div>
            <h3>Device Registering...</h3>
            <br />
            <button type="button" className="btn btn-danger btn-block"
                onClick={() => handleAbortState().then(res => {
                    if (res.data && res.data.msg) {
                        NotificationManager.success(res.data.msg, '', 2000);
                    }
                })}
            >Abort</button>
        </div>
    )
}