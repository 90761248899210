import { createContext } from "react";
import axios from "axios";
import { environment } from "../.././environment";
import { getToken } from ".././Utils/Common";
const baseURL = environment.MICALYS_POSTGRES_API;
const baseURLV2 = environment.MICALYS_API_V2;

export const pageReducer = (state, action) => {
  switch (action.type) {
    case "set_componentToRender":
      return {
        ...state,
        componentToRender: action.payload,
      };
    case "set_componentToRenderInImagePages":
      return {
        ...state,
        componentToRenderInImagePages: action.payload,
      };
    case "set_showAbortWithLoader":
      return {
        ...state,
        showAbortWithLoader: action.payload,
      };
    case "set_timeInterval":
      return {
        ...state,
        timeInterval: action.payload,
      };
    case "set_data":
      return {
        ...state,
        data: {
          ...state.data,
          email: action.payload.remote_email,
          deviceName: action.payload.DeviceName,
          connectionStatus: action.payload.register_msg,
          message: action.payload.register_msg,
        },
      };
    case "set_deviceName":
      return {
        ...state,
        data: { ...state.data, deviceName: action.payload },
      };
    case "set_slideToken":
      return {
        ...state,
        slide_access_token: action.payload,
      };
    case "set_slideId":
      return {
        ...state,
        slide_id: action.payload,
      };
    case "set_wbcList":
      return {
        ...state,
        unclassifiedList: [...state.unclassifiedList, action.payload.unclassified],
        lymphocyteList: [...state.lymphocyteList, action.payload.lymphocyte],
        eoisinophilList: [...state.eoisinophilList, action.payload.eoisinophil],
        basophilList: [...state.basophilList, action.payload.basophil],
        monocyteList: [...state.monocyteList, action.payload.monocyte],
      };
    case "set_isDeviceError":
      return {
        ...state,
        isDeviceError: action.payload,
      };
    case "set_errorMSG":
      return {
        ...state,
        errText: action.payload,
      };
    case "set_running_module_name":
      return {
        ...state,
        runningModule: action.payload,
      };

    default:
      return state;
  }
};

export const handleInitialState = () => {
  return axios.get(baseURL + "get_device_state", { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const handleStartState = () => {
  return axios.get(baseURL + "1", { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const handleplaceSlideState = () => {
  return axios.get(baseURL + "22", { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const handleAbortState = () => {
  return axios.get(baseURL + "503", { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const handleAbortedState = () => {
  return axios.get(baseURL + "0", { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getCanvasImage = () => {
  return axios.get(baseURL + "get_ws_image", { headers: { Authorization: `Bearer ${getToken()}` }, responseType: "arraybuffer" });
};

export const getCanvasData = () => {
  return axios.get(baseURL + "get_ws_qr", { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const handleRectCoordinates = async (obj) => {
  return await axios.post(baseURL + "23", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getLiveImage = async () => {
  return await axios.get(baseURL + "get_live_image", { headers: { Authorization: `Bearer ${getToken()}` }, responseType: "arraybuffer" });
};

export const getSlideToken = (slide_id) => {
  var data = {
    slide_id: slide_id,
  };
  return axios.post(baseURL + "get_slidetoken", data, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getGCSlideList = (obj, status) => {
  if (status === true) {
    status = 1;
  } else if (status === false) {
    status = 0;
  }
  obj["delete_slide"] = status;
  return axios.post(baseURL + "get_gc_slidelist_with_pagination", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getDownloadFile = (slide_id) => {
  let obj = {
    slide_id: slide_id,
  };
  axios
    .post(baseURLV2 + "get_gc_file", obj, { headers: { Authorization: `Bearer ${getToken()}` } })
    .then(async (res) => {
      if (res && res.data && res.data.downloadKey) {
        window.open(`${baseURLV2}download_gc_file?downloadKey=${res.data.downloadKey}`, "_blank")
      }
    })
    .catch((e) => {
      console.log(e);
    });
};

// export const exportSlide = (slide_id) => {
//     let obj = {
//         slide_id: slide_id
//     }
//     return axios.post(baseURL + 'export_this_slide', obj, { headers: { Authorization: `Bearer ${getToken()}` } })
// }

export const deleteStatus = (slide_id) => {
  if (window.confirm("Sure want to delete?")) {
    let obj = {
      slide_id: slide_id,
    };
    return axios.post(baseURL + "delete_this_slide", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
  }
};


export const getListTumbnailName = (jwt) => {
  return axios.get(baseURLV2 + "list_gc_images", { headers: { Authorization: `Bearer ${jwt}` } });
};
export const getTumbnailImage = (name, slidejwt, id) => {
  return axios.get(baseURLV2 + `gc_thumbnail/${id}/${name}`, 
  {
    headers: { Authorization: `Bearer ${slidejwt}` },
    responseType: "arraybuffer",
  });
};
export const getMainImage = (name, slidejwt, id) => {
  return axios.get(baseURLV2 + `gc_image/${id}/${name}`, { headers: { Authorization: `Bearer ${slidejwt}` }, responseType: "arraybuffer" });
};

export const getUploadStatus = (slidejwt) => {
  return axios.post(baseURLV2 + `gc_get_upload_status`, {}, { headers: { Authorization: `Bearer ${slidejwt}` } });
};

export const setReupload = (slidejwt) => {
  return axios.post(baseURLV2 + `gc_slide_reupload`, {}, { headers: { Authorization: `Bearer ${slidejwt}` } });
};

export const getSlideUploadStatus = () => {
  return axios.post(baseURLV2 + `gc_slide_upload_status`, {}, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const updateSlidename = (obj) => {
  return axios.post(baseURL + "update_slidename", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getannotated = (slide_token) => {
  return axios.get(baseURL + "getannotated", {
    headers: { Authorization: `Bearer ${slide_token}` },
    responseType: "arraybuffer",
  });
};

export const PageContext = createContext(null);
