import Feature from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import { getCenter } from "ol/extent";
import Point from "ol/geom/Point";
import Polygon from "ol/geom/Polygon";
import { Modify } from "ol/interaction";
import Draw, { createBox } from "ol/interaction/Draw";
import Select from "ol/interaction/Select";
import { Vector as VectorLayer } from "ol/layer";
import ImageLayer from "ol/layer/Image";
import "ol/ol.css";
import Projection from "ol/proj/Projection";
import { Vector as VectorSource } from "ol/source";
import Static from "ol/source/ImageStatic";
import { Circle, Fill, Stroke, Style } from "ol/style";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getDefaultLens } from "../../../Utils/Common";
import { getCanvasImage, getSlotData, getSubmittedSlotData, setSlotStatus_0_7 } from "../../Service";
import Captured from "./Captured";
import Capturing from "./Capturing";
import ErrorSlot from "./ErrorSlot";
import IgnoreSlot from "./IgnoreSlot";
import Initial from "./Initial";
import { NotificationManager } from "react-notifications";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { MdOutlineCheckBox } from "react-icons/md";


export const WsiImgContext = new React.createContext();

const SingleSlot = ({ slot_title, slot_number, slot_status }) => {
  // let freeze_slot_title = `freeze${slot_title}`;

  const initialValues = {
    image: "",
    quality: "1",
    lens: getDefaultLens(),
    coordinates: [],
    raw_data:{}
  };
  const [state, setState] = useState(initialValues);
  const [noOfLayers, setNoOfLayers] = useState(1);
  const [LocalslideName, setLocalSlideName] = useState("");
  const [checkedFeature, setCheckedFeature] = useState();
  const [mappingData, setMappingData] = useState({});
  const [enableCheckbox, setEnableCheckbox] = useState(true); //for enable disable when no feature is drawn on image
  const [focusPointState, setFocusPointState] = useState([]);
  const [canvasBlobURL, setCanvasBlobUrl] = useState();
  const [canvasImg, setCanvasImg] = useState();
  const [src, setSrc] = useState(null); // actual feature
  const [src1, setSrc1] = useState(null); // boundary box around actual feature
  const [vect, setVect] = useState(null);
  const [map, setMap] = useState(null);
  const [isChecked, setChecked] = useState(false);
  const [activeSlot, setActiveSlot] = useState();

  const handleIgnoreSlot = () => {
    fetchSlotStatus_0_7(true)
  };
  const handleIncludeSlot = () => {
    fetchSlotStatus_0_7(false)
  };

  const [submittedLens, setSubmittedLens] = useState();
  const [submittedQuality, setSubmittedQuality] = useState();
  const [drawCords, setDrawCords] = useState();
  const [focusPt, setFocusPt] = useState([]);
  const selectClick = useRef(null);
  const selectedFeature = useRef(null);
  const draw = useRef(null);
  const mode = useRef("draw");
  const value = useRef(null);
  const isDrawRectFeatureChecked = useRef(false);
  const freeze_slot_title = useRef(`freeze${slot_title}`);
  const [status, setStatus] = useState(0);

  // const [isSelectDeleteFeatureChecked, setIsSelectDeleteFeatureChecked] = useState(false);
  // const [isDrawRectFeatureChecked, setIsDrawRectFeatureChecked] = useState();
  // const src = useRef(null);
  // const src1 = useRef(null);

  const undoButtonRef = useRef(`undo${slot_title}`);
  const removeLastButtonRef = useRef(`removeLast${slot_title}`);
  const deleteFeatureCheckboxRef = useRef(`deleteFeature${slot_title}`);
  const drawRectCheckboxRef = useRef(`deleteFeature${slot_title}`);

  let focus_point_xy = [400, 200];

  // let draw; // mode->draw->new Draw({})
  // let mode = "draw"; // mode-> draw(box/polygon) / modify(remove feature)
  let geometryFunction; // mode->draw->'Box'->createBox()
  // let value; // mode->draw-> 'Box' / 'Polygon' --> to set color on drawn polygon
  let pointF; // focusPt feature is created
  // let selectedFeature = null; // mode->modify->new Select({})->feature is removed
  // let selectClick = null; // mode->modify->new Select({})
  // let isDrawRectFeatureChecked = false; // true->addInteraction('Box'); /  false->addInteraction('Polygon')
  let img_h = canvasImg?.height;
  let colorList = ["#Daa520", "#808000", "#7fff00", "#F88379", "#40e0d0", "#Ff00ff", "#4b0082", "#003153", "#800020", "#Ff1493"];
  let colorString;

  // to set diff color on drawn polygon
  function setColor() {
    colorString = colorList[Math.floor(Math.random() * colorList.length)];
    return colorString;
  }

  // interaction to draw rect or polygon(diff color)
  function addInteraction(val) {
    value.current = val;
    if (value.current === "Box") {
      value.current = "Circle";
      geometryFunction = createBox();
    } else {
      value.current = "Polygon";
      geometryFunction = "";
    }

    draw.current = new Draw({
      source: src,
      type: value.current,
      geometryFunction: geometryFunction,
    });

    draw.current.on("drawend", function (event) {
      if (value.current === "Polygon") {
        //for setting style on inner feature i.e. inner polygon
        setColor();
        var style = new Style({
          stroke: new Stroke({
            color: colorString,
            width: 3,
          }),
          fill: new Fill({
            color: "rgba(0, 0, 255, 0.1)",
          }),
        });
        event.feature.setStyle(style);
      }
    });
    map.addInteraction(draw.current);
  }

  // drawing feature
  function drawExtent() {
    //Note:source is what we drawn i.e polygon & source1 is the bouding box around drawn feature ie. source
    src1.clear();
    var features = src.getFeatures();

    function ResetFeature() {
      setEnableCheckbox(true); //If there are 0 features left on map then change mode to draw excpet "point feature"
      // setCheckedFeature(false);
      setState({ ...state, coordinates: [] });
      var interactions = map.getInteractions();

      map.removeInteraction(selectClick.current);
      mode.current = "draw";
      // setMode("draw");
      for (var i = 0; i < interactions.getLength(); i++) {
        var interaction = interactions.item(i);
        interaction.setActive(true);
      }
    }

    if (features.length === 1 && features[0].getGeometry().getType() === "Point") {
      ResetFeature();
    }
    if (!features.length) {
      //this condition will not occur because there will always be one feature i.e point
      ResetFeature();
    }

    if (features.length > 1) {
      setEnableCheckbox(false); //If there are 1 features left on map and that feature it point then change mode to draw
    }
    //for making bounding box i.e source1
    for (let f in features) {
      var [x1, y2, x2, y1] = features[f].getGeometry().getExtent();

      var pol = new Polygon([
        [
          [x1, y1],
          [x1, y2],
          [x2, y2],
          [x2, y1],
        ],
      ]);
      var f1 = new Feature({
        geometry: pol,
      });

      f1.setStyle(
        new Style({
          //for making rectangle boundary of drawn polygon
          fill: new Fill({
            color: "rgba(100,100,0,0.2)",
          }),
          stroke: new Stroke({
            color: "blue",
            width: 1.25,
          }),
        })
      );

      if (mode.current === "draw") {
        src1.addFeature(f1); //adding bounding box around source and on map
      }
    }

    var out = [];
    let contourLineData = null;

    let coordsArray = [];
    for (let f in features) {
      if (features[f].getGeometry().getType() === "Point") {
        //get coordinates of point
        setFocusPointState(features[f].getGeometry().getCoordinates());
      }

      if (features[f].getGeometry().getType() === "Polygon") {
        let val = features[f].getGeometry().getCoordinates();
        contourLineData = JSON.stringify(val[0]); //for contour data

        var arr = features[f].getGeometry().getExtent(); //for ???
        var X1 = Math.floor(arr[0]);

        var Y2 = img_h - Math.floor(arr[1]);

        var X2 = Math.floor(arr[2]);

        var Y1 = img_h - Math.floor(arr[3]);

        // const c2=[X2,Y1]
        // const c1=[X1,Y2]
        // const raw_data={"raw_data":{"coordinates":[c1,c2]}}
        const raw_data={"raw_data":{"coordinates":[]}}
        


        var [x, y, w, h] = [X1, Y1, X2 - X1, Y2 - Y1];

        out.push({ x: x, y: y, w: w, h: h, contourLineData: contourLineData });
        // console.log("out to check contour in box", out)
        setState({ ...state, coordinates: out,raw_data:raw_data });
        coordsArray.push(val);
      }
    }
  }

  // Point
  const handleUndoFeature = () => {
    draw.current.removeLastPoint();
  };

  // Clear all
  const handleRemoveLastFeature = () => {
    var features = src.getFeatures();
    if (features.length > 0) {
      features.forEach((el) => {
        return src.removeFeature(el);
      });
      // setState({ ...state, coordinates: [] });
    }
    showFocusPoint(); //do not delete point feature on clearall cz it shd be checked always
  };

  // Select Feature to Delete
  const handleDeleteFeatureChange = useCallback(
    (e, value = "") => {
      const isChecked = value === false ? value : e.target.checked;
      // console.log("isChecked", isChecked);
      const interactions = map.getInteractions();
      // const features = src.getFeatures();
      mode.current = "modify";

      if (isChecked && mode.current === "modify") {
        // Disable drawing features
        for (let i = 0; i < interactions.getLength(); i++) {
          const interactionData = interactions.item(i);
          interactionData.setActive(false);
        }

        // if (!selectClick.current) {
        // Clear bounding box (outer feature)
        if (src1) src1.clear();

        // Initialize selectClick interaction
        selectClick.current = new Select({
          condition: (event) => event.type === "click",
          style: new Style({
            stroke: new Stroke({
              color: "#3399CC",
              width: 1.25,
            }),
            fill: new Fill({
              color: "rgba(255,255,255,0.4)",
            }),
          }),
        });

        map.addInteraction(selectClick.current);

        selectClick.current.on("select", (event) => {
          if (event.selected.length > 0) {
            selectedFeature.current = event.selected[0];
            if (mode.current === "modify") {
              src.removeFeature(selectedFeature.current);
            }

            if (selectedFeature.current.getGeometry().getType() === "Point") {
              showFocusPoint();
            }
          }
        });
        // }
      }
      // else  {
      //   console.log('else')
      //   // Enable drawing features
      //   for (let j = 0; j < interactions?.getLength(); j++) {
      //     const interactionData = interactions?.item(j);
      //     interactionData.setActive(true);
      //   }

      //   selectClick.current = null;
      //   drawExtent();

      //   if (features.length) {
      //     if (isDrawRectFeatureChecked) {
      //       map.removeInteraction(draw.current);
      //       value.current = "Box";
      //       addInteraction(value.current);
      //     } else {
      //       map.removeInteraction(draw.current);
      //       value.current = "Polygon";
      //       addInteraction(value.current);
      //     }
      //   }

      //   if (!features.length) {
      //     map.removeInteraction(selectClick.current);
      //   }
      // }
    },
    [map]
  );

  // draw feature -->  polygon/rectangle
  const handleDrawFeatureChange = useCallback(
    (e) => {
      const isChecked = e.target.checked;
      const interactions = map.getInteractions();
      const features = src.getFeatures();
      mode.current = "draw";

      if (!isChecked && mode.current === "draw") {
        // Enable drawing features
        for (let j = 0; j < interactions?.getLength(); j++) {
          const interactionData = interactions?.item(j);
          interactionData.setActive(true);
        }

        selectClick.current = null;
        drawExtent();

        if (features.length >= 0) {
          console.log("isDrawRectFeatureChecked.current", isDrawRectFeatureChecked.current);
          if (isDrawRectFeatureChecked.current) {
            map.removeInteraction(draw.current);
            value.current = "Box";
            addInteraction(value.current);
          } else {
            map.removeInteraction(draw.current);
            value.current = "Polygon";
            addInteraction(value.current);
          }
        }

        if (!features.length) {
          map.removeInteraction(selectClick.current);
        }
      }
    },
    [map]
  );

  // Draw Rect
  const handleDrawRectChange = (event) => {
    // setIsDrawRectFeatureChecked(event.target.checked);
    if (event.target.checked) {
      isDrawRectFeatureChecked.current = true;
      if (mode.current === "draw") {
        map.removeInteraction(draw.current);
        value.current = "Box";
        addInteraction(value.current);
      }
    }
    if (!event.target.checked) {
      isDrawRectFeatureChecked.current = false;
      if (mode.current === "draw") {
        map.removeInteraction(draw.current);
        value.current = "Polygon";
        addInteraction(value.current);
      }
    }
  };

  // Add focus point
  function showFocusPoint() {
    if (slot_status === 0) {
      const fp = focusPt.length>0 ? focusPt :focus_point_xy
      pointF = new Feature({
        // geometry: new Point(focus_point_xy),
        geometry: new Point(fp),
      });
      pointF.setStyle(
        new Style({
          image: new Circle({
            radius: 5,
            fill: new Fill({
              color: "red",
            }),
          }),
        })
      );
      return vect.getSource().addFeature(pointF);
    } else if (slot_status === 1) {
      const [focusPointData] = focusPt;
      const { x, y } = focusPointData || {};
      if (x !== undefined && y !== undefined) {
        const focusPointFeature = new Feature({
          geometry: new Point([x, y]),
        });
        focusPointFeature.setStyle(
          new Style({
            image: new Circle({
              radius: 5,
              fill: new Fill({
                color: "red",
              }),
            }),
          })
        );
        return vect.getSource().addFeature(focusPointFeature);
      }
    }
  }

  useEffect(() => {
    if (!canvasImg && !canvasBlobURL) return;
    canvasImg.src = canvasBlobURL;
    // console.log("canvasImg", canvasImg);

    canvasImg.onload = function () {
      vect.getSource().on("addfeature", function (evt) {
        drawExtent();
      });
      vect.getSource().on("removefeature", function (evt) {
        drawExtent();
      });
      vect.getSource().on("changefeature", function (evt) {
        drawExtent();
      });

      addInteraction("Polygon");

      if (drawCords?.length > 0) {
        drawCords.map((el) => {
          var feature = new Feature({
            geometry: new Polygon([el]),
          });
          return vect.getSource().addFeature(feature);
        });
      }

      showFocusPoint();
    };

    return () => { };
  }, [map]);

  useEffect(() => {
    if (!canvasImg && !canvasBlobURL) return;
    // canvasImg.src = canvasBlobURL;
    canvasImg.onload = function () {
      const extent = [0, 0, canvasImg.width, canvasImg.height];

      const projection = new Projection({
        code: "xkcd-image",
        units: "pixels",
        extent: extent,
      });

      const IMG_Layer = new ImageLayer({
        source: new Static({
          attributions: '© <a href="http://xkcd.com/license.html">xkcd</a>',
          imageLoadFunction: function (image, src) {
            image.getImage().src = canvasBlobURL;
          },
          imageExtent: extent,
        }),
      });

      const source1 = new VectorSource({ wrapX: false });
      setSrc1(source1);
      // src1.current = source1;

      const vector1 = new VectorLayer({
        source: source1,
      });

      const source = new VectorSource({ wrapX: false });
      setSrc(source);
      // src.current = source;

      const vector = new VectorLayer({
        source: source,
      });
      setVect(vector);

      const name = slot_status === 0 ? `slot${slot_number}` : freeze_slot_title.current;

      const new_map = new Map({
        layers: [IMG_Layer, vector, vector1],
        // target: `slot${slot_number}`,
        target: name,
        view: new View({
          projection: projection,
          center: getCenter(extent),
          zoom: 1,
        }),
        controls: [],
      });
      // map.setTarget(`slot${slot_number}`);
      setMap(new_map);

      const modify = new Modify({ source: source }); //for modifying the interaction
      new_map.addInteraction(modify);
    };

    return () => {
      if (map) map.setTarget(null);
    };
  }, [canvasBlobURL, canvasImg, slot_number, slot_status]);

  useEffect(() => {
    if (canvasImg && canvasBlobURL) {
      canvasImg.src = canvasBlobURL;
    }
  }, [canvasImg, canvasBlobURL]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const canvasData = await getSlotData(slot_number);

        if (canvasData && canvasData.data) {
          const { slidename, active_lens, focus_point, drawcoords } = canvasData?.data;
          setLocalSlideName(slidename);
          setMappingData(active_lens);

          let coordsArr = [];
          if (canvasData.data.drawcoords && canvasData.data.drawcoords.length > 0) {
            coordsArr = [...drawcoords];
            setDrawCords(coordsArr);
          }

          if (canvasData.data.focus_point && canvasData.data.focus_point.length > 0) {
            focus_point_xy = [...focus_point];
            setFocusPt(focus_point_xy);
          }

          const res = await getCanvasImage(`slot${slot_number}`);
          if (res && res.data && res.data.byteLength > 0) {
            var img = new Image();
            setCanvasImg(img);
            let blob = new Blob([res.data], { type: res.headers["content-type"] });
            let blobURL = URL.createObjectURL(blob);
            setCanvasBlobUrl(blobURL);
            // prepareImageCrop(img, blobURL, coordsArr, `slot${slot_number}`, slot_status, focus_point_xy);
          }
        }
      } catch (error) {
        // Handle errors if needed
        console.error("Error fetching data:", error);
      }
    };
    if (slot_status === 0 || status === "0") {
      fetchData();
    }
  }, [slot_status, status]);

  useEffect(() => {
    const fetchSubmittedSlotData = async () => {
      try {
        const resp = await getSubmittedSlotData(slot_number);
        const { cropArea, lens, q, slide_name, zstack, focus_point } = resp.data.slot_data;
        setLocalSlideName(slide_name);
        setNoOfLayers(zstack);
        setFocusPt(focus_point);
        // setState({ ...state, quality: q });
        if (q === 1) {
          setSubmittedQuality("Standard");
        } else if (q === 2) {
          setSubmittedQuality("High");
        } else if (q === 3) {
          setSubmittedQuality("Best");
        }

        if (lens === 240) {
          // setState({ ...state, lens: "40X(NC)" });
          setSubmittedLens("40X(NC)");
        } else {
          // setState({ ...state, lens: `${lens}X` });
          setSubmittedLens(`${lens}X`);
        }
        let coordsArray = [];
        if (resp.data.slot_data?.cropArea) {
          for (var d of cropArea) {
            if (d) {
              const data = JSON.parse(d.contourLineData);
              coordsArray.push(data);
              setDrawCords(coordsArray);
            }
          }
        }
        try {
          const res = await getCanvasImage(`slot${slot_number}`);
          // console.log('res',res)
          var img = new Image();
          setCanvasImg(img);
          let blob = new Blob([res.data], { type: res.headers["content-type"] });
          let blobURL = URL.createObjectURL(blob);
          setCanvasBlobUrl(blobURL);
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      // var img = new Image()

      // prepareImageCrop(img, blobURL, coordsArray, freeze_slot_title, slot_status, focus_point);
    };
    if (slot_status === 1) {
      fetchSubmittedSlotData();
    }
  }, [slot_status]);

  const fetchSlotStatus_0_7 = async (isIgnored) => {
    try {
      const obj = {
        ignore: isIgnored,
      };
      const res = await setSlotStatus_0_7(slot_number, obj);
      // console.log("res.data.slot_status", res.data.slot_status);
      setStatus(res.data.slot_status);
      if (res.data.slot_status === "7") {
        NotificationManager.success("slot is ignored", "", 2000);
      }
      // else if (res.data.slot_status === "0") {
      //   NotificationManager.error("slot is available", "", 2000);
      // }
    } catch (error) {
      // NotificationManager.error("error occured", "", 2000);
      console.log(error);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        {slot_status === 0 && (<button className="buttonStyle"  onClick={handleIgnoreSlot}><MdCheckBoxOutlineBlank />&nbsp;Ignore Slot</button>)}
        {slot_status === 7 && (<button className="buttonStyle"  onClick={handleIncludeSlot}><MdOutlineCheckBox />&nbsp;Include Slot</button>)}
      </div>
      <WsiImgContext.Provider value={{ handleDeleteFeatureChange, handleDrawRectChange, handleUndoFeature, handleRemoveLastFeature, handleDrawFeatureChange }}>
        {(slot_status === 0 || slot_status === 1) && status !== "7" && activeSlot !== 2 && (
          <Initial
            slot_title={slot_title}
            slot_number={slot_number}
            mappingData={mappingData}
            enableCheckbox={enableCheckbox}
            focusPointState={focusPointState}
            initialSlideName={LocalslideName}
            state={state}
            setState={setState}
            setCheckedFeature={setCheckedFeature}
            slot_status={slot_status}
            noOfLayers={noOfLayers}
            freeze_slot_title={freeze_slot_title}
            submittedLens={submittedLens}
            submittedQuality={submittedQuality}
            undoButtonRef={undoButtonRef}
            removeLastButtonRef={removeLastButtonRef}
            deleteFeatureCheckboxRef={deleteFeatureCheckboxRef}
            drawRectCheckboxRef={drawRectCheckboxRef}
            setActiveSlot={setActiveSlot}
          />
        )}
        {(slot_status === 2 || activeSlot === 2) && slot_status !== 3 && <Capturing slot_number={slot_number}/>}
        {(slot_status === 3 || activeSlot === 3) && <Captured />}
        {(slot_status === -1 || activeSlot === -1) && <ErrorSlot />}
        {(slot_status === 7 || activeSlot === 7) && <IgnoreSlot />}
      </WsiImgContext.Provider>
    </>
  );
};

export default SingleSlot;
