import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from "react-dom/client";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'react-notifications/lib/notifications.css';
import "cropperjs/dist/cropper.min.css";
import 'ag-grid-community/styles/ag-grid.css'; 
import 'ag-grid-community/styles/ag-theme-alpine.css';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

createRoot(document.getElementById('root')).render(<App />);


reportWebVitals();
