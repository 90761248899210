import React, { useEffect, useState } from "react";
import { getConfigJsonFileList, get_Config_JsonFile, update_Config_JsonFile, update_Config_JsonFile_in_remote } from "../Service";
import { NotificationContainer, NotificationManager } from "react-notifications";

export const DeviceConfigurationFile = () => {
  const [jsonFileName, setJsonFileName] = useState("");
  const [json, setJson] = useState("");
  const [localJson, setLocalJson] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [configJsonList, setConfigJsonList] = useState([]);
  const [selectedButton, setSelectedButton] = useState(null);

  useEffect(() => {
    async function getConfigJsonFileListData() {
      const res = await getConfigJsonFileList();
      // console.log(res.data)
      setConfigJsonList(res.data.data);
    }
    getConfigJsonFileListData();
  }, []);



  useEffect(() => {
    if (jsonFileName){
      console.log("jsonFileName:",jsonFileName)
      getConfigJsonFile();
    }
    setErrors([]);
  }, [jsonFileName]);

  const getConfigJsonFile = async () => {
    try {
      let obj = {
        json_file_name: jsonFileName,
      };
      setLoading(true);
      const response = await get_Config_JsonFile(obj);

      setJson(JSON.stringify(response.data, null, 2));
      setLocalJson(JSON.stringify(response.data, null, 2));
    } catch (error) {
      console.error("Error fetching JSON file:", error);
      setJson("");
      setLocalJson("");
    } finally {
      setLoading(false);
    }
  };

  let handleJsonChange = (event) => {
    const newJson = event.target.value;
    setLocalJson(newJson);

    try {
      // Try to parse the JSON
      const parsedJson = JSON.parse(newJson);
      setErrors([]);
    } catch (error) {
      // Handle JSON parsing error
      console.error("Error parsing JSON:", error);
      setErrors([{ message: "Invalid JSON format" }]);
    }
  };

  // useEffect(()=>{
  //   handleJsonChange(event)
  // },[jsonFileName])

  const saveConfigJsonFile = async () => {
    try {
      let obj = {
        json_file_name: jsonFileName,
        json_data: JSON.parse(localJson),
      };

      setLoading(true);
      const response = await update_Config_JsonFile(obj);
      if (response) {
        NotificationManager.success("JSON file uploaded", "Notification", 2000);
      }

      // Handle the response, e.g., show a success message
    } catch (error) {
      console.error("Error saving JSON file:", error);
      NotificationManager.error("Something went wrong ", "Notification", 2000);
    } finally {
      setLoading(false);
    }
  };
  const saveConfigJsonFileToRemote = async () => {
    try {
      let obj = {
        json_file_name: jsonFileName,
        json_data: localJson,
        // json_data: JSON.stringify(localJson),
      };

      setLoading(true);
      const response = await update_Config_JsonFile_in_remote(obj);
      if (response) {
        NotificationManager.success("JSON file uploaded", "Notification", 2000);
      }

      // Handle the response, e.g., show a success message
    } catch (error) {
      console.error("Error saving JSON file:", error);
      NotificationManager.error("Something went wrong ", "Notification", 2000);
    } finally {
      setLoading(false);
    }
  };

  const handleButtonClick = (fileName) => {
    setJsonFileName(fileName);
    setSelectedButton(fileName);
  };

  return (
    <div style={{ position: "relative" }}>
      <NotificationContainer />
      <div>
        {configJsonList.map((eachJson) => (
          <button
            key={eachJson}
            onClick={() => handleButtonClick(eachJson)}
            disabled={loading}
            style={{
              backgroundColor: selectedButton === eachJson ? "green" : "white",
              color: selectedButton === eachJson ? "white" : "black",
            }}
          >
            {eachJson}
          </button>
        ))}
      </div>
      <textarea
        id="json_textarea"
        value={localJson}
        onChange={handleJsonChange}
        rows={20}
        cols={80}
        style={{ width: "100%", marginBottom: "40px" }}
      />
      {errors.length > 0 && (
        <div style={{ color: "red" }}>
          JSON validation errors:
          <ul>
            {errors.map((error, index) => (
              <li key={index}>{error.message}</li>
            ))}
          </ul>
        </div>
      )}
      <button
        onClick={saveConfigJsonFile}
        disabled={loading || errors.length > 0}
        style={{ position: "absolute", bottom: "10px", right: "10px" }}
      >
        Save JSON File
      </button>
      <button
        onClick={saveConfigJsonFileToRemote}
        disabled={loading || errors.length > 0}
        style={{ position: "absolute", bottom: "10px", left: "10px" }}
      >
        Save to Remote Server
      </button>
    </div>
  );
};
