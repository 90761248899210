import React from "react";
import { Badge, Card } from "react-bootstrap";

const CardInfo = ({ name, active, handleClick }) => {
  // const randomColor = `hsl(${Math.floor(Math.random() * 360)}, 100%, 50%)`;
  const badgeCustomCreate = (name) => {
    let val = name.split(" ").map((ele) => {
      return ele[0];
    });
    return val.join("");
  };
  return (
    <Card
      onClick={handleClick}
      style={{ borderRadius: "25px" }}
      className="chipCard"
    >
      <Card.Body className="d-flex flex-column justify-content-center align-items-center">
        <div
          class="circle-letter mb-2"
          style={{ backgroundColor: "blue" }}
        >
          {badgeCustomCreate(name)}
        </div>
        <Card.Title>{name}</Card.Title>
        {Number(active) === 0 ? <Badge variant="secondary">Inactive</Badge> : <Badge variant="primary">Active</Badge>}
      </Card.Body>
    </Card>
  );
};

export default CardInfo;
