import { createContext } from "react";
import axios from "axios";
import { environment } from "../../environment";
import { getToken } from "../Utils/Common";
const baseURL = environment.MICALYS_POSTGRES_API;
const baseURLV2 = environment.MICALYS_API_V2;

export const pageReducer = (state, action) => {
  switch (action.type) {
    case "set_userList":
      return {
        ...state,
        userList: action.payload,
      };
    case "set_diskSpaceData":
      return {
        ...state,
        diskSpaceData: action.payload,
      };
    case "set_modalVisible":
      return {
        ...state,
        modalVisible: action.payload,
      };
    case "set_deviceName":
      return {
        ...state,
        deviceName: action.payload,
      };
    case "set_generateCloudIdmodalVisible":
      return {
        ...state,
        generateCloudIdModalVisible: action.payload,
      };
    case "set_Device_Name":
      return {
        ...state,
        registered_device_Name: action.payload,
      };
    case "set_is_Device_name_misMatched":
      return {
        ...state,
        is_Device_name_misMatched: action.payload,
      };
    default:
      return state;
  }
};

export const getUserList = (dispatch) => {
  axios.get(baseURL + "list_users", { headers: { Authorization: `Bearer ${getToken()}` } }).then((res) => {
    dispatch({ type: "set_userList", payload: res.data });
  });
};

export const getDiskSpaceData = (dispatch) => {
  // console.log(dispatch,"sdgds")
  axios.get(baseURL + "get_disk_space", { headers: { Authorization: `Bearer ${getToken()}` } }).then((res) => {
    dispatch({ type: "set_diskSpaceData", payload: res.data });
  });
};

export const addUser = (data) => {
  return axios.post(baseURLV2 + "admin_add_user", data, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const deleteUser = (data) => {
  return axios.post(baseURLV2 + "admin_delete_user", data, { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const adminChangePassword = (data) => {
  return axios.put(baseURLV2 + "admin_change_password", data, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const changePassword = (data) => {
  return axios.post(baseURL + "change_password", data, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getDeviceName = (dispatch) => {
  axios.get(baseURL + "get_devicename", { headers: { Authorization: `Bearer ${getToken()}` } }).then((res) => {
    if (res && res.data) {
      dispatch({ type: "set_deviceName", payload: res.data.DeviceName });
    }
  });
};

export const submitCloudId = (id) => {
  return axios.post(baseURL + "set_cloudid", id, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const checkIfCloudIdExists = () => {
  return axios.get(baseURL + "isCloudIdExists", { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const deleteCloudId = () => {
  return axios.get(baseURL + "delete_cloudid", { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const setSlideActiveDays = (days) => {
  return axios.post(baseURL + "set_slide_active_days", days, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const get_cam_status = () => {
  return axios.get(baseURL + "get_cam_status", { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const restart_cam = () => {
  return axios.get(baseURL + "restart_cam", { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getSlideActiveDays = () => {
  return axios.get(baseURL + "get_slide_active_days", { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const initializeRegistration = (obj) => {
  return axios.post(baseURLV2 + "initialize_registration", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const checkRegistrationProcess = () => {
  return axios.post(baseURLV2 + "is_registration_complete", {}, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const get_Config_JsonFile = (obj) => {
  return axios.post(baseURLV2 + "get_config_json_file", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getConfigJsonFileList = () => {
  return axios.get(baseURLV2 + "get_config_json_file_list", { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const update_Config_JsonFile = (obj) => {
  return axios.post(baseURLV2 + "update_config_json_file", obj, { headers: { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` } });
};
export const update_Config_JsonFile_in_remote = (obj) => {
  return axios.post(baseURLV2 + "upload_json_config_file_in_remote", obj, {
    headers: { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` },
  });
};

export const getAllTags = () => {
  return axios.get(baseURLV2 + "get_all_tags", { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const setAllTags = (obj) => {
  return axios.post(baseURLV2 + "set_all_tags", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const create_new_diagnosis = (obj) => {
  return axios.post(baseURLV2 + "create_new_diagnosis", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const delete_existing_diagnosis = (obj) => {
  return axios.post(baseURLV2 + "delete_existing_diagnosis", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const get_all_existing_diagnosis = () => {
  return axios.get(baseURLV2 + "get_all_existing_diagnosis", { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const add_device_config = (obj) => {
  return axios.post(baseURLV2 + "add_device_config", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const add_module_device_config = (obj) => {
  return axios.post(baseURLV2 + "add_module_to_device_config", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const updateActiveValues = (obj) => {
  return axios.post(baseURLV2 + "update_active_values", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const delete_module_device_config = (obj) => {
  return axios.post(baseURLV2 + "delete_module_from_device_config", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const get_device_config = (obj) => {
  return axios.get(baseURLV2 + "get_device_config", { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const get_module_config = (obj) => {
  return axios.get(baseURLV2 + "get_module_config_from_device_config", { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const getSubscriptionData = () => {
  return axios.get(baseURLV2 + "get_all_subscription_data", { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const resetSubscriptionData = (obj) => {
  return axios.post(baseURLV2 + "reset_subscription_modules_txt_file", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const add_admin_device_config = (obj) => {
  return axios.post(baseURLV2 + "add_admin_device_config", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const get_admin_device_config = () => {
  return axios.get(baseURLV2 + "get_admin_device_config", { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const get_last_modified = () => {
  return axios.get(baseURLV2 + "get_last_modified", { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const tunnel_url_csv_download = () => {
  return axios.get(baseURLV2 + "tunnel_url_csv_download", { headers: { Authorization: `Bearer ${getToken()}` }, responseType: "arraybuffer" });
};
export const reset_qs_secret_key = () => {
  return axios.post(baseURLV2 + "reset_quickshare_secret_key", null, { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const PageContext = createContext(null);
