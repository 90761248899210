import { Oval } from "react-loader-spinner";

export const LoaderComponent = () => {
    const loaderStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '10vh',
        backgroundColor: '#f5f5f5' 
    };

    return (
        <div style={loaderStyle}>
            <Oval 
                color="#808080"  
                height={50} 
                width={50} 
                ariaLabel="loading-indicator"  
            />
       </div>
    );
}
