import React, { createContext, useContext, useEffect, useReducer } from "react";
import { RunningStatusMSGComponent } from "../shared/RunningStatusMSGComponent";
import { Aborted, Aborting } from "../WS Scanning/Register/Components/AbortStates";
import { LoaderComponent } from "../WS Scanning/Register/Components/Loader";
import { handleAbortState } from "../WS Scanning/Service";
import { EnterMaintenance } from "./components/EnterMaintenance";
import MaintenanceLogUi from "./components/MaintenanceLogUi";
import { handleInitialState, pageReducer } from "./Service";
import Alert from "react-bootstrap/Alert";
import { NotificationContainer } from "react-notifications";
import { Navigation } from "../shared/Navigation";
import { setCustomRoute } from "../Utils/Common";

const MaintenanceView = () => {
  const initialState = {
    timeInterval: 500,
    showAbortWithLoader: false,
    isDeviceError: false,
    errText: "",
    componentToRender: <LoaderComponent />,
    componentToRenderInImagePages: null,
    getDeviceTaskStatus: (interval) =>
      handleInitialState().then((res) => {
        console.log("res.data.device_task:",res.data.device_task,res.data.device_status)
        if (!res.data.device_error_msg) {
          if (res.data.device_task === "0" && res.data.device_status === "0") {
            pageDispatcher.componentToRender(<LoaderComponent />);
          }
          else if (res.data.device_task === "1" && res.data.device_status === "1") {
            // setCustomRoute("Maintenance Mode")
            pageDispatcher.set_timeInterval(3000);
            pageDispatcher.componentToRender(<EnterMaintenance />);
          }
          else if (res.data.device_task === "107" && res.data.device_status === "0") {
            pageDispatcher.set_timeInterval(3000);
            pageDispatcher.set_showAbortWithLoader(false);
            pageDispatcher.componentToRender(<MaintenanceLogUi />);
          }
          else if (res.data.device_task === "503" && res.data.device_status === "1") {
            pageDispatcher.set_timeInterval(3000);
            pageDispatcher.componentToRender(<Aborted />);
          }
          else if (res.data.device_task === "2" || res.data.device_task === "3") {
            setCustomRoute("WSI Module")
            pageDispatcher.componentToRender(<RunningStatusMSGComponent runningComponent={"WSI"} />);
          }
          // else if (res.data.device_task === "11" || res.data.device_task === "12" || res.data.device_task === "13") {
          //   pageDispatcher.componentToRender(<RunningStatusMSGComponent runningComponent={"PBS"} />);
          // }
          else if (res.data.device_task === "22" || res.data.device_task === "23") {
            setCustomRoute("GC Module")
            pageDispatcher.componentToRender(<RunningStatusMSGComponent runningComponent={"Grid Capture"} />);
          }
          else if (res.data.device_task === "7") {
            pageDispatcher.componentToRender(<RunningStatusMSGComponent runningComponent={"Live Control"} />);
          }
        }
        if (res.data.device_error_msg) {
          console.log("ERR", res.data.device_error_msg);
          pageDispatcher.set_isDeviceError(true);
          pageDispatcher.set_errorMSG(res.data.device_error_msg);
          //stop interval as well
          return clearInterval(interval);
        }
      }),
  };
  const [pageState, dispatch] = useReducer(pageReducer, initialState);

  useEffect(() => {
    if (pageState) {
      const interval = setInterval(() => {
        pageState.getDeviceTaskStatus(interval);
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [pageState.timeInterval]); // eslint-disable-line react-hooks/exhaustive-deps

  const pageDispatcher = {
    componentToRender: (component) => dispatch({ type: "set_componentToRender", payload: component }),
    componentToRenderInImagePages: (component) => dispatch({ type: "set_componentToRenderInImagePages", payload: component }),
    set_showAbortWithLoader: (value) => dispatch({ type: "set_showAbortWithLoader", payload: value }),
    set_timeInterval: (value) => dispatch({ type: "set_timeInterval", payload: value }),
    set_isDeviceError: (value) => dispatch({ type: "set_isDeviceError", payload: value }),
    set_errorMSG: (val) => dispatch({ type: "set_errorMSG", payload: val }),
  };
  return (
    <>
      <Navigation />
      <PageContext.Provider value={{ pageState, pageDispatcher }}>
        <NotificationContainer />

        {pageState.isDeviceError && (
          <div
            className="container"
            style={{ marginTop: "14%" }}
          >
            <DeviceErrorAbortComponent />
          </div>
        )}
        {pageState && !pageState.componentToRender.key && !pageState.isDeviceError && (
          <div className="container">
            <div className="maintenanceCardpage ">
              <div className="card-body">{pageState.componentToRender}</div>
            </div>
          </div>
        )}
      </PageContext.Provider>
    </>
  );
};

export default MaintenanceView;

const DeviceErrorAbortComponent = () => {
  const context = useContext(PageContext);

  return (
    <Alert variant="danger">
      <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
      <p>{context.pageState.errText}</p>
      <br />
      {context.pageState.isDeviceError && (
        <div
          className="col-2"
          style={{ marginLeft: "42%" }}
        >
          <button
            type="button"
            className="btn btn-danger btn-block"
            onClick={() =>
              handleAbortState().then((res) => {
                if (res && res.data.msg === "ok") {
                  context.pageDispatcher.componentToRender(<Aborting />);

                  setTimeout(function () {
                    // window.location.reload();
                    context.pageDispatcher.set_timeInterval(100);
                    context.pageDispatcher.set_isDeviceError(false);
                  }, 100);
                }
              })
            }
          >
            Abort
          </button>
        </div>
      )}
    </Alert>
  );
};

export const PageContext = createContext(null);
