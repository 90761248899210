import React from 'react'
import { Link } from 'react-router-dom'
import {FaArrowLeft} from "react-icons/fa"

export const PageTitle = (props) => {
  //console.log("props",props)
  return (
    <div>
      <div style={{ float: 'left', margin: '0 0px 0px 0' }}>
        <p>
          {props.backLink && (
            <Link to={props.backLink}>
              <FaArrowLeft aria-hidden="true" />&nbsp;&nbsp;
            </Link>
          )}
          {props.title}
        </p>
      </div>
    </div>
  )
}