import React, { useState, useEffect } from 'react';

const UserConfigPermissions = () => {
  // Initialize state based on localStorage, default to false if not set
  const [is24hrFormat, setIs24hrFormat] = useState(() => {
    const savedValue = localStorage.getItem('24hrFormat');
    return savedValue === 'true';
  });

  // Update localStorage whenever the checkbox is toggled
  const handleToggle = () => {
    setIs24hrFormat(prevValue => {
      const newValue = !prevValue;
      localStorage.setItem('24hrFormat', newValue);
      return newValue;
    });
  };

  useEffect(() => {
    // Set default value in localStorage if not already set
    if (localStorage.getItem('24hrFormat') === null) {
      localStorage.setItem('24hrFormat', 'false');
    }
  }, []);

  return (
    <div>
      <label>
        <input
          type="checkbox"
          checked={is24hrFormat}
          onChange={handleToggle}
          className='mr-3'
        />
        Use 24-Hour Format
      </label>
    </div>
  );
};

export default UserConfigPermissions;
