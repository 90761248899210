import React from "react";
import { add_admin_device_config } from "../Service";
import { NotificationManager } from "react-notifications";


const AdminConfigPermissions = ({setIsPermissionRequired,isPermissionRequired}) => {
  const handleCheckboxChange =async (e) => {
    setIsPermissionRequired(e.target.checked);
    const obj = {
        is_permission_required: e.target.checked ? parseInt(1) : parseInt(0),
      };
      try {
        const res = await add_admin_device_config(obj);
        if (res.status === 200) NotificationManager.success("updated the setting", "", 2000);
      } catch (error) {
        NotificationManager.error("error occurred", "", 2000);
      }
  };

  

  return (
    <div className=" col-md-12 col-lg-10 offset-lg-2 mt-3 d-flex align-items-center">
    <input
      type="checkbox"
      checked={isPermissionRequired}
      onChange={handleCheckboxChange}
      style={{ marginRight: '5px' }}
    />
    <span className="ml-2">Allow downloading of slide for other users</span>
  </div>
  );
};

export default AdminConfigPermissions;
