import React, { useEffect, useState } from "react";
import AutoCaptureAdminSettings from "./AutoCaptureAdminSettings";
import AdminConfigPermissions from "./AdminConfigPermissions";
import { add_admin_device_config, get_admin_device_config } from "../Service";
import { NotificationManager } from "react-notifications";

const AdminConfiguration = () => {
  const [noOfLayers, setNoOfLayers] = useState("1");
  const [quality, setQuality] = useState("1");
  const [lens, setLens] = useState("10");
  const [isNameRequired, setIsNameRequired] = useState(true);
  const [isPermissionRequired, setIsPermissionRequired] = useState(true);
  const [expandedCard, setExpandedCard] = useState(null);

  const handleOkClick = async () => {
    const obj = {
      lens: parseInt(lens),
      q: parseInt(quality),
      z_stack: parseInt(noOfLayers || 1),
      is_name_required: isNameRequired ? parseInt(1) : parseInt(0),
    };

    try {
      const res = await add_admin_device_config(obj);
      if (res.status === 200) NotificationManager.success("Updated the setting", "", 2000);
    } catch (error) {
      NotificationManager.error("Error occurred", "", 2000);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleZStackChange = (e) => {
    setNoOfLayers(e.target.value);
  };

  const options = [1, 3, 5, 7, 9, 11];

  const fetchData = async () => {
    const res = await get_admin_device_config();
    const { lens, q, z_stack, is_name_required, is_permission_required } = res?.data;
    setNoOfLayers(z_stack);
    setLens(lens);
    setQuality(q);
    parseInt(is_name_required) ? setIsNameRequired(true) : setIsNameRequired(false);
    parseInt(is_permission_required) ? setIsPermissionRequired(true) : setIsPermissionRequired(false);
  };

  const handleCheckboxChange = (e) => {
    setIsNameRequired(e.target.checked);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCardClick = (cardName) => {
    setExpandedCard(expandedCard === cardName ? null : cardName);
  };

  return (
    <div>
      <div className="card">
        <div className="card-header" onClick={() => handleCardClick("autoCapture")}>
          <h5 className="mb-0" style={{color:"#007bff"}}>Auto Capture Admin Settings</h5>
        </div>
        {expandedCard === "autoCapture" && (
          <div className="card-body">
            <AutoCaptureAdminSettings
              lens={lens}
              quality={quality}
              noOfLayers={noOfLayers}
              isNameRequired={isNameRequired}
              handleCheckboxChange={handleCheckboxChange}
              setIsNameRequired={setIsNameRequired}
              handleZStackChange={handleZStackChange}
              setNoOfLayers={setNoOfLayers}
              handleSubmit={handleSubmit}
              handleOkClick={handleOkClick}
              setQuality={setQuality}
              setLens={setLens}
            />
          </div>
        )}
      </div>

      <div className="card mt-3">
        <div className="card-header" onClick={() => handleCardClick("configPermissions")}>
          <h5 className="mb-0" style={{color:"#007bff"}}>Admin Config Permissions</h5>
        </div>
        {expandedCard === "configPermissions" && (
          <div className="card-body">
            <AdminConfigPermissions
              setIsPermissionRequired={setIsPermissionRequired}
              isPermissionRequired={isPermissionRequired}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminConfiguration;
