import { useState, useRef } from "react"
import { Button, Modal, Container, Row } from 'react-bootstrap'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { environment } from "../../environment";
import { getToken, setQSExpiry, getQSExpiry, isQSLive } from "../Utils/Common";
import axios from 'axios';
import { FaLink } from "react-icons/fa";

const nodeURL = environment.MICALYS_POSTGRES_API;

export const QuickshareCopyLink = (props) => {

    // let nodeURL = 'http://localhost:4000/';

    const [state, setState] = useState({
        copySuccess: false,
        modalVisible: false,
        tunnelURL: '',
        oldslideid: ''
    })


    const getTunnelURL = () => {
        //setDefaultExpiry here only
        if (!getQSExpiry() && isQSLive() === true) {   // check if expiry already exists : if not then set QSExpiry
            setQSExpiry('2h');
        }
        
        let obj = {
            slide_id: props.slideid,
            expiryHRS: getQSExpiry()
        }
        axios.post(nodeURL + 'getTunnelURL', obj, { headers: { Authorization: `Bearer ${getToken()}` } })
            .then(res => {
                if (res && res.data.tunnelURL) {
                    let renderURL = `${res.data.slidename}    ${res.data.tunnelURL}?slideid=${props.slideid}&slidename=${res.data.slidename}&accesstoken=${res.data.slideToken}`
                    setState({ ...state, modalVisible: true, tunnelURL: renderURL, oldslideid: res.data.slideid }) //Open Modal
                    console.log("URLTUNNEL", renderURL)
                }
                if (res.data.message) {
                    alert(res.data.message)
                }

            }).catch(e => {
                alert('Tunnel is not initialized!')
            })
    }


    return (
        <div>
            <button type="button" data-toggle="tooltip" data-placement="top" title="open to copy link" style={{ color: "#008cff", border: "none", backgroundColor: 'white' }}
                onClick={() => {
                    getTunnelURL();
                }}
            >
                <FaLink aria-hidden="true"/>

            </button>

            {
                state && state.tunnelURL &&
                <QuickLinkModal value={{ state, setState }} />

            }

        </div>
    )
}

export const QuickLinkModal = (props) => {
    const { state, setState } = props.value;
    const urlElement = useRef(null);

    const handleCopyClick = () => {
        var copyText = urlElement.current
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */
        navigator.clipboard.writeText(copyText.value);
        setState({ ...state, modalVisible: false });
        NotificationManager.success("copied!", '', 1500);

    }

    return (
        <>

            <Modal show={state.modalVisible}
                onHide={() => setState({ ...state, modalVisible: false })}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="md"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Share Link: (Expires In {getQSExpiry()})</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <NotificationContainer />

                        <Row>
                            <textarea className="form-control" ref={urlElement} id="exampleFormControlTextarea1" rows="5" defaultValue={state.tunnelURL} readOnly></textarea>
                        </Row>
                    </Container>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="success"
                        onClick={() =>
                            handleCopyClick()
                        }
                    >
                        copy link
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    )
}