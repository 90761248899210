import React, { useContext, useState, useEffect } from "react";
import MapComponent from "./Map/MapComponent";
import Layers from "./Map/Layers";
import VectorLayer from "./Map/VectorLayer";
import { Point } from "ol/geom";
import Feature from "ol/Feature";
import { PageContext } from "../Remote";
import { getWsImage } from "../Service";

const WsImage1 = () => {
  const context = useContext(PageContext);

  const [image, setImage] = useState("");
  useEffect(() => {
    getWsImage().then(async (res) => {
      if (res && res.data && res.data.byteLength > 0) {
        let blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });
        let blobURL = URL.createObjectURL(blob);
        var img = new Image();
        img.src = blobURL;
        setImage(img);
      }
    });
  }, []);

  return (
    <div>
      <MapComponent>
        <Layers>
          <VectorLayer
            source={
              new Feature({
                geometry: new Point([context.pageState.positionData[0]["px-x"], image.height - context.pageState.positionData[0]["px-y"]]),
              })
            }
          />
        </Layers>
      </MapComponent>
    </div>
  );
};
export default WsImage1;
