import axios from "axios";
import React, { memo, useEffect, useState } from "react";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  getSelectedChipset,
  getToken,
  getTypeOfSlidelide,
  getUser,
  removeCookie
} from "../../components/Utils/Common";
import { environment } from "../../environment";
import { FaDatabase } from "react-icons/fa";
const baseURL = environment.MICALYS_POSTGRES_API;

export const Navigation = memo(() => {
  const [space, setSpace] = useState();

  const navigate = useNavigate();

  const handleLogout = () => {
    axios.post(baseURL + "logout", "", { headers: { Authorization: `Bearer ${getToken()}` } }).then((res) => {
      if (res && res.data.msg) {
        removeCookie();
        localStorage.clear();
        if (window.opener) {
          window.opener.location.reload();
        }
        navigate("/Login");
      }
    });
  };

  const hasDataUpdated = async () => {
    return await axios.get(baseURL + "time_last_update", { headers: { Authorization: `Bearer ${getToken()}` } });
  };

  useEffect(() => {
    hasDataUpdated().then((res) => {
      if (res && res.data && res.data.disk_use) {
        setSpace(res.data.disk_use);
      }
    });
  }, []);


  let storagePercentColor;

  const changeColor = (space) => {
    if (space > 75) {
      storagePercentColor = "#e65a5e";
    } else if (space > 50) {
      storagePercentColor = "#fcb103";
    } else {
      storagePercentColor = "#d7fc03";
    }
  };

  changeColor(space);
  const onClickSettings = () => {
    // setCurrentModule("Settings");
    console.log("settings");
  };

  // const handleLinkClick = () => {
  //   const currentModule=getSelectedChipset()
  //   setCurrentModule(currentModule);
  // };

  return (
    <div>
      {getToken() && (
        <Navbar
          bg="dark"
          variant="dark"
          expand="lg"
          className="w-100 d-flex justify-content-between"
        >
          {/* Left section: Main logo link */}
          <Navbar.Brand
            as={Link}
            to={
              getTypeOfSlidelide() === ("WSI" || "Histo")
                ? "/caselist"
                : getTypeOfSlidelide() === "GC"
                ? "/gridCaptureList"
                : getTypeOfSlidelide() === "livecontrol"
                ? "/livecontrol"
                : "#!"
            }
            style={{ color: "#008cff" }}
          >
            <img
              src={process.env.PUBLIC_URL + "/navbar_logo.png"}
              height="50"
              alt="logo"
              className="logo-custom"
            />
          </Navbar.Brand>
  
          {/* Right section: Disk icon and user settings */}
          <div className="d-flex align-items-center">
            {/* Disk icon */}
            <div className="d-flex align-items-center">
              <FaDatabase color={storagePercentColor} fontSize="25" />
              <span>
                <h3
                  style={{
                    color: "#ffffff",
                    fontSize: 16,
                    margin: "auto",
                    marginBottom: 0,
                    marginLeft: 5,
                    marginRight: 10,
                  }}
                >
                  {space}%
                </h3>
                <h3
                  style={{
                    color: "#ffffff",
                    fontSize: 10,
                    margin: "auto",
                    marginTop: 0,
                    marginLeft: 5,
                    marginRight: 10,
                  }}
                >
                  Disc
                </h3>
              </span>
            </div>

            <small className="me-1" style={{ color: "#008cff" }}>{getSelectedChipset()}</small>
  
            {/* Navbar toggle and user dropdown */}
            <>
              <Navbar.Toggle aria-controls="navbarText" />
              <Navbar.Collapse id="navbarText" className="justify-content-end">
                <Nav>
                  <NavDropdown title={getUser()} id="dropdown-basic" align="end">
                    <NavDropdown.Item as={Link} to="/settings" onClick={onClickSettings}>
                      Settings
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="#" onClick={handleLogout}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </>
          </div>
        </Navbar>
      )}
    </div>
  );
});

