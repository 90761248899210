import React from "react";
import { BsBrightnessHigh } from "react-icons/bs";
import { FaBarcode } from "react-icons/fa";
import { IoMdContrast } from "react-icons/io";
import { MdExposure } from "react-icons/md";

const TileLayerStyleControls = () => {

  return (
    <>
      <div className="d-flex align-items-center m-1">
        <BsBrightnessHigh className="mr-1" />
        <input
          id="brightness"
          type="range"
          min="-0.5"
          max="1"
          step="0.01"
          className="mr-1"
        />{" "}
        <span
          className="mr-1"
          id="brightness-value"
        ></span>
      </div>

      <div className="d-flex align-items-center m-1">
        <MdExposure />
        <input
          id="exposure"
          type="range"
          min="-0.5"
          max="1"
          step="0.01"
          className="mr-1 ml-1"
        />
        <span id="exposure-value"></span>
      </div>

      <div className="d-flex align-items-center m-1">
        <IoMdContrast />
        <input
          id="contrast"
          type="range"
          min="-0.5"
          max="1"
          step="0.01"
          className="mr-1  ml-1"
        />
        <span id="contrast-value"></span>
      </div>

      <div className="d-flex align-items-center m-1">
      <FaBarcode />
        <input
          id="saturation"
          type="range"
          min="-0.5"
          max="1"
          step="0.01"
          className="mr-1  ml-1"
        />
        <span id="saturation-value"></span>
      </div>

      <div style={{ textAlign: "center" }}>
        <button
          type="button"
          className="btn btn-secondary btn-sm"
          id="resettilestyle"
        >
          Reset
        </button>
      </div>
    </>
  );
};

export default TileLayerStyleControls;
