import { Abort } from "./AbortStates";
import { handleplaceSlideState } from "../../Service";
import { NotificationManager } from "react-notifications";
import { BsInfoCircle } from "react-icons/bs";

export const PlaceSlide = ({ previous_module_status,previous_module_status_time }) => {
  const handleClick = async () => {
    try {
      const res = await handleplaceSlideState();
      if (res.data.msg) {
        NotificationManager.success(res.data.msg, "", 2000);
        console.log("Start");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.log("error.response:", error.response.data.existing_module_type);
      } else {
        console.error("Error fetching canvas data:", error);
      }
      NotificationManager.error("Failed to start scan", "", 2000);
    }
  };

  return (
    <div>
      <>
        {/* <div className="d-flex">
          <BsInfoCircle
            style={{ color: "#17a2b8" }}
            className="mr-1 mt-1 ml-1"
          />{" "}
        </div> */}
        <div className="mt-5">
        {previous_module_status && <p style={{ color: "#17a2b8" }}>Last Activity: {previous_module_status} at {previous_module_status_time}</p>}
          <h3>Place Slide and Click Ok</h3> <br />
          <div className="d-flex flex-row justify-content-center">
            <div
              style={{ marginTop: "10px" }}
            >
              <Abort />
            </div>
            <div
            className="ms-5 me-5"
              style={{ marginTop: "10px" }}
            >
              <button
                className="btn btn-primary btn-block ml-2"
                onClick={handleClick}
                style={{ width: "150px" }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
