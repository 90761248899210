import { Feature } from "ol";
import { fromExtent } from "ol/geom/Polygon";
import VectorLayer from "ol/layer/Vector";
import { Stroke, Style } from "ol/style";
import { useContext, useEffect, useState } from "react";
import { PageContext } from "../../CaseViewer";
import MapContext from "../Map/MapContext";
import VectorSource from "ol/source/Vector";

const AnnotationBoxLayer = () => {
  const { map } = useContext(MapContext);
  const { state } = useContext(PageContext);
  const { scale_data } = state.selected_slide;
  const [annotationBoxLayerInState, setAnnotationBoxLayer] = useState(null);

let annotationBoxlayer
  useEffect(() => {
   
    if (!map || !state.selectedAnnotationBoxVisibility) return;
    console.log("annotationBoxlayer enter")

     annotationBoxlayer = new VectorLayer({
      name: "my_annotationBoxlayer",
      source: new VectorSource({
        wrapX: false,
      }),
      style: new Style({
        stroke: new Stroke({
          color: "#ff0000",
          width: 2,
        }),
      }),
      tagName: 'annotation-box-layer', 
      zIndex: 72, 
    });

    const rectangleBox = function (resolution, width, height) {
    console.log("annotationBoxlayer rectangleBox")

      var coordinate = map.getView().getCenter();

      let lineStyle = new Style({
        stroke: new Stroke({
          color: "red",
          width: 2,
          lineDash: [4, 8],
        }),
      });
      var draw = new Feature({
        geometry: new fromExtent([
          coordinate[0] - (width * resolution) / 2,
          coordinate[1] - (height * resolution) / 2,
          coordinate[0] + (width * resolution) / 2,
          coordinate[1] + (height * resolution) / 2,
        ]),
      });
      draw.setStyle(lineStyle);
      annotationBoxlayer.getSource().addFeature(draw);
    };
    map.removeLayer(annotationBoxLayerInState);    //when scale changes it removes and adds new annotation layer on go
    rectangleBox(scale_data, localStorage.getItem("x"), localStorage.getItem("y"));
    setAnnotationBoxLayer(annotationBoxlayer)
  }, [map,localStorage.getItem("x"),localStorage.getItem("y"),state.selectedAnnotationBoxVisibility]);


  useEffect(() => {
    if (state.selectedAnnotationBoxVisibility && annotationBoxLayerInState) {
      map.addLayer(annotationBoxLayerInState);
      console.log("annotationBoxlayer addLayer enter")
    } 
  }, [annotationBoxLayerInState]);

  useEffect(()=>{
    if (!state.selectedAnnotationBoxVisibility){
      map.removeLayer(annotationBoxLayerInState);
      console.log("annotationBoxlayer removeLayer enter")
    }
  },[state.selectedAnnotationBoxVisibility])

  return null;
};

export default AnnotationBoxLayer;
