import React from 'react'


const LogView = ({ state }) => {
    return (
        <div className="card rounded border border-dark ">
            <div className="card-header d-flex justify-content-between heading">
                <span >Device Msg</span>
                <span>Log</span>
                <span >Command </span>
            </div>
            <div className="card-body scroll">
                {/* {console.log(state)} */}
                {state && state.map((log, i) =>
                    <div key={i}>
                        {(log.length !== 0 && typeof (log) !== "string") && log.map((logEle, i) =>
                            <div key={i}>
                                <h5 className='text-left text-danger font-weight-bold'>{logEle}</h5>
                            </div>
                        )
                        }
                        {
                            (log.length !== 0 && typeof (log) === "string") && <h5 className='text-right text-success font-weight-bold'>{log}</h5>
                        }
                        <div className="dropdown-divider"></div>
                    </div>
                )}

            </div>
        </div>
    )
}

export default LogView
