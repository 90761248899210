import React, { useContext, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useParams } from "react-router-dom";
import { setDefaultLens } from "../../../Utils/Common";
import { getSlotStatus_0_7, handleRectCoordinates, addCase } from "../../Service";
import RadioButtonForLens from "./RadioButtonForLens";
import { WsiImgContext } from "./SingleSlot";
import { FaEdit, FaMinusCircle, FaUndo } from "react-icons/fa";
import StickyModal from "./StickyModal";
import { PageContext } from "../StitchSlide";
import { Form, Row, Col } from "react-bootstrap";

// import { MdOutlineModeEditOutline } from "react-icons/md";

const Initial = ({
  slot_title,
  slot_number,
  mappingData,
  enableCheckbox,
  focusPointState,
  initialSlideName,
  state,
  setState,
  setCheckedFeature,
  slot_status,
  noOfLayers: noZstackLayer,
  freeze_slot_title,
  submittedLens,
  submittedQuality,
  undoButtonRef,
  removeLastButtonRef,
  deleteFeatureCheckboxRef,
  drawRectCheckboxRef,
  setActiveSlot,
  // setSlotStatus0,
  // setSlotStatus1,
  // setSlotStatus2,
  // setSlotStatus3,
  // handleDeleteFeatureChange
}) => {
  const [noOfLayers, setNoOfLayers] = useState(1);
  const [slideName, setSubmittedSlideName] = useState(initialSlideName);
  const [userProvidedCaseId, setUserProvidedCaseId] = useState("");
  const [modelOptions, setModelOptions] = useState(["Filaria", "WBC"]);
  const [modelName, setModelName] = useState("");
  const handleChange = (event) => {
    setModelName(event.target.value);
  };

  // console.log("userProvidedCaseId:",userProvidedCaseId)
  const { id, case_name } = useParams();
  const { pageState: StitchSlideState, pageDispatcher: StitchSlidePageDispatcher } = useContext(PageContext);

  const [currentCaseName, setCurrentCaseName] = useState(case_name);

  const [show, setShow] = useState(false);

  // const [isChecked, setChecked] = useState(false);
  const options = [1, 3, 5, 7, 9, 11];
  // const handleCheckboxChange = () => {
  //   setChecked(!isChecked);
  // };

  const { handleDeleteFeatureChange, handleDrawRectChange, handleUndoFeature, handleRemoveLastFeature, handleDrawFeatureChange } = useContext(WsiImgContext);
  // handleDeleteFeatureChange
  useEffect(() => {
    setSubmittedSlideName(initialSlideName);
  }, [initialSlideName]);

  const handleOpenModal = () => setShow(true);

  const handleAddNewCase = async (e) => {
    e.preventDefault();
    let obj = {
      case_name: currentCaseName,
    };

    try {
      const res = await addCase(obj);
      if (res.data.case_id) {
        setUserProvidedCaseId(res.data.case_id);
        NotificationManager.success(res.data.Message, "", 2000);
      }

      // pageDispatcher.set_caseDatalist({
      //   caselist: updatedCaseList,
      // });
    } catch (error) {
      NotificationManager.error("error occurred", "", 2000);
    }
  };

  const handleOkClick = async () => {
    let dataArr = state.coordinates;
    let raw_data = state.raw_data;
    let slideNameValue = slideName;
    let quality = state.quality;
    let lens = state.lens;
    let case_id = userProvidedCaseId || id;

    if (dataArr.length > 0) {
      if (slideNameValue !== "") {
        let obj = {
          slide_name: slideNameValue,
          q: parseInt(quality),
          lens: parseInt(lens),
          cropArea: dataArr,
          raw_data: raw_data,
          focus_point: [{ x: focusPointState[0], y: focusPointState[1] }],
          case_id: case_id,
          zstack: parseInt(noOfLayers),
          slot_number: slot_number,
          model_name: modelName,
        };
        const res = await handleRectCoordinates(obj);
        if (res.data.current_active_slot_status) {
          console.log("res.data.current_active_slot_status", res?.data?.current_active_slot_status);
          setActiveSlot(res.data.current_active_slot_status);
          StitchSlidePageDispatcher.set_current_active_slot_status(res?.data?.current_active_slot_status);
          StitchSlidePageDispatcher.set_slot_status(res?.data?.slot_status_array);
        }
      } else {
        NotificationManager.error("SlideName Is Required!!", "", 2000);
      }
    } else {
      NotificationManager.error("Feature Is Required!!", "", 2000);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleZStackChange = (e) => {
    setNoOfLayers(e.target.value);
  };

  const handleDeleteFeature = (e) => {
    const { checked } = e.target;
    if (checked) {
      handleDeleteFeatureChange(e);
    } else {
      handleDrawFeatureChange(e);
    }
  };

  const handleRemoveFeature = () => {
    handleRemoveLastFeature();
    if (deleteFeatureCheckboxRef.current) {
      deleteFeatureCheckboxRef.current.checked = false;
    }
  };

  return (
    <>
      {/* {console.log("Slot TAB")} */}
      <div className="row justify-content-between">
        <div className="col-md-7 col-sm-12">
          <div className="row">
            {!slot_status && (
              <div
                className="col-12 "
                style={{ paddingBottom: "10px" }}
              >
                <div className="d-flex flex-row justify-content-end">
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-3">
                    <input
                      type="checkbox"
                      // id="deleteFeature"
                      ref={deleteFeatureCheckboxRef}
                      className="form-check-input"
                      disabled={enableCheckbox}
                      // checked={checkedFeature}
                      onChange={(e) => {
                        // setCheckedFeature(e.target.checked);
                        // handleDeleteFeatureChange(e);
                        handleDeleteFeature(e);
                      }}
                    />
                    <label
                      className="form-check-label"
                      style={{ fontSize: "13px" }}
                    >
                      Select Feature to Delete
                    </label>
                  </div>

                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-3">
                    <input
                      type="checkbox"
                      // id="drawRect"
                      ref={drawRectCheckboxRef}
                      className="form-check-input"
                      // checked={drawRect}
                      onChange={(e) => {
                        // setDrawRect(e.target.checked);
                        handleDrawRectChange(e);
                      }}
                    />
                    <label
                      className="form-check-label"
                      style={{ fontSize: "13px" }}
                    >
                      Draw Rect
                    </label>
                  </div>

                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-3">
                    <button
                      className="btn btn-light btn-sm btn-block"
                      // id="undo"
                      ref={undoButtonRef}
                      style={{ fontSize: "12px" }}
                      onClick={handleUndoFeature}
                    >
                      <FaUndo className="fa-lg" aria-hidden="true" />
                      &nbsp; Point
                    </button>
                  </div>

                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-3">
                    <button
                      className="btn btn-light btn-sm btn-block"
                      // id="removeLast"
                      ref={removeLastButtonRef}
                      style={{ fontSize: "12px" }}
                      onClick={handleRemoveFeature}
                    >
                      {" "}
                      <FaMinusCircle className="fa-lg" aria-hidden="true" />
                      &nbsp; Clear all
                    </button>
                  </div>
                </div>
              </div>
            )}
            {slot_status === 0 && (
              <div className="col-12">
                <div
                  id={slot_title}
                  className=""
                  style={{ width: "100%", height: "400px", border: "1px solid gray" }}
                />
              </div>
            )}
            {slot_status === 1 && (
              <div className="col-12">
                <div
                  ref={freeze_slot_title}
                  className=""
                  style={{ width: "100%", height: "400px", border: "1px solid gray", pointerEvents: "none" }}
                />
              </div>
            )}
          </div>
          <br />
        </div>

        {!slot_status ? (
          <div className="col-md-5">
            {/* <h3>Select the area to capture</h3> <br /> */}
            <h3>Select the area to capture for {slot_title}</h3> <br />
            <div className="row">
              <div className="col-md-10 col-sm-11 offset-sm-1">
                <form onSubmit={handleSubmit}>
                  <div className="form-group mb-3">
                    <div className="d-flex light-gray-strip">
                      <p
                        className="col-md-4 col-lg-3 mt-2 mb-0"
                        style={{ fontWeight: "bold" }}
                      >
                        Slide Name:
                      </p>
                      <div className="col-md-8 col-lg-9">
                      <input
                        type="text"
                        id="slidename"
                        value={slideName}
                        onChange={(e) => {
                          setSubmittedSlideName(e.target.value);
                        }}
                        className="form-control col-md-6 col-lg-6 ml-3"
                        placeholder="Please enter slide name"
                      />
                      </div>
                    </div>
                  </div>

                  <div className="form-group  mb-3">
                    <d className="d-flex light-gray-strip">
                      <label
                        htmlFor="inputPassword3"
                        className="col-md-4 col-lg-3 col-form-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Choose Quality:
                      </label>

                      <div className="d-flex justify-content-between flex-row col-md-8 col-lg-7 mt-2">
                        <div>
                          <div className="col-md-4 col-lg-4 col-sm-12 ">
                            <div className="form-check pull-left">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="quality"
                                id="check1"
                                value="1"
                                checked={state?.quality === "1"}
                                onChange={(e) => {
                                  setState({ ...state, quality: e.target.value });
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="check1"
                              >
                                Standard
                              </label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="col-md-4 col-lg-4 col-sm-12">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="quality"
                                id="check2"
                                value="2"
                                checked={state?.quality === "2"}
                                onChange={(e) => {
                                  setState({ ...state, quality: e.target.value });
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="check2"
                              >
                                High
                              </label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="col-md-4 col-lg-4 col-sm-12">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="quality"
                                id="check3"
                                value="3"
                                checked={state?.quality === "3"}
                                onChange={(e) => {
                                  setState({ ...state, quality: e.target.value });
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="check3"
                              >
                                Best
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </d>
                  </div>
                </form>

                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    {mappingData && (
                      <div className="d-flex light-gray-strip mb-3">
                        <label
                          htmlFor="inputPassword3"
                          className="col-md-4 col-lg-3 col-form-label"
                          style={{ fontWeight: "bold" }}
                        >
                          Lens:
                        </label>
                        <div className="d-flex justify-content-between flex-row col-md-8 col-lg-7 mt-2">
                          {Object.entries(mappingData).map(([k, value]) => (
                            <div key={k}>
                              {k !== "" && value !== "" && (
                                <RadioButtonForLens
                                  k={k}
                                  value={value}
                                  state={state}
                                  setState={setState}
                                  setDefaultLens={setDefaultLens}
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <div className="d-flex align-items-center light-gray-strip mb-3">
                      <p
                        style={{ fontWeight: "bold" }}
                        className="mb-2 mt-2 col-md-4 col-lg-3 mb-0"
                      >
                        zstack:
                      </p>
                      <select
                        className="custom-select custom-select-sm ml-3 col-md-8 col-lg-6"
                        style={{ width: "30%" }}
                        value={noOfLayers}
                        onChange={handleZStackChange}
                      >
                        {options.map((option, index) => (
                          <option
                            key={index}
                            value={option}
                          >
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                    {StitchSlideState.hideMlUi && (
                    <Form className="light-gray-strip mb-3">
                      <Form.Group
                        as={Row}
                        controlId="SelectModel"
                        className="m-0 p-0"
                      >
                        <Form.Label
                          style={{ fontWeight: "bold" }}
                          className="mt-2 col-md-4 col-lg-3"
                        >
                          Select Model:
                        </Form.Label>
                        <Col className="col-md-8 col-lg-6 m-0 pr-0">
                          <Form.Control
                            as="select"
                            value={modelName}
                            onChange={handleChange}
                            className="pl-0 ml-0"
                          >
                            <option
                              value=""
                              disabled
                              selected
                              hidden
                            >
                              Select a model...
                            </option>
                            {modelOptions.map((option) => (
                              <option
                                key={option}
                                value={option}
                              >
                                {option}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    </Form>
                    )}
                  </div>
                </form>
                {/* <div className="d-flex light-gray-strip mb-3"> */}
                <div
                  className="d-flex justify-content-start light-gray-strip mb-3"
                  onClick={handleOpenModal}
                >
                  <p
                    style={{ fontWeight: "bold" }}
                    className="mb-2 mt-2 col-md-4 col-lg-3 ps-0 ms-0"
                  >
                    Case Name :
                  </p>
                  <p
                    className="mb-2 mt-2 col-md-8 col-lg-6"
                    style={{ cursor: "pointer", textAlign: "left" }}
                  >
                    {currentCaseName}
                    <sup>
                      <FaEdit
                        // size={12}
                        style={{ color: "#0275d8", marginLeft: "3px" }}
                      />
                    </sup>
                  </p>
                </div>
                {/* </div> */}
                <StickyModal
                  show={show}
                  setShow={setShow}
                  case_name={case_name}
                  setUserProvidedCaseId={setUserProvidedCaseId}
                  setCurrentCaseName={setCurrentCaseName}
                  currentCaseName={currentCaseName}
                />
                <div className="row justify-content-center">
                  <div className="col-md-6 col-sm-4">
                    <button
                      type="button"
                      size="lg"
                      id="btn"
                      className="btn btn-primary w-75"
                      onClick={handleOkClick}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <p
              name="textout"
              id="textout"
            ></p>
          </div>
        ) : (
          <div className="col-md-5 d-flex flex-column mr-5">
            <h3 className="my-3 mr-5">Slide Name: {slideName}</h3>
            <h3 className="my-3">Quality: {submittedQuality}</h3>
            <h3 className="my-3">Lens: {submittedLens}</h3>
            <h3 className="my-3">Z-stack: {noZstackLayer}</h3>
          </div>
        )}

        {/* <label>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          Ignore
        </label> */}
      </div>
    </>
  );
};

export default Initial;
