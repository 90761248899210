import React, { createContext, useEffect, useReducer, useContext } from "react";
import { Start } from "./Components/Start";
import { PlaceSlide } from "./Components/PlaceSlide";
import { Canvas } from "./Components/Canvas";
import { Capture, CaptureOver } from "./Components/CaptureStates";
import { LiveImage } from "./Components/LiveImage";
import { LoaderComponent } from "./Components/Loader";
import { Abort, Aborting, Aborted } from "./Components/AbortStates";
import { RunningStatusMSGComponent } from "../../shared/RunningStatusMSGComponent";
import { pageReducer, handleInitialState, handleAbortState } from "../Service";
import Alert from "react-bootstrap/Alert";
import { NotificationContainer } from "react-notifications";
import { Navigation } from "../../shared/Navigation";
import { GridModuleMismatchWarning } from "./Components/GridModuleMismatchWarning";
export const SlideGridCapture = () => {
  const initialState = {
    timeInterval: 500,
    showAbortWithLoader: false,
    isDeviceError: false,
    errText: "",
    componentToRender: <LoaderComponent />,
    componentToRenderInImagePages: null,
    runningModule: "",
    getDeviceTaskStatus: (interval) =>
      handleInitialState().then((res) => {
        if (!res.data.device_error_msg) {
          if (res.data.device_task === "0" && res.data.device_status === "1") {
            pageDispatcher.set_timeInterval(3000);
            pageDispatcher.componentToRender(<Start />);
          } else if ((res.data.device_task === "1" || res.data.device_task === "3") && res.data.device_status === "1") {
            pageDispatcher.set_timeInterval(3000);
            pageDispatcher.set_showAbortWithLoader(false);
            pageDispatcher.componentToRender(
              <PlaceSlide
                previous_module_status={res.data.previous_module_status}
                previous_module_status_time={res.data.previous_module_status_time}
              />
            );
          } else if (res.data.device_task === "2" && res.data.device_status === "1") {
            pageDispatcher.set_timeInterval(3000);
            pageDispatcher.set_showAbortWithLoader(false);
            pageDispatcher.componentToRender(<GridModuleMismatchWarning running_module_type={res.data.running_module_type} />);
          } else if (res.data.device_task === "22" && res.data.device_status === "0") {
            pageDispatcher.componentToRender(<Capture />);
          } else if (res.data.device_task === "22" && res.data.device_status === "1") {
            pageDispatcher.set_timeInterval(3000);
            pageDispatcher.componentToRender(<Canvas key={true} />);
          } else if (res.data.device_task === "23" && res.data.device_status === "0") {
            pageDispatcher.set_timeInterval(3000);
            pageDispatcher.componentToRender(<LiveImage key={true} />);
          } else if (res.data.device_task === "23" && res.data.device_status === "1") {
            pageDispatcher.set_timeInterval(3000);
            pageDispatcher.componentToRender(
              <PlaceSlide
                previous_module_status={res.data.previous_module_status}
                previous_module_status_time={res.data.previous_module_status_time}
              />
            );
          } else if (res.data.device_task === "21" && res.data.device_status === "0") {
            pageDispatcher.set_timeInterval(500);
            pageDispatcher.set_showAbortWithLoader(true);
            pageDispatcher.componentToRender(<Abort />);
          } else if (res.data.device_task === "503" && res.data.device_status === "0") {
            pageDispatcher.componentToRender(<Aborting />);
          } else if (res.data.device_task === "0" && res.data.device_status === "0") {
            pageDispatcher.componentToRender(<LoaderComponent />);
          } else if (res.data.device_task === "503" && res.data.device_status === "1") {
            pageDispatcher.set_timeInterval(3000);
            pageDispatcher.componentToRender(<Aborted />);
          } else if (res.data.device_task === "107") {
            pageDispatcher.componentToRender(<RunningStatusMSGComponent runningComponent={"Maintenance Mode"} />);
          }
        }
        if (res.data.device_error_msg) {
          pageDispatcher.set_isDeviceError(true);
          pageDispatcher.set_errorMSG(res.data.device_error_msg);
          //stop interval as well
          return clearInterval(interval);
        }
      }),
  };
  const [pageState, dispatch] = useReducer(pageReducer, initialState);
  useEffect(() => {
    if (pageState) {
      const interval = setInterval(() => {
        pageState.getDeviceTaskStatus(interval);
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [pageState.timeInterval]); // eslint-disable-line react-hooks/exhaustive-deps

  const pageDispatcher = {
    componentToRender: (component) => dispatch({ type: "set_componentToRender", payload: component }),
    componentToRenderInImagePages: (component) => dispatch({ type: "set_componentToRenderInImagePages", payload: component }),
    set_showAbortWithLoader: (value) => dispatch({ type: "set_showAbortWithLoader", payload: value }),
    set_timeInterval: (value) => dispatch({ type: "set_timeInterval", payload: value }),
    set_isDeviceError: (value) => dispatch({ type: "set_isDeviceError", payload: value }),
    set_errorMSG: (val) => dispatch({ type: "set_errorMSG", payload: val }),
    set_running_module_name: (val) => dispatch({ type: "set_running_module_name", payload: val }),
  };

  return (
    <>
      <Navigation />
      <PageContext.Provider value={{ pageState, pageDispatcher }}>
        <NotificationContainer />
        {pageState.isDeviceError && (
          <div
            className="container p-0"
            style={{ marginTop: "14%" }}
          >
            <DeviceErrorAbortComponent />
          </div>
        )}
        {pageState && !pageState.componentToRender.key && !pageState.isDeviceError && (
          <div className="p-0 m-0">
            <div className="stitchcardpage text-center m-0">
              <div className="card-body p-0">{pageState.componentToRender}</div>
            </div>
          </div>
        )}

        {pageState && pageState.componentToRender.key && !pageState.isDeviceError && <div className="imagepages">{pageState.componentToRender}</div>}
      </PageContext.Provider>
    </>
  );
};

const DeviceErrorAbortComponent = () => {
  const context = useContext(PageContext);

  return (
    <Alert variant="danger">
      <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
      <p>{context.pageState.errText}</p>

      <br />
      {context.pageState.isDeviceError && (
        <div
          className="col-2"
          style={{ marginLeft: "42%" }}
        >
          <button
            type="button"
            className="btn btn-danger btn-block"
            onClick={() =>
              handleAbortState().then((res) => {
                if (res && res.data.msg === "ok") {
                  context.pageDispatcher.componentToRender(<Aborting />);

                  setTimeout(function () {
                    // window.location.reload();
                    context.pageDispatcher.set_timeInterval(100);
                    context.pageDispatcher.set_isDeviceError(false);
                  }, 100);
                }
              })
            }
          >
            Abort
          </button>
        </div>
      )}
    </Alert>
  );
};

export const PageContext = createContext(null);
