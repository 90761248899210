import { handleStartState } from "../../Service";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useContext, useState } from "react";
import { PageContext } from "../SlideGridCapture";
import { GridModuleMismatchWarning } from "./GridModuleMismatchWarning";

export const Start = () => {
  const [ui, setUI] = useState(true);
  const { pageState, pageDispatcher} = useContext(PageContext);

  const navigate = useNavigate();
  const renderTooltip = (props) => (
    <Tooltip
      id="back-tooltip"
      {...props}
    >
      Back
    </Tooltip>
  );
  const handleGoBack = () => {
    const newUrl = `/gridCaptureList`;
    navigate(newUrl);
  };

  const handleClick = async () => {
    try {
      const res = await handleStartState();
      if (res.data.msg) {
        NotificationManager.success(res.data.msg, "", 2000);
        console.log("Start");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.log("error.response:", error.response.data.existing_module_type);
        pageDispatcher.set_running_module_name(error.response.data.existing_module_type);
        setUI(true);
      } else {
        console.error("Error fetching canvas data:", error);
      }
      NotificationManager.error("Failed to start scan", "", 2000);
    }
  };


  return (
    <div>
        {(pageState.runningModule )? (
        <GridModuleMismatchWarning />
      ) : (
        
    <>
      <div
        className="col-md-4 offset-md-4 text-center"
        style={{ marginTop: "30px" }}
      >
        <p>Start scanning your slide</p>
        <button
          className="btn btn-primary btn-block"
          onClick={handleClick}
        >
          Start Scan
        </button>
      </div>
      <div>
        <OverlayTrigger
          placement="bottom"
          overlay={renderTooltip}
        >
          <button
            className="back-button"
            onClick={handleGoBack}
          >
            <BiArrowBack size={30} />
          </button>
        </OverlayTrigger>
      </div>
    </>
)}
</div>
  );
};
