import { useEffect, useRef } from "react";
import { handleAbortState, takeControlState } from "../Service";
import { NotificationContainer, NotificationManager } from "react-notifications";

export const LoadSlide = () => {
  const audioElement = useRef(null);
  useEffect(() => {
    var audio1 = audioElement.current;
    audio1.play().catch((err) => {});

    return () => audio1.pause();
  }, []);

  return (
    // <div className="d-flex flex-column justify-content-center align-items-center">
    <div className="">
      <h3> Take control to load slide...</h3>
      {/* <audio ref={audioElement} autoPlay loop hidden src="http://commondatastorage.googleapis.com/codeskulptor-assets/Collision8-Bit.ogg"></audio> */}
      <audio
        ref={audioElement}
        autoPlay
        loop
        hidden
        src="./ting-long.mp3"
      ></audio>
      <div className="row justify-content-center">
        <div
          className="col-md-3"
          style={{ marginTop: "10px" }}
        >
          <button
            type="button"
            className="btn btn-danger btn-block"
            onClick={() =>
              handleAbortState().then((res) => {
                if (res.data && res.data.msg) {
                  NotificationManager.success(res.data.msg, "", 2000);
                }
              })
            }
          >
            Abort
          </button>
        </div>
        <div
          className="col-md-3"
          style={{ marginTop: "10px" }}
        >
          <button
            id="stopAudio"
            className="btn btn-primary btn-block"
            onClick={() =>
              takeControlState().then((res) => {
                if (res.data && res.data.msg) {
                  NotificationManager.success(res.data.msg, "", 2000);
                  console.log("LoadSlide");
                }
              })
            }
          >
            Ok
          </button>
        </div>
      </div>
    </div>
    // </div>
  );
};
