import { Feature } from "ol";
import { unByKey } from "ol/Observable";
import Overlay from "ol/Overlay";
import { LineString } from "ol/geom";
import Polygon from "ol/geom/Polygon";
import Circle from "ol/geom/Circle";
import Point from "ol/geom/Point";
import Draw, { createBox } from "ol/interaction/Draw";
import Modify from "ol/interaction/Modify";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import { useContext, useEffect, useRef, useState } from "react";
import { PageContext } from "../../CaseViewer";
import MapContext from "../Map/MapContext";
import { Button } from "react-bootstrap";
import { addAnnotation } from "../../../Service";
import { NotificationManager } from "react-notifications";
import { getLength } from "ol/sphere.js";

const LayerVector = () => {
  const { map } = useContext(MapContext);
  const { state, pageDispatcher } = useContext(PageContext);
  const { scale_data, id: slide_id, token } = state.selected_slide;
  const { cordinate, clicked, primarykey, radius: circleRadius } = state.selected_annotation_slide;
  const vector_layer = useRef(null);
  const ml_vector_layer = useRef(null);
  const dropdownRef = useRef(null);
  const measureTooltipRef = useRef(null);
  const [tooltipCord, setTooltipCord] = useState([]);
  const popupElementRef = useRef(null);
  const [commentPopUpVisibility, setCommentPopUpVisibility] = useState(false);
  const [output, setOutput] = useState("");
  const [radius, setRadius] = useState("");
  const [geomType, setGeomType] = useState(null);
  const [rerenderOnError, setRerenderOnError] = useState(false);
  const [measureOverlayUid, setMeasureOverlayUid] = useState("");
  const [annotationCoordinates, setAnnotationCoordinates] = useState(null);
  const [comment, setComment] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [annotationName, setAnnotationName] = useState("");
  const [diagComm, setDiagComm] = useState([]);
  // let dropdownOptions = ["Option 1", "Option 2", "Option 3"];

  let dropdownOptions;

  useEffect(() => {
    if (state.multi_question_answer && state.multi_question_answer.comment) {
      const isCommentArray = state.multi_question_answer.comment instanceof Array;
      if (isCommentArray) {
        setDiagComm(state.multi_question_answer.comment);
      }
    }
  }, [state.multi_question_answer.comment]);

  let measurementValue, tooltipCoord, tempMeasureTooltipElement, tempmeasureTooltip, tempgeomMeasureTooltipElement;
  const handleFeatureDrawn = (feature) => {
    const geomTypeFeature = feature.getGeometry();
    // console.log("selectedAnnotation geomTypeFeature:",geomTypeFeature)

    if (geomTypeFeature.getType() === "Polygon") {
      const coordinates = feature.getGeometry().getCoordinates();
      const exteriorCoords = coordinates[0];

      const Xarr = exteriorCoords.map((el) => Math.round(el[0]));
      const Yarr = exteriorCoords.map((el) => Math.round(el[1]));

      measurementValue = polygonArea(Xarr, Yarr, Xarr.length);
      tooltipCoord = geomTypeFeature.getInteriorPoint().getCoordinates();
    } else if (geomTypeFeature.getType() === "LineString") {
      const coordinates = feature.getGeometry().getCoordinates();
      const roundedCoords = coordinates.map((el) => [Math.round(el[0]), Math.round(el[1])]);
      measurementValue = lineStringLength(roundedCoords);
      tooltipCoord = geomTypeFeature.getLastCoordinate();
    } else if (geomTypeFeature.getType() === "Circle") {
      const circleRadius = feature.getGeometry().getRadius();
      tooltipCoord = feature.getGeometry().getCenter();
      measurementValue = circleArea(circleRadius);
      setRadius(circleRadius);
    } else if (geomTypeFeature.getType() === "Point") {
      const coordinates = feature.getGeometry().getCoordinates();
      const roundedCoords = coordinates.map((el) => [Math.round(el)]);
      const pointCoords = [
        [roundedCoords[0][0], roundedCoords[1][0]],
        [roundedCoords[0][0], roundedCoords[1][0]],
      ];
      measurementValue = lineStringLength(pointCoords);
      tooltipCoord = geomTypeFeature.getLastCoordinate();
    }
    setOutput(measurementValue);
    setTooltipCord(tooltipCoord);
    setCommentPopUpVisibility(true);
    setSelectedOption("");
    createTextBoxTooltip(tooltipCoord);
  };

  const polygonArea = (X, Y, n) => {
    let area = 0.0;
    let j = n - 1;
    for (let i = 0; i < n; i++) {
      area += (X[j] + X[i]) * (Y[j] - Y[i]);
      j = i;
    }

    let val = Math.abs(area / 2.0);

    if (!Number.isInteger(val)) {
      val.toFixed(2);
    }

    let output;
    if (scale_data) {
      output = `${(val / Math.pow(scale_data, 2)).toFixed(2)} µm<sup>2</sup>`;
    } else {
      output = `${val} px<sup>2</sup>`;
    }

    return output;
  };

  const circleArea = (circleRadius) => {
    let area = 0.0;
    area = Math.PI * Math.pow(circleRadius, 2);
    if (!Number.isInteger(area)) {
      area.toFixed(2);
    }

    let output;
    if (scale_data) {
      output = `${(area / Math.pow(scale_data, 2)).toFixed(2)} µm<sup>2</sup>`;
    } else {
      output = `${area} px<sup>2</sup>`;
    }
    return output;
  };

  const lineStringLength = (lineCoords) => {
    let output, data;
    let dataarr = [];
    for (let i = 0; i < lineCoords.length; i++) {
      let xn = lineCoords[i][0];
      let yn = lineCoords[i][1];

      if (lineCoords[i + 1] !== undefined) {
        let xinc = lineCoords[i + 1][0];
        let yinc = lineCoords[i + 1][1];

        data = Math.sqrt(Math.pow(xinc - xn, 2) + Math.pow(yinc - yn, 2));
        dataarr.push(data);
      }
    }

    let sum = dataarr.reduce(function (accumulator, currentValue) {
      return accumulator + currentValue;
    });

    let val = Math.round(sum);

    if (!Number.isInteger(val)) {
      val.toFixed(2);
    }

    if (scale_data) {
      output = `${(val / scale_data).toFixed(2)} µm`;
    } else {
      output = `${val} px`;
    }

    return output;
  };

  const formatLength = function (line) {
    const length = getLength(line);
    // console.log("length:",length)
    let output;
    if (!Number.isInteger(length)) {
      length.toFixed(2);
    }

    if (scale_data) {
      output = `${(length / scale_data).toFixed(2)} µm`;
    } else {
      output = `${length} px`;
    }
    return output;
  };

  const createMeasureTooltip = (coord, output, uiqueIdMeasure) => {
    let measureTooltipElement = document.createElement("div");
    measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
    measureTooltipElement.innerHTML = output;

    let measureTooltip = new Overlay({
      element: measureTooltipElement,
      offset: [0, -15],
      positioning: "bottom-center",
      stopEvent: false,
      insertFirst: false,
    });
    measureTooltip.customProperty = { masure_toolTip_id: uiqueIdMeasure };
    map.addOverlay(measureTooltip);
    measureTooltip.setPosition(coord);
    measureTooltipElement.style.display = "block";
  };
  const createTempMeasureTooltip = (coord, output, uiqueIdMeasure) => {
    if (tempMeasureTooltipElement) {
      tempMeasureTooltipElement.parentNode.removeChild(tempMeasureTooltipElement);
    }
    tempMeasureTooltipElement = document.createElement("div");
    tempMeasureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
    tempmeasureTooltip = new Overlay({
      element: tempMeasureTooltipElement,
      offset: [0, -15],
      positioning: "bottom-center",
      stopEvent: false,
      insertFirst: false,
    });
    map.addOverlay(tempmeasureTooltip);
  };

  const createTextBoxTooltip = (coord) => {
    // console.log("createTextBoxTooltip called with coordinates:", coord);
    // const popupElement = document.getElementById("popup");
    // console.log("popupElement ", popupElement);

    const popupElement = popupElementRef.current;
    if (popupElement) {
      // console.log("selectedAnnotation createTextBoxTooltip fun");

      const popup = new Overlay({
        element: popupElement,
        // position: coord,
        positioning: "center-left",
        offset: [0, 0],
        stopEvent: false,
        insertFirst: false,
        id: "text-popup",
      });

      map.addOverlay(popup);
      popup.setPosition(coord);
      popupElement.style.display = "block";
    }
  };

  const createCommentTooltip = (coord, comment, uiqueIdComment) => {
    const tooltipElement = document.createElement("div");
    tooltipElement.className = "ol-tooltip ol-tooltip-comment";
    tooltipElement.innerHTML = comment;

    const tooltip = new Overlay({
      element: tooltipElement,
      positioning: "center-left",
      offset: [0, 0],
      stopEvent: false,
      insertFirst: false,
    });
    tooltip.customProperty = { comment_toolTip_id: uiqueIdComment };
    map.addOverlay(tooltip);
    tooltip.setPosition(coord);
    tooltipElement.style.display = "block";
  };

  const handleSubmitComment = async () => {
    const epochTime = Math.floor(Date.now() / 1000); // Get current epoch timestamp
    const lastFourDigits = epochTime % 10000; // Get last four digits

    let obj = {
      slide_id: slide_id,
      type_of: "annotation",
      color: state.annotationColor,
      label: annotationName + " " + lastFourDigits.toString(),
      raw_data: {
        type: geomType,
        coordinates: annotationCoordinates,
        length: output,
        comment: comment,
        radius: radius,
      },
      profile_content: { annotation_hide: "0", annotation_comment: "0", annotation_measurement: "0" },
    };

    try {
      const res = await addAnnotation(token, obj);
      if (res.data.Message) NotificationManager.success(res.data.Message, "", 2000);
      // console.log("annotation_id:", res.data.annotation_id);
      createCommentTooltip(tooltipCord, comment, res.data.annotation_id);
      createMeasureTooltip(tooltipCord, output, res.data.annotation_id);
      let newAnnotation = obj;
      newAnnotation["annotationId"] = res.data.annotation_id;
      newAnnotation["created"] = res.data.created;
      newAnnotation["color"] = state.annotationColor;
      newAnnotation["profileContent"] = newAnnotation["profile_content"];
      newAnnotation["username"] = state.username_cookie;
      delete newAnnotation["profile_content"];
      newAnnotation["rawData"] = { raw_data: newAnnotation.raw_data };
      delete newAnnotation["raw_data"];
      const updatedAnnotations = [newAnnotation, ...state.annotation_list];
      pageDispatcher.set_annotation_list(updatedAnnotations);
    } catch (error) {
      if (rerenderOnError) {
        setRerenderOnError(false);
      } else {
        setRerenderOnError(true);
      }
      console.log(error);
      NotificationManager.error("error occurred", "", 2000);
    }
    setCommentPopUpVisibility(false);
    setComment("");
    setRadius("");
  };

  useEffect(() => {
    const element = document.getElementById("popup");
    popupElementRef.current = element;
  }, []);

  useEffect(() => {
    if (!map) return;
    let draw, measureTooltip, sketch;
    let typeSelect = state.AnnotationElement.selectedAnnotationElement;
    const continueLineMsg = "Click to continue drawing the line";
    const continuePolygonMsg = "Click to continue drawing the polygon";

    const pointerMoveHandler = function (evt) {
      // console.log("selectedAnnotation selectedAnnotationMoveHandler 2");
      // createMeasureTooltip();

      if (evt.dragging) {
        // console.log("selectedAnnotation dragging");

        return;
      }
    };

    if (typeSelect) {
      map.removeInteraction(draw);
      map.on("selectedAnnotationmove", pointerMoveHandler);
      // createMeasureTooltip();
      // console.log("selectedAnnotation IF typeSelect");
      addInteraction(typeSelect);

      // For Labelling Annotation
      if (typeSelect == "LineString") {
        setAnnotationName("Line");
      } else {
        setAnnotationName(typeSelect);
      }
    } else {
      map.removeInteraction(draw);
      sketch = null;
      map.removeOverlay(measureTooltip);
    }

    function addInteraction(selectedElement) {
      // if (!!selectedElement?.id) {
      let type = selectedElement;
      let geometryFunction;
      // console.log("selectedAnnotation addInteraction fun");

      if (type === "Box") {
        type = "Circle";
        geometryFunction = createBox();
      }
      draw = new Draw({
        source: vector_layer.current.getSource(),
        type: type,
        geometryFunction: geometryFunction,
        zIndex: Infinity,
        style: new Style({
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)",
          }),
          stroke: new Stroke({
            color: state.annotationColor,
            // lineDash: [10, 10],
            width: 2,
          }),
          image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
              color: "rgba(0, 0, 0, 0.7)",
            }),
            fill: new Fill({
              color: "rgba(255, 255, 255, 0.2)",
            }),
          }),
        }),
      });

      map.addInteraction(draw);

      createTempMeasureTooltip();

      let listener;

      draw.on("drawstart", function (evt) {
        // console.log("draw drawstart:",evt.feature)
        sketch = evt.feature;
        let tooltipCoorddd = evt.coordinate;
        listener = sketch.getGeometry().on("change", function (event) {
          const geom = event.target;
          let outputtt;
          if (geom instanceof LineString) {
            // console.log('geom:', geom);
            outputtt = formatLength(geom);
            tooltipCoorddd = geom.getLastCoordinate();
          }
          tempMeasureTooltipElement.innerHTML = outputtt;
          tempmeasureTooltip.setPosition(tooltipCoorddd);
        });

        createTextBoxTooltip();
      });

      draw.on("drawend", function (event) {
        // console.log("draw drawend:",event)
        const feature = event.feature;
        const style = new Style({
          image: new CircleStyle({
            radius: 6, // Adjust the radius of the circle as needed
            fill: new Fill({
              color: state.annotationColor,
            }),
            stroke: new Stroke({
              color: state.annotationColor, // Stroke color for the circle's outline
              width: 2,
            }),
          }),
          stroke: new Stroke({
            color: state.annotationColor,
            width: 2,
          }),
        });
        feature.setStyle(style);
        // console.log("feature getType:",feature)
        setGeomType(feature.getGeometry().getType());
        if (feature.getGeometry().getType() == "Circle") {
          setAnnotationCoordinates([feature.getGeometry().getCenter()]);
        } else {
          setAnnotationCoordinates(feature.getGeometry().getCoordinates());
        }
        handleFeatureDrawn(feature);

        unByKey(listener);
        map.removeInteraction(draw);
        map.un("selectedAnnotation", pointerMoveHandler);
        sketch = null;
        const obj = { type: null, status: false };
        pageDispatcher.set_selectedAnnotationElement(obj);
      });
    }

    return () => {
      map.removeInteraction(draw);
      map.removeOverlay(tempmeasureTooltip);
    };
  }, [state.AnnotationElement]);

  useEffect(() => {
    if (!map) return;

    // let draw, measureTooltip, sketch;
    // let typeSelect = state.AnnotationElement.selectedAnnotationElement;
    const dropdown = dropdownRef.current;

    // if (!!dropdownOptions && !!dropdown) {
    // if (!!diagComm && !!dropdown) {
    //   while (dropdown.hasChildNodes()) {
    //     dropdown.removeChild(dropdown.firstChild);
    //   }
    // dropdownOptions?.forEach((option) => {
    //     diagComm?.forEach((option) => {
    //     const optionElement = document.createElement("option");
    //     optionElement.value = option;
    //     optionElement.textContent = option;
    //     dropdown?.appendChild(optionElement);
    //   });
    // }

    if (!!dropdown) {
      // console.log("Change selectedOptionUpdate")
      dropdown?.addEventListener("change", selectedOptionUpdate);
    }

    // console.log("comment:",comment)

    const updateTextareaValue = () => {
      setComment((prevComment) => prevComment + " " + dropdownRef.current.value);
    };

    function selectedOptionUpdate() {
      setSelectedOption(dropdownRef.current.value);
      updateTextareaValue();
    }

    return () => {
      // map.removeInteraction(draw);
      if (measureTooltipRef.current) {
        map.removeOverlay(measureTooltipRef.current);
      }
      if (dropdown) {
        dropdown?.removeEventListener("change", selectedOptionUpdate);
      }
    };
  }, [map]);
  // state.AnnotationElement
  useEffect(() => {
    if (!map) return;

    const styleFunction = (feature) => {
      const color = feature.get("color");
      return new Style({
        image: new CircleStyle({
          radius: 4,
          fill: new Fill({
            color: color,
          }),
          stroke: new Stroke({
            color: color, // Stroke color for the circle's outline
            width: 2,
          }),
        }),
        stroke: new Stroke({
          color: color,
          width: 2,
        }),
      });
    };

    vector_layer.current = new VectorLayer({
      name: "my_vectorlayer",
      // source: source,
      source: new VectorSource({
        wrapX: false,
      }),
      zIndex: 100,
      style: styleFunction,
      // style: new Style({
      //   fill: new Fill({
      //     color: "rgba(255, 255, 255, 0.2)",
      //   }),
      //   stroke: new Stroke({
      //     // color: `rgb(${r},${g},${b})`,
      //     width: 2,
      //   }),
      //   image: new CircleStyle({
      //     radius: 7,
      //     fill: new Fill({
      //       color: "#ff0000",
      //     }),
      //   }),
      // }),
    });
    map.addLayer(vector_layer.current);


    ml_vector_layer.current = new VectorLayer({
      name: "ml_vectorlayer",
      source: new VectorSource({
        wrapX: false,
      }),
      zIndex: 100,
      style: styleFunction,
    });
    map.addLayer(ml_vector_layer.current);

    return () => {
      map.removeLayer(vector_layer.current);
      map.removeLayer(ml_vector_layer.current);
    };
  }, [slide_id]);

  function getLastCoordinates(coordinatesList) {
    const lastCoordinate = coordinatesList[coordinatesList.length - 1];
    if (Array.isArray(lastCoordinate[0])) {
      return lastCoordinate[lastCoordinate.length - 1];
    } else {
      return lastCoordinate;
    }
  }

  useEffect(() => {
    if (!map || !vector_layer.current) return;
    const vectorSource = vector_layer.current.getSource();
    vectorSource.clear();
    map.once("rendercomplete", () => {
      map.getOverlays().clear();

      state.annotation_list.forEach((coords) => {
        // console.log("coords:::",coords)
        if (coords && coords.rawData.raw_data.coordinates.length > 0 && coords.rawData.raw_data.type === "LineString") {
          // console.log("rendercomplete lineString")
          const lineFeature = new Feature({ geometry: new LineString(coords.rawData.raw_data.coordinates), color: coords.color });
          lineFeature.set(
            "annotation_id",
            coords.annotationId,
            "annotation_hide",
            coords.profileContent.annotation_hide,
            "annotation_comment",
            coords.profileContent.annotation_comment,
            "annotation_measurement",
            coords.profileContent.annotation_measurement
          );
          if (coords.profileContent.annotation_hide === "0") {
            vectorSource.addFeature(lineFeature);
            if (coords.profileContent.annotation_comment === "0" && coords.rawData.raw_data.comment) {
              const lastCoordinates2 = getLastCoordinates(coords.rawData.raw_data.coordinates);
              createCommentTooltip(lastCoordinates2, coords.rawData.raw_data.comment, coords.annotationId);
            }
            if (coords.profileContent.annotation_measurement === "0") {
              const lastCoordinates2 = getLastCoordinates(coords.rawData.raw_data.coordinates);
              createMeasureTooltip(lastCoordinates2, coords.rawData.raw_data.length, coords.annotationId);
            }
          }
        } else if (coords && coords.rawData.raw_data.coordinates.length > 0 && coords.rawData.raw_data.type === "Polygon") {
          // console.log("rendercomplete Polygon")

          const polygonFeature = new Feature({ geometry: new Polygon([coords.rawData.raw_data.coordinates[0]]), color: coords.color });
          const overlayCoords = polygonFeature.getGeometry().getInteriorPoint().getCoordinates();
          polygonFeature.set(
            "annotation_id",
            coords.annotationId,
            "annotation_hide",
            coords.profileContent.annotation_hide,
            "annotation_comment",
            coords.profileContent.annotation_comment,
            "annotation_measurement",
            coords.profileContent.annotation_measurement
          );
          if (coords.profileContent.annotation_hide === "0") {
            vectorSource.addFeature(polygonFeature);
            if (coords.profileContent.annotation_comment === "0" && coords.rawData.raw_data.comment) {
              createCommentTooltip(overlayCoords, coords.rawData.raw_data.comment, coords.annotationId);
            }
            if (coords.profileContent.annotation_measurement === "0") {
              createMeasureTooltip(overlayCoords, coords.rawData.raw_data.length, coords.annotationId);
            }
          }
        } else if (coords && coords.rawData.raw_data.coordinates.length > 0 && coords.rawData.raw_data.type === "Circle") {
          // console.log("rendercomplete Circle:",coords.rawData.raw_data.coordinates[0],coords.rawData.raw_data.radius)

          const circleFeature = new Feature({
            geometry: new Circle(coords.rawData.raw_data.coordinates[0], coords.rawData.raw_data.radius),
            color: coords.color,
          });
          const overlayCoords = circleFeature.getGeometry().getCenter();
          circleFeature.set(
            "annotation_id",
            coords.annotationId,
            "annotation_hide",
            coords.profileContent.annotation_hide,
            "annotation_comment",
            coords.profileContent.annotation_comment,
            "annotation_measurement",
            coords.profileContent.annotation_measurement
          );
          if (coords.profileContent.annotation_hide === "0") {
            vectorSource.addFeature(circleFeature);
            if (coords.profileContent.annotation_comment === "0" && coords.rawData.raw_data.comment) {
              createCommentTooltip(overlayCoords, coords.rawData.raw_data.comment, coords.annotationId);
            }
            if (coords.profileContent.annotation_measurement === "0") {
              createMeasureTooltip(overlayCoords, coords.rawData.raw_data.length, coords.annotationId);
            }
          }
        } else if (coords && coords.rawData.raw_data.coordinates.length > 0 && coords.rawData.raw_data.type === "Point") {
          // console.log("rendercomplete Point")
          const pointFeature = new Feature({ geometry: new Point(coords.rawData.raw_data.coordinates), color: coords.color });
          pointFeature.set(
            "annotation_id",
            coords.annotationId,
            "annotation_hide",
            coords.profileContent.annotation_hide,
            "annotation_comment",
            coords.profileContent.annotation_comment,
            "annotation_measurement",
            coords.profileContent.annotation_measurement
          );
          if (coords.profileContent.annotation_hide === "0") {
            vectorSource.addFeature(pointFeature);
            if (coords.profileContent.annotation_comment === "0" && coords.rawData.raw_data.comment) {
              const lastCoordinates2 = getLastCoordinates([coords.rawData.raw_data.coordinates]);
              createCommentTooltip(lastCoordinates2, coords.rawData.raw_data.comment, coords.annotationId);
            }
            // if (coords.profileContent.annotation_measurement === "0") {
            //   const lastCoordinates2 = getLastCoordinates([coords.rawData.raw_data.coordinates]);
            //   createMeasureTooltip(lastCoordinates2, coords.rawData.raw_data.length, coords.annotationId);
            // }
          }
        }
      });
    });
  }, [state.annotation_list, rerenderOnError]);

  useEffect(() => {
    if (!map || !vector_layer.current) return;
    if (cordinate.type === "LineString" && cordinate.coordinates.length > 0 && clicked === true) {
      const polygonGeometry = new Polygon([cordinate.coordinates]);
      map.getView().fit(polygonGeometry.getExtent(), { padding: [100, 100, 100, 100] });
      pageDispatcher.set_selected_annotation_slide({ primarykey: null, cordinate: cordinate.coordinates, clicked: false });
    } else if (cordinate.type === "Polygon" && cordinate.coordinates[0].length > 0 && clicked === true) {
      const polygonGeometry = new Polygon([cordinate.coordinates[0]]);
      map.getView().fit(polygonGeometry.getExtent(), { padding: [100, 100, 100, 100] });
      pageDispatcher.set_selected_annotation_slide({ primarykey: null, cordinate: cordinate.coordinates[0], clicked: false });
    } else if (cordinate.type === "Circle" && cordinate.coordinates[0].length > 0 && clicked === true) {
      const polygonGeometry = new Circle(cordinate.coordinates[0], circleRadius);
      map.getView().fit(polygonGeometry.getExtent(), { padding: [100, 100, 100, 100] });
      pageDispatcher.set_selected_annotation_slide({ primarykey: null, cordinate: cordinate.coordinates[0], clicked: false });
    } else if (cordinate.type === "Point" && cordinate.coordinates.length > 0 && clicked === true) {
      const polygonGeometry = new Polygon([[cordinate.coordinates, cordinate.coordinates]]);
      map.getView().fit(polygonGeometry.getExtent(), { padding: [100, 100, 100, 100] });
      pageDispatcher.set_selected_annotation_slide({ primarykey: null, cordinate: cordinate.coordinates, clicked: false });
    }
  }, [primarykey]);

  useEffect(() => {
    if (!map && !vector_layer.current) return;
    const vectorSource = vector_layer.current.getSource();
    let drawInteraction;
    let modifyInteraction;
    let startClick;
    let geometryFn = createBox();
    function handleMapClick(evt) {
      if (state.selectArea) {
        startClick = evt.coordinate;
      }
    }

    map.on("click", handleMapClick);

    function getBoxCoordinate(feature) {
      if (!feature) return;

      const customStyle = new Style({
        stroke: new Stroke({
          color: "#00FF00", // Green color
          weight: 2,
          lineDash: [5, 10], // Optional: Dashed line pattern
        }),
        fill: new Fill({
          color: "rgba(0, 255, 0, 0.3)", // Transparent green fill
        }),
      });
      feature.setStyle(customStyle);

      const boxGeometry = feature.getGeometry();
      let coordinates = boxGeometry.getCoordinates()[0];
      coordinates[1][1] = Math.abs(coordinates[1][1]);
      coordinates[3][1] = Math.abs(coordinates[3][1]);

      pageDispatcher.set_SelectedAreaCoordinate([coordinates[3], coordinates[1]]);
      pageDispatcher.set_selectArea(false);
    }

    if (state.selectArea) {
      drawInteraction = new Draw({
        source: vectorSource,
        type: "Circle",
        geometryFunction: geometryFn,
        zIndex: Infinity,
        style: new Style({
          stroke: new Stroke({
            color: "#FF0000",
            weight: 5,
            lineDash: [8, 3],
          }),
          fill: new Fill({
            color: "rgba(255, 0, 0, 0.2)",
          }),
          image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
              color: "#FF0000",
            }),
            fill: new Fill({
              color: "#FF0000",
            }),
          }),
        }),
      });

      if (!drawInteraction) {
        console.error("Error creating draw interaction");
        return;
      }

      map.addInteraction(drawInteraction);
      vectorSource.on("addfeature", (evt) => {
        getBoxCoordinate(evt.feature);
      });

    } else {
      if (drawInteraction) {
        map.removeInteraction(drawInteraction);
        drawInteraction = null;
      }
      map.un("click", handleMapClick);
    }

    return () => {
      if (drawInteraction) {
        map.removeInteraction(drawInteraction);
      }
    };
  }, [map, state.selectArea, state.EditSelectArea]);

  useEffect(() => {
    if (!map || !ml_vector_layer.current || !state?.selected_ml_job_data?.coordinate) return;

    const vectorSource = ml_vector_layer.current.getSource();

    // Handle potential undefined coordinate data
    const coordinate = state.selected_ml_job_data.coordinate;
    if (!Array.isArray(coordinate) || coordinate.length !== 2) {
      console.error("Invalid coordinate format for box creation.");
      return; // Exit if coordinate format is invalid
    }

    const [x0, y0] = coordinate[0];
    const [x1, y1] = coordinate[1];

    var feature = [
      [x0, -y0],
      [x1, -y0],
      [x1, -y1],
      [x0, -y1],
      [x0, -y0],
    ];

    const boxFeature = new Feature({
      geometry: new Polygon([feature]),
    });

    const customStyle = new Style({
      stroke: new Stroke({
        color: "#00FF00",
        weight: 2,
        lineDash: [5, 10],
      }),
      fill: new Fill({
        color: "rgba(0, 255, 0, 0.3)",
      }),
    });
    boxFeature.setStyle(customStyle);
    vectorSource.addFeature(boxFeature);
    const features = vectorSource.getFeatures();

    return () => {
      vectorSource.clear();
    };
  }, [state.selected_ml_job_data.coordinate]);

  return (
    <div
      id="popup"
      className="ol-popup"
      style={{ display: commentPopUpVisibility ? "block" : "none" }}
    >
      <div id="popup-content">
        <div className="d-flex justify-content-between align-items-center mb-1">
          <textarea
            id="tooltip-input"
            rows="2"
            cols="20"
            placeholder="add comments"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
          <Button
            variant="outline-primary"
            id="tooltip-submit"
            className="ml-2"
            size="sm"
            onClick={handleSubmitComment}
          >
            →
          </Button>
        </div>
        <select
          id="dropdown"
          ref={dropdownRef}
        >
          <option value="">Select an option</option>
          {diagComm.map((option) => (
            <option
              key={option}
              value={option}
            >
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default LayerVector;
