import React, { useContext, useState } from "react";
import { BsFileEarmarkArrowDownFill } from "react-icons/bs";
import { FaCircle, FaDrawPolygon, FaPencilAlt, FaRegCircle, FaRegSquare, FaTimes } from "react-icons/fa";
import { PageContext } from "../../CaseViewer";
import ColorButtons from "./ColorButons";

const colorNames = ["red", "green", "orange", "blue", "brown", "aqua", "black"];

const DrawFeaturesComponent = () => {
  const [selectedColor, setSelectedColor] = useState("black");
  const { state, pageDispatcher } = useContext(PageContext);
  // const { token: slide_token } = state.selected_slide;

  const annotationActive = state.AnnotationElement.selectedAnnotationElement;

  const selectedAnnotation = (annotation) => {
    if (annotation !== "cancel") {
      const obj = { type: annotation, status: true };
      pageDispatcher.set_selectedAnnotationElement(obj);
    } else {
      const obj = { type: null, status: false };
      pageDispatcher.set_selectedAnnotationElement(obj);
    }
  };

  const downloadJSON = (slideName, slideId) => {
    // Convert the data array to a JSON string
    const jsonString = JSON.stringify(state.annotation_list, null, 2);

    // Create a Blob with the JSON string
    const blob = new Blob([jsonString], { type: "application/json" });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element for downloading
    const a = document.createElement("a");
    a.href = url;
    a.download = `${slideName}-${slideId}.json` || "data.json"; // Set the default file name

    // Trigger a click event to initiate the download
    a.click();

    // Release the object URL
    URL.revokeObjectURL(url);
  };

  // const handleStartMlModel = async () => {
  //   const obj = { model_name: "yolo" };
  //   try {
  //     const res = await addMlSlide(obj, slide_token);
  //     console.log(res.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <>
      <div
        className="d-flex align-items-center "
        id="selectedElement"
      >
        <button
          type="button"
          className={`btn ${annotationActive !== "Point" ? "btn-outline-secondary" : "btn-secondary"} btn-sm m-1`}
          onClick={() => selectedAnnotation("Point")}
        >
          <FaCircle
            id="length"
            aria-hidden="true"
            title="Point"
          />
        </button>
        <button
          type="button"
          className={`btn ${annotationActive !== "LineString" ? "btn-outline-secondary" : "btn-secondary"} btn-sm m-1`}
          onClick={() => selectedAnnotation("LineString")}
        >
          <FaPencilAlt id="length" aria-hidden="true" title="Line" />
        </button>
        <button
          type="button"
          className={`btn ${annotationActive !== "Polygon" ? "btn-outline-secondary" : "btn-secondary"} btn-sm m-1`}
          onClick={() => selectedAnnotation("Polygon")}
          title="Polygon"
        >
          <FaDrawPolygon />
        </button>
        <button
          type="button"
          className={`btn ${annotationActive !== "Circle" ? "btn-outline-secondary" : "btn-secondary"} btn-sm m-1`}
          onClick={() => selectedAnnotation("Circle")}
        >
          {" "}
          <FaRegCircle id="area" aria-hidden="true" title="Circle" />
        </button>
        <button
          type="button"
          className={`btn ${annotationActive !== "Box" ? "btn-outline-secondary" : "btn-secondary"} btn-sm m-1`}
          onClick={() => selectedAnnotation("Box")}
        >
          {" "}
          <FaRegSquare id="area" aria-hidden="true" title="Box" />
        </button>
      </div>
      <>
        <button
          type="button"
          className="btn btn-outline-secondary btn-sm m-1"
          onClick={() => selectedAnnotation("cancel")}
        >
          <FaTimes aria-hidden="true" id="none" title="none" />
        </button>
        <button
          type="button"
          className="btn btn-outline-secondary btn-sm"
          onClick={() => downloadJSON(state.selected_slide.name, state.selected_slide.id)}
        >
          <BsFileEarmarkArrowDownFill />
        </button>
        {/* <button
          type="button"
          className="btn btn-outline-secondary btn-sm"
          onClick={handleStartMlModel}
        >
          ML
        </button> */}
      </>
      <div>
        {colorNames.map((color) => (
          <ColorButtons
            key={color}
            color={color}
            setSelectedColor={setSelectedColor}
            selectedColor={selectedColor}
          />
        ))}
      </div>
    </>
  );
};

export default DrawFeaturesComponent;
