import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { getSyncStatus, setSyncStatus } from "../../WS Scanning/Service";
import { NotificationContainer, NotificationManager } from "react-notifications";


const FindAndSyncData = () => {
  const [displayData, setDisplayData] = useState("");

  const handleOffClick = async () => {
    const obj = {
      action: 0,
    };
    try {
      const res = await setSyncStatus(obj);
      NotificationManager.info("Sending Request....", "Notification", 2000);
      console.log("res-off", res.data);
    } catch (error) {
      NotificationManager.error("Something went wrong ", "Notification", 2000);
      console.log(error);
    }
  };

  const handleOnClick = async () => {
    const obj = {
      action: 1,
    };
    try {
      const res = await setSyncStatus(obj);
      NotificationManager.info("Sending Request....", "Notification", 2000);
      console.log("res-on", res.data);
    } catch (error) {
      NotificationManager.error("Something went wrong ", "Notification", 2000);
      console.log(error);
    }
  };

  const handleRefreshClick = async () => {
    try {
      const res = await getSyncStatus();
      // console.log('res-refresh',res.data.action)
      setDisplayData(res.data.action);
      NotificationManager.success("Sending Request....", "Notification", 2000);

    } catch (error) {
      NotificationManager.error("Something went wrong ", "Notification", 2000);
      console.log(error);
    }
  };

  return (
    <div className="w-50 m-auto">
      <div className="d-flex justify-content-between align-items-center">
        <Button
          variant="outline-primary"
          size="sm"
          onClick={handleOffClick}
          className="mx-1"
        >
          Off
        </Button>
        <Button
          variant="outline-primary"
          size="sm"
          onClick={handleOnClick}
          className="mx-1"
        >
          On
        </Button>
        <Button
          variant="outline-primary"
          size="sm"
          onClick={handleRefreshClick}
          className="mx-1"
        >
          Refresh
        </Button>
      </div>
      <div className="mt-4">
        {displayData === "0" ? <h1>Off</h1> : ""}
        {displayData === "1" ? <h1>Start</h1> : ""}
        {displayData === "2" ? <h1>Syncing</h1> : ""}
        {displayData === "3" ? <h1>Finish</h1> : ""}
        {displayData === "-1" ? <h1>Error</h1> : ""}
      </div>
    </div>
  );
};

export default FindAndSyncData;
