import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, Form, OverlayTrigger, Popover } from "react-bootstrap";
import { FaEdit, FaRegSquare } from "react-icons/fa";
import { NotificationManager } from "react-notifications";
import { addMlSlide, getMlJobList } from "../../../Service";
import { PageContext } from "../../CaseViewer";
import MLcard from "./MLcard";

const AiRun = () => {
  const { state, pageDispatcher } = useContext(PageContext);
  const { token: slide_token, id } = state.selected_slide;
  const [modelName, setModelName] = useState("");
  const [modelOptions, setModelOptions] = useState(["Filaria", "WBC"]);
  const [showPopover, setShowPopover] = useState(false);
  const { job_id } = state.ml_job_list;

  const handleSelectArea = () => {
    if (state.selectArea) pageDispatcher.set_selectArea(false);
    else pageDispatcher.set_selectArea(true);
  };

  const handleEditSelectArea = () => {
    if (state.EditSelectArea) pageDispatcher.set_EditSelectArea(false);
    else pageDispatcher.set_EditSelectArea(true);
  };

  const handleChange = (event) => {
    setModelName(event.target.value);
  };

  const getCreatedDate = (timestamp) => {
    const date = new Date(timestamp * 1000);

    // Function to pad single-digit values with a leading zero
    const pad = (number) => number.toString().padStart(2, "0");

    // Format the date
    const formattedDate = `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`;

    // Get created time (hours, minutes, seconds)
    const createdTime = `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;

    // Return an object with both formattedDate and createdTime
    return `${formattedDate} ${createdTime}`;
  };

  const handleSubmitData = async (e) => {
    e.preventDefault();
    const obj = {
      slide_id: state.selected_slide.id,
      model_name: modelName || "Filaria",
      raw_data: {
        coordinates: state.SelectedAreaCoordinate,
      },
    };
    try {
      const res = await addMlSlide(obj, slide_token);
      const { job_id, slide_id, created } = res.data;
      const newData = {
        job_id,
        slide_id,
        created,
        model_name: modelName || "Malaria",
        job_details: {
          raw_data: {
            coordinates: state.SelectedAreaCoordinate,
          },
        },
      };
      if (res.status === 200) {
        NotificationManager.success("data is successfully created", "", 2000);
      }
      setShowPopover(false);
      pageDispatcher.set_ml_job_list({ replace: false, data: newData });
    } catch (error) {
      NotificationManager.error("Something went wrong", "", 2000);
    }
  };

  const fetchMlListData = async (id, token) => {
    const obj = {
      slide_id: id,
    };
    try {
      if (token) {
        const res = await getMlJobList(obj, token);
        const sortedJobList = res.data.ml_job_list.slice().sort((a, b) => b.job_id - a.job_id);
        pageDispatcher.set_ml_job_list({ replace: true, data: sortedJobList });
      } else {
        console.log("Token not available for API call");
      }
    } catch (error) {
      NotificationManager.error("Something went wrong", "", 2000);
    }
  };

  const handleCardClick = (data) => {
    if (data.job_id === state.selected_ml_job_data.job_id) {
      pageDispatcher.set_selected_ml_job_data({});
    } else {
      pageDispatcher.set_selected_ml_job_data(data);
    }
  };

  useEffect(() => {
    if (id && slide_token) fetchMlListData(id, slide_token);
  }, [id, slide_token]);

  return (
    <div>
      <OverlayTrigger
        trigger="click"
        key="bottom"
        placement="bottom"
        show={showPopover}
        onHide={() => setShowPopover(false)}
        overlay={
          <Popover id={`popover-positioned-${"bottom"}`}>
            <Popover.Header as="h3">Jobid Details</Popover.Header>
            <Popover.Body>
              <Form onSubmit={handleSubmitData}>
                <Form.Group controlId="SelectModel">
                  <Form.Label>Select Model</Form.Label>
                  <Form.Control
                    as="select"
                    value={modelName}
                    onChange={handleChange}
                  >
                    {modelOptions.map((option) => (
                      <option
                        key={option}
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="SelectArea">
                  <Form.Label>Select Area:</Form.Label>
                  <Button
                    variant="outline-primary"
                    onClick={handleSelectArea}
                  >
                    <FaRegSquare
                      id="area"
                      aria-hidden="true"
                      title="box"
                    />
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={handleEditSelectArea}
                  >
                    <FaEdit />
                  </Button>
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  onClick={() => setShowPopover(false)}
                >
                  Submit
                </Button>
              </Form>
            </Popover.Body>
          </Popover>
        }
      >
        <Button
          variant="outline-primary"
          onClick={() => setShowPopover(!showPopover)}
        >
          +
        </Button>
      </OverlayTrigger>
      {state?.ml_job_list?.map((ele, i) => (
        <Card
          key={ele.job_id}
          border={ele.job_id === state.selected_ml_job_data.job_id ? "dark" : "none"}
          bg={ele.job_id === state.selected_ml_job_data.job_id ? "light" : "none"}
          className="m-2"
          onClick={() => handleCardClick(ele)}
          disabled
        >
          <Card.Body>
            <span className="mr-2">
              <b>Ml Job Id: </b> <i>{ele.job_id}</i>
            </span>
            <span
              style={{ fontSize: "14px" }}
              className="mr-2"
            >
              <i>{getCreatedDate(ele?.created)}</i>
            </span>
            <br />
            <span>
              <b>Ml Model: </b>
              <i>{ele.model_name}</i>
            </span>
            {ele.job_id === state.ml_status["job_id"] ? <MLcard /> : ""}
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default AiRun;
