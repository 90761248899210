import React, { useState, useEffect } from "react";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { environment } from "../../../environment";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { getToken, isQSLive } from "../../Utils/Common";
import axios from "axios";
import { Form, Button } from "react-bootstrap";
import {
  add_admin_device_config,
  add_device_config,
  get_admin_device_config,
  get_last_modified,
  reset_qs_secret_key,
  tunnel_url_csv_download,
} from "../Service";
import { FaFile, FaFileAlt, FaKey } from "react-icons/fa";

const nodeURL = environment.MICALYS_POSTGRES_API;

export const Quickshare = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [qs_type, setQs_Type] = useState("local");
  const [domainName, setDomainName] = useState("");
  const [dayOrHrs, setDayOrHrs] = useState("hr");
  // let nodeURL = 'http://localhost:4000/';
  const [extendExpiry, setExtendExpiry] = useState("2h");
  const [showRestartMessage, setShowRestartMessage] = useState(false);
  const [lastModified, setLastModified] = useState(false);

  useEffect(() => {
    axios.get(nodeURL + "qsstatus", { headers: { Authorization: `Bearer ${getToken()}` } }).then((res) => {
      if (res && res.data.status === 1) {
        localStorage.setItem("isQSLive", JSON.stringify(true)); //setIsSharing to true
        setIsChecked(isQSLive()); //for state

        if (!res.data.type) {
          console.log("qs_type status Empty", res.data.type);

          setQs_Type("tunnel");
        } else {
          console.log("else res.data.type");

          setQs_Type(res.data.type);
        }
      } else if (res && res.data.status === 0) {
        // localStorage.setItem("isQSLive", JSON.stringify(true)); //setIsSharing to true
        // setIsChecked(isQSLive()); //for state

        if (!res.data.type) {
          console.log("qs_type status Empty", res.data.type);

          setQs_Type("tunnel");
        } else {
          console.log("else res.data.type");

          setQs_Type(res.data.type);
        }
      } else {
        localStorage.removeItem("isQSLive");
      }
    });
  }, []);

  useEffect(() => {
    const fetchLastMOdified = async () => {
      try {
        const res = await get_last_modified();
        console.log("lastModified USEEFFECT:", res.data.last_modified);
        setLastModified(res.data.last_modified);
      } catch (error) {
        console.log(error);
      }
    };
    fetchLastMOdified();
  }, []);

  console.log("lastModified:", lastModified);

  const convertDaysToHours = (days) => {
    return days * 24;
  };

  const convertHoursToDays = (hours) => {
    return hours / 24;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let qs_expiry_data;

    if (dayOrHrs === "days") {
      qs_expiry_data = convertDaysToHours(parseInt(extendExpiry.replace("h", "")));
    } else {
      qs_expiry_data = parseInt(extendExpiry.replace("h", ""));
    }

    const obj = {
      qs_extended_expiry_in_hr: qs_expiry_data,
      domain_name: domainName,
    };

    try {
      const res = await add_admin_device_config(obj);
      if (res.status === 200) NotificationManager.success("data added successfully", "", 2000);
    } catch (error) {
      console.log(error);
    }
  };
  const handleExpirySubmit = async () => {
    let qs_expiry_data;

    if (dayOrHrs === "days") {
      qs_expiry_data = convertDaysToHours(parseInt(extendExpiry.replace("h", "")));
    } else {
      qs_expiry_data = parseInt(extendExpiry.replace("h", ""));
    }

    const obj = {
      qs_extended_expiry_in_hr: qs_expiry_data,
    };

    try {
      const res = await add_admin_device_config(obj);
      if (res.status === 200) NotificationManager.success("data added successfully", "", 2000);
    } catch (error) {
      console.log(error);
    }
  };

  const fetch_admin_config = async () => {
    try {
      const res = await get_admin_device_config();
      console.log("get_admin_device_config", res.data);
      const { qs_extended_expiry_in_hr, domain_name } = res.data;
      if (domain_name) setDomainName(domain_name);
      if (qs_extended_expiry_in_hr) {
        console.log("qs_extended_expiry_in_hr:", qs_extended_expiry_in_hr);
        setExtendExpiry(qs_extended_expiry_in_hr + "h");
        // const days = convertHoursToDays(qs_extended_expiry_in_hr)
        setDayOrHrs("hr");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetch_admin_config();
  }, []);

  const resetSecretJwt = async () => {
    try {
      const response = await reset_qs_secret_key();

      if (response.status == 200) {
        NotificationManager.success("Data Reset successfull", "", 2000);
        console.log("Reset successful:", response.status);
      } else {
        console.error("API call failed:", response.status);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  const generateList = async () => {
    let obj = { slide_list_jwt_status: "1" };
    try {
      const response = await add_admin_device_config(obj);

      if (response.status == 200) {
        NotificationManager.success("Request sent successfully", "", 2000);
        console.log("Request sent successfully:", response.status);
      } else {
        console.error("API call failed:", response.status);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleButtonClick = () => {
    if (window.confirm("Are you sure you want to reset the authentication key?")) {
      resetSecretJwt();
      setShowRestartMessage(true); // Show the message after resetting the key
    }
  };

  const generateListButtonClick = () => {
    generateList();
  };

  const getLastModified = async () => {
    try {
      const response = await get_last_modified();

      if (response.status == 200) {
        NotificationManager.success("Request sent successfully", "", 2000);
        console.log("Request sent successfully:", response.status);
      } else {
        console.error("API call failed:", response.status);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const getLastModifiedOnClick = () => {
    getLastModified();
  };

  const csvDownload = async () => {
    try {
      const response = await tunnel_url_csv_download();

      if (response.status === 200) {
        const contentDisposition = response.headers["content-disposition"];
        const filename = contentDisposition ? contentDisposition.split("filename=")[1].trim() : "qs_slide_list_url.csv";

        // Manually create a Blob from the response data (arraybuffer)
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob); // Create object URL
        const a = document.createElement("a"); // Create anchor element
        a.href = url;
        a.download = filename; // Use filename from response headers
        document.body.appendChild(a);
        a.click(); // Trigger download
        a.remove(); // Remove element after download
        window.URL.revokeObjectURL(url); // Cleanup URL

        NotificationManager.success("CSV file downloaded successfully", "", 2000);
      } else {
        console.error("API call failed:", response.status);
        NotificationManager.error("Failed to download CSV file", "", 2000);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      NotificationManager.error("Error during file download", "", 2000);
    }
  };
  const onClickTunnelUrlCSVDownload = () => {
    csvDownload();
  };

  return (
    <div style={{ float: "center" }}>
      <NotificationContainer />
      <>
        {qs_type === "local" ? (
          <>
            <div
              className="form-group col-md-2"
              style={{ marginLeft: "42%" }}
            >
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="domain-name">
                  <Form.Label className="font-weight-bold">Add Domain Name:</Form.Label>
                  <Form.Control
                    placeholder="domain name"
                    type="text"
                    className="form-control"
                    style={{ minWidth: "200px" }}
                    value={domainName}
                    onChange={(event) => {
                      setDomainName(event.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="expiry">
                  <Form.Label className="font-weight-bold">Extend Expiry:</Form.Label>
                  <div className="d-flex justify-content-around align-items-center">
                    <Form.Control
                      placeholder="days/hrs"
                      type="number"
                      className="form-control"
                      style={{ minWidth: "75px" }}
                      min="2"
                      value={extendExpiry.replace("h", "")}
                      onChange={(event) => {
                        setExtendExpiry(event.target.value + "h"); //For smaintaining state,onchange also save in localstorage and get from localstorage only in value={}
                      }}
                    />
                    <Form.Control
                      as="select"
                      value={dayOrHrs}
                      onChange={(e) => setDayOrHrs(e.target.value)}
                      style={{ minWidth: "75px" }}
                    >
                      <option value="days">days</option>
                      <option value="hr">hr</option>
                    </Form.Control>
                  </div>
                </Form.Group>
                <Button type="submit">Submit</Button>
              </Form>
            </div>
          </>
        ) : (
          <>
            <BootstrapSwitchButton
              checked={isChecked}
              onstyle="success"
              onChange={(checked) => {
                if (checked) {
                  setIsChecked(checked);

                  axios
                    .get(nodeURL + "qsstart", { headers: { Authorization: `Bearer ${getToken()}` } })
                    .then((res) => {
                      if (res && res.data.message) {
                        if (!isQSLive()) {
                          localStorage.setItem("isQSLive", JSON.stringify(true));
                          setIsChecked(isQSLive());
                        }
                        NotificationManager.success(res.data.message, "", 2000);
                      }
                    })
                    .catch((err) => NotificationManager.error("something went wrong!", "", 2000));
                } else {
                  axios
                    .get(nodeURL + "qsstop", { headers: { Authorization: `Bearer ${getToken()}` } })
                    .then((res) => {
                      if (res && res.data.message) {
                        // delQSExpiry(); // do not del this..
                        localStorage.removeItem("isQSLive");
                        setIsChecked(false);
                        NotificationManager.info(res.data.message, "", 2000);
                      }
                    })
                    .catch((err) => NotificationManager.error("something went wrong!", "", 2000));
                }
              }}
            />
            <br />
            <br />
            {isChecked && (
              <div
                className="form-group col-md-2"
                style={{ marginLeft: "42%" }}
              >
                Extend Expiry:{" "}
                <input
                  type="number"
                  className="form-control"
                  min="2"
                  value={extendExpiry.replace("h", "")}
                  onChange={(event) => {
                    setExtendExpiry(event.target.value + "h"); //For smaintaining state,onchange also save in localstorage and get from localstorage only in value={}
                  }}
                />
                <Button
                  onClick={handleExpirySubmit}
                  className="mt-2"
                >
                  Submit
                </Button>
              </div>
            )}
          </>
        )}
        <div className="d-flex flex-column align-items-center">
          <Button
            className="btn btn-secondary mt-5"
            onClick={handleButtonClick}
            style={{ maxWidth: "350px" }}
          >
            <FaKey
              aria-hidden="true"
              style={{ marginRight: "8px" }}
            />
            Reset Authentication Key
          </Button>

          <Button
            className="btn btn-secondary mt-5"
            onClick={generateListButtonClick}
            style={{ maxWidth: "150px" }}
          >
            <FaFileAlt
              aria-hidden="true"
              style={{ marginRight: "8px" }}
            />
            Generate List
          </Button>
          <Button
            className="btn btn-secondary mt-5"
            onClick={onClickTunnelUrlCSVDownload}
            style={{ maxWidth: "150px" }}
          >
            <FaFile
              aria-hidden="true"
              style={{ marginRight: "8px" }}
            />
            Download File
          </Button>
          {lastModified && <p>Last Modified on: {lastModified}</p>}
        </div>
        {showRestartMessage && (
          <div className="alert alert-info mt-3">
            Authentication key reset successful. <strong>Restart your system</strong>
          </div>
        )}
      </>
    </div>
  );
};
