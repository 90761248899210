import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { adminChangePassword } from "../Service";
import { NotificationManager } from "react-notifications";

const ChangePasswordForm = ({ data, hide }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isMatch, setIsMatch] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleNewPasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);
    validateForm(value, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    validateForm(newPassword, value);
  };

  const validateForm = (newPass, confPass) => {
    const isMatch = newPass === confPass;
    setIsMatch(isMatch);
    setIsDisabled(!(newPass && confPass && isMatch));
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    const res = await adminChangePassword({ username: data, new_password: confirmPassword });
    if(res.status === 200) {
      NotificationManager.success("password changed successfully Successfully", "", 500);
      hide()
    } else {
      console.log("error occured");
    }
  };

  return (
    <div
      className="Login d-flex flex-column align-items-center justify-content-center"
      style={{ padding: "20px" }}
    >
      <h4>change password of {data} user</h4>
      <Form
        noValidate
        onSubmit={handleSubmit}
      >
        <Form.Group
          size="md"
          controlId="newPassword"
        >
          <Form.Label>Enter New Password</Form.Label>
          <Form.Control
            type="password"
            name="new_password"
            value={newPassword}
            required
            onChange={handleNewPasswordChange}
            isInvalid={newPassword.length === 0}
          />
          <Form.Control.Feedback type="invalid">Please provide a new password.</Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          size="md"
          controlId="confirmPassword"
        >
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            name="confirm_password"
            value={confirmPassword}
            required
            onChange={handleConfirmPasswordChange}
            isInvalid={confirmPassword.length === 0}
          />
          <Form.Control.Feedback type="invalid">Please provide a confirm password.</Form.Control.Feedback>
          {!isMatch && <span className="text-danger">The passwords do not match</span>}
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          disabled={isDisabled}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default ChangePasswordForm;
