import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { updateCaseData, updateSlideActiveDays } from "../../../Service";
import { PageContext } from "../../CaseViewer";
import { CancelButton, SaveButton } from "../updateSlide/Buttons";

const CaseExpiry = () => {
  const { state, pageDispatcher } = useContext(PageContext);
  const { case_owner, slide_active_days, case_token, case_id } = state.case_info;
  // console.log("slide_active_days:", slide_active_days);

  const [isEditing, setIsEditing] = useState(false);
  const [activeDays, setActiveDays] = useState(slide_active_days);
  const [originalActiveDays, setOriginalActiveDays] = useState(slide_active_days);
  const [isHovered, setIsHovered] = useState(false);
  const [isChecked, setIsChecked] = useState(slide_active_days == -1);
  // console.log("slide_active_days:", slide_active_days == -1, activeDays == -1, isChecked);

  const handleCheckboxChange = async () => {
    console.log("Cancel Checkbox:", isChecked);
    if (isChecked == false) {
      console.log("Cancel Checkbox if:", isChecked);
      const obj = {
        case_id: case_id,
        slide_active_days: -1,
      };
      try {
        await updateSlideActiveDays(obj);
        setActiveDays("N/A");
        setIsChecked(!isChecked);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Cancel Checkbox else:", isChecked);
      setIsEditing(true);
    }
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    setActiveDays(slide_active_days);
  }, [slide_active_days]);

  useEffect(() => {
    if (slide_active_days == -1) {
      setIsChecked(true);
    }
  }, [slide_active_days]);

  const handleActiveDaysChange = (event) => {
    setActiveDays(event.target.value);
  };

  const handleEditButtonClick = () => {
    setOriginalActiveDays(activeDays);
    setIsEditing(true);
  };

  const handleSaveButtonClick = async () => {
    if (activeDays > 0) {
      const obj = {
        case_id: case_id,
        slide_active_days: activeDays,
      };
      try {
        await updateSlideActiveDays(obj);
        setIsEditing(false);
        setIsChecked(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCancelButtonClick = () => {
    if (isChecked) {
      console.log("Cancel if:", activeDays, isChecked);
      setActiveDays(-1);
      setIsEditing(false);
      setIsChecked(true);
    } else {
      console.log("Cancel else:", activeDays, isChecked, originalActiveDays);
      setActiveDays(originalActiveDays);
      setIsChecked(false);
      setIsEditing(false);
    }
  };

  return (
    <div className="mb-1">
      {isEditing ? (
        <div>
          <input
            type="number"
            value={activeDays === -1 ? "N/A" : activeDays}
            onChange={handleActiveDaysChange}
          />
          <Button
            variant="outline-primary"
            size="sm"
            onClick={handleSaveButtonClick}
            className="mx-1"
          >
            <SaveButton />
          </Button>
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={handleCancelButtonClick}
            className="mx-1"
          >
            <CancelButton />
          </Button>
        </div>
      ) : (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <span>Expires In: {activeDays === (-1 || "") ? "N/A" : activeDays}</span>
            {case_owner && !isChecked ? (
              <sup>
                <MdOutlineModeEditOutline
                  size={15}
                  className={isHovered ? "edit-icon hovered" : "edit-icon"}
                  onMouseEnter={handleHover}
                  onMouseLeave={handleMouseLeave}
                  onClick={handleEditButtonClick}
                />
              </sup>
            ) : (
              ""
            )}
          </div>
          {case_owner ? (
            <label style={{ marginTop: "3%" }}>
              {/* {console.log("UI Render:", isChecked)} */}
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              Never
            </label>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default CaseExpiry;
