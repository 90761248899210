import axios from "axios";
import "ol/ol.css";
import React, { createContext, useContext, useEffect, useReducer } from "react";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useParams } from "react-router-dom";
import { environment } from "../../../environment";
import { getAnnotationListData, getCaseData, getGrossImageList, getSlideData } from "../Service";
import { AnnotationBoxLayer, Layers, TileDependentLayer } from "./Map/Layers";
import LayerVector from "./Map/Layers/LayerVector";
import { MapContainer } from "./Map/Map";
import { Controls, RightSideFeature, ZoomLevels } from "./Map/components";
import Cookies from "js-cookie";
import OverviewMapAreaTracker from "./Map/components/OverviewMapAreaTracker";
import ZstackZoom from "./Map/components/ZstackZoom";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { getToken } from "../../Utils/Common";
import SelectedSlideName from "./Map/components/SelectedSlideName";
// import { WebSocketContext } from "../../../App";

const zoomifyURL = environment.MICALYS_POSTGRES_API + "slidedata/";

const CaseViewer = () => {
  const initialState = {
    xInput: "100",
    yInput: "100",
    disableTools: true,
    isOverviewMapVisible: true,
    scaleValue: "",
    case_id: "",
    modalVisible: false,
    case_info: {
      case_findings: "",
      case_id: "",
      case_info: "",
      case_name: "",
      case_owner: false,
      case_token: "",
      created: "",
      slide_active_days: "",
      delete_status: 0,
      patient_age: 0,
      patient_gender: "",
      patient_id: "",
      patient_name: "",
      report_status: 0,
      macroScopicImgName: [],
      microScopicImgName: [],
    },
    macroScopicImg: [],
    slide_list: [],
    ml_job_list: [],
    ml_status: { job_id: "", task_type: "", percent: "" },
    wsImage: "",
    selected_slide: {
      id: "",
      token: "",
      scale_data: "",
      name: "",
      numOfLayer: 1,
      lens: 40,
    },
    profile: {},
    annotation_list: [],
    comment_list: [],
    selected_annotation_slide: {
      primarykey: "",
      cordinate: [],
      clicked: false,
      radius: null,
    },
    selected_ml_job_data: {
      job_id: "",
      slide_id: "",
      created: "",
      model_name: "",
      coordinate: [],
    },
    comment_annotation_slide: {
      primarykey: "",
      type: "",
      label: "",
      username: "",
      time: "",
      data: "",
    },
    selectedOption: "",
    multi_question_answer: {
      next: "",
      previous: "",
      option: [],
      current_Question: "",
      current_Question_key: "",
      comment: [],
    },
    location: {
      center_x: "",
      center_y: "",
    },
    zoom_level_z: "",
    selected_img_properties: {
      tileSize: "",
      imgHeight: "",
      imgWidth: "",
    },
    drawn_annotation: {
      type_of: "",
      color: "",
      label: "",
      uid: "",
      raw_data: {
        coordinates: [],
        comment: "",
        type: "",
        length: "",
      },
    },
    selectedBoxVisibility: false,
    selectedGridBoxVisibility: false,
    selectedAnnotationBoxVisibility: false,
    selectedMagnifyVisibility: false,
    AnnotationElement: {
      selectedAnnotationElement: null,
      status: false,
    },
    drawCircle: {
      previousZoomValue: null,
      zoomValue: null,
      // status: false,
      diameterValue: null,
    },
    drawCircleStatus: false,
    colorBox: {
      start: false,
      // hide: true,
      hide: false,
      delete: false,
      togglePopup: true,
    },
    coordinatesLen: {
      length4X: 0,
      length10X: 0,
      length20X: 0,
      length40X: 0,
      length60X: 0,
      length80X: 0,
    },
    annotationColor: "black",
    username_cookie: "",
    lensValueSelected: "",
    zoomOffSetValue: 0,
    zStackValue: 0,
    isSingleMode: true,
    toggleNewCase: true,
    zoomLensCapture: [
      { value: "-1", label: "--X" },
      { value: "4X", label: "4X" },
      { value: "10X", label: "10X" },
      { value: "20X", label: "20X" },
      { value: "40X", label: "40X" },
      { value: "60X", label: "60X" },
      { value: "100X", label: "100X" },
      { value: "240X", label: "40X(NC)" },
    ],
    selectArea: false,
    EditSelectArea: false,
    SelectedAreaCoordinate: [],
  };

  const [state, dispatch] = useReducer(pageReducer, initialState);

  const pageDispatcher = {
    // set_expandWidth: (value) => dispatch({ type: "set_expandWidth", payload: value }),
    set_compressWidth: (value) => dispatch({ type: "set_compressWidth", payload: value }),
    set_disableTools: (value) => dispatch({ type: "set_disableTools", payload: value }),
    set_isOverviewMapVisible: (value) => dispatch({ type: "set_isOverviewMapVisible", payload: value }),
    set_selected_slide: (value) => dispatch({ type: "set_selected_slide", payload: value }),
    set_selected_img_properties: (value) => dispatch({ type: "set_selected_img_properties", payload: value }),
    set_selectedOption: (value) => dispatch({ type: "set_selectedOption", payload: value }),
    set_multi_question_answer: (value) => dispatch({ type: "set_multi_question_answer", payload: value }),
    set_selected_annotation_slide: (value) => dispatch({ type: "set_selected_annotation_slide", payload: value }),
    set_comment_annotation_slide: (value) => dispatch({ type: "set_comment_annotation_slide", payload: value }),
    set_modalVisible: (value) => dispatch({ type: "set_modalVisible", payload: value }),
    set_scale_value: (value) => dispatch({ type: "set_scale_value", payload: value }),
    set_xInput: (value) => dispatch({ type: "set_xInput", payload: value }),
    set_yInput: (value) => dispatch({ type: "set_yInput", payload: value }),
    set_macroScopicImg: (value) => dispatch({ type: "set_macroScopicImg", payload: value }),
    set_case_info: (value) => dispatch({ type: "set_case_info", payload: value }),
    set_location_x_y: (value) => dispatch({ type: "set_location_x_y", payload: value }),
    set_zoom_z: (value) => dispatch({ type: "set_zoom_z", payload: value }),
    set_drawn_annotation: (value) => dispatch({ type: "set_drawn_annotation", payload: value }),
    set_annotation_list: (value) => dispatch({ type: "set_annotation_list", payload: value }),
    set_comment_list: (value) => dispatch({ type: "set_comment_list", payload: value }),
    set_annotation_profile: (value) => dispatch({ type: "set_annotation_profile", payload: value }),
    set_selectedBoxVisibility: (value) => dispatch({ type: "set_selectedBoxVisibility", payload: value }),
    set_selectedGridBoxVisibility: (value) => dispatch({ type: "set_selectedGridBoxVisibility", payload: value }),
    set_selectedAnnotationBoxVisibility: (value) => dispatch({ type: "set_selectedAnnotationBoxVisibility", payload: value }),
    set_selectedAnnotationElement: (value) => dispatch({ type: "set_selectedAnnotationElement", payload: value }),
    set_selectedMagnifyVisibility: (value) => dispatch({ type: "set_selectedMagnifyVisibility", payload: value }),
    set_Draw_Circle: (value) => dispatch({ type: "set_Draw_Circle", payload: value }),
    set_colorBox: (value) => dispatch({ type: "set_colorBox", payload: value }),
    set_coordinatesLen: (value) => dispatch({ type: "set_coordinatesLen", payload: value }),
    set_Annotation_color: (value) => dispatch({ type: "set_Annotation_color", payload: value }),
    set_UserName_Cookie: (value) => dispatch({ type: "set_UserName_Cookie", payload: value }),
    set_Lens_value: (value) => dispatch({ type: "set_Lens_value", payload: value }),
    set_zoomOffSetValue: (value) => dispatch({ type: "set_zoomOffSetValue", payload: value }),
    set_zStackValue: (value) => dispatch({ type: "set_zStackValue", payload: value }),
    set_Layer_Mode: (value) => dispatch({ type: "set_Layer_Mode", payload: value }),
    set_fresh_case_list: (value) => dispatch({ type: "set_fresh_case_list", payload: value }),
    set_drawCircleStatus: (value) => dispatch({ type: "set_drawCircleStatus", payload: value }),
    set_selectArea: (value) => dispatch({ type: "set_selectArea", payload: value }),
    set_EditSelectArea: (value) => dispatch({ type: "set_EditSelectArea", payload: value }),
    set_SelectedAreaCoordinate: (value) => dispatch({ type: "set_SelectedAreaCoordinate", payload: value }),
    set_ml_job_list: (value) => dispatch({ type: "set_ml_job_list", payload: value }),
    set_selected_ml_job_data: (value) => dispatch({ type: "set_selected_ml_job_data", payload: value }),
  };

  const { id: case_id } = useParams();
  const { id: slide_id, token } = state.selected_slide;
  const { tileSize } = state.selected_img_properties;

  const socketUrl = "ws://127.0.0.1/apiv2/websocket";
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    retryOnError: true,
    shouldReconnect: () => true,
    reconnectAttempts: 5,
    reconnectInterval: 3000,
  });

  useEffect(() => {
    if (!state.case_id) return;

    let intervalId;

    const sendMessages = () => {
      if (readyState === ReadyState.OPEN) {
        if (document.visibilityState === "visible") {
          const token_data = getToken();
          sendMessage(JSON.stringify({ type: ["ml_status"], case_id: state.case_id, token: token_data }));
        }
      }
    };
    // Immediately send a message when the component mounts
    sendMessages();
    // Set up interval to send messages every one second
    intervalId = setInterval(sendMessages, 3000);
    // Clean up the interval when the component unmounts or when case_id changes
    return () => clearInterval(intervalId);
  }, [readyState, sendMessage, state.case_id]);

  useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data);
      if (data.ml_status !== undefined) {
        dispatch({
          type: "update_ml_percent",
          payload: { job_id: data.ml_status["job_id"], task_type: data.ml_status["task_type"], percent: data.ml_status["percent"] },
        });
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    const storedUsername = Cookies.get("username");
    if (storedUsername) {
      pageDispatcher.set_UserName_Cookie(storedUsername);
    }
  }, []);

  const fetchCaseData = async () => {
    let obj = {
      case_id: case_id,
    };
    try {
      const result = await getCaseData(obj);
      var case_info = result.data.case_info;
      if (case_info.case_findings == null) {
        case_info.case_findings = "--";
      }
      if (case_info.case_info == null) {
        case_info.case_info = "--";
      }
      dispatch({ type: "set_case_info", payload: case_info });
      dispatch({ type: "set_slide_list", payload: result.data.slide_list });
      dispatch({ type: "set_case_id", payload: case_id });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCaseData();
  }, [state.toggleNewCase]);

  useEffect(() => {
    let interval;
    if (state.slide_list) {
      const slideIds = state.slide_list
        .map((slide) => {
          if (slide.stitch_status == 1 && slide.import_slide === 7 && slide.delete_slide === 0) {
            return slide.slide_id;
          }
          return null;
        })
        .filter((slideId) => slideId !== null);

      if (slideIds.length > 0) {
        const getSlideInfo = async (slideIds) => {
          const response = await getSlideData({ slide_id: slideIds }, state.case_info.case_token);
          const data = response.data.slide_info;
          const slideIdsData = data.map((entry) => entry.slide_id);
          const slideIdObject = {};
          for (const entry of data) {
            slideIdObject[entry.slide_id] = entry;
          }

          const updatedSlideData = state.slide_list.map((slide) => {
            for (let i = 0; i < slideIdsData.length; i++) {
              if (slide.slide_id === slideIdsData[i]) {
                return slideIdObject[slideIdsData[i]];
              }
            }
            return slide;
          });

          dispatch({ type: "set_slide_list", payload: updatedSlideData });
        };

        interval = setInterval(() => {
          getSlideInfo(slideIds);
        }, 5000);
      }
    }
    return () => clearInterval(interval);
  }, [state.slide_list]);

  const getImageProperties = async (slide_id, slide_token) => {
    try {
      const res = await getSlideViewerData(slide_id, slide_token);
      if (res?.data) {
        if (localStorage.getItem("x") == null && localStorage.getItem("y") == null) {
          localStorage.setItem("x", 100);
          localStorage.setItem("y", 100);
        }
        var parser = new DOMParser();
        var xmlDoc = parser.parseFromString(res.data, "text/xml");
        var elements = xmlDoc.getElementsByTagName("IMAGE_PROPERTIES");
        var tileSize = Number(elements[0].getAttribute("TILESIZE"));
        if (elements[0].getAttribute("WIDTH") != null && elements[0].getAttribute("HEIGHT") != null) {
          var imgWidth = Number(elements[0].getAttribute("WIDTH"));
          var imgHeight = Number(elements[0].getAttribute("HEIGHT"));
          pageDispatcher.set_selected_img_properties({ imgHeight, imgWidth, tileSize });
        }
      }
    } catch (error) {
      NotificationManager.error("An error occurred", "Error", 3000);
      console.log(error);
    }
  };

  useEffect(() => {
    if (state.selected_slide.id) {
      getImageProperties(slide_id, token);
    }
  }, [slide_id]);

  const getAnnotationList = async (slide_token) => {
    try {
      const response = await getAnnotationListData(slide_token);
      const annotations = response.data.annotation_list;
      // Use map() to transform the annotations
      const transformedAnnotations = annotations.map((annotation) => {
        const annotationId = annotation.annotation_id;
        const slideId = annotation.slide_id;
        const color = annotation.color;
        const label = annotation.label;
        const created = annotation.created;
        const username = annotation.username;
        const rawData = JSON.parse(annotation.raw_data); // Parse the raw_data property
        const profileContent = JSON.parse(annotation.profile_content); // Parse the profile_content property
        // Create an object with the desired properties
        return {
          annotationId,
          slideId,
          color,
          label,
          created,
          username,
          rawData,
          profileContent,
        };
      });
      transformedAnnotations.sort((a, b) => b.created - a.created);
      // console.log('transformedAnnotations',transformedAnnotations)

      pageDispatcher.set_annotation_list(transformedAnnotations);
      // console.log("console getAnnotationList pageDispatcher END::");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (state.selected_slide.id) {
      getAnnotationList(token);
    }
  }, [slide_id]);

  return (
    <div style={{ position: "relative" }}>
      <PageContext.Provider value={{ state, pageDispatcher }}>
        <NotificationContainer />
        <>
          {state?.slide_list[0] && (
            <>
              {slide_id && (
                <MapContainer>
                  {tileSize && (
                    <Layers>
                      <TileDependentLayer />
                      <LayerVector />
                      <AnnotationBoxLayer />
                    </Layers>
                  )}
                  <SelectedSlideName />
                  <OverviewMapAreaTracker />
                  <RightSideFeature />
                  <ZoomLevels />
                  {state.selected_slide.numOfLayer > 1 && !state.isSingleMode && <ZstackZoom />}
                </MapContainer>
              )}
            </>
          )}
          <Controls />
        </>
      </PageContext.Provider>
    </div>
  );
};

export default CaseViewer;

export const getScaleData = (slide_id, slide_token) => {
  return axios.get(zoomifyURL + slide_id + "/scale.json", {
    headers: { Authorization: `Bearer ${slide_token}` },
  });
};

export const getSlideViewerData = (slide_id, slide_token) => {
  return axios.get(zoomifyURL + slide_id + "/ImageProperties.xml", {
    headers: { Authorization: `Bearer ${slide_token}` },
  });
};

export const pageReducer = (state, action) => {
  switch (action.type) {
    case "set_xInput":
      return {
        ...state,
        xInput: action.payload,
      };
    case "set_yInput":
      return {
        ...state,
        yInput: action.payload,
      };
    case "set_case_id":
      return {
        ...state,
        case_id: action.payload,
      };
    case "set_compressWidth":
      return {
        ...state,
        compressWidth: action.payload,
      };
    case "set_selectedOption":
      return {
        ...state,
        selectedOption: action.payload,
      };
    case "set_zoomOffSetValue":
      return {
        ...state,
        zoomOffSetValue: action.payload,
      };
    case "set_zStackValue":
      return {
        ...state,
        zStackValue: action.payload,
      };
    case "set_Layer_Mode":
      return {
        ...state,
        isSingleMode: action.payload,
      };
    case "set_isOverviewMapVisible":
      return {
        ...state,
        isOverviewMapVisible: action.payload,
      };
    case "set_case_info":
      state = {
        ...state,
        case_info: {
          ...state.case_info,
          case_findings: action.payload.case_findings,
          case_id: action.payload.case_id,
          case_info: action.payload.case_info,
          case_name: action.payload.case_name,
          case_owner: action.payload.case_owner,
          case_token: action.payload.case_token,
          created: action.payload.created,
          slide_active_days: action.payload.slide_active_days,
          delete_status: action.payload.delete_status,
          patient_age: action.payload.patient_age,
          patient_gender: action.payload.patient_gender,
          patient_id: action.payload.patient_id,
          patient_name: action.payload.patient_name,
          report_status: action.payload.report_status,
          macroScopicImgName: action.payload.macroScopicImgName,
          microScopicImgName: action.payload.microScopicImgName,
        },
      };
      return state;

    case "set_annotation_list":
      return {
        ...state,
        annotation_list: action.payload,
      };
    case "set_comment_list":
      return {
        ...state,
        comment_list: action.payload,
      };

    case "set_selected_annotation_slide":
      state = {
        ...state,
        selected_annotation_slide: {
          // ...state.selected_annotation_slide,
          primarykey: action.payload.primarykey,
          cordinate: action.payload.cordinate,
          clicked: action.payload.clicked,
          radius: action.payload.radius,
        },
      };
      return state;

    case "set_comment_annotation_slide":
      state = {
        ...state,
        comment_annotation_slide: {
          ...state.comment_annotation_slide,
          primarykey: action.payload.primarykey,
          type: action.payload.type,
          label: action.payload.label,
          username: action.payload.username,
          time: action.payload.time,
          data: action.payload.data,
        },
      };
      return state;

    case "set_drawn_annotation":
      state = {
        ...state,
        drawn_annotation: {
          ...state.drawn_annotation,
          type_of: action.payload.type_of,
          color: action.payload.color,
          label: action.payload.label,
          uid: action.payload.uid,
          raw_data: {
            ...state.drawn_annotation.raw_data,
            coordinates: action.payload.coordinates,
            comment: action.payload.comment,
            type: action.payload.type,
            length: action.payload.length,
          },
        },
      };
      return state;

    case "set_slide_list":
      return {
        ...state,
        slide_list: action.payload,
      };
    // case "set_ml_job_list":
    //   return {
    //     ...state,
    //     ml_job_list: action.payload,
    //   };
    case "set_ml_job_list": {
      const {
        payload: { replace, data },
      } = action;

      return {
        ...state,
        ml_job_list: replace ? data : [data, ...state.ml_job_list],
      };
    }
    case "set_wsImage":
      return {
        ...state,
        wsImage: action.payload,
      };
    case "set_disableTools":
      return {
        ...state,
        disableTools: action.payload,
      };
    case "set_modalVisible":
      return {
        ...state,
        modalVisible: action.payload,
      };
    case "set_scale_value":
      return {
        ...state,
        scaleValue: action.payload,
      };
    case "set_macroScopicImg":
      return {
        ...state,
        macroScopicImg: action.payload,
      };
    case "set_selected_slide":
      state = {
        ...state,
        selected_slide: {
          ...state.selected_slide,
          scale_data: action.payload.scale_data,
          id: action.payload.id,
          token: action.payload.token,
          name: action.payload.name,
          numOfLayer: action.payload.numOfLayer,
          lens: action.payload.lens,
        },
      };
      return state;
    case "set_selected_img_properties":
      state = {
        ...state,
        selected_img_properties: {
          ...state.selected_img_properties,
          tileSize: action.payload.tileSize,
          imgHeight: action.payload.imgHeight,
          imgWidth: action.payload.imgWidth,
        },
      };
      return state;
    case "set_multi_question_answer":
      state = {
        ...state,
        multi_question_answer: {
          ...state.multi_question_answer,
          next: action.payload.next,
          previous: action.payload.previous,
          option: action.payload.option,
          current_Question: action.payload.current_Question,
          current_Question_key: action.payload.current_Question_key,
          comment: action.payload.comment,
        },
      };
      return state;
    case "set_annotation_profile":
      // console.log("profileeee", action.payload);

      state = {
        ...state,
        profile: {
          ...state.profile,
          annotation_id: action.payload.annotation_id,
          annotation_hide: action.payload.annotation_hide,
          annotation_comment: action.payload.annotation_comment,
          annotation_measurement: action.payload.annotation_measurement,
        },
      };
      return state;
    case "set_location_x_y":
      state = {
        ...state,
        location: {
          ...state.location,
          center_x: action.payload.center_x,
          center_y: action.payload.center_y,
        },
      };
      return state;
    case "set_selectedAnnotationElement":
      // console.log("selectedAnnotation set_selectedAnnotationElement action.payload", action.payload);

      state = {
        ...state,
        AnnotationElement: {
          ...state.AnnotationElement,
          selectedAnnotationElement: action.payload.type,
          status: action.payload.status,
        },
      };
      return state;
    case "set_Draw_Circle":
      // console.log("set_Draw_Circle", action.payload);

      state = {
        ...state,
        drawCircle: {
          ...state.drawCircle,
          previousZoomValue: action.payload.previousZoomValue,
          zoomValue: action.payload.zoomValue,
          status: action.payload.status,
          diameterValue: action.payload.diameterValue,
        },
      };
      return state;
    case "set_colorBox":
      state = {
        ...state,
        colorBox: {
          ...state.colorBox,
          start: action.payload.start,
          hide: action.payload.hide,
          delete: action.payload.delete,
          togglePopup: action.payload.togglePopup,
        },
      };
      return state;
    case "set_coordinatesLen":
      state = {
        ...state,
        coordinatesLen: {
          ...state.coordinatesLen,
          length4X: action.payload.length4X,
          length10X: action.payload.length10X,
          length20X: action.payload.length20X,
          length40X: action.payload.length40X,
          length60X: action.payload.length60X,
          length80X: action.payload.length80X,
        },
      };
      return state;
    case "set_zoom_z":
      return {
        ...state,
        zoom_level_z: action.payload,
      };
    case "set_selectedBoxVisibility":
      return {
        ...state,
        selectedBoxVisibility: action.payload,
      };
    case "set_selectedGridBoxVisibility":
      return {
        ...state,
        selectedGridBoxVisibility: action.payload,
      };
    case "set_selectedAnnotationBoxVisibility":
      return {
        ...state,
        selectedAnnotationBoxVisibility: action.payload,
      };
    case "set_selectedMagnifyVisibility":
      return {
        ...state,
        selectedMagnifyVisibility: action.payload,
      };
    case "set_Annotation_color":
      return {
        ...state,
        annotationColor: action.payload,
      };
    case "set_UserName_Cookie":
      return {
        ...state,
        username_cookie: action.payload,
      };
    case "set_Lens_value":
      return {
        ...state,
        lensValueSelected: action.payload,
      };
    case "set_fresh_case_list":
      return {
        ...state,
        toggleNewCase: action.payload,
      };
    case "set_drawCircleStatus":
      return {
        ...state,
        drawCircleStatus: action.payload,
      };
    case "set_selectArea":
      return {
        ...state,
        selectArea: action.payload,
      };
    case "set_EditSelectArea":
      return {
        ...state,
        EditSelectArea: action.payload,
      };
    case "set_SelectedAreaCoordinate":
      return {
        ...state,
        SelectedAreaCoordinate: action.payload,
      };
    case "update_ml_percent":
      state = {
        ...state,
        ml_status: {
          ...state.ml_status,
          job_id: action.payload.job_id,
          task_type: action.payload.task_type,
          percent: action.payload.percent,
        },
      };
      return state;
    case "set_selected_ml_job_data":
      let parsedJobDetails;
      // Check if job_details is defined and is a valid JSON string
      try {
        parsedJobDetails = JSON.parse(action.payload.job_details);
      } catch (error) {
        console.error('Failed to parse job_details:', error);
        parsedJobDetails = {}; // default to an empty object or handle accordingly
      }

      return {
        ...state,
        selected_ml_job_data: {
          ...state.ml_status,
          job_id: action.payload.job_id,
          slide_id: action.payload.slide_id,
          created: action.payload.created,
          model_name: action.payload.model_name,
          coordinate: parsedJobDetails.raw_data?.coordinates
        },
      };


    default:
      return state;
  }
};

export const PageContext = createContext(null);
