import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import Map from "ol/Map";
import { FullScreen, ScaleLine , defaults as defaultControls} from "ol/control";
import { useContext, useEffect, useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import { diagnosisNextButton, sendNewXYZvalues, uploadMicroscopicImg } from "../../../Service";
import { PageContext } from "../../CaseViewer";
import MapContext from "./MapContext";
import { DragRotateAndZoom, defaults as defaultInteractions } from 'ol/interaction';




const MapContainer = ({ children, prepareSlideViewerData }) => {
  const { state, pageDispatcher } = useContext(PageContext);
  const { id, token, scale_data, name } = state.selected_slide;
  const { case_token } = state.case_info;
  const { current_Question_key } = state.multi_question_answer;
  const { selectedOption, zoom_level_z } = state;
  const { center_x, center_y } = state.location;

  const mapRef = useRef();
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (!id) return;
    // console.log("console MapContainer useEffect new Map creation")
    // console.log("console id:",id)
    var map = new Map({
      interactions: defaultInteractions().extend([new DragRotateAndZoom()]),
      loadTilesWhileAnimating: true,
      loadTilesWhileInteracting: true,
    });

    map.setTarget(mapRef.current);

    setMap(map);

    return () => map.setTarget(null);
  }, [id]);

  // scale_data change handler
  useEffect(() => {

    if (!map) return;
    const scaleControl = new ScaleLine({
      units: "metric",
      className: "ol-scale-line",
    });

    if (scale_data) {
      map.addControl(scaleControl);
      map.addControl(new FullScreen());
    }

  }, [map,scale_data]);

  // useEffect(() => {
    let exp_btn = document.getElementById("export-png");

    if (exp_btn) {
      exp_btn.onclick = async () => {
        doScreenshot();
      };
    }

    async function doScreenshot() {
      const mapElement = mapRef.current;

      // Temporarily hide unwanted elements
      // const overviewMap = document.querySelector(".ol-overviewmap");
      // const scaleLine = document.querySelector(".ol-scale-line");
      const zStackZoom = document.querySelector(".zStackZoom");
      const overviewMapAreaTracker = document.querySelector(".overviewMapAreaTracker");
      const zoomControls = document.querySelector(".ol-zoom");
      const fullScreenControl = document.querySelector(".ol-full-screen");
      const rightPanelContainer = document.querySelector(".right-panel-container");
      const lensZoomButtons = document.querySelector(".lens-zoom-buttons");
      const resetRotate = document.querySelector(".ol-rotate");

      fullScreenControl.style.display = "none";
      // overviewMap.style.display = "none";
      // scaleLine.style.display = "none";
      overviewMapAreaTracker.style.display = "none";
      // zStackZoom.style.display = "none";
      zoomControls.style.display = "none";
      rightPanelContainer.style.display = "none";
      lensZoomButtons.style.display = "none";
      resetRotate.style.display = "none";

      html2canvas(mapElement).then((canvas) => {
        // Restore visibility of elements
        // overviewMap.style.display = "block";
        // scaleLine.style.display = "block";
        overviewMapAreaTracker.style.display = "block";
        // zStackZoom.style.display = "block";
        zoomControls.style.display = "block";
        fullScreenControl.style.display = "block";
        rightPanelContainer.style.display = "block";
        lensZoomButtons.style.display = "block";
        resetRotate.style.display = "block";

        // Convert canvas to image and download
        canvas.toBlob((blob) => {
          saveAs(blob, "map-screenshot.png");
        });
      });
    }
  // }, []);

  useEffect(() => {
    let exp_btn_backend = document.getElementById("export-png-to-backend");
    let exp_btn_backend_on_submit = document.getElementById("export-png-to-backend_on_submit");

    if (exp_btn_backend_on_submit) {
      exp_btn_backend_on_submit.onclick = async () => {
        doScreenshot();
      };
    }

    if (exp_btn_backend) {
      exp_btn_backend.onclick = async () => {
        doScreenshot();
      };
    }

    async function doScreenshot() {
      // if (!isMapLoaded) {
      //   console.log("Map view is not fully loaded yet.");
      //   return;
      // }
      const mapElement = mapRef.current;

      // Temporarily hide unwanted elements
      // const overviewMap = document.querySelector(".ol-overviewmap");
      const zoomControls = document.querySelector(".ol-zoom");
      const fullScreenControl = document.querySelector(".ol-full-screen");
      const rightPanelContainer = document.querySelector(".right-panel-container");
      const lensZoomButtons = document.querySelector(".lens-zoom-buttons");
      const resetRotate = document.querySelector(".ol-rotate");

      fullScreenControl.style.display = "none";
      // overviewMap.style.display = "none";
      zoomControls.style.display = "none";
      rightPanelContainer.style.display = "none";
      lensZoomButtons.style.display = "none";
      resetRotate.style.display = "none";

      html2canvas(mapElement).then((canvas) => {
        // Restore visibility of elements
        // overviewMap.style.display = "block";
        zoomControls.style.display = "block";
        fullScreenControl.style.display = "block";
        rightPanelContainer.style.display = "block";
        lensZoomButtons.style.display = "block";
        resetRotate.style.display = "block";

        canvas.toBlob(async (blob) => {
          if (blob) {
            const formData = new FormData();
            formData.append("question_key", current_Question_key);
            formData.append("selected_answer", selectedOption);
            formData.append("image", blob, "screenshot.png");
            const center = map.getView().getCenter()
            const zoom = map.getView().getZoom()
            formData.append("center_x", center[0]);
            formData.append("center_y", center[1]);
            formData.append("zoom_level_z", zoom);

            try {
              const res = await diagnosisNextButton(token, formData);
              const { N, P, answer, qid, question, answer_selected, location, comment } = res.data;
              // Check if "location" exists in the response
              if (location) {
                const [x, y, z] = location;
                pageDispatcher.set_location_x_y({ center_x: x, center_y: y });
                pageDispatcher.set_zoom_z(z);
              }
              pageDispatcher.set_multi_question_answer({ next: N, previous: P, option: answer, current_Question: question, current_Question_key: qid, comment:comment });
              pageDispatcher.set_selectedOption(answer_selected);
            } catch (error) {
              console.log(error);
            }
          }
        }, "image/png");
      });
    }
  }, [selectedOption]);
  
  useEffect(() => {
    let exp_btn_backend = document.getElementById("export-png-to-backend-on-re-capture");

    if (exp_btn_backend) {
      exp_btn_backend.onclick = async () => {
        doScreenshot();
      };
    }

    async function doScreenshot() {
      const mapElement = mapRef.current;

      // Temporarily hide unwanted elements
      const zoomControls = document.querySelector(".ol-zoom");
      const fullScreenControl = document.querySelector(".ol-full-screen");
      const rightPanelContainer = document.querySelector(".right-panel-container");
      const lensZoomButtons = document.querySelector(".lens-zoom-buttons");
      const resetRotate = document.querySelector(".ol-rotate");

      fullScreenControl.style.display = "none";
      zoomControls.style.display = "none";
      rightPanelContainer.style.display = "none";
      lensZoomButtons.style.display = "none";
      resetRotate.style.display = "none";

      html2canvas(mapElement).then((canvas) => {
        // Restore visibility of elements
        zoomControls.style.display = "block";
        fullScreenControl.style.display = "block";
        rightPanelContainer.style.display = "block";
        lensZoomButtons.style.display = "block";
        resetRotate.style.display = "block";

        canvas.toBlob(async (blob) => {
          if (blob) {
            const formData = new FormData();
            formData.append("question_key", current_Question_key);
            formData.append("image", blob, "screenshot.png");
            const center = map.getView().getCenter()
            const zoom = map.getView().getZoom()
            formData.append("center_x", center[0]);
            formData.append("center_y", center[1]);
            formData.append("zoom_level_z", zoom);

            try {
              const res = await sendNewXYZvalues(token, formData);
            } catch (error) {
              console.log(error);
            }
          }
        }, "image/png");
      });
    }
  }, [current_Question_key]);

  useEffect(() => {
    let upload_microscopic_png_btn = document.getElementById("upload-microscopic-png");

    if (upload_microscopic_png_btn) {
      upload_microscopic_png_btn.onclick = async () => {
        doScreenshot();
      };
    }

    async function doScreenshot() {
      const mapElement = mapRef.current;

      // Temporarily hide unwanted elements
      const overviewMap = document.querySelector(".ol-overviewmap");
      const zoomControls = document.querySelector(".ol-zoom");
      const fullScreenControl = document.querySelector(".ol-full-screen");
      const resetRotate = document.querySelector(".ol-rotate");

      fullScreenControl.style.display = "none";
      overviewMap.style.display = "none";
      zoomControls.style.display = "none";
      resetRotate.style.display = "block";

      html2canvas(mapElement).then((canvas) => {
        // Restore visibility of elements
        overviewMap.style.display = "block";
        zoomControls.style.display = "block";
        fullScreenControl.style.display = "block";
        resetRotate.style.display = "block";

        canvas.toBlob(async (blob) => {
          if (blob) {
            const formData = new FormData();
            const unixTimestamps = Date.now();
            const file_name = name + "-" + unixTimestamps.toString();
            formData.append("image", blob, `${file_name}.png`);
            try {
              const res = await uploadMicroscopicImg(formData, case_token);
              NotificationManager.success(res.data.Message, "", 2000);
            } catch (error) {
              NotificationManager.error("error occured", "", 2000);
            }
          }
        }, "image/png");
      });
    }
  }, []);

  return (
    <MapContext.Provider value={{ map }}>
      <div
        ref={mapRef}
        className="map"
        id="main-map"
      >
        {children}
      </div>
    </MapContext.Provider>
  );
};

export default MapContainer;
