import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { deleteUser, getUserList } from "../Service";
import { NotificationManager } from "react-notifications";
import { PageContext } from "../Settings";

const DeleteUser = ({ data, hide }) => {
  const context = useContext(PageContext);
  const { pageState: state, pageDispatcher: pageReducer, dispatch } = context;


  const handleDelete = async () => {
    const res = await deleteUser({ username: data });
    if (res.status === 200) {
      NotificationManager.success("deleted Successfully", "", 500);
      hide();
      getUserList(dispatch);

    } else {
      console.log("error occured");
    }
  };
  
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <h4>you want to delete {data} user</h4>
      <Button
        variant="danger"
        onClick={handleDelete}
      >
        Delete
      </Button>
    </div>
  );
};

export default DeleteUser;
