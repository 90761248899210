import React, { createContext, useContext, useEffect, useReducer } from "react";
import { PageTitle } from "../shared/PageTitle";
import { pageReducer, getUserList, getDiskSpaceData, getDeviceName } from "./Service";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { DiskSpace } from "./TabsComponent/DiskSpace";
import { Users } from "./TabsComponent/Users";
import { DeviceInfo } from "./TabsComponent/DeviceInfo";
import { ChangePassword } from "./TabsComponent/ChangePassword";
import { getUser, setCustomRoute } from "../Utils/Common";
import { Quickshare } from "./TabsComponent/Quickshare";
import { SlideActiveDays } from "./TabsComponent/SlideActiveDays";
import { Link } from "react-router-dom";
import { setCurrentModule } from "../Utils/Common.js";
import { ControlModule } from "./TabsComponent/ControlModule";
import TagsList from "./TabsComponent/TagsList";
import { Navigation } from "../shared/Navigation";
import FindAndSyncData from "./TabsComponent/FindAndSyncData";
import QuestionGraph from "./TabsComponent/QuestionGraph";
import { DeviceRegistration } from "./TabsComponent/DeviceRegistration";
import { DeviceConfigurationFile } from "./TabsComponent/DeviceConfigurationFile";
import DeviceConfiguration from "./TabsComponent/DeviceConfiguration";
import { SubscriptionModules } from "./TabsComponent/SubscriptionModules";
import AdminConfiguration from "./TabsComponent/AdminConfiguration";
import { NotificationContainer, NotificationManager } from "react-notifications";
import ModuleConfiguration from "./TabsComponent/ModuleConfiguration";
import UserConfiguration from "./TabsComponent/UserConfiguration";

export const Settings = () => {
  const initialState = {
    // columnDefs: [
    //     { headerName: "Users", field: "username" },
    // ],
    modalVisible: false,
    generateCloudIdModalVisible: false,
    userList: [],
    diskSpaceData: {},
    deviceName: "",
    registered_device_Name:"",
    is_Device_name_misMatched:false,

  };

  const [pageState, dispatch] = useReducer(pageReducer, initialState);

  useEffect(() => {
    if (getUser() === "Admin") {
      getUserList(dispatch);
      getDeviceName(dispatch);
    }
    getDiskSpaceData(dispatch);
  }, []);
  const pageDispatcher = {
    set_diskSpaceData: (value) => dispatch({ type: "set_diskSpaceData", payload: value }),
    set_modalVisible: (value) => dispatch({ type: "set_modalVisible", payload: value }),
    set_generateCloudIdmodalVisible: (value) => dispatch({ type: "set_generateCloudIdmodalVisible", payload: value }),
    set_Device_Name: (value) => dispatch({ type: "set_Device_Name", payload: value }),
    set_is_Device_name_misMatched: (value) => dispatch({ type: "set_is_Device_name_misMatched", payload: value }),
  };

  useEffect(()=>{
    if (localStorage.getItem("isMaint")==1){
      console.log("localStorage.getItem:",localStorage.getItem("device_name"))
      dispatch({ type: "set_Device_Name", payload: localStorage.getItem("device_name") });
      dispatch({ type: "set_is_Device_name_misMatched", payload: JSON.parse(localStorage.getItem("isNameMisMatched")) });
    }
  },[])

console.log("pageState LS",typeof localStorage.getItem("isNameMisMatched"))
  return (
    <>
      <Navigation />
      <NotificationContainer />
      <div className="container-fluid">
        <PageContext.Provider value={{ pageState, pageDispatcher, dispatch }}>
          {/* <PageTitle
          title={"PageTitle"}
          backLink="/slidelist"
        ></PageTitle> */}
          {/* <br /> */}

          <div style={{ marginTop: "5%" }}>
            {pageState.userList && pageState.userList.length > 0 && getUser() === "Admin" && (
              <div className="rtable">
                <AdminTabComponent />
              </div>
            )}
            {getUser() !== "Admin" && (
              <div className="rtable">
                <UserTabComponent />
              </div>
            )}
          </div>
        </PageContext.Provider>
      </div>
    </>
  );
};

// const onClickMaintenance = () => {
//   setCustomRoute("Maintenance Mode")
//   // setCurrentModule("Maintenance Mode");
// };

export const AdminTabComponent = () => {
  const { pageState, pageDispatcher } = useContext(PageContext)
  return (
    <Tab.Container
      id="left-tabs-example"
      defaultActiveKey="1"
    >
      <Row>
        <Col sm={2}>
          <Nav
            variant="pills"
            className="flex-column align-content-start"
          >
            <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="1">All Users</Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="2">Disk Space</Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="3">Device Info & Enter Cloud Id</Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="4">Change Password</Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="5">QuickShare ON/OFF</Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="6">Slide Active Days</Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="7">Control Module</Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="8">TagsList</Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="9">Find/Sync Unsynced Data</Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="10">Question Graph</Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-flex align-content-start">
              <Nav.Link
                as={Link}
                to="/maintenance"
                // onClick={onClickMaintenance}
              >
                Maintenance Mode
              </Nav.Link>
            </Nav.Item>
            {(localStorage.getItem("isMaint")==1) && <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="11">Device Registration</Nav.Link>
            </Nav.Item>}
            {(localStorage.getItem("isMaint")==1) && <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="12">Maintainer Configuration</Nav.Link>
            </Nav.Item>}
            {(localStorage.getItem("isMaint")==1) && <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="13">Configuration File</Nav.Link>
            </Nav.Item>}
            <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="14">Subscription Modules</Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="15">Admin Configuration</Nav.Link>
            </Nav.Item>
            {(localStorage.getItem("isMaint")==1) && <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="16">Module Configuration</Nav.Link>
            </Nav.Item>}
            <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="17">User Configuration</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey="1">
              <Users />
            </Tab.Pane>
            <Tab.Pane eventKey="2">
              <DiskSpace />
            </Tab.Pane>
            <Tab.Pane eventKey="3">
              <DeviceInfo />
            </Tab.Pane>
            <Tab.Pane eventKey="4">
              <ChangePassword />
            </Tab.Pane>
            <Tab.Pane eventKey="5">
              <Quickshare />
            </Tab.Pane>
            <Tab.Pane eventKey="6">
              <SlideActiveDays />
            </Tab.Pane>
            <Tab.Pane eventKey="7">
              <ControlModule />
            </Tab.Pane>
            <Tab.Pane eventKey="8">
              <TagsList />
            </Tab.Pane>
            <Tab.Pane eventKey="9">
              <FindAndSyncData />
            </Tab.Pane>
            <Tab.Pane eventKey="10">
              <QuestionGraph/>
            </Tab.Pane>
            <Tab.Pane eventKey="11">
              <DeviceRegistration/>
            </Tab.Pane>
            <Tab.Pane eventKey="12">
              <DeviceConfiguration/>
            </Tab.Pane>
            <Tab.Pane eventKey="13">
              <DeviceConfigurationFile/>
            </Tab.Pane>
            <Tab.Pane eventKey="14">
              <SubscriptionModules/>
            </Tab.Pane>
            <Tab.Pane eventKey="15">
              <AdminConfiguration />
            </Tab.Pane>
            <Tab.Pane eventKey="16">
              <ModuleConfiguration />
            </Tab.Pane>
            <Tab.Pane eventKey="17">
              <UserConfiguration />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export const UserTabComponent = () => {
  return (
    <Tab.Container
      id="left-tabs-example"
      defaultActiveKey="1"
    >
      <Row>
        <Col sm={2}>
          <Nav
            variant="pills"
            className="flex-column"
          >
            <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="1">Disk Space</Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="2">Enter Cloud Id</Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="3">Change Password</Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="5">QuickShare ON/OFF</Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-flex align-content-start">
              <Nav.Link eventKey="6">User Configuration</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey="1">
              <DiskSpace />
            </Tab.Pane>
            <Tab.Pane eventKey="2">
              <DeviceInfo />
            </Tab.Pane>
            <Tab.Pane eventKey="3">
              <ChangePassword />
            </Tab.Pane>
            <Tab.Pane eventKey="5">
              <Quickshare />
            </Tab.Pane>
          </Tab.Content>
          <Tab.Pane eventKey="6">
              <UserConfiguration />
            </Tab.Pane>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export const PageContext = createContext(null);
