import React, { useRef, useState, useEffect, useContext } from 'react';
import MapContext from './MapContext';
import Map from 'ol/Map';
import { getCenter } from 'ol/extent';
import ImageLayer from 'ol/layer/Image';
import Projection from 'ol/proj/Projection';
import Static from 'ol/source/ImageStatic';
import View from 'ol/View';
import { environment } from '../../../../environment';
import { getWsImage } from '../../Service';
import { PageContext } from '../../Remote';
const baseURL = environment.MICALYS_POSTGRES_API;

const MapComponent = ({ children }) => {
    const context = useContext(PageContext);
    const mapRef = useRef(null);
    const [map, setMap] = useState(null);
    // const [image, setImage] = useState('');
    // const [blobUrl, setBlobUrl] = useState('');

    const prepareImageCrop = (img, blobURL) => {
        if (mapRef.current.children.length > 0) {
            var child = mapRef.current.lastElementChild;
            while (child) {
                mapRef.current.removeChild(child);
                child = mapRef.current.lastElementChild;
            }
        }
        img.onload = () => {
            var extent = [0, 0, img.width, img.height];
            var projection = new Projection({
                code: 'xkcd-image',
                units: 'pixels',
                extent: extent,
            });

            var IMG_Layer = new ImageLayer({
                source: new Static({
                    attributions:
                        '© <a href="http://xkcd.com/license.html">xkcd</a>',

                    url: baseURL + 'get_ws_image',

                    imageLoadFunction: function (image, src) {
                        image.getImage().src = blobURL;
                    },
                    imageExtent: extent,
                }),
            });

            let options = {
                layers: [IMG_Layer],
                // target: mapRef.current,
                view: new View({
                    projection: projection,
                    center: getCenter(extent),
                    zoom: 1,
                }),
                controls: [],
            };

            let mapObject = new Map(options);
            mapObject.getView().fit(extent);

            mapObject.setTarget(mapRef.current);
            setMap(mapObject);
            // console.log(mapObject);
        };
    };
    // on component mount
    useEffect(async () => {
        getWsImage().then(async (res) => {
            if (res && res.data && res.data.byteLength > 0) {
                let blob = new Blob([res.data], {
                    type: res.headers['content-type'],
                });
                let blobURL = URL.createObjectURL(blob);
                var img = new Image();
                img.src = blobURL;
                // setImage(img);
                // setBlobUrl(blobURL);
                prepareImageCrop(img, blobURL);
            }
        });
    }, [context.pageState.positionData[0].last_ws]);

    return (
        <MapContext.Provider value={{ map }}>
            <div
                id="map"
                ref={mapRef}
                style={{
                    width: '100%',
                    height: '250px',
                    cursor: 'pointer',
                }}>
                {children}
            </div>
        </MapContext.Provider>
    );
};
export default MapComponent;
