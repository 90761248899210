import { useContext, useEffect } from 'react';
import MapContext from '../Map/MapContext';
import { Vector as VectorSource } from 'ol/source';
import { Vector as OLVectorLayer } from 'ol/layer';
import { PageContext } from '../../Remote';

const VectorLayer = ({ source }) => {
    const { map } = useContext(MapContext);
    const context = useContext(PageContext);

    useEffect(() => {
        if (!map) return;
        var source1 = new VectorSource({
            wrapX: false,
            // source,
        });
        let vector = new OLVectorLayer({
            source: source1,
        });
        vector.getSource().addFeature(source);
        map.addLayer(vector);
        return () => {
            if (map) {
                map.removeLayer(vector);
            }
        };
    }, [
        map,
        context.pageState.positionData[0]['px-x'],
        context.pageState.positionData[0]['px-y'],
    ]);
    return null;
};
export default VectorLayer;
