import { useContext } from "react"
import { PageContext } from "../Settings";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register necessary chart components for Pie charts
ChartJS.register(ArcElement, Tooltip, Legend);

export const DiskSpace = () => {
    return (
        <div>
            <PieChart />
        </div>
    )
}
export const PieChart = () => {
    const context = useContext(PageContext);

    // ***********************Input Data List*************************//
    let Idata = [
        context.pageState.diskSpaceData.free_input_space,
        context.pageState.diskSpaceData.used_input_space,
    ]
    let InputLabels = ['free_space', 'used_space']

    let InputCustomLabels = InputLabels.map((label, index) => `${label}: ${Idata[index]}GB`)

    const Inputdata = {

        labels: InputCustomLabels,
        datasets: [{
            data: Idata,
            label: "Disk Space",
            backgroundColor: [
                'pink',
                'purple'
            ],
            hoverOffset: 4
        }]
    };

    // ***********************Output Data List*************************//

    let Odata = [
        context.pageState.diskSpaceData.free_output_space,
        context.pageState.diskSpaceData.used_output_space
    ]

    let OutputLabels = ['free_output_space', 'used_output_space']

    let OutputCustomLabels = OutputLabels.map((label, index) => `${label}: ${Odata[index]}GB`)


    const OutputData = {
        labels: OutputCustomLabels,
        datasets: [{
            data: Odata,
            backgroundColor: [
                'blue',
                'green'
            ],
            hoverOffset: 4
        }]
    };

    // ***********************Common Options*************************//

    var options = {
        maintainAspectRatio: false,
        legend: { display: true, position: "right" },
    }


    return (

        <div className="row" >
            <div className="col-md-6 col-sm-12" >
                <Pie data={Inputdata} options={options}
                    height={324}
                    width={642}
                />
            </div>

            {/* <div className="col-md-6 col-sm-12">
                <Pie data={OutputData} options={options}
                    height={324}
                    width={642}
                />
            </div> */}

        </div>

    )
}