import React, { useContext, useState } from "react";
import { PageContext } from "../Settings";
import { AddUser } from "../Components";
import { MdDelete, MdEdit } from "react-icons/md";
import CommonModal from "../../shared/CommonModal";

export const Users = (props) => {
  const context = useContext(PageContext);
  const [modalShowPS, setModalShowPS] = useState(false);
  const [modalShowD, setModalShowD] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState(null);
  // const { pageState: state, pageDispatcher: pageReducer } = context;

  // const onGridReady = params => {
  //     params.api.sizeColumnsToFit()
  // }

  return (
    <>
      {/* <div
                className="ag-theme-alpine"
                style={{
                    boxSizing: "border-box",
                    height: '500px',
                    width: 'auto'
                }}
            >
                <AgGridReact
                    columnDefs={context.pageState.columnDefs}
                    onGridReady={onGridReady}
                    rowData={context.pageState.userList}
                >
                </AgGridReact>
            </div> */}
      <div style={{ float: "left" }}>
        <AddUser />
      </div>

      <div className="table">
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Sr</th>
              <th>Users</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          {/* <tbody>
                        {context.pageState.userList && context.pageState.userList.map((item, key) => (
                            <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{item.username}</td>
                            </tr>
                        ))}
                    </tbody> */}
          <tbody>
            {context.pageState.userList &&
              context.pageState.userList.map((item, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{item.username}</td>
                  <td>
                    <MdEdit
                      onClick={() => {
                        setSelectedUsername(item.username);
                        setModalShowPS(true);
                      }}
                    />
                    <CommonModal
                      key={`edit-${key}`}
                      show={modalShowPS}
                      onHide={() => setModalShowPS(false)}
                      data={selectedUsername}
                      //   option="ChangePassword"
                      //   onSave={handleSave}
                    />
                  </td>
                  <td>
                    <MdDelete
                      onClick={() => {
                        setSelectedUsername(item.username);
                        setModalShowD(true);
                      }}
                    />
                    <CommonModal
                      key={`delete-${key}`}
                      show={modalShowD}
                      onHide={() => setModalShowD(false)}
                      data={selectedUsername}
                      option="Delete"
                      //   onDelete={handleDelete}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
