import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { AiOutlineSave } from "react-icons/ai";
import { ImCancelCircle } from "react-icons/im";

export const SaveButton = () => {
  const saveTooltip = <Tooltip id="save-tooltip">Save</Tooltip>;
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 100, hide: 100 }}
      overlay={saveTooltip}
    >
      <AiOutlineSave />
    </OverlayTrigger>
  );
};

export const CancelButton = () => {
  const cancelTooltip = <Tooltip id="cancel-tooltip">Cancel</Tooltip>;
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 100, hide: 100 }}
      overlay={cancelTooltip}
    >
      <ImCancelCircle />
    </OverlayTrigger>
  );
};
