import { handleAbortedState } from "../Service";
import { NotificationContainer, NotificationManager } from 'react-notifications';

export const Aborted = () => {
    // const context = useContext(PageContext);

    return (
            <div >
                <h3>Operation Aborted.</h3>
                <br />
                <div className="col-md-4 offset-md-4 text-center" style={{ marginTop: '10px' }}>
                    <button className="btn btn-primary btn-block"
                        onClick={() => handleAbortedState().then(res => {
                            if (res.data && res.data.msg) {
                                NotificationManager.success(res.data.msg, '', 2000);
                            }
                        })}

                    >Ok</button>
                </div>

            </div>
            )
}