import React, { useContext, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { updateCaseData } from "../../../Service";
import { PageContext } from "../../CaseViewer";

const ReportStatus = () => {
  const { state, pageDispatcher } = useContext(PageContext);
  const { report_status, case_token } = state.case_info;

  const [reportStatus, setReportStatus] = useState(report_status);

  // useEffect(() => {
  //   setReportStatus(report_status);
  // }, [report_status]);

  const handleReportingStatus = async (val) => {
    setReportStatus(val);
    let obj = {
      report_status: val,
    };
    try {
      const res = await updateCaseData(obj, case_token);
      if (res && res.data.Message) {
        NotificationManager.success(res.data.Message, "", 2000);
      }
      // setIsEditing(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="d-flex justify-content-start align-self-center mb-1">
      <p className="align-self-center m-0 mr-2">Report Status:</p>
      <select
        className="custom-select custom-select-sm"
        style={{ width: "60%" }}
        value={report_status}
        onChange={(e) => {
          handleReportingStatus(e.target.value);
        }}
      >
        <option value="0">Not Reported</option>
        <option value="1">Reporting</option>
        <option value="2">Reported</option>
        <option value="-1">Flag Issue</option>
      </select>
    </div>
  );
};

export default ReportStatus;
