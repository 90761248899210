import {Oval} from "react-loader-spinner";
import { handleAbortState } from "../Service";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useEffect, useState } from "react";

export const LoaderComponent = () => {
  const [message, setMessage] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMessage("something went wrong, please check");
    }, 15000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Oval
        type="TailSpin"
        color="#00BFFF"
        height={80}
        width={80}
      />
      {message}
      <button
        type="button"
        className="btn btn-danger btn-block"
        onClick={() =>
          handleAbortState().then((res) => {
            if (res.data && res.data.msg) {
              NotificationManager.success(res.data.msg, "", 2000);
            }
          })
        }
      >
        Abort
      </button>
    </>
  );
};
