import React, { useState } from "react";
import UserConfigPermissions from "./UserConfigPermissions";

const UserConfiguration = () => {
  const [expandedCard, setExpandedCard] = useState(null);

  const handleCardClick = (cardName) => {
    setExpandedCard(expandedCard === cardName ? null : cardName);
  };

  return (
    <div>
      <div className="card mt-3">
        <div className="card-header" onClick={() => handleCardClick("configPermissions")}>
          <h5 className="mb-0" style={{color:"#007bff"}}>User Interface Settings</h5>
        </div>
        {expandedCard === "configPermissions" && (
          <div className="card-body">
            <UserConfigPermissions/>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserConfiguration;
