
const prod = {
    MICALYS_POSTGRES_API:'/api/',
    MICALYS_API_V2:'/apiv2/',
};

const dev = {
    MICALYS_POSTGRES_API:'http://127.0.0.1/api/',
    MICALYS_API_V2:'http://127.0.0.1/apiv2/',
};
  


export const environment = process.env.NODE_ENV === 'development' ? dev : prod;


