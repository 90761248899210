import React, { memo } from "react";
import LeftSideFeature from "./LeftSideFeature";

const Controls = memo(() => {
  return (
    <>
      <LeftSideFeature />
    </>
  );
});
export default Controls;
