import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { Login } from "./components/Login/Login";
import { CaseList } from "./components/WS Scanning/List/CaseList";
import { SlideList } from "./components/WS Scanning/List/SlideList";
import { SlideViewer } from "./components/WS Scanning/View/SlideViewer";
import { Settings } from "./components/Settings/Settings";
import { StitchSlide } from "./components/WS Scanning/Register/StitchSlide";
import { GridCaptureList } from "./components/GridCapture/List/GridCaptureList";
import { GridView } from "./components/GridCapture/GridView/GridView";
import { SlideGridCapture } from "./components/GridCapture/Register/SlideGridCapture";
import { Remote } from "./components/Live Control/Remote";
import MaintenanceView from "./components/Maintenance Mode/MaintenanceView";
import { PageNotFound } from "./components/Utils/PageNotFound";
import { GridCaptureRoute, HistoRoute, LiveControlRoute, MaintenanceRoute, PrivateRoute } from "./components/Utils/PrivateRoute";
import { PublicRoute } from "./components/Utils/PublicRoute";
import CaseViewer from "./components/WS Scanning/View/CaseViewer";
import InitialPath from "./components/shared/InitialPath";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* check for current module and then navigate to coressponding list OR better if such situation occurs set default modult to histo again and redirect OR write condition fro current modul check*/}
          {/* <Route path="/Login" element={getToken() && window.location.pathname === "/Login" ? setCurrentModule("Histo") && <Navigate to="/slidelist" /> : <Login />} /> */}

          <Route
            path="/Login"
            element={<PublicRoute component={Login} />}
          />
        </Routes>
        <>
          <InitialPath />

          <Routes>
            <Route
              path="/slidelist"
              element={<HistoRoute component={SlideList} />}
            />
            <Route
              path="/caselist"
              element={<HistoRoute component={CaseList} />}
            />
            <Route
              path="/slideviewer/:id"
              element={<HistoRoute component={SlideViewer} />}
            />
            <Route
              path="/caseview/:id"
              element={<HistoRoute component={CaseViewer} />}
            />
            <Route
              path="/slidestitch"
              element={<HistoRoute component={StitchSlide} />}
            />
            <Route
              path="/slidestitch/:id/:case_name"
              element={<HistoRoute component={StitchSlide} />}
            />
            <Route
              path="/maintenance"
              element={<MaintenanceRoute component={MaintenanceView} />}
            />
            <Route
              path="/slideGridCapture"
              element={<GridCaptureRoute component={SlideGridCapture} />}
            />
            <Route
              path="/gridCaptureList"
              element={<GridCaptureRoute component={GridCaptureList} />}
            />
            <Route
              path="/gridview/:id"
              element={<GridCaptureRoute component={GridView} />}
            />

            <Route
              path="/livecontrol"
              element={<LiveControlRoute component={Remote} />}
            />

            <Route
              path="/settings"
              element={<PrivateRoute component={Settings} />}
            />

            <Route
              path="/pagenotfound"
              element={<PageNotFound />}
            />
          </Routes>
        </>
      </Router>
    </div>
  );
}

export default App;
