import React, { useContext } from "react";
import { PageContext } from "./GridView";
import { ThumbnailItem } from "./ThumbnailItem";

const Thumbnail = ({ accessToken, activePage }) => {
  const { state } = useContext(PageContext);

  return (
    <ul className="thumbnails-list scroll mx-5">
      {state.thumbnail_img.map((eachImage, i) => {
        return (
          <ThumbnailItem
            key={state.list_name[0][i + 10 * activePage - 10]}
            name={state.list_name[0][i + 10 * activePage - 10]}
            imageUrl={eachImage}
            accessToken={accessToken}
            isActive={state.selectedThumbnailImg === state.list_name[0][i + 10 * activePage - 10]}
            ithofTen={i}
          />
        );
      })}
    </ul>
  );
};

export default Thumbnail;
