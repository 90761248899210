import { handleAbortState, placeSlideState } from "../Service";
import { NotificationContainer, NotificationManager } from 'react-notifications';

export const PlaceSlide = () => {
    return (
        <div>
            <h3>Place Slide and Click Ok</h3>
            <div className="row justify-content-center">
                <div className="col-md-3" style={{ marginTop: '10px' }}>
                    <button type="button" className="btn btn-danger btn-block"
                        onClick={() => handleAbortState().then(res => {
                            if (res.data && res.data.msg) {
                                NotificationManager.success(res.data.msg, '', 2000);
                            }
                        })}
                    >Abort</button>
                </div>
                <div className="col-md-3" style={{ marginTop: '10px' }}>
                    <button className="btn btn-primary btn-block"
                       onClick={() => placeSlideState()
                        .then(res => {
                            if (res.data && res.data.msg) {
                                NotificationManager.success(res.data.msg, '', 2000);
                                console.log(res.data)
                            }
                        })
                    }
                    >Ok</button>
                </div>
            </div>
        </div>
            )
}