import React, { useState, useEffect, useRef } from "react";
import { Carousel, Container, Row, Spinner } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { deleteGrossImage, getGrossImage } from "../../../Service";
import { NotificationManager } from "react-notifications";
import "./css/ImageCarousel.css";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css"; // Import Cropper's CSS

const ImageCarousel = ({ arrImage, slide_token, fetchGrossImageName, setImageNameList, setShow }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [imageBlobs, setImageBlobs] = useState({});
  const [loading, setLoading] = useState(false);
  const cropperRefs = useRef([]); // Ref array to store Cropper instances for each image

  const handleDeleteImage = async () => {
    try {
      const res = await deleteGrossImage({ image_name: arrImage[activeIndex] }, slide_token);
      NotificationManager.success("Image deleted successfully", "", 2000);
      setShow(false)
      if (cropperRefs.current[activeIndex] && cropperRefs.current[activeIndex].cropper) {
        cropperRefs.current[activeIndex].cropper.destroy();
        cropperRefs.current[activeIndex].cropper = null;
      }
      await fetchGrossImageName(slide_token);
      if (arrImage.length === 1) {
        // If there's only one image left and it was deleted, clear the list and reset the active index
        setImageNameList([]);
        setActiveIndex(0);
      } else if (activeIndex >= arrImage.length - 1) {
        // If deleting the last image, move to the previous image
        setActiveIndex((prevIndex) => prevIndex - 1);
      }else {
        // For all other cases, keep the index the same (i.e., move to the next image which will shift left in the array)
        setActiveIndex(activeIndex);
      }
      if (activeIndex === 0) {
        // If deleting the first image, move to the next image (index remains 0, which is now the new first image)
        setActiveIndex(0);
        // setActiveIndex((prevIndex) => prevIndex + 1);
      }
      
    } catch (error) {
      NotificationManager.error("Image doesn't exist", "", 2000);
    }
  };

  const handleGetGrossImage = async (imageName) => {
    try {
      setLoading(true);
      const res = await getGrossImage({ image_name: imageName }, slide_token);
      if (res && res.data) {
        let blob = new Blob([res.data], { type: res.headers["content-type"] });
        let blobURL = URL.createObjectURL(blob);

        setImageBlobs((prev) => ({
          ...prev,
          [imageName]: blobURL,
        }));
        setLoading(false); // Stop loading when image is loaded
      }
    } catch (error) {
      setLoading(false); // Stop loading on error
      NotificationManager.error("Image doesn't exist", "", 2000);
    }
  };

  const initializeCropper = (index) => {
    const imageElement = cropperRefs.current[index];
    console.log('cropperRefs.current',cropperRefs.current.length)

    if (imageElement) {
      if (cropperRefs.current[index].cropper) {
        cropperRefs.current[index].cropper.destroy(); // Destroy previous cropper instance
      }

      cropperRefs.current[index].cropper = new Cropper(imageElement, {
        viewMode: 1,
        dragMode: "move",
        zoomable: true,
        background: false,
        autoCrop: false,
        responsive: true,
        wheelZoomRatio: 0.1,
        toggleDragModeOnDblclick: false,
        minContainerWidth: 100,
        minContainerHeight: 100,
        minCanvasWidth: 100,
        minCanvasHeight: 100,
        cropBoxResizable: true,
        aspectRatio: 16 / 9, // Optional: Set an aspect ratio if needed
      });
    }
  };

  useEffect(() => {
    if (arrImage.length > 0) {
      handleGetGrossImage(arrImage[0]);
    }
  }, [arrImage]);

  useEffect(() => {
    initializeCropper(activeIndex); // Initialize Cropper.js when the image changes
    return () => {
      // Clean up the Cropper instance when unmounting or switching images
      if (cropperRefs.current[activeIndex] && cropperRefs.current[activeIndex].cropper) {
        cropperRefs.current[activeIndex].cropper.destroy();
        cropperRefs.current[activeIndex].cropper = null;
      }
    };
  }, [imageBlobs[activeIndex]]); // Reinitialize cropper when the image changes

  const handleSlideChange = (selectedIndex) => {
    if (selectedIndex < 0 || selectedIndex >= arrImage.length) return;
    setActiveIndex(selectedIndex);
    const imageName = arrImage[selectedIndex];

    if (!imageBlobs[imageName]) {
      handleGetGrossImage(imageName);
    }
  };

  const isLastSlide = activeIndex === arrImage.length - 1;
  const isFirstSlide = activeIndex === 0;

  return (
    <Container>
      <Row>
        <Carousel
          interval={null}
          prevLabel=""
          nextLabel=""
          activeIndex={activeIndex}
          onSelect={handleSlideChange}
          prevIcon={
            !isFirstSlide ? (
              <span
                aria-hidden="true"
                className="carousel-control-prev-icon"
              />
            ) : null
          }
          nextIcon={
            !isLastSlide ? (
              <span
                aria-hidden="true"
                className="carousel-control-next-icon"
              />
            ) : null
          }
          indicators={false} // Disable indicators for simplicity
        >
          {arrImage.map((ele, i) => (
            <Carousel.Item
              key={i}
              className="carousel-item-custom position-relative"
            >
              {loading ? (
                <div className="loading-spinner-container">
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                </div>
              ) : (
                <img
                  src={imageBlobs[ele] || ""}
                  alt={`Slide ${i + 1}`}
                  className="d-block w-100"
                  ref={(el) => (cropperRefs.current[i] = el)} // Store reference to each image element
                  onLoad={() => initializeCropper(i)} // Initialize cropper after image load
                />
              )}
              <MdDelete
                onClick={handleDeleteImage}
                className="delete-icon position-absolute"
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Row>
    </Container>
  );
};

export default ImageCarousel;
