import { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";
import { add_device_config, get_device_config } from "../Service";
import { NotificationContainer, NotificationManager } from "react-notifications";


const DeviceConfiguration = () => {

  const [modifiedData, setModifiedData] = useState({});
  const [activeCard, setActiveCard] = useState(null);
  const [deviceConfigs, setDeviceConfigs] = useState([]);

  console.log("modifiedData:",modifiedData)

  const fetchData = async () => {
    try {
      const res = await get_device_config();
      setDeviceConfigs(res.data.device_configs)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);


  const handleToggle = (cardIndex) => {
    setActiveCard(cardIndex === activeCard ? null : cardIndex);
    // Reset modified data when toggling cards
    setModifiedData({});
  };

  const handleChange = (type_of_config, newValue) => {
    // Update modified data for the active card
    setModifiedData((prevData) => ({
      ...prevData,
      [type_of_config]: newValue,
    }));
  };

  const handleSave = async () => {
    // Merge default values with modified values
    const mergedData = deviceConfigs[activeCard][Object.keys(deviceConfigs[activeCard])[0]].reduce((acc, item) => {
      acc[item.type_of_config] = modifiedData.hasOwnProperty(item.type_of_config) ? modifiedData[item.type_of_config] : item.data;
      return acc;
    }, {});
  
    // Validate merged data
    if ((0 > parseInt(mergedData.SOFT_CHECK)) || (parseInt(mergedData.SOFT_CHECK) > 3)) {
      alert("Soft Limit should be 0 to 3.");
      return; // Stop further execution
    }
    if ((0 > parseInt(mergedData.HARD_CHECK)) || (parseInt(mergedData.HARD_CHECK)> 5)) {
      alert("Hard Limit should be 0 to 5.");
      return; // Stop further execution
    }
    if ((1 > parseInt(mergedData.TOTAL_SLOTS)) ||  (parseInt(mergedData.TOTAL_SLOTS)> 6)) {
      alert("Total no of slots 1 to 6.");
      return; // Stop further execution
    }
  
    try {
      const res = await add_device_config(mergedData);
      if (res.status === 200) {
        NotificationManager.success("Saved Successfully", "", 500);
        // Clone the deviceConfigs array to avoid mutation
        const updatedConfigs = [...deviceConfigs];
  
        // Iterate over each configuration
        updatedConfigs.forEach((config) => {
          const category = Object.keys(config)[0];
          config[category].forEach((item) => {
            const type_of_config = item.type_of_config;
            if (mergedData.hasOwnProperty(type_of_config)) {
              // Update the data property if mergedData contains the type_of_config
              item.data = mergedData[type_of_config];
            }
          });
        });
  
        // Set the updated deviceConfigs array back to the state
        setDeviceConfigs(updatedConfigs);
  
        // Reset modifiedData
        setModifiedData({});
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  return (
    <div className="settings_flex_card-container">
      <NotificationContainer />
      {deviceConfigs?.map((config, index) => (
        <div
          key={index}
          className={`device_config_card ${activeCard === index ? "active" : ""}`}
        >
          <div className={`card-header device_config_card-header ${activeCard === index ? "active" : ""}`}>
            <h5 className="mb-0">
              <button
                className={`btn btn-link ${activeCard === index ? "" : "collapsed"}`}
                onClick={() => handleToggle(index)}
                aria-expanded={activeCard === index ? "true" : "false"}
              >
                {Object.keys(config)[0]}
              </button>
            </h5>
          </div>
          <div
            className={`collapse ${activeCard === index ? "show" : ""}`}
            aria-labelledby={`heading${index}`}
          >
            <div className="card-body">
              {config[Object.keys(config)[0]].map((item, subIndex) => (
                <div key={subIndex} className="mb-2">
                  <Row className="light-gray-strip">
                    <Col className="d-flex justify-content-start">
                      <Form.Label className="align-self-center mt-2">{item.label_name}:</Form.Label>
                    </Col>
                    <Col className="d-flex justify-content-start align-items-center pl-0">
                      {item.control_type === "toggle" ? (
                        <ToggleSubscription
                          subscriptionStatus={modifiedData[item.type_of_config] !== undefined ? modifiedData[item.type_of_config] : item.data}
                          handleToggle={() => handleChange(item.type_of_config, modifiedData[item.type_of_config] !== undefined ? (modifiedData[item.type_of_config]=="1"?"0":"1") : (item.data =="1"?"0":"1") )}
                        />
                      ) : item.control_type === "nothing" ? (
                        <span>{new Date(parseInt(item.data) * 1000).toLocaleDateString()}</span>
                      ) : item.control_type === "number" ? (
                        <Form.Control
                          type="number"
                          value={modifiedData[item.type_of_config] || item.data}
                          onChange={(e) => handleChange(item.type_of_config, e.target.value)}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          value={modifiedData[item.type_of_config] !== undefined ? modifiedData[item.type_of_config] : item.data}
                          onChange={(e) => handleChange(item.type_of_config, e.target.value)}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              ))}
              <hr/>
              <Button onClick={handleSave}>Save</Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

// Component for Toggle Subscription
const ToggleSubscription = ({ subscriptionStatus, handleToggle }) => {
  console.log("subscriptionStatus:",subscriptionStatus)
  return subscriptionStatus === "1" ? (
    <BsToggleOn
      style={{ color: "#007bff", fontSize: "24px",cursor: 'pointer' }}
      onClick={handleToggle}
    />
  ) : (
    <BsToggleOff
      style={{ color: "#007bff", fontSize: "24px",cursor: 'pointer' }}
      onClick={handleToggle}
    />
  );
};

export default DeviceConfiguration;
