import React, { createContext, useEffect, useState } from "react";
import { getGCSlideList, deleteStatus, updateSlidename, getSlideUploadStatus, getDownloadFile } from "../Service";
import { Pagination } from "../../shared/Pagination";
import { AgGridReact } from "ag-grid-react";
import { getSelectedChipset, getTypeOfSlidelide } from "../../Utils/Common";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import { Navigation } from "../../shared/Navigation";
import { Button, Modal } from "react-bootstrap";
import { FaDownload, FaEllipsisH, FaExclamationTriangle, FaRegCheckSquare, FaRegEdit, FaSearch } from "react-icons/fa";

export const GridCaptureList = () => {
  // const history = useHistory()
  // const navigate = useNavigate()
  const location = useNavigate();
  const initialState = {
    showmsg: location.state && location.state.showMessage ? true : false,
    allowPaging: true,
    gcList: [],
    number_of_items: 10,
    totalRecords: 0,
    index: 0,
    hideDeletedSlideStatus: true,
    slide_type: "GC",
    searchSlide: "",
    eta_stitch: "",
    eta_capture: "",
    slideUploadStatus: "",
  };

  // const [pageState, dispatch] = useReducer(pageReducer, initialState)

  // const pageDispatcher = {

  // }
  console.log("666 GridCaptureList component");

  const [state, setState] = useState(initialState);

  return (
    <>
      <Navigation />
      <div className="container-fluid">
        <NotificationContainer />
        <ListComponent value={{ state, setState }} />
      </div>
    </>
  );
};

export const ListComponent = (props) => {
  const [isCaseNameEditable, setIsCaseNameEditable] = useState(false);
  let statusExists;
  const { state, setState } = props.value;

  const checkStatusForSlideView = (params) => {
    // console.log("params", params.data)
    // let slide_stitch_status = params.data.stitch_status
    // if (slide_stitch_status === 2 && params.data.delete_slide === 0) {
    //     const win = window.open('/pbsview/' + params.data.slide_id, "_blank");
    //     // const win = window.open('/pbsview/' + 12, "_blank");
    //     win.focus();
    // }
    // let slide_capture_status = params.data.capture_status;
    // if (slide_capture_status === 2 && params.data.delete_slide === 0) {
    //   const win = window.open("/pbsview/" + params.data.slide_id, "_blank");
    //   win.focus();
    // }
    let slide_capture_status = params.data.capture_status;
    if (slide_capture_status === 2 && params.data.delete_slide === 0) {
      const win = window.open("/gridview/" + params.data.slide_id, "_blank");
      win.focus();
    }
  };
  const StatusIcon = (params) => {
    // const context = useContext(PageContext)
    switch (params.colDef.headerName) {
      case "Processing":
        return (
          <div>
            {params.data.stitch_status === 0 ? (
              <>
                <FaEllipsisH aria-hidden="true" />
                <br />
                <label>Initialized</label>
              </>
            ) : params.data.stitch_status === 1 ? (
              <>
                <FaEllipsisH aria-hidden="true" />
                <br />
                <label>Running</label>
                <br />
                {state.eta_stitch !== -1 && <>{state.eta_stitch}</>}
              </>
            ) : params.data.stitch_status === 2 ? (
              <>
                <FaRegCheckSquare aria-hidden="true" />
                <br />
                <label>Done</label>
              </>
            ) : params.data.stitch_status === -1 ? (
              <>
                <FaExclamationTriangle aria-hidden="true" />
                <br />
                <label>Error</label>
              </>
            ) : (
              ""
            )}
          </div>
        );

      case "Capture":
        return (
          <div>
            {params.data.capture_status === 0 ? (
              <>
                <FaEllipsisH aria-hidden="true" />
                <br />
                <label>Initialized</label>
              </>
            ) : params.data.capture_status === 1 ? (
              <>
                <FaEllipsisH aria-hidden="true" />
                <br />
                <label>Running</label>
                <br />
                {state.eta_capture !== -1 && <>{state.eta_capture}</>}
              </>
            ) : params.data.capture_status === 2 ? (
              <>
                <FaRegCheckSquare aria-hidden="true" />
                <br />
                <label>Done</label>
              </>
            ) : params.data.capture_status === -1 ? (
              <>
                <FaExclamationTriangle aria-hidden="true" />
                <br />
                <label>Error</label>
              </>
            ) : (
              ""
            )}
          </div>
        );

      case "Upload":
        return (
          <div>
            {params.data.upload_status === 0 ? (
              <>
                <FaEllipsisH aria-hidden="true" />
                <br />
                <label>Initialized</label>
              </>
            ) : params.data.upload_status === 1 ? (
              <>
                <FaEllipsisH aria-hidden="true" />
                <br />
                <label>Running</label>
              </>
            ) : params.data.upload_status === 2 ? (
              <>
                <FaRegCheckSquare aria-hidden="true" />
                <br />
                <label>Done</label>
              </>
            ) : params.data.upload_status === -1 ? (
              <>
                <FaExclamationTriangle aria-hidden="true" />
                <br />
                <label>Error</label>
              </>
            ) : (
              ""
            )}
          </div>
        );

      default:
        return "";
    }
  };

  const columnDefs = [
    {
      headerName: "Slides",
      wrapText: true,
      autoHeight: true,
      children: [
        {
          headerName: "Name",
          field: "slide_name",
          width: 400,
          sortable: true,
          wrapText: true,
          autoHeight: true,
          editable: isCaseNameEditable,

          cellRenderer: (params) => (
            <div className="d-flex justify-content-between align-items-center">
              <button
                onClick={() => checkStatusForSlideView(params)}
                style={{ color: "#008cff", border: "none", backgroundColor: "white" }}
              >
                {params.data.slide_name}
              </button>
              <div>
                <FaRegEdit
                  aria-hidden="true"
                  // size="lg"
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsCaseNameEditable(!isCaseNameEditable)}
                />
              </div>
            </div>
          ),
        },
        {
          headerName: "Image",
          field: "slide_name",
          width: 398,
          wrapText: true,
          height: 250,
          cellRenderer: (params) => (
            <button style={{ color: "#008cff", border: "none", backgroundColor: "white" }}>
              <img
                src={`data:image/png;base64,${params.data.imagedata}`}
                alt="ResponsiveImage"
                className="img-fluid"
                onClick={() => checkStatusForSlideView(params)}
              />
            </button>
          ),
        },
      ],
    },
    {
      headerName: "Created",
      field: "created",
      sortable: true,
      width: 200,
      wrapText: true,
      autoHeight: true,
      suppressMovable: true,
    },
    {
      headerName: "Status",
      children: [
        {
          headerName: "Capture",
          field: "capture_status",
          sortable: true,
          suppressMovable: true,
          width: 120,
          wrapText: true,
          autoHeight: true,
          cellRenderer: (params) => StatusIcon(params),
        },
        // {
        //   headerName: "Processing",
        //   field: "stitch_status",
        //   sortable: true,
        //   suppressMovable: true,
        //   wrapText: true,
        //   width: 120,
        //   autoHeight: true,
        //   cellRenderer: (params) => StatusIcon(params),
        // },
        // {
        //     headerName: "Upload",
        //     field: "upload_status",
        //     sortable: true,
        //     suppressMovable: true,
        //     width: 110,
        //     wrapText: true,
        //     autoHeight: true,
        //     cellRenderer: (params) => StatusIcon(params)
        // }
      ],
    },
    {
      headerName: "Delete",
      field: "delete_slide",
      sortable: true,
      width: 246,
      suppressMovable: true,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params) => {
        return (
          <div>
            {params.data.delete_slide === 0 ? (
              <>
                <a
                  href="#!"
                  onClick={() => {
                    // let currentIndex = parseInt(localStorage.currentIndex);
                    let currentIndex = state.index;
                    deleteStatus(params.data.slide_id)
                      .then(async (res) => {
                        if (res && res.data.Message) {
                          // NotificationManager.success(res.data.Message, '', 2000);
                          let obj = {
                            slide_name: state.searchSlide,
                            index: currentIndex,
                            number_of_items: state.number_of_items,
                            slide_type: state.slide_type,
                            selectedChipset: getSelectedChipset(),
                          };
                          getGCSlideList(obj, state.hideDeletedSlideStatus).then((res) => {
                            if (res && res.data) {
                              console.log("666 1");
                              setState({
                                ...state,
                                gcList: res.data.slidelist,
                                totalRecords: res.data.counter,
                                index: res.data.current_index,
                                searchSlide: "",
                              });
                              NotificationManager.success(res.data.Message, "", 2000);
                            }
                          });
                        }
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  }}
                >
                  Delete
                </a>
              </>
            ) : params.data.delete_slide === 1 ? (
              <>
                &nbsp;
                <FaEllipsisH aria-hidden="true" />
              </>
            ) : params.data.delete_slide === 2 ? (
              <>
                &nbsp;
                <FaRegCheckSquare aria-hidden="true" />
              </>
            ) : params.data.delete_slide === -1 ? (
              <>
                &nbsp;
                <FaExclamationTriangle aria-hidden="true" />
              </>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      headerName: "Download",
      field: "download_slide",
      sortable: true,
      suppressMovable: true,
      wrapText: true,
      width: 140,
      autoHeight: true,
      cellRenderer: (params) => {
        return (
          <div>
            <a
              href="#!"
              onClick={() => {
                getDownloadFile(params.data.slide_id);
              }}
            >
              <FaDownload aria-hidden="true" />
              Download
            </a>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    console.log("666 RES Before", state.gcList);

    setState({ ...state, index: 0 });
    let obj = {
      slide_name: state.searchSlide,
      index: state.index,
      number_of_items: state.number_of_items,
      slide_type: state.slide_type,
      selectedChipset: getSelectedChipset(),
    };
    getGCSlideList(obj, state.hideDeletedSlideStatus).then((res) => {
      if (res && res.data) {
        console.log("666 RES", res.data.slidelist);
        setState({ ...state, gcList: res.data.slidelist, totalRecords: res.data.counter });
      }
    });
    statusExists = state.hideDeletedSlideStatus;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onGridReady = (params) => {
    params.api.resetRowHeights();
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      console.log("666 2");

      setState({ ...state, searchSlide: state.searchSlide, index: 0 });
      // context.getSlideRecords(tempSearch, context.index, context.number_of_items, statusExists);
      let obj = {
        slide_name: state.searchSlide,
        index: 0,
        number_of_items: state.number_of_items,
        slide_type: state.slide_type,
        selectedChipset: getSelectedChipset(),
      };
      getGCSlideList(obj, state.hideDeletedSlideStatus).then((res) => {
        if (res && res.data) {
          console.log("666 3");

          setState({ ...state, gcList: res.data.slidelist, totalRecords: res.data.counter, index: res.data.current_index });
        }
      });
    }
  };

  const handleSlideUploadStatus = async () => {
    console.log("666 handleSlideUploadStatus");

    try {
      const res = await getSlideUploadStatus(state.slide_token);
      console.log("666 4", state.gcList);

      setState({ ...state, slideUploadStatus: res.data.status });
    } catch (error) {
      console.log(error);
      // console.log(state.slide_token);
    }
  };

  const onCellValueChanged = (event) => {
    let obj = {
      slide_id: event.data.slide_id,
      slide_name: event.data.slide_name,
    };
    let currentIdx = parseInt(localStorage.currentIndex);

    updateSlidename(obj).then((res) => {
      if (res && res.data.Message) {
        state.getSlideRecords(state.slide_name, currentIdx, state.number_of_items, statusExists, state.tag_search);
        NotificationManager.success(res.data.Message, "", 2000);
      }
    });
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (show) {
      handleSlideUploadStatus();
    }
  }, [show]);

  console.log("666 state.gcList:", state.gcList);

  return (
    <div className="rtable">
      <ul
        className="navbar-nav"
        style={{
          backgroundColor: "transparent",
          textTransform: "upperCase",
          fontSize: "13px",
          textAlign: "start",
        }}
      >
        <li className="nav-item">
          {getTypeOfSlidelide() === ("WSI" || "Histo") && <Link to="/slidestitch">Scan Slide</Link>}
          {getTypeOfSlidelide() === ("GC" || "Grid Capture") && <Link to="/slideGridCapture">Grid Capture</Link>}
          {getTypeOfSlidelide() === "Live Control" && <Link to="/livecontrol">Live Control</Link>}
        </li>
      </ul>
      {state && state.gcList && (
        <>
          <div className="row justify-content-between">
            <div className="col-md-4 d-flex justify-content-between align-items-center">
              <div className="input-group">
                <input
                  type="search"
                  className="form-control rounded"
                  value={state.searchSlide}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      // setState({ ...state, searchSlide: e.target.value });
                      let obj = {
                        slide_name: e.target.value,
                        index: 0,
                        number_of_items: state.number_of_items,
                        slide_type: state.slide_type,
                        selectedChipset: getSelectedChipset(),
                      };
                      getGCSlideList(obj, state.hideDeletedSlideStatus).then((res) => {
                        if (res && res.data) {
                          console.log("666 5");

                          setState({
                            ...state,
                            gcList: res.data.slidelist,
                            totalRecords: res.data.counter,
                            index: res.data.current_index,
                            searchSlide: "",
                            hideDeletedSlideStatus: true,
                          });
                        }
                      });
                    } else {
                      console.log("666 6");

                      setState({ ...state, index: 0, searchSlide: e.target.value });
                    }
                  }}
                  onKeyDown={handleKeyDown}
                  placeholder="Search by SlideName"
                />
                <span
                  className="input-group-text border-0"
                  id="search-addon"
                >
                  <FaSearch
                    aria-hidden="true"
                    onClick={(e) => {
                      console.log("666 7");

                      setState({ ...state, index: 0, searchSlide: e.target.value });
                      let obj = {
                        slide_name: state.searchSlide,
                        index: state.index,
                        number_of_items: state.number_of_items,
                        slide_type: state.slide_type,
                        selectedChipset: getSelectedChipset(),
                      };
                      getGCSlideList(obj, state.hideDeletedSlideStatus).then((res) => {
                        if (res && res.data) {
                          console.log("666 8");

                          setState({ ...state, gcList: res.data.slidelist, totalRecords: res.data.counter, index: res.data.current_index });
                        }
                      });
                    }}
                  />
                </span>
              </div>
              <>
                <Button
                  size="sm"
                  // onClick={handleUploadStatus}
                  onClick={handleShow}
                >
                  Slide Upload Status
                </Button>

                <Modal
                  show={show}
                  onHide={handleClose}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Slide Upload Status</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ maxHeight: "300px", overflowY: "auto" }}>{state.slideUploadStatus}</Modal.Body>
                </Modal>
              </>
            </div>
            <div className="col-md-8">
              <Pagination
                initialState={state}
                refreshListwithSearch={() => {
                  let obj = {
                    slide_name: "",
                    index: 0,
                    number_of_items: state.number_of_items,
                    slide_type: state.slide_type,
                    selectedChipset: getSelectedChipset(),
                  };
                  getGCSlideList(obj, state.hideDeletedSlideStatus).then((res) => {
                    if (res && res.data) {
                      console.log("666 9");

                      setState({
                        ...state,
                        gcList: res.data.slidelist,
                        totalRecords: res.data.counter,
                        index: 0,
                        searchSlide: "",
                        hideDeletedSlideStatus: true,
                      });
                    }
                  });
                }}
                setIndex={(indexValue) => {
                  console.log("666 10");

                  setState({ ...state, index: indexValue });
                  let obj = {
                    slide_name: state.searchSlide,
                    index: indexValue,
                    number_of_items: state.number_of_items,
                    slide_type: state.slide_type,
                    selectedChipset: getSelectedChipset(),
                  };
                  getGCSlideList(obj, state.hideDeletedSlideStatus).then((res) => {
                    if (res && res.data) {
                      // console.log("RES", res.data)
                      console.log("666 11");

                      setState({ ...state, gcList: res.data.slidelist, totalRecords: res.data.counter, index: res.data.current_index });
                    }
                  });
                }}
              />
            </div>
          </div>
          <div
            className="form-check d-flex align-items-center" style={{ justifyContent: "flex-end" }}
          >
            <input
              type="checkbox"
              className="form-check-input"
              checked={state.hideDeletedSlideStatus}
              onChange={(e) => {
                console.log("666 12");

                setState({ ...state, hideDeletedSlideStatus: e.target.checked });
                let obj = {
                  slide_name: state.searchSlide,
                  index: state.index,
                  number_of_items: state.number_of_items,
                  slide_type: state.slide_type,
                  selectedChipset: getSelectedChipset(),
                };
                getGCSlideList(obj, e.target.checked).then((res) => {
                  if (res && res.data) {
                    console.log("666 13");

                    setState({
                      ...state,
                      gcList: res.data.slidelist,
                      totalRecords: res.data.counter,
                      index: res.data.current_index,
                      hideDeletedSlideStatus: e.target.checked,
                    });
                  }
                });
              }}
            />
            <label className="form-check-label" style={{ marginLeft: "5px" }}>Hide Deleted Slide</label>
          </div>
          <div className="ag-theme-alpine">
            <AgGridReact
              colResizeDefault="shift"
              domLayout="autoHeight"
              columnDefs={columnDefs}
              rowData={state.gcList}
              headerHeight="32"
              getRowHeight={() => {
                return 130;
              }}
              onGridReady={onGridReady}
              onCellValueChanged={onCellValueChanged}
            />
          </div>
        </>
      )}
    </div>
  );
};

export const PageContext = createContext(null);
