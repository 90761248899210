import React, { useEffect, useState } from "react";
import { cancelSlot, getLiveImage } from "../../Service";
import { NotificationManager } from "react-notifications";


const Capturing = ({ slot_number }) => {
  const [liveImageData, setLiveImageData] = useState("");
  const [cancelButtonDisabled, setCancelButtonDisabled] = useState(false);

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getLiveImage().then(async (res) => {
        if (res && res.data && res.data.byteLength > 0) {
          let blob = new Blob([res.data], { type: res.headers["content-type"] });
          let image = URL.createObjectURL(blob);
          setLiveImageData(image);
        }
      });
    }, 500);
    return () => clearInterval(interval);
  }, []);

  const cancel_slot = async () => {
    try {
      const obj = {
        slot_number: slot_number,
      };
      const res = await cancelSlot(obj);
      if (res.data) {
        NotificationManager.success("Capturing Cancelled", "", 2000);
        // Disable the Cancel button after successful response
        setCancelButtonDisabled(true);
      }
    } catch (error) {
      NotificationManager.error("Some Error Occurred", "", 2000);
      console.log(error);
    }
  };

  const handleCancelCapturing = () => {
    cancel_slot();
  };

  return (
    <div
      className="liveimgbody"
      style={{ background: "linear-gradient(lightGray, white)" }}
    >
      <div className="liveimagebackground">
        {liveImageData && Object.keys(liveImageData).length > 0 && (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="row">
              <div className="col-md-9">
                <h1>Capturing</h1>
              </div>
              <div className="col-md-3 d-flex justify-content-end">
                <button
                  className="btn btn-danger"
                  onClick={handleCancelCapturing}
                  disabled={cancelButtonDisabled}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="img-container col-md-4 col-sm-12">
              <img
                src={liveImageData}
                alt=""
                id="liveimg"
                style={{ width: "auto", height: "50vh", marginTop: "45px", border: "1px solid green" }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Capturing;
