import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import { Modal, Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { PageContext } from "../../CaseViewer";
import { getToken } from "../../../../Utils/Common";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import { environment } from "../../../../../environment";
import { LoaderComponent } from "./Loader";
import Lens from "../updateSlide/Lens";
import { TfiLayersAlt } from "react-icons/tfi";
import StickyModal from "./StickyModal";
import { GoRepoPush } from "react-icons/go";
import ImageModal from "./ImageModal";
import { getGrossImageList } from "../../../Service";

const WsiImage = lazy(() => import("../updateSlide/WsiImage"));
const SlideName = lazy(() => import("../updateSlide/SlideName"));
const SampleOrigin = lazy(() => import("../updateSlide/SampleOrigin"));
const StainType = lazy(() => import("../updateSlide/StainType"));
const StatusSymbol = lazy(() => import("./StatusSymbol"));
const EXportSlide = lazy(() => import("./EXportSlide"));
const QuickshareCopyLink = lazy(() => import("./QuickshareCopyLink"));

const baseURL = environment.MICALYS_POSTGRES_API;

const SlideInfoCard = ({ list, setExportSlideId, setExportSlideToken, handleShow }) => {
  const { state, pageDispatcher } = useContext(PageContext);
  const { case_owner } = state.case_info;
  const selectedSlideView = state.selected_slide.id;
  // const { token } = state.selected_slide;
  const [CurrentToken, setCurrentToken] = useState();
  const [show, setShow] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const handleOpenModal = () => setShow(true);
  const [ImageNameList, setImageNameList] = useState([]);
  const { slide_id,slide_token } = list;


  const fetchGrossImageName = async (token) => {
    try {
      const res = await getGrossImageList(token);
      if (res.data.image_name_list && res.data.image_name_list.length > 0) {
        setImageNameList(res.data.image_name_list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (slide_token) {
      fetchGrossImageName(slide_token);
    }
  }, [slide_token]);

  const handleOpenImageModal = () => {
    setShowImageModal(true);
    setCurrentToken(slide_token)
    fetchGrossImageName(slide_token)
  }

  // {console.log("list:",list)}

  const deleteSlideStatus = (slide_id) => {
    if (window.confirm("Sure want to delete?")) {
      let obj = {
        slide_id: slide_id,
      };
      axios
        .post(baseURL + "delete_this_slide", obj, { headers: { Authorization: `Bearer ${getToken()}` } })
        .then(async (res) => {
          if (res && res.data.Message) {
            NotificationManager.success(res.data.Message, "", 2000);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <Card
      border={slide_id === selectedSlideView ? "dark" : "none"}
      bg={slide_id === selectedSlideView ? "light" : "none"}
      key={slide_id}
      className="caseViewCard  p-1"
    >
      <Card.Body className="case-view-card-body">
        <Card.Text>
          <Suspense fallback={<LoaderComponent />}>
            <WsiImage list={list} />
          </Suspense>

          <Suspense fallback={<LoaderComponent />}>
            <div className="d-flex justify-content-between align-items-center mt-1">
              <SlideName list={list} />
              <div className="d-flex align-items-center">
                <div>
                  <TfiLayersAlt title="No. of Layers" />
                  <span style={{ fontSize: "12px" }}>&nbsp;{list.zstack}</span>
                </div>
                <Lens list={list} />
              </div>
            </div>
          </Suspense>

          <Suspense fallback={<LoaderComponent />}>
            <StainType list={list} />
          </Suspense>

          <div className="d-flex justify-content-between align-items-center">
            <Suspense fallback={<LoaderComponent />}>
              <SampleOrigin list={list} />
            </Suspense>
            <span style={{ fontSize: "12px" }}>{list.created}</span>
          </div>

          <div className="d-flex align-items-center">
            <Suspense fallback={<LoaderComponent />}>
              <EXportSlide
                setExportSlideId={setExportSlideId}
                setExportSlideToken={setExportSlideToken}
                handleShow={handleShow}
                list={list}
              />
            </Suspense>
            {case_owner && (
              <Suspense fallback={<LoaderComponent />}>
                <QuickshareCopyLink slide_id={list.slide_id} />
              </Suspense>
            )}

            {case_owner && (
              <OverlayTrigger
                key={`bottom-delete-${list.slide_id}`}
                placement="bottom"
                overlay={
                  <Tooltip id={`tooltip-bottom-delete-${list.slide_id}`}>
                    <strong>Delete Slide</strong>
                  </Tooltip>
                }
              >
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={() => {
                    deleteSlideStatus(list.slide_id);
                  }}
                  className="mx-1"
                >
                  <AiOutlineDelete />
                </Button>
              </OverlayTrigger>
            )}

            {case_owner && (
              <Suspense fallback={<LoaderComponent />}>
                <StatusSymbol list={list} />
              </Suspense>
            )}

            {case_owner && (
              <>
                <OverlayTrigger
                  key={`bottom-change-case-${list.slide_id}`} // Unique key for this OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-bottom-change-case-${list.slide_id}`}>
                      <strong>Change Case</strong>
                    </Tooltip>
                  }
                >
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={handleOpenModal}
                    className="mx-1"
                  >
                    CC
                  </Button>
                </OverlayTrigger>
                <StickyModal
                  show={show}
                  setShow={setShow}
                  list={list}
                />
              </>
            )}
            <>
              <Button
                variant="outline-primary"
                size="sm"
                className="mx-1"
                onClick={handleOpenImageModal}
                title="Gross Image"
              >
                <GoRepoPush />
              </Button>

              <ImageModal
                show={showImageModal}
                setShow={setShowImageModal}
                slide_token={CurrentToken}
                ImageNameList={ImageNameList}
                setImageNameList={setImageNameList}
                fetchGrossImageName={fetchGrossImageName}
              />
            </>
          </div>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default SlideInfoCard;
