import { useLocation } from "react-router-dom";
import { Navigation } from "../shared/Navigation";
import { getSelectedChipset } from "./Common";
// import './PageNotFound.css'; // Import the CSS file

export const PageNotFound = () => {
  const location = useLocation();
  console.log("location:", location.pathname);

  return (
    <>
      <Navigation />
      <div className="parentContainer">
        <div className="boxStyle">
          <h1>Your Active module is {getSelectedChipset()}</h1>
          <br/>
          <h2>Login Again to go {location.pathname}</h2>
          <br/>
          <h1>OR</h1>
          <br/>
          <p>Click on top right Logo to go to {getSelectedChipset()} module</p>
        </div>
      </div>
    </>
  );
};
