import React, { useContext, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { updateCaseData } from "../../../Service";
import { PageContext } from "../../CaseViewer";

const PatientGender = () => {
  const { state } = useContext(PageContext);
  const { patient_gender, case_token } = state.case_info;

  const [patientGender, setPatientGender] = useState(state.case_info.patient_gender);

  const options = ["Male", "Female", "Other", "N/A"];

  useEffect(() => {
    setPatientGender(patient_gender);
  }, [state.case_info.patient_gender]);

  const handleGenderChange = async (e) => {
    let selected_gender=e.target.value
    let obj = {
      patient_gender: e.target.value,
    };
    try {
      const res = await updateCaseData(obj, case_token);
      setPatientGender(selected_gender);
      if (res && res.data.Message) {
        NotificationManager.success(res.data.Message, "", 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="d-flex justify-content-start align-items-center mb-1">
      Patient Gender:
      <select
        className="custom-select custom-select-sm ml-2"
        style={{width:"60%"}}
        value={patientGender}
        onChange={handleGenderChange}
      >
        <option value="">Select Gender</option>
        {options.map((option, index) => (
          <option
            key={index}
            value={option}
          >
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default PatientGender;
