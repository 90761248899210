import React, { useEffect, useState } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import LogView from './LogView';
import { handleAbortState, handleCommand, handleDeviceLog } from '../Service';

const MaintenanceLogUi = () => {
  const [state, setState] = useState([]);

  handleDeviceLog().then(res => {
    if (res.data && res.data.device_log.length >= 1) {
      setState([...state, res.data.device_log])
    }
  })

  return (
    <>
      {/* <NotificationContainer /> */}
      <h3>Maintenance Mode</h3>

      <div className="col-md-4 offset-md-4 d-flex justify-content-end mt-3">
        <button className="btn btn-danger btn-block "
          onClick={() => handleAbortState()
            .then(res => {
              if (res.data && res.data.msg) {
                NotificationManager.success(res.data.msg, '', 2000);
              }
            })}
        >Exit</button>
      </div>

      <div className="d-flex mt-3">

        <button className="btn btn-light btn-block mr-2"
          onClick={() => {
            let obj = { cmd_key: "homex" };
            handleCommand(obj);
            setState([...state, 'HomeX']);
          }}
        >Home X</button>

        <button className="btn btn-light btn-block mr-2"
          onClick={() => {
            let obj = { cmd_key: "homey" };
            handleCommand(obj);
            setState([...state, 'HomeY']);
          }}>Home Y</button>

        <button className="btn btn-light btn-block"
          onClick={() => {
            let obj = { cmd_key: "homez" };
            handleCommand(obj);
            setState([...state, 'HomeZ']);
          }}>Home Z</button>

        <button className="btn btn-light btn-block mr-2"
          onClick={() => {
            let obj = { cmd_key: "replug" };
            handleCommand(obj);
            setState([...state, 'replug']);
          }}>Replug Camera</button>
      </div>

      <div className="mt-3">
        <LogView state={state} />
      </div>

    </>
  )
}

export default MaintenanceLogUi
