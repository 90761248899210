import React, { useEffect, useState } from "react";

const RadioButtonForLens = ({ k,value, state, setState, setDefaultLens}) => {
  const [orgValue, setOrgValue] = useState();

  useEffect(() => {
    setOrgValue(value);
  }, [value]);


  return (
    <div className="col-md-5 col-lg-4 col-sm-12 ">
      <div className="form-check pull-left">
        <input
          className="form-check-input"
          type="radio"
          id={k}
          value={orgValue}
          checked={state.lens === orgValue}
          onChange={(e) => {
            // console.log('e.target.value',e.target.value)
            setState({ ...state, lens: parseInt(e.target.value) });
            setDefaultLens(parseInt(e.target.value));
          }}
        />
        <label
          className="form-check-label"
          htmlFor={k}
        >
          {k}
        </label>
      </div>
    </div>
  );
};

export default RadioButtonForLens;