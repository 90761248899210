import React, { useEffect, useState } from "react";
import { get_cam_status, restart_cam } from "../Service";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { FaSync } from "react-icons/fa";

export const ControlModule = () => {
  const [moduleStatus, setmoduleStatus] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    get_cam_status().then((res) => {
      setmoduleStatus(res.data.status);
    });
  });

  return (
    <div>
      <NotificationContainer />
      <form onSubmit={handleSubmit}>
        {/* <div className="form-group row justify-content-center">
          <div className="col-md-10">
            <p>Status: {moduleStatus}</p>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => {
                get_cam_status().then((res) => {
                  setmoduleStatus(res.data.status);
                });
              }}
            >
             <FaSync aria-hidden="true" /> Check
            </button>
          </div>
        </div>
        <br />
        <div className="row justify-content-center">
          <div className="">
            <button
              type="button"
              size="lg"
              id="btn"
              onClick={() => {
                restart_cam();
              }}
              className="btn btn-primary btn-block"
            >
              Restart
            </button>
          </div>
        </div> */}
        <div className="d-flex flex-column align-items-center justify-content-center ">
          <NotificationContainer />
          <form
            onSubmit={handleSubmit}
            className="w-50"
          >
            <div className="form-group text-center">
              <p>Status: {moduleStatus}</p>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  get_cam_status().then((res) => {
                    setmoduleStatus(res.data.status);
                  });
                }}
              >
                <i className="fa fa-refresh"></i> Check
              </button>
            </div>
            <br />
            <div className="text-center">
              <button
                type="button"
                size="lg"
                id="btn"
                onClick={() => {
                  restart_cam();
                }}
                className="btn btn-primary"
              >
                Restart
              </button>
            </div>
          </form>
        </div>
      </form>
    </div>
  );
};
