import { Abort } from "./AbortStates";
import { handleAutoSlideCapture, handleplaceSlideState } from "../../Service";
import { NotificationManager } from "react-notifications";
import { useParams } from "react-router-dom";
import { useContext,useState, useEffect } from "react";
import { PageContext } from "../StitchSlide";

export const PlaceSlide = ({ previous_module_status,previous_module_status_time }) => {
  const [formattedTime, setFormattedTime] = useState('');
  const { pageState } = useContext(PageContext);

  const { id, case_name } = useParams();

  const autoSlideCapture = async () => {
    try {
      let obj = {
        case_id: id,
      };

      //   setLoading(true);
      const response = await handleAutoSlideCapture(obj);
      if (response) {
        NotificationManager.success("Auto Capture in progress", "Notification", 2000);
      }

      // Handle the response, e.g., show a success message
    } catch (error) {
      console.error("Error in Auto mode:", error);
      NotificationManager.error("Something went wrong ", "Notification", 2000);
    } finally {
      //   setLoading(false);
    }
  };

  const checkForErrors = (arr) => {
    const errorSlots = arr.reduce((acc, value, index) => {
      if (value === -1) {
        acc.push(index + 1);
      }
      return acc;
    }, []);

    if (errorSlots.length === 0) {
      return (
        <div>
          <p style={{ color: "#17a2b8" }}>Last Activity: {previous_module_status} at {formattedTime}</p>
        </div>
      );
    }

    const errorMessage = `Error occurred in slot${errorSlots.length > 1 ? "s" : ""} ${errorSlots.join(" and ")}`;
    return (
      <div>
        <p style={{ color: "red" }}> Last Activity: {errorMessage} at {formattedTime}</p>
      </div>
    );
  };

  useEffect(() => {
    const is24hrFormat = localStorage.getItem('24hrFormat') === 'true';

    // Extract time and date from the previous_module_status_time
    const [time, date] = previous_module_status_time.split(' ');

    let [hours, minutes, seconds] = time.split(':');

    // Convert to 12-hour format if 24hrFormat is false
    if (!is24hrFormat) {
      const suffix = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12 || 12; // Convert "00" to "12" for midnight
      setFormattedTime(`${hours}:${minutes}:${seconds} ${suffix} ${date}`);
    } else {
      // Keep the time in 24-hour format
      setFormattedTime(`${time} ${date}`);
    }
  }, [previous_module_status_time]);


  return (
    <div>
      <div className="mt-5 ml-5 mr-5">
      {previous_module_status && checkForErrors(pageState.slot_status)}
        <h3>Place Slide and Click Ok</h3> <br />
        <div className=" d-flex flex-row justify-content-center">
          <div
            style={{ marginTop: "10px", textAlign: 'center'  }}
          >
            <Abort />
          </div>
          <div
          className="ms-5 me-5"
            style={{ marginTop: "10px",textAlign: 'center'  }}
          >
            <button
              className="btn btn-primary custom-button-width"
              onClick={() =>
                handleplaceSlideState().then((res) => {
                  if (res.data && res.data.msg) {
                    NotificationManager.success(res.data.msg, "", 2000);
                  }
                })
              }
              style={{ width: "150px" }}
            >
              Ok
            </button>
          </div>
          <div
            style={{ marginTop: "10px",textAlign: 'center'  }}
          >
            <button
              className="btn btn-secondary custom-button-width"
              onClick={autoSlideCapture}
              style={{ width: "150px" }}
            >
              Auto Capture
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
