import { handleAbortState } from "../Service";
import { NotificationContainer, NotificationManager } from 'react-notifications';


export const Aborting = () => {
    // const [message, setMessage] = useState('');

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         setMessage('something went wrong')
    //     }, 15000);
      
    //     return () => clearInterval(intervalId);
    //   }, []);

    return (
        <div>
            <h3>Aborting...</h3>
            <br />
            {/* {message} */}
            <button type="button" className="btn btn-danger btn-block"
                onClick={() => handleAbortState().then(res => {
                    if (res.data && res.data.msg) {
                        NotificationManager.success(res.data.msg, '', 2000);
                    }
                })}
            >Abort</button>
        </div>
    )
}
