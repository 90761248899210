import React, { useEffect, useState } from "react";
import "ol/ol.css";
import Draw, { createBox } from "ol/interaction/Draw";
import Map from "ol/Map";
import View from "ol/View";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import ImageLayer from "ol/layer/Image";
import Projection from "ol/proj/Projection";
import Static from "ol/source/ImageStatic";
import { getCenter } from "ol/extent";
import Polygon from "ol/geom/Polygon";
import Feature from "ol/Feature";
import Select from "ol/interaction/Select";
import { Circle, Fill, Stroke, Style } from "ol/style";
import Point from "ol/geom/Point";
import { Modify } from "ol/interaction";
import { Abort } from "./AbortStates";
import { environment } from "../../../../environment";
import { getCanvasImage, getCanvasData, handleRectCoordinates, handleplaceSlideState } from "../../Service";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { getSelectedChipset } from "../../../Utils/Common";
import { FaUndo, FaMinusCircle } from "react-icons/fa";

const baseURL = environment.MICALYS_POSTGRES_API;

export const Canvas = () => {
  const initialValues = {
    image: "",
    quality: "1",
    coordinates: [],
  };
  const [state, setState] = useState(initialValues);
  const [slideName, setSlideName] = useState("");
  const [checkedFeature, setCheckedFeature] = useState(false); //for check uncheck of "Delete feature"
  const [drawRect, setDrawRect] = useState(false); //for drawing rectangle check uncheck
  const [enableCheckbox, setEnableCheckbox] = useState(true); //for enable disable when no feature is drawn on image
  const [focusPointState, setFocusPointState] = useState([]);
  var focus_point_xy = [400, 200];
  const prepareImageCrop = (img, blobURL, drawcoords) => {
    var img_h = 0;
    img.src = blobURL;

    img.onload = function () {
      var extent = [0, 0, img.width, img.height];
      img_h = img.height;
      //****************************************step:2 define objects of initialized map start***************************************//
      var projection = new Projection({
        code: "xkcd-image",
        units: "pixels",
        extent: extent,
      });

      var IMG_Layer = new ImageLayer({
        source: new Static({
          attributions: '© <a href="http://xkcd.com/license.html">xkcd</a>',

          url: baseURL + "get_ws_image",

          imageLoadFunction: function (image, src) {
            image.getImage().src = blobURL;
          },
          imageExtent: extent,
        }),
      });

      var source1 = new VectorSource({ wrapX: false }); //this is the over lay draw

      var vector1 = new VectorLayer({
        source: source1,
      });

      var source = new VectorSource({ wrapX: false });
      var colorList = ["#Daa520", "#808000", "#7fff00", "#F88379", "#40e0d0", "#Ff00ff", "#4b0082", "#003153", "#800020", "#Ff1493"];

      let colorString;
      function setColor() {
        colorString = colorList[Math.floor(Math.random() * colorList.length)];
        return colorString;
      }
      var vector = new VectorLayer({
        source: source,
      });
      //****************************************step:2 define objects of initialized map end*******************************************//

      //*****************************************step:1 Initialize map start**********************************************************//
      var map = new Map({
        layers: [IMG_Layer, vector, vector1],
        target: "map",
        view: new View({
          projection: projection,
          center: getCenter(extent),
          zoom: 1,
        }),
        controls: [],
      });

      var modify = new Modify({ source: source });
      map.addInteraction(modify);
      //*****************************************step:1 Initialize map end**********************************************************//

      var draw;
      let mode = "draw";
      var geometryFunction;
      var value;
      var pointF;
      //******************************************step:3 Add interactiom start******************************************************//
      function addInteraction(val) {
        value = val;
        if (value === "Box") {
          value = "Circle";
          geometryFunction = createBox();
        } else {
          value = "Polygon";
          geometryFunction = "";
        }
        draw = new Draw({
          source: source,
          type: value,
          geometryFunction: geometryFunction,
        });

        draw.on("drawend", function (event) {
          // console.log("featureonDrawEnd", event.feature.getGeometry().getCoordinates())
          if (value === "Polygon") {
            //for setting style on innner feature i.e. inner polygon
            setColor();
            var style = new Style({
              stroke: new Stroke({
                color: colorString,
                width: 3,
              }),
              fill: new Fill({
                color: "rgba(0, 0, 255, 0.1)",
              }),
            });
            event.feature.setStyle(style);
          }
        });
        map.addInteraction(draw);
      }
      //******************************************step:3 Add interactiom end******************************************************//

      //************************ drawExtent() common function to be called on all events when feature is drawn or removed start***********//
      function drawExtent() {
        //Note:source is what we drawn i.e polygon & source1 is the bouding box around drawn feature ie. source
        source1.clear();

        var features = source.getFeatures();
        if (!features.length) {
          setEnableCheckbox(true); //If there are 0 features left on map then change mode to draw
          setCheckedFeature(false);
          setState({ ...state, coordinates: [] });
          var interactions = map.getInteractions();

          map.removeInteraction(selectClick);
          mode = "draw";
          for (var i = 0; i < interactions.getLength(); i++) {
            var interaction = interactions.item(i);
            interaction.setActive(true);
          }
        }

        if (features.length > 0) {
          setEnableCheckbox(false); //If there are 0 features left on map then change mode to draw
        }

        //for making bounding box i.e source1
        for (let f in features) {
          var [x1, y2, x2, y1] = features[f].getGeometry().getExtent();

          var pol = new Polygon([
            [
              [x1, y1],
              [x1, y2],
              [x2, y2],
              [x2, y1],
            ],
          ]);
          var f1 = new Feature({
            geometry: pol,
          });

          f1.setStyle(
            new Style({
              //for making rectangle boundary of drawn polygon
              fill: new Fill({
                color: "rgba(100,100,0,0.2)",
              }),
              stroke: new Stroke({
                color: "blue",
                width: 1.25,
              }),
            })
          );

          if (mode === "draw") {
            source1.addFeature(f1); //adding bounding box around source and on map
          }
        }

        var out = [];
        let contourLineData = null;

        //calculation to get (x,y,h,w,contourdata)
        for (let f in features) {
          // let val = features[f].getGeometry().getCoordinates();
          // contourLineData = JSON.stringify(val[0]); //for contour data
          if (features[f].getGeometry().getType() === "Point") {
            //get coordinates of point
            setFocusPointState(features[f].getGeometry().getCoordinates());
          }

          if (features[f].getGeometry().getType() === "Polygon") {
            let val = features[f].getGeometry().getCoordinates();
            contourLineData = JSON.stringify(val[0]); //for contour data
            var arr = features[f].getGeometry().getExtent(); //for ???
            var X1 = Math.floor(arr[0]);
            var Y2 = img_h - Math.floor(arr[1]);
            var X2 = Math.floor(arr[2]);
            var Y1 = img_h - Math.floor(arr[3]);

            var [x, y, w, h] = [X1, Y1, X2 - X1, Y2 - Y1];

            out.push({
              x: x,
              y: y,
              w: w,
              h: h,
              contourLineData: contourLineData,
            });
            // console.log("out to check contour in box", out)
            setState({ ...state, coordinates: out });
          }
        }
      }
      //************************ drawExtent() common function to be called on all events when feature is drawn or removed start***********//

      // ****************************************step:4 Associated events for drawn feature start**************************************//
      vector.getSource().on("addfeature", function (evt) {
        // var feature = evt.feature;
        // console.log("feature", feature)
        // var coords = feature.getGeometry().getCoordinates()
        // console.log("coords", coords)
        drawExtent();
      });
      vector.getSource().on("removefeature", function (evt) {
        drawExtent();
      });
      vector.getSource().on("changefeature", function (evt) {
        drawExtent();
      });

      // ****************************************step:4 Associated events for drawn feature start**************************************//

      // ***************************************************step 5:Undu & Delete Feature Start*************************************************************************//
      document.getElementById("undo").addEventListener("click", function () {
        draw.removeLastPoint();
      });

      document.getElementById("removeLast").addEventListener("click", function (event) {
        var features = source.getFeatures();
        if (features.length > 0) {
          features.forEach((el) => {
            return source.removeFeature(el);
          });
          setState({ ...state, coordinates: [] });
        }
        showFocusPoint();
      });

      let selectedFeature = null;
      let selectClick = null;

      let isDrawRectFeatureChecked = false;

      document.getElementById("deleteFeature").addEventListener("change", function (event) {
        var interactions = map.getInteractions();
        var features = source.getFeatures();
        mode = "modify";

        if (event.target.checked && mode === "modify") {
          //set interaction inactive on map & mode to modify if checkbox is checked
          mode = "modify";

          for (var i = 0; i < interactions.getLength(); i++) {
            var interactionData = interactions.item(i);
            interactionData.setActive(false);
          }

          //clear bounding box (outer feature)
          source1.clear();

          //####### add Select on map start //#######

          selectClick = new Select({
            condition: function (event) {
              return event.type === "click";
            },
            style: new Style({
              stroke: new Stroke({
                color: "#3399CC",
                width: 1.25,
              }),
              fill: new Fill({
                color: "rgba(255,255,255,0.4)",
              }),
            }),
          });

          map.addInteraction(selectClick);

          selectClick.on("select", function (event) {
            // console.log("mode on select feature", mode)

            if (event.selected.length > 0) {
              selectedFeature = event.selected[0];

              if (mode === "modify") {
                source.removeFeature(selectedFeature);
              }
              if (selectedFeature.getGeometry().getType() === "Point") {
                showFocusPoint();
              }
            }
          });
          //####### add Select on map end //#######
        } else {
          // ##### set interaction active on map & mode to draw if checkbox is unchecked ##### //
          mode = "draw";

          for (var j = 0; j < interactions.getLength(); j++) {
            let interactionData = interactions.item(j);
            interactionData.setActive(true);
          }
          selectClick = null;
          drawExtent();
          if (features.length) {
            if (isDrawRectFeatureChecked) {
              map.removeInteraction(draw);
              value = "Box";
              addInteraction(value);
            } else {
              map.removeInteraction(draw);
              value = "Polygon";
              addInteraction(value);
            }
          }
          if (!features.length) {
            map.removeInteraction(selectClick);
          }
        }
      });
      document.getElementById("drawRect").addEventListener("change", function (event) {
        if (event.target.checked) {
          isDrawRectFeatureChecked = true;
          if (mode === "draw") {
            map.removeInteraction(draw);
            value = "Box";
            addInteraction(value);
          }
        }
        if (!event.target.checked) {
          isDrawRectFeatureChecked = false;
          if (mode === "draw") {
            map.removeInteraction(draw);
            value = "Polygon";
            addInteraction(value);
          }
        }
      });
      // ***************************************************step:5 Undu & Delete Feature End*************************************************************************//

      addInteraction("Polygon"); // START POINT (1) if this won't call then cursor would not be in draw mode

      //step 1.1****************************** draw features on map with given coordinates start************************************//

      // console.log("drawcoords", drawcoords)
      if (drawcoords.length > 0) {
        drawcoords.map((el) => {
          var feature = new Feature({
            geometry: new Polygon([el]),
          });
          return vector.getSource().addFeature(feature);
        });
      }

      function showFocusPoint() {
        pointF = new Feature({
          geometry: new Point(focus_point_xy),
        });
        pointF.setStyle(
          new Style({
            image: new Circle({
              radius: 5,
              fill: new Fill({
                color: "red",
              }),
            }),
          })
        );

        return vector.getSource().addFeature(pointF);
      }
      showFocusPoint();

      //step 1.1****************************** draw features on map with given coordinates end************************************//
    };
  };

  useEffect(() => {
    getCanvasImage().then(async (res) => {
      if (res && res.data && res.data.byteLength > 0) {
        let blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });
        let blobURL = URL.createObjectURL(blob);
        var img = new Image();

        await setState({ ...state, image: blobURL });

        getCanvasData().then((res) => {
          if (res && res.data) {
            setSlideName(res.data.slidename);

            let coordsArr = [];
            if (res.data.drawcoords.length > 0) {
              coordsArr = [...res.data.drawcoords];
            }
            if (res.data.focus_point && res.data.focus_point.length > 0) {
              focus_point_xy = [...res.data.focus_point];
            }
            prepareImageCrop(img, blobURL, coordsArr);
          }
        });

        // await prepareImageCrop(img, blobURL)
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="container-fluid">
      {/* <NotificationContainer /> */}

      <div style={{ marginTop: "12px" }}>
        <div className="row justify-content-between">
          <div className="col-md-7 col-sm-12">
            <div className="row">
              <div
                className="col-12 "
                style={{ paddingBottom: "10px" }}
              >
                <div className="d-flex flex-row justify-content-end">
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-3">
                    <input
                      type="checkbox"
                      id="deleteFeature"
                      className="form-check-input"
                      disabled={enableCheckbox}
                      checked={checkedFeature}
                      onChange={(e) => {
                        setCheckedFeature(e.target.checked);
                      }}
                    />
                    <label
                      className="form-check-label"
                      style={{ fontSize: "13px" }}
                    >
                      Select Feature to Delete
                    </label>
                  </div>

                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-3">
                    <input
                      type="checkbox"
                      id="drawRect"
                      className="form-check-input"
                      checked={drawRect}
                      onChange={(e) => {
                        setDrawRect(e.target.checked);
                      }}
                    />
                    <label
                      className="form-check-label"
                      style={{ fontSize: "13px" }}
                    >
                      Draw Rect
                    </label>
                  </div>

                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-3">
                    <button
                      className="btn btn-light btn-sm btn-block"
                      id="undo"
                      style={{ fontSize: "12px" }}
                    >
                      <FaUndo
                        aria-hidden="true"
                        // size="lg"
                      />
                      &nbsp; Point
                    </button>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-3">
                    <button
                      className="btn btn-light btn-sm btn-block"
                      id="removeLast"
                      style={{ fontSize: "12px" }}
                    >
                      {" "}
                      <FaMinusCircle
                        aria-hidden="true"
                        // size="lg"
                      />
                      &nbsp; Clear all
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div
                  id="map"
                  className=""
                  style={{
                    width: "100%",
                    height: "400px",
                    border: "1px solid gray",
                  }}
                ></div>
              </div>
            </div>
            <br />

            <div className="d-flex ">
              <div
                className="col-md-2 col-sm-4"
                style={{ marginTop: "10px" }}
              >
                <Abort />
              </div>
              <div
                className="col-md-2 col-sm-4 "
                style={{ marginTop: "10px" }}
              >
                <button
                  type="button"
                  className="btn btn-success btn-block"
                  onClick={() =>
                    handleplaceSlideState().then((res) => {
                      if (res.data.msg) {
                        NotificationManager.success(res.data.msg, "", 2000);
                        console.log("canvas");
                      }
                    })
                  }
                  style={{ width: "150px" }}
                >
                  Retake
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            {/* <a href="/api/livecontrol" target="_blank" style={{ float: 'right' }}> Live Image</a> */}
            <h3>Select the area to capture</h3> <br />
            <div className="row">
              <div className="col-md-10 col-sm-11 offset-sm-1">
                <form>
                  <div className="form-group row justify-content-around">
                    <div className="col-md-10">
                      <input
                        type="text"
                        id="slidename"
                        value={slideName}
                        onChange={(e) => {
                          setSlideName(e.target.value);
                        }}
                        className="form-control"
                        placeholder="Please enter slide name"
                      />
                    </div>
                  </div>

                  <div className="form-group mb-3 mt-3">
                    <div className="d-flex light-gray-strip">
                      <label
                        htmlFor="inputPassword3"
                        className="col-md-4 col-lg-3 col-form-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Choose Quality:
                      </label>

                      <div className="d-flex justify-content-between flex-row col-md-8 col-lg-7 mt-2">
                        <div className="col-md-4 col-lg-4 col-sm-12">
                          <div className="form-check pull-left">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="quality"
                              id="check1"
                              value="1"
                              checked={state.quality === "1"}
                              onChange={(e) => {
                                setState({
                                  ...state,
                                  quality: e.target.value,
                                });
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="check1"
                            >
                              Standard
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3 col-lg-3 col-sm-12">
                          <div className="form-check pull-left">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="quality"
                              id="check2"
                              value="2"
                              checked={state.quality === "2"}
                              onChange={(e) => {
                                setState({
                                  ...state,
                                  quality: e.target.value,
                                });
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="check2"
                            >
                              High
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3 col-lg-3 col-sm-12">
                          <div className="form-check pull-left">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="quality"
                              id="check3"
                              value="3"
                              checked={state.quality === "3"}
                              onChange={(e) => {
                                setState({
                                  ...state,
                                  quality: e.target.value,
                                });
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="check3"
                            >
                              Best
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="row justify-content-center">
                  <div className="col-md-6 col-sm-4">
                    <button
                      type="button"
                      size="lg"
                      id="btn"
                      className="btn btn-primary btn-block"
                      onClick={() => {
                        // handleRectCoordinates(state.coordinates, slideName, state.quality)
                        let dataArr = state.coordinates;
                        let slideNameValue = slideName;
                        let quality = state.quality;

                        if (dataArr.length > 0) {
                          if (slideNameValue !== "") {
                            let obj = {
                              slide_name: slideNameValue,
                              q: parseInt(quality),
                              cropArea: dataArr,
                              focus_point: [{ x: focusPointState[0], y: focusPointState[1] }],
                              selectedChipset: getSelectedChipset(),
                            };
                            // console.log("FINAL", obj)
                            // return await axios.post(baseURL + '3', obj, { headers: { Authorization: `Bearer ${getToken()}` } })
                            handleRectCoordinates(obj);
                          } else {
                            NotificationManager.error("SlideName Is Required!!", "", 2000);
                          }
                        } else {
                          NotificationManager.error("Feature Is Required!!", "", 2000);
                        }
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <p
              name="textout"
              id="textout"
            ></p>
          </div>
        </div>
      </div>
    </div>
  );
};
