import React, { useContext } from "react";
import { PageContext } from "../../CaseViewer";
import DrawFeaturesComponent from "./DrawFeaturesComponent";
import AnnotationCard from "./AnnotationCard";

const AnnotationList = ({handleShow}) => {
  const { state } = useContext(PageContext);

  return (
    <div>
      <hr className="m-1" />
      <DrawFeaturesComponent />
      <div style={{ maxHeight: '300px', overflowY: 'auto'}}>
      {(state.annotation_list.length !== 0) && state.annotation_list.map((data) => (
        <AnnotationCard key={data.annotationId}  data={data} handleShow={handleShow}/>
      ))}
      </div>
    </div>
  );
};

export default AnnotationList;
