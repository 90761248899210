import { Feature, Overlay } from "ol";
import TileState from "ol/TileState";
import View from "ol/View";
import { OverviewMap } from "ol/control";
import { Circle as CircleGeometry, LineString, Polygon } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import TileLayer from "ol/layer/WebGLTile.js";
import { Projection } from "ol/proj";
import { getVectorContext } from "ol/render";
import VectorSource from "ol/source/Vector";
import Zoomify from "ol/source/Zoomify";
import { Fill, Stroke, Style } from "ol/style";
import { useContext, useEffect, useRef, useState } from "react";
import { environment } from "../../../../../environment";
import { PageContext } from "../../CaseViewer";
import MapContext from "../Map/MapContext";
import { getViewTracking, updateViewTracking } from "../../../Service";
import axios from "axios";

const TileDependentLayer = () => {
  const [boxLayerInState, setBoxLayer] = useState(null);
  const [circleLayer, setCircleLayer] = useState(null);
  const [gridBoxLayerInState, setGridBoxLayer] = useState(null);
  const [offsetCoordinate, setOffsetCoordinate] = useState({});
  const { state, pageDispatcher } = useContext(PageContext);
  const { id, token, scale_data } = state.selected_slide;
  const { imgHeight, imgWidth, tileSize } = state.selected_img_properties;
  // const { length80X, length60X, length40X, length20X, length10X, length4X } = state.coordinatesLen;
  const { map } = useContext(MapContext);
  const source = useRef(null);
  const tileLayer = useRef(null);
  const overviewMapControl = useRef(null);
  const magnifyOverviewMapControl = useRef(null);
  const colorBoxLayer = useRef(null);
  const proj = useRef(null);
  // const magnifyOverlay = useRef(null);
  // const [overlay, setOverlay] = useState(null);
  const { current_Question_key } = state.multi_question_answer;
  const [getExtentCoordinate, setGetExtentCoordinate] = useState([]);
  // console.log("getExtentCoordinate:", getExtentCoordinate);
  const zoomLevels = ["80X", "60X", "40X", "20X", "10X", "4X"];

  const [extentCoordinates, setExtentCoordinates] = useState({});
  const [colorBoxFeatures, setColorBoxFeatures] = useState({});
  // console.log("colorBoxFeatures:", colorBoxFeatures);

  const [layers, setLayers] = useState([]);
  const [currentLayer, setCurrentLayer] = useState(0);
  const zoomLevelCache = {};
  const zoomLevelRanges = [
    { minResolution: 0, maxResolution: 1.972748815165877, label: "80X" },
    { minResolution: 1.972748815165877, maxResolution: 2.9324644549763033, label: "60X" },
    { minResolution: 2.9324644549763033, maxResolution: 5.8649289099526065, label: "40X" },
    { minResolution: 5.8649289099526065, maxResolution: 11.729857819905213, label: "20X" },
    { minResolution: 11.729857819905213, maxResolution: 29.324644549763033, label: "10X" },
    { minResolution: 29.324644549763033, maxResolution: 10000, label: "4X" },
  ];
  let zoomChangeTimeout;

  // Initialize refs to store coordinates for different zoom levels
  const coordinateRefs = {
    "4X": useRef([]),
    "10X": useRef([]),
    "20X": useRef([]),
    "40X": useRef([]),
    "60X": useRef([]),
    "80X": useRef([]),
  };

  // Function to update coordinates for a specific zoom level
  function updateCoordinates(zoomLevel, newCoordinate) {
    // coordinateRefs[zoomLevel].current = [...coordinateRefs[zoomLevel].current, newCoordinate];
    if (coordinateRefs.hasOwnProperty(zoomLevel)) {
      const refObject = coordinateRefs[zoomLevel];
      const currentCoordinates = refObject.current;
      refObject.current = [...currentCoordinates, newCoordinate];
    } else {
      console.error(`Invalid zoomLevel: ${zoomLevel}`);
    }
  }

  useEffect(() => {
    // console.log("colorBoxFeatures:",colorBoxFeatures)
    // Initialize extentCoordinates and colorBoxFeatures
    const initialExtentCoordinates = {};
    const initialColorBoxFeatures = {};

    zoomLevels.forEach((zoom) => {
      initialExtentCoordinates[zoom] = [];
      initialColorBoxFeatures[zoom] = [];
    });

    setExtentCoordinates(initialExtentCoordinates);
    setColorBoxFeatures(initialColorBoxFeatures);
  }, [state.colorBox.delete]);

  useEffect(() => {
    coordinateRefs["4X"].current = [];
    coordinateRefs["10X"].current = [];
    coordinateRefs["20X"].current = [];
    coordinateRefs["40X"].current = [];
    coordinateRefs["60X"].current = [];
    coordinateRefs["80X"].current = [];
    if (colorBoxLayer.current) colorBoxLayer.current.getSource().clear();
  }, [state.colorBox.delete]);

  let variable;
  const updates = {};

  function setStyleToTiles(variables, layer) {
    for (variable in variables) {
      const name = variable;
      const element = document.getElementById(name);

      const value = variables[name];
      element.value = value.toString();
      document.getElementById(name + "-value").innerText = value.toFixed(2);

      const listener = function (event) {
        const value = parseFloat(event.target.value);
        document.getElementById(name + "-value").innerText = value.toFixed(2);
        updates[name] = value;
        layer.updateStyleVariables(updates);
      };
      element.addEventListener("input", listener);
      element.addEventListener("change", listener);

      document.getElementById("resettilestyle").addEventListener("click", () => {
        element.value = "0.00";
        document.getElementById(name + "-value").innerText = "0.00";
        updates[name] = 0;
        layer.updateStyleVariables(updates);
      });
    }
  }

  const handleKeyboardEvent = (event) => {
    const view = map.getView();
    const overviewMapElement = document.querySelector(".ol-overviewmap-box");

    switch (event.key) {
      case "ArrowUp":
        view.setCenter([view.getCenter()[0], view.getCenter()[1] + 100]);
        break;
      case "ArrowDown":
        view.setCenter([view.getCenter()[0], view.getCenter()[1] - 100]);
        break;
      case "ArrowRight":
        view.setCenter([view.getCenter()[0] + 100, view.getCenter()[1]]);
        break;
      case "ArrowLeft":
        view.setCenter([view.getCenter()[0] - 100, view.getCenter()[1]]);
        break;
      default:
        break;
    }
    if (overviewMapElement) {
      // overviewMapElement.style.backgroundColor = 'blue';
      overviewMapElement.style.backgroundColor = "#ffffff00";
    }
  };

  const createZoomifySource = (layer) => {
    return new Zoomify({
      url: `${environment.MICALYS_API_V2}slidedata/${id}/${layer}/`,
      // url: `${environment.MICALYS_POSTGRES_API}slidedata/${id}/${layer}/`,
      size: [imgWidth, imgHeight],
      crossOrigin: "anonymous",
      zDirection: -1,
      tileSize: tileSize / 1,
      tilePixelRatio: 1,
    });
  };

  const updateLayers = () => {
    const newLayers = [];
    const layerInfo = [];
    let n = state.selected_slide.numOfLayer;
    for (let i = -Math.floor(n / 2); i <= Math.floor(n / 2); i++) {
      if (i !== 0) {
        layerInfo.push({ id: i, zIndex: i + Math.floor(n / 2), tagName: i });
      }
    }

    layerInfo.forEach((info) => {
      const source = createZoomifySource(info.id);

      source.setTileLoadFunction(async function (tile, src) {
        if (tile.getState() === TileState.LOADED) {
          return;
        }

        try {
          const response = await axios.get(src, {
            responseType: "blob",
            headers: {
              Authorization: "Bearer " + token,
            },
          });

          if (response.status === 200) {
            const data = response.data;
            tile.getImage().src = URL.createObjectURL(data);
          } else {
            tile.setState(TileState.ERROR);
          }
        } catch (error) {
          tile.setState(TileState.ERROR);
        }
      });
      const layer = new TileLayer({
        source: source,
        zIndex: info.zIndex,
        tagName: info.tagName,
        visible: false,
      });
      newLayers.push(layer);
    });
    setLayers(newLayers);
    return newLayers;
  };

  useEffect(() => {
    if (state.isSingleMode) {
      console.log("console single layer");
      pageDispatcher.set_zStackValue(0);

      layers.forEach((layer) => {
        layer.setVisible(false);
      });
    } else {
      console.log("console multiple layer");
      layers.forEach((layer) => {
        layer.setVisible(true);
      });
    }
  }, [state.isSingleMode]);

  const switchLayer = (tagName) => {
    // Get the index of the layer with the specified tag name.
    const layerIndex = layers.findIndex((layer) => layer.get("tagName") === tagName);
    console.log("tagName layerIndex", layerIndex);
    if (tileLayer.current) {
      // const name = tileLayer.current.get("tagName");
      if (tagName === tileLayer.current.get("tagName")) {
        tileLayer.current.setZIndex(50);
      } else {
        tileLayer.current.setZIndex(49);
      }
    }

    if (layerIndex !== -1) {
      layers.forEach((layer) => {
        if (Number(layer.getZIndex()) === 50) layer.setZIndex(49);
      });
      layers[layerIndex].setZIndex(50);

      layers.forEach((layer) => {
        console.log("layer.getZIndex()", layer.getZIndex(), layer.get("tagName"), tagName);
      });
    }
  };

  useEffect(() => {
    if (layers.length > 0) {
      switchLayer(state.zStackValue);
    }
  }, [state.zStackValue, layers]);

  useEffect(() => {
    if (!map) return;
    const layers = updateLayers();
    map.getLayers().extend(layers);
  }, [map]);

  useEffect(() => {
    if (!tileSize) return;
    source.current = new Zoomify({
      // url: environment.MICALYS_POSTGRES_API + "slidedata/" + id + "/",
      url: environment.MICALYS_API_V2 + "slidedata/" + id + "/",
      size: [imgWidth, imgHeight],
      crossOrigin: "anonymous",
      zDirection: -1,
      tileSize: tileSize / 1,
      tilePixelRatio: 1,
    });

    const variables = {
      exposure: 0,
      contrast: 0,
      saturation: 0,
      brightness: 0,
    };

    var extent = source.current.getTileGrid().getExtent();
    // console.log("extent:", extent);
    // source.current.setTileLoadFunction(function (tile, src) {
    //   var xhr = new XMLHttpRequest();
    //   xhr.responseType = "blob";
    //   xhr.addEventListener("loadend", function (evt) {
    //     var data = this.response;
    //     if (data !== undefined) {
    //       tile.getImage().src = URL.createObjectURL(data);
    //     } else {
    //       tile.setState(TileState.ERROR);
    //     }
    //   });
    //   xhr.addEventListener("error", function () {
    //     tile.setState(TileState.ERROR);
    //   });
    //   xhr.open("GET", src);
    //   xhr.setRequestHeader("Authorization", "Bearer " + token);

    //   xhr.send();
    // });

    source.current.setTileLoadFunction(async function (tile, src) {
      if (tile.getState() === TileState.LOADED) {
        return;
      }

      try {
        const response = await axios.get(src, {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        if (response.status === 200) {
          const data = response.data;
          tile.getImage().src = URL.createObjectURL(data);
        } else {
          tile.setState(TileState.ERROR);
        }
      } catch (error) {
        tile.setState(TileState.ERROR);
      }
    });

    tileLayer.current = new TileLayer({
      style: {
        exposure: ["var", "exposure"],
        contrast: ["var", "contrast"],
        saturation: ["var", "saturation"],
        brightness: ["var", "brightness"],
        variables: variables,
      },
      source: source.current,
      zIndex: 50,
      tagName: 0,
    });

    let px_To_mmVal = 1 / scale_data;

    proj.current = new Projection({
      units: "pixels",
      extent: [0, 0, imgWidth, imgHeight],
      metersPerUnit: px_To_mmVal / 1000000, //(this is in micro unit)=>here converted px to mm & then mm to micro meter to get value
    });

    // overviewMapControl.current = new OverviewMap({
    //   className: "ol-overviewmap ol-custom-overviewmap",
    //   layers: [
    //     new TileLayer({
    //       source: source.current,
    //     }),
    //     // heatmapLayerGroup,
    //   ],
    //   collapseLabel: "\u00BB",
    //   label: "\u00AB",
    //   // collapsed: false,
    //   collapsible: true,
    //   // controls: Control.defaults(),
    //   view: new View({
    //     resolutions: layer.getSource().getTileGrid().getResolutions(),
    //     extent: [imgWidth, 0, 0, -imgHeight], //by adding this extent,overview map stopped shaking
    //     projection: scale_data ? proj.current : "",
    //     constrainOnlyCenter: true,
    //     maxZoom: 0,
    //     minZoom: 0,
    //     zoom: 0,
    //   }),
    // });

    // overviewMapControl.current.getOverviewMap().on("click", function (event) {
    //   map.getView().setCenter(event.coordinate);
    // });

    // map.addControl(overviewMapControl.current);
    setStyleToTiles(variables, tileLayer.current);

    map.setView(
      new View({
        resolutions: tileLayer.current.getSource().getTileGrid().getResolutions(),
        extent: extent,
        projection: proj.current,
        zoom: 7,
        constrainOnlyCenter: true,
        // center: [500000, 6000000],
      })
    );
    map.getView().fit(extent, map.getSize());

    map.addLayer(tileLayer.current);

    const handleZoomChange = () => {
      const resolution = map.getView().getResolution();
      let resScale = scale_data / 5.275;
      if (resolution <= Math.round(5.5 * resScale)) {
        pageDispatcher.set_Layer_Mode(false);
        console.log("console multiple layer");
      } else {
        pageDispatcher.set_Layer_Mode(true);
        console.log("console single layer");
      }
    };

    if (state.selected_slide.numOfLayer !== 1) {
      map.getView().on("change:resolution", handleZoomChange);
    }

    return () => {
      if (map) {
        map.removeLayer(tileLayer.current);
        map.getView().un("change:resolution", handleZoomChange);
        // map.removeControl(overviewMapControl.current);
      }
    };
  }, [map]);

  useEffect(() => {
    if (!source.current) return;
    if (state.selectedMagnifyVisibility) {
      magnifyOverviewMapControl.current = new OverviewMap({
        className: "ol-overviewmap ol-magnify-overview-map",
        layers: [
          new TileLayer({
            source: source.current,
          }),
        ],
        collapsible: true,
        collapsed: !state.selectedMagnifyVisibility,
        view: new View({
          projection: scale_data ? proj.current : "",
        }),
      });

      map.addControl(magnifyOverviewMapControl.current);
    }

    return () => {
      if (map) {
        map.removeControl(magnifyOverviewMapControl.current);
      }
    };
  }, [map, state.selectedMagnifyVisibility]);

  useEffect(() => {
    if (!source.current) return;
    if (state.colorBox.togglePopup) {
      overviewMapControl.current = new OverviewMap({
        className: "ol-overviewmap ol-custom-overviewmap",
        layers: [
          new TileLayer({
            source: source.current,
          }),
        ],
        collapseLabel: "\u00BB",
        label: "\u00AB",
        collapsible: true,
        collapsed: !state.colorBox.togglePopup,
        view: new View({
          resolutions: tileLayer.current.getSource().getTileGrid().getResolutions(),
          extent: [imgWidth, 0, 0, -imgHeight],
          projection: scale_data ? proj.current : "",
          constrainOnlyCenter: true,
          maxZoom: 0,
          minZoom: 0,
          zoom: 0,
        }),
      });

      overviewMapControl.current.getOverviewMap().on("click", function (event) {
        map.getView().setCenter(event.coordinate);
      });

      map.addControl(overviewMapControl.current);
    }
    return () => {
      if (map) {
        map.removeControl(overviewMapControl.current);
      }
    };
  }, [map, state.colorBox.togglePopup]);

  const sendViewTracking = async (obj, token) => {
    try {
      const response = await updateViewTracking(obj, token);
      // console.log("Data sent to backend:", response.data);

      if (response.data.message || response.status === 200) {
        const lensData = response.data.lensData;
        const coordinateProperties = ["80X", "60X", "40X", "20X", "10X", "4X"];

        coordinateProperties.forEach((property) => {
          if (lensData[property]) {
            const propertyName = `length${property}`;
            pageDispatcher.set_coordinatesLen({ [propertyName]: lensData[property] });
          }
        });
      }
    } catch (error) {
      console.error("Error sending data to backend:", error);
    }
  };

  useEffect(() => {
    let intervalId;
    // console.log('state.colorBox.start',state.colorBox.start)
    if (state.colorBox.start) {
      intervalId = setInterval(() => {
        const lensData = [];
        Object.keys(coordinateRefs).forEach((zoomLevel) => {
          // console.log("zoomLevel", zoomLevel);
          let coordinateArray = coordinateRefs[zoomLevel].current;
          let newcoordinateArray = coordinateArray.slice(state.coordinatesLen[`length${zoomLevel}`], coordinateArray.length + 1);
          const obj = {
            // coordinate: coordinateArray,
            coordinate: newcoordinateArray,
            lens: zoomLevel,
            totalLength: coordinateArray.length,
          };
          lensData.push(obj);
        });
        sendViewTracking({ data: lensData }, token);
      }, 15000);
    }

    return () => clearInterval(intervalId);
  }, [state.colorBox.start]);

  useEffect(() => {
    if (state.colorBox.start === false) {
      const lensData = [];
      Object.keys(coordinateRefs).forEach((zoomLevel) => {
        let coordinateArray = coordinateRefs[zoomLevel].current;
        let newcoordinateArray = coordinateArray.slice(state.coordinatesLen[`length${zoomLevel}`], coordinateArray.length + 1);
        const obj = {
          coordinate: newcoordinateArray,
          lens: zoomLevel,
          totalLength: coordinateArray.length,
        };
        lensData.push(obj);
      });
      sendViewTracking({ data: lensData }, token);
    }
  }, [state.colorBox.start]);

  useEffect(() => {
    async function fetchViewTracking() {
      try {
        const res = await getViewTracking(token);
        setGetExtentCoordinate(res.data.data);
        // pageDispatcher.set_coordinatesLen({ length80X: 0, length60X: 0, length40X: 0, length20X: 0, length10X: 0, length4X: 0 });
      } catch (error) {
        console.log(error);
      }
    }
    if (id) fetchViewTracking();
  }, [id]);

  useEffect(() => {
    const features = colorBoxLayer.current?.getSource().getFeatures();
    features?.forEach((feature) => {
      feature?.setStyle(
        new Style({
          fill: new Fill({
            color: state.colorBox.hide ? "rgba(155,154,254,0.3)" : "transparent",
          }),
        })
      );
    });
    // console.log('state.colorBox.hide',state.colorBox.hide)
  }, [state.colorBox.hide]);

  function createPolygonFromExtent(extent) {
    const polygon = new Polygon([
      [
        [extent[0], extent[1]],
        [extent[0], extent[3]],
        [extent[2], extent[3]],
        [extent[2], extent[1]],
        [extent[0], extent[1]],
      ],
    ]);
    return polygon;
  }

  function zoomLens() {
    const currentResolution = map.getView().getResolution();

    // Check if the zoom level is already in the cache
    if (zoomLevelCache[currentResolution]) {
      return zoomLevelCache[currentResolution];
    }

    let matchedZoomLevel = "fit";

    for (const range of zoomLevelRanges) {
      if (currentResolution >= range.minResolution && currentResolution <= range.maxResolution) {
        matchedZoomLevel = range.label;
        break;
      }
    }

    // Store the calculated zoom level in the cache
    zoomLevelCache[currentResolution] = matchedZoomLevel;
    return matchedZoomLevel;
  }

  function drawColorBox(extent, matchedZoomLevel) {
    // let matchedZoomLevel = zoomLens()
    // console.log("drawColorBox:",matchedZoomLevel,extent);
    const colorBoxPolygon = createPolygonFromExtent(extent);

    const colorBoxFeature = new Feature({
      geometry: colorBoxPolygon,
    });

    colorBoxFeature.setStyle(
      new Style({
        fill: new Fill({
          color: state.colorBox.hide ? "rgba(155,154,254,0.3)" : "transparent",
        }),
      })
    );

    // add colorBoxFeature to colorBoxLayer
    colorBoxLayer.current?.getSource().addFeature(colorBoxFeature);

    setColorBoxFeatures((prevColorBoxFeatures) => ({
      ...prevColorBoxFeatures,
      [matchedZoomLevel]: [...prevColorBoxFeatures[matchedZoomLevel], colorBoxFeature],
    }));
  }

  function drawZoomColorBox(extents, lens) {
    // console.log("drawZoomColorBox:",lens, extents);
    colorBoxLayer.current?.getSource().clear();
    extents?.forEach((extent) => {
      drawColorBox(extent, lens);
    });
  }

  function hideUnhideAlreadyDrawnBox(zoomlevel) {
    colorBoxLayer.current?.getSource().clear();
    Object.keys(colorBoxFeatures).forEach((level) => {
      colorBoxFeatures[level].forEach((colorBoxFeature) => {
        // const shouldHide = level !== zoomlevel || state.colorBox.hide;
        const shouldHide = level !== zoomlevel && state.colorBox.hide;
        // const shouldHide = level !== zoomlevel;
        colorBoxFeature.setStyle(
          new Style({
            fill: new Fill({
              color: shouldHide ? "transparent" : "rgba(155,154,254,0.3)",
              // color: "rgba(155,154,254,0.3)",
            }),
          })
        );
        colorBoxLayer.current?.getSource().addFeature(colorBoxFeature);
      });
    });
  }

  const optimizeHideShowBoxWithZoom = (matchedZoomLevel) => {
    // console.log("rerender -optimizeHideShowBoxWithZoom");
    let matchedExtent, matched;

    colorBoxLayer.current?.getSource().clear();
    if (getExtentCoordinate) {
      // api call we have to draw feature from extend
      matchedExtent = getExtentCoordinate[matchedZoomLevel] || [];
      matched = { coordinate: matchedExtent || [], lens: matchedZoomLevel };
    } else {
      // show and hide already drawn feature
      const coordinate = coordinateRefs[matchedZoomLevel].current;
      matched = { coordinate, lens: matchedZoomLevel };
      // hideUnhideAlreadyDrawnBox(matchedZoomLevel);
    }
    console.log("rerender optimizeHideShowBoxWithZoom:", matchedExtent);

    colorBoxLayer.current
      ?.getSource()
      .getFeatures()
      .forEach((feature) => {
        feature.setStyle(
          new Style({
            fill: new Fill({
              color: "transparent",
            }),
          })
        );
      });

    // Show the color boxes for the matched extent
    drawZoomColorBox(matchedExtent, matchedZoomLevel);
  };

  const hideShowBoxWithZoom = () => {
    const matchedZoomLevel = zoomLens();
    // console.log("rerender -hideShowBoxWithZoom:",matchedZoomLevel);
    optimizeHideShowBoxWithZoom(matchedZoomLevel);
  };

  const handleZoomChange = () => {
    // Clear any previous timeout
    if (zoomChangeTimeout) {
      clearTimeout(zoomChangeTimeout);
    }

    // Set a new timeout to determine when the zoom action has stopped
    zoomChangeTimeout = setTimeout(() => {
      hideShowBoxWithZoom();
    }, 300);
  };

  function updateColorBox() {
    if (state.colorBox.start) {
      // Get the extent of the main map
      // const matchedZoomLevel = state.lensValueSelected;
      const matchedZoomLevel = zoomLens();
      // console.log("updateColorBox matchedZoomLevel:", matchedZoomLevel);
      const mainMapExtent = map.getView().calculateExtent(map.getSize());

      // Color box doesn't exist for this extent and zoom level, create it
      if (!coordinateRefs[matchedZoomLevel].current.includes(mainMapExtent)) {
        updateCoordinates(matchedZoomLevel, mainMapExtent);
        if (state.colorBox.hide) {
          // console.log("updateColorBox drawColorBox:", matchedZoomLevel);

          drawColorBox(mainMapExtent, matchedZoomLevel);
        }
      }
      // setExtentCoordinates((prevExtentCoordinates) => ({
      //   ...prevExtentCoordinates,
      //   [matchedZoomLevel]: [...prevExtentCoordinates[matchedZoomLevel], mainMapExtent],
      // }));
      // setGetExtentCoordinate((prevExtentCoordinates) => ({
      //   ...prevExtentCoordinates,
      //   [matchedZoomLevel]: [...prevExtentCoordinates[matchedZoomLevel], mainMapExtent],
      // }));
      setGetExtentCoordinate((prevExtentCoordinates) => {
        const updatedCoordinates = { ...prevExtentCoordinates };
        // console.log("updateColorBox setGetExtentCoordinate:", prevExtentCoordinates);

        if (!updatedCoordinates.hasOwnProperty(matchedZoomLevel)) {
          // If matchedZoomLevel is not present, initialize it as an empty array
          updatedCoordinates[matchedZoomLevel] = [];
        }

        updatedCoordinates[matchedZoomLevel] = [...updatedCoordinates[matchedZoomLevel], mainMapExtent];

        return updatedCoordinates;
      });
    }
  }

  useEffect(() => {
    if (state.colorBox.hide) {
      handleZoomChange();
      map.getView().on("change:resolution", handleZoomChange);
    } else {
      map.getView().un("change:resolution", handleZoomChange);
    }
    return () => {
      map.getView().un("change:resolution", handleZoomChange);
    };
  }, [state.colorBox.hide, getExtentCoordinate]);

  // useEffect(() => {
  //   if (state.colorBox.hide) {
  //     map.getView().on("change:resolution", hideShowBoxWithZoom);
  //   } else {
  //     map.getView().un("change:resolution", hideShowBoxWithZoom);
  //   }
  //   // map.getView().on("change:resolution", hideShowBoxWithZoom);
  //   return () => {
  //     map.getView().un("change:resolution", hideShowBoxWithZoom);
  //   };
  // }, [state.colorBox.hide, getExtentCoordinate]);

  useEffect(() => {
    if (!overviewMapControl.current) return;
    colorBoxLayer.current = new VectorLayer({
      source: new VectorSource(),
      zIndex: 74,
    });

    // Add the colorBoxLayer.current to the overview map
    overviewMapControl?.current?.getOverviewMap().addLayer(colorBoxLayer.current);
  }, [id, state.colorBox.togglePopup]);

  useEffect(() => {
    if (state.colorBox.start) {
      map.on("moveend", updateColorBox);
    } else {
      map.un("moveend", updateColorBox);
    }

    return () => {
      if (map) {
        map.un("moveend", updateColorBox);
      }
    };
  }, [state.colorBox.start]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyboardEvent);
    return () => {
      if (map) {
        document.removeEventListener("keydown", handleKeyboardEvent);
      }
    };
  }, []);

  useEffect(() => {
    if (state.location.center_x && state.location.center_y && state.zoom_level_z) {
      // console.log("console state.location.center_y");
      map.getView().setCenter([state.location.center_x, state.location.center_y]);
      map.getView().setZoom(state.zoom_level_z);
      // console.log("set center and zoom level")
    }
  }, [current_Question_key]);

  let gridlayer;
  useEffect(() => {
    // console.log("selected GRID USEEFFECT", state.selectedGridBoxVisibility);
    if (!source.current || !state.selectedGridBoxVisibility) return;
    // console.log("selected GRID USEEFFECT", state.selectedGridBoxVisibility);
    gridlayer = new VectorLayer({
      scale_data: "my_gridlayer",
      // source: source,
      source: new VectorSource({
        wrapX: false,
      }),
      style: new Style({
        stroke: new Stroke({
          color: "#ffff00",
          width: 2,
          lineDash: [4, 8],
        }),
      }),
      tagName: "grid-box-layer",
      zIndex: 70,
    });

    var extent = source.current.getTileGrid().getExtent();

    let feature = new Feature({
      geometry: new Polygon([
        [
          [extent[0], extent[1]],
          [extent[0], extent[3]],
          [extent[2], extent[3]],
          [extent[2], extent[1]],
          [extent[0], extent[1]],
        ],
      ]),
    });

    gridlayer.getSource().addFeature(feature);

    gridlayer.once("postrender", function (event) {
      const gridBox = (X, Y) => {
        let lineStyle = new Style({
          stroke: new Stroke({
            color: "#aeaeae",
            width: 2,
            lineDash: [4, 8],
          }),
        });

        let offsetVX = X * scale_data;
        let offsetVY = Y * scale_data;
        var [minx, miny, maxx, maxy] = extent;
        var X1 = minx;
        var Y1 = miny;

        var features = gridlayer.getSource().getFeatures();
        features.forEach((feature) => {
          gridlayer.getSource().removeFeature(feature);
        });

        while (X1 < maxx) {
          var draw = new Feature({
            geometry: new LineString([
              [X1, miny],
              [X1, maxy],
            ]),
          });
          draw.setStyle(lineStyle);
          gridlayer.getSource().addFeature(draw);
          X1 = X1 + offsetVX;
        }

        while (Y1 < maxy) {
          var draw1 = new Feature({
            geometry: new LineString([
              [minx, Y1],
              [maxx, Y1],
            ]),
          });
          draw1.setStyle(lineStyle);
          gridlayer.getSource().addFeature(draw1);
          Y1 = Y1 + offsetVY;
        }
      };
      // console.log("selected GRID USEEFFECT", state.selectedBoxVisibility);
      map.removeLayer(gridBoxLayerInState);
      gridBox(localStorage.getItem("x"), localStorage.getItem("y"));
    });
    setGridBoxLayer(gridlayer);
  }, [map, localStorage.getItem("x"), localStorage.getItem("y"), state.selectedGridBoxVisibility]);

  let boxlayer;
  useEffect(() => {
    if (!source.current || !state.selectedBoxVisibility) return;
    // console.log("selected Box USEEFFECT", state.selectedBoxVisibility);
    boxlayer = new VectorLayer({
      name: "my_boxlayer",
      // source: source,
      source: new VectorSource({
        wrapX: false,
      }),
      style: new Style({
        stroke: new Stroke({
          color: "#ffffff",
          width: 2,
          lineDash: [4, 8],
        }),
      }),
      tagName: "box-layer",
      zIndex: 71,
    });
    var extent = source.current.getTileGrid().getExtent();

    let feature = new Feature({
      geometry: new Polygon([
        [
          [extent[0], extent[1]],
          [extent[0], extent[3]],
          [extent[2], extent[3]],
          [extent[2], extent[1]],
          [extent[0], extent[1]],
        ],
      ]),
    });

    boxlayer.getSource().addFeature(feature);

    boxlayer.on("postrender", function (event) {
      var vectorContext = getVectorContext(event);
      var viewState = event.frameState.viewState;
      var style = new Style({
        stroke: new Stroke({
          color: "black",
          width: 2,
          lineDash: [4, 8],
        }),
      });

      var center = viewState.center;

      const newCoordinates = [];
      var X = localStorage.getItem("x");
      var Y = localStorage.getItem("y");
      let offsetVX = (X * scale_data) / 2;
      let offsetVY = (Y * scale_data) / 2;
      newCoordinates.push([center[0] + offsetVX, center[1] + offsetVY]);

      newCoordinates.push([center[0] + offsetVX, center[1] - offsetVY]);

      newCoordinates.push([center[0] - offsetVX, center[1] - offsetVY]);

      newCoordinates.push([center[0] - offsetVX, center[1] + offsetVY]);
      newCoordinates.push([center[0] + offsetVX, center[1] + offsetVY]);

      var geometry = new Polygon([newCoordinates]);
      var feature = new Feature(geometry);
      vectorContext.drawFeature(feature, style);
      // console.log("selected Box useEffect", state.selectedBoxVisibility);
    });

    setBoxLayer(boxlayer);
  }, [map, localStorage.getItem("x"), localStorage.getItem("y"), state.selectedBoxVisibility]);

  useEffect(() => {
    if (!map || !state.drawCircle || !state.drawCircle.diameterValue) return;

    const radius = state.drawCircle.diameterValue / 2;

    const circleSource = new VectorSource({
      wrapX: false,
    });

    var extent = source.current.getTileGrid().getExtent();

    const boundingBoxFeature = new Feature({
      geometry: new Polygon([
        [
          [extent[0], extent[1]],
          [extent[0], extent[3]],
          [extent[2], extent[3]],
          [extent[2], extent[1]],
          [extent[0], extent[1]],
        ],
      ]),
    });

    circleSource.addFeature(boundingBoxFeature);

    const newCircleLayer = new VectorLayer({
      source: circleSource,
      style: new Style({
        stroke: new Stroke({
          // color: "#ffffff",
          color: "#000000",
          width: 2,
          lineDash: [4, 8],
        }),
      }),
      tagName: "lens-circle",
      zIndex: 73,
      // visible: state.drawCircle.status
    });

    newCircleLayer.on("postrender", function (event) {
      const vectorContext = getVectorContext(event);
      const viewState = event.frameState.viewState;
      const style = new Style({
        stroke: new Stroke({
          color: "gray",
          width: 5,
        }),
      });

      const center = viewState.center;
      const geometry = new CircleGeometry(center, radius);
      const feature = new Feature(geometry);
      vectorContext.drawFeature(feature, style);
    });

    if (circleLayer) {
      map.removeLayer(circleLayer);
    }
    if (!state.drawCircleStatus) map.removeLayer(circleLayer);

    setCircleLayer(newCircleLayer);
    // map.addLayer(newCircleLayer);
    if (state.drawCircleStatus) {
      map.addLayer(newCircleLayer);
    } 

    return () => {
      if (circleLayer) map.removeLayer(circleLayer);
      if (!state.drawCircleStatus) map.removeLayer(circleLayer);
    };
  }, [map, state.drawCircle.diameterValue,state.drawCircleStatus]);

  useEffect(() => {
    if (state.selectedBoxVisibility && boxLayerInState) {
      map.addLayer(boxLayerInState);
      console.log("annotationBoxlayer addLayer enter");
    }
  }, [boxLayerInState]);

  useEffect(() => {
    if (!state.selectedBoxVisibility) {
      map.removeLayer(boxLayerInState);
      // console.log("annotationBoxlayer removeLayer enter");
    }
  }, [state.selectedBoxVisibility]);

  useEffect(() => {
    if (state.selectedGridBoxVisibility && gridBoxLayerInState) {
      map.addLayer(gridBoxLayerInState);
    }
  }, [gridBoxLayerInState]);

  useEffect(() => {
    if (!state.selectedGridBoxVisibility) {
      map.removeLayer(gridBoxLayerInState);
    }
  }, [state.selectedGridBoxVisibility]);

  useEffect(() => {
    if (!map) {
      console.error("map is not available in useEffect");
      return;
    }

    const view = map.getView();
    if (!view) {
      console.error("view is not available in useEffect");
      return;
    }
    // const magnifyOverlay = new Overlay({
    //   element: document.getElementById("magnifier"),
    //   offset: [0, 0],
    //   positioning: "center-center",
    // });

    // map.addOverlay(magnifyOverlay);
    // setOverlay(magnifyOverlay);

    if (state.selectedMagnifyVisibility) {
      map.on("pointermove", handlePointerMove);
      // map.on("pointerdrag", handlePointerMove);

      // map.on("moveend", handlePointerMove);
      // view.on("change:resolution", handlePointerMove);
      // map.getView().on("change:resolution", handlePointerMove);
    }

    return () => {
      if (map) {
        map.un("pointermove", handlePointerMove);
        // map.un("pointerdrag", handlePointerMove);
        // map.un("moveend", handlePointerMove);
        // view.un("change:resolution", handlePointerMove);
        // map.getView().un("change:resolution", handlePointerMove);
      }
    };
  }, [map, state.selectedMagnifyVisibility]);

  const handlePointerMove = (event) => {
    const coordinate = event.coordinate;
    const xOffset = 0;
    const yOffset = 0;
    // const zoomOffset = state.zoomOffSetValue;

    const pixel = map.getPixelFromCoordinate(coordinate);
    const offsetPixel = [pixel[0] + xOffset, pixel[1] + yOffset];
    const offsetCoordinate = map.getCoordinateFromPixel(offsetPixel);

    // Update the offsetCoordinate state
    // setOffsetCoordinate(offsetCoordinate);

    setOffsetCoordinate({
      coordinate: offsetCoordinate,
      zoom: map.getView().getZoom(),
      // zoom: map.getView().getZoom() + zoomOffset,
    });
  };

  useEffect(() => {
    if (offsetCoordinate && magnifyOverviewMapControl.current) {
      const overviewMapView = magnifyOverviewMapControl.current.getOverviewMap().getView();
      overviewMapView.setCenter(offsetCoordinate.coordinate);
      const newZoomOffset = Number(state.zoomOffSetValue);
      overviewMapView.setZoom(offsetCoordinate.zoom + newZoomOffset);
    }
  }, [offsetCoordinate, magnifyOverviewMapControl, state.zoomOffSetValue]);

  return null;
};

export default TileDependentLayer;
