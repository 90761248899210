import React, { useEffect, useState } from "react";
import { getSubscriptionData, resetSubscriptionData } from "../Service";
import { Button } from "react-bootstrap";
import { NotificationContainer, NotificationManager } from "react-notifications";

export const SubscriptionModules = () => {
  const [subscriptionData, setSubscriptionData] = useState([]);

  useEffect(() => {
    fetchSubscriptionData();
  }, []);

  const fetchSubscriptionData = async () => {
    try {
      const res = await getSubscriptionData();
      setSubscriptionData(formatSubscriptionData(res.data));
    } catch (error) {
      console.error("Error fetching subscription data:", error);
    }
  };

  const formatSubscriptionData = (data) => {
    return data.map((subscription) => {
      return Object.keys(subscription).reduce((acc, type) => {
        if (type === "Live Control") {
          const { "Total Duration": totalDuration, "Last Visited": lastVisited } = subscription[type];
          acc[type] = { "Total Duration": formatDuration(totalDuration), "Last Visited": formatDateAndTime(lastVisited) };
        } else {
          acc[type] = subscription[type];
        }
        return acc;
      }, {});
    });
  };

  const formatDuration = (seconds) => {
    if (seconds < 60) {
      return `${seconds} seconds`;
    } else if (seconds < 3600) {
      return `${Math.floor(seconds / 60)} minutes`;
    } else {
      const hours = Math.floor(seconds / 3600);
      const remainingSeconds = seconds % 3600;
      const minutes = Math.floor(remainingSeconds / 60);
      return `${hours} hour${hours > 1 ? "s" : ""} ${minutes} minute${minutes > 1 ? "s" : ""}`;
    }
  };

  const formatDateAndTime = (epoch) => {
    if (epoch === 0) {
      return "N/A";
    }
    const date = new Date(epoch * 1000);
    return date.toLocaleString(); // You can adjust the date and time format as needed
  };

  const handleReset = async (cardType) => {
    let obj = { cardType: cardType };
    try {
      const res = await resetSubscriptionData(obj);
      if (res.status === 200) {
        console.log("res:", res.data["message"]);
        NotificationManager.success(res.data["message"], "", 1000);
        fetchSubscriptionData();
      }
    } catch (error) {
      console.error(`Error resetting ${cardType} data:`, error);
    }
  };

  return (
    <div
      className="settings_flex_card-container"
      style={{ display: "flex", justifyContent: "center", alignItems: "start", height: "100vh" }}
    >
      <NotificationContainer />
      {subscriptionData.map((subscription, index) => (
        <div
          className="subscription-card"
          key={index}
        >
          {Object.keys(subscription).map((type, index) => (
            <div key={index}>
              <h2 style={{ color: "#0275d8" }}>{type}</h2>
              <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                {Object.keys(subscription[type]).map((key, index) => (
                  <div
                    key={index}
                    style={{ background: "#f0f0f0", padding: "10px", borderRadius: "5px" }}
                  >
                    <strong>{key}:</strong> {subscription[type][key]}
                  </div>
                ))}
              </div>
            </div>
          ))}
          {localStorage.getItem("isMaint") == 1 && (
            <div style={{ marginTop: "20px" }}>
              <Button onClick={() => handleReset(Object.keys(subscription)[0])}>Reset</Button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
