import { useEffect, useState } from "react";
import { Accordion, Card, Form, Button, Row, Col } from "react-bootstrap";
import { add_module_device_config, delete_module_device_config, get_module_config, updateActiveValues } from "../Service";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { AiOutlineDelete } from "react-icons/ai";

const ModuleConfiguration = () => {
  const [configData, setConfigData] = useState({});
  const [newModule, setNewModule] = useState({ module_name: "", module_type: "" });

  console.log("888 configData:", configData);

  const fetchData = async () => {
    try {
      const res = await get_module_config();
      setConfigData(res.data.result[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (moduleName, key, value) => {
    setConfigData((prevState) => ({
      ...prevState,
      [moduleName]: {
        ...prevState[moduleName],
        [key]: value,
      },
    }));
  };

  const handleNewModuleChange = (key, value) => {
    setNewModule((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSave = async () => {
    // Extract active values
    const activeValues = Object.keys(configData).map((moduleName) => ({
      moduleName,
      active: configData[moduleName].active,
    }));

    console.log("888 activeValues:", activeValues);
    console.log("888 activeValues:", typeof activeValues);

    try {
      // Send data to backend to update
      const res = await updateActiveValues({ activeValues: activeValues }); // Assuming there's a function named updateActiveValues to send data to the backend
      if (res.status === 200) {
        NotificationManager.success("Saved Successfully", "", 500);
      }
    } catch (error) {
      console.log(error);
      NotificationManager.error("Failure occur", "", 500);
    }
  };

  const handleAddModule = async () => {
    // Logic to send new module data to backend
    console.log("888 New Module Data:", newModule);
    try {
      const res = await add_module_device_config(newModule);
      if (res.status === 200) {
        NotificationManager.success("Saved Successfully", "", 500);

        // Update configData after addModule
        setConfigData((prevConfigData) => ({
          ...prevConfigData,
          [newModule.module_name]: {
            type_of_slide: newModule.module_type,
            active: "1", // Assuming active should be set to '1' for new modules
          },
        }));
      }
    } catch (error) {
      console.log(error);
      NotificationManager.error("Failure occur", "", 500);
    }
  };

  const handleDeleteModule = async (moduleName) => {
    // Logic to delete module from backend
    console.log("Deleting Module:", moduleName);
    try {
      const res = await delete_module_device_config({ moduleName: moduleName });
      if (res.status === 200) {
        NotificationManager.success("Deleted Successfully", "", 500);

        // Update configData after deletion
        setConfigData((prevConfigData) => {
          const updatedConfigData = { ...prevConfigData };
          delete updatedConfigData[moduleName];
          return updatedConfigData;
        });
      }
    } catch (error) {
      console.log(error);
      NotificationManager.error("Failure occur", "", 500);
    }
  };

  return (
    <div className="container mt-4">
      <Row>
        <Col md={6}>
          <Accordion defaultActiveKey={null}>
            <Accordion.Item eventKey="0">
              <Card>
                <Accordion.Header style={{ color: "#007bff" }}>Module_Configuration</Accordion.Header>
                {/* <Accordion.Toggle as={Card.Header} eventKey="0" style={{ color: "#007bff" }}>
                Module_Configuration
              </Accordion.Toggle> */}
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    {Object.keys(configData).map((moduleName, index) => (
                      <div
                        key={index}
                        className="mb-3"
                      >
                        <Row className="align-items-center light-gray-strip">
                          <Col
                            className="d-flex justify-content-start"
                            xs={4}
                          >
                            <Form.Label className="align-self-center mt-2">{moduleName}:</Form.Label>
                          </Col>
                          <Col
                            className="d-flex justify-content-start align-items-center pl-0"
                            style={{ maxWidth: "30%" }}
                          >
                            <Form.Control
                              type="text"
                              readOnly
                              value={configData[moduleName].type_of_slide}
                            />
                          </Col>
                          <Col xs={3}>
                            <Form.Control
                              type="text"
                              value={configData[moduleName].active}
                              onChange={(e) => handleChange(moduleName, "active", e.target.value)}
                            />
                          </Col>
                          <Col xs={1}>
                            <button
                              style={{ border: "none", background: "none", paddingRight: "10px" }}
                              title="Delete"
                              onClick={() => handleDeleteModule(moduleName)}
                            >
                              <AiOutlineDelete />
                            </button>
                          </Col>
                        </Row>
                      </div>
                    ))}
                    <hr />
                    <Button onClick={handleSave}>Save</Button>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion.Item>
          </Accordion>
        </Col>

        <Col md={6}>
          <Accordion defaultActiveKey={null}>
            <Accordion.Item eventKey="1">
              <Card>
                <Accordion.Header
                  // as={Card.Header}
                  // eventKey="1"
                  // style={{ color: "#007bff" }}
                >
                  Add Module
                </Accordion.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <Form>
                      <Row className="mb-3 light-gray-strip">
                        <Col xs={4}>
                          <Form.Label>Module Name</Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="text"
                            value={newModule.module_name}
                            onChange={(e) => handleNewModuleChange("module_name", e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3 light-gray-strip">
                        <Col xs={4}>
                          <Form.Label>Module Type</Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            as="select"
                            value={newModule.module_type}
                            onChange={(e) => handleNewModuleChange("module_type", e.target.value)}
                          >
                            <option value="">Select Type</option>
                            <option value="WSI">WSI</option>
                            <option value="GC">GC</option>
                          </Form.Control>
                        </Col>
                      </Row>
                      <Button onClick={handleAddModule}>Add</Button>
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </div>
  );
};

export default ModuleConfiguration;
