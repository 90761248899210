import React, { createContext, useEffect, useReducer, useState } from "react";
import { RunningStatusMSGComponent } from "../shared/RunningStatusMSGComponent";
import { pageReducer, handleInitialState, remoteStatus } from "./Service";
import { NotificationContainer } from "react-notifications";
import { RemoteComponent } from "./components/RemoteComponent";
import { DeviceRegistering } from "./components/DeviceRegistering";
import { LoaderComponent } from "./components/Loader";
import { ConnectionStatus } from "./components/ConnectionStatus";
import { RegisteringDevice } from "./components/RegisteringDevice";
import { DeviceRegistered } from "./components/DeviceRegistered";
import { RemoteUserConnecting } from "./components/RemoteUserConnecting";
import { RemoteUserConnected } from "./components/RemoteUserConnected";
import { DefaultPage } from "./components/DefaultPage";
import { LoadSlide } from "./components/LoadSlide";
import { PlaceSlide } from "./components/PlaceSlide";
import { Aborting } from "./components/Aborting";
import { Aborted } from "./components/Aborted";
import { DeviceErrorAbort } from "./components/DeviceErrorAbort";
import { OvmLiveVedio } from "./components/OvmLiveVedio";
import WsImage1 from "./components/WsImage1";
import { Navigation } from "../shared/Navigation";

export const Remote = () => {
  const initialState = {
    timeInterval: 500,
    // timeOut: 40000,
    device_task: 0,
    device_status: 1,
    device_substate: "",
    streamStatus: false,
    liveFps: 500,
    data: {
      email: "",
      deviceName: "",
      connectionStatus: "",
      message: "",
    },
    positionData: [{ x: 0, y: 44, z: 0, "px-x": 0, "px-y": 0, last_ws: 0 }],
    isDeviceError: false,
    errText: "",
    isCloudIdMsgErr: false,
    modalVisible: false,
    componentToRender: <LoaderComponent />,
    getDeviceTaskStatus: (interval) =>
      handleInitialState().then((res) => {
        if (res && res.data && res.data.livecontrol_log !== null) {
          localStorage.setItem("livecontrol_log_data", JSON.stringify(res.data.livecontrol_log || ""));
          pageDispatcher.setPosition(
            res.data.livecontrol_log
            // res.data.device_message && res.data.device_message
          );
        }

        if (res.data.device_error_message || res.data.device_error_msg) {
          //this err comes when we're trying to connect & cloudId not found OR when device err found.
          if (res.data.device_error_msg) {
            //for device err
            pageDispatcher.set_isDeviceError(true);
            pageDispatcher.set_errorMSG(res.data.device_error_msg);
          }
          if (res.data.device_error_message) {
            //for cloud id not found
            pageDispatcher.set_isCloudIdMsgErr(true);
            pageDispatcher.set_errorMSG(res.data.device_error_message);
          }
          //stop interval as well
          return clearInterval(interval);
        }

        if (!res.data.device_error_msg) {
          if (res.data.device_task === "1" && res.data.device_status === "1") {
            pageDispatcher.componentToRender(<RemoteComponent />);
          } else if (res.data.device_task === "7" && res.data.device_status === "1") {
            // console.log("RES..", res.data)
            remoteStatus().then(async (Res) => {
              if (Res && Res.data) {
                await pageDispatcher.setInfo(Res.data, res.data.device_message && res.data.device_message);
              }
            });
            // var device_substate = 'e'
            var device_substate = res.data.device_subState;
            if (device_substate === "w") {
              if (pageState.device_substate !== device_substate) {
                pageDispatcher.set_device_substate("w");
                pageDispatcher.componentToRender(<ConnectionStatus child1={<DeviceRegistering />} />);
              }
            } else if (device_substate === "x") {
              if (pageState.device_substate !== device_substate) {
                pageDispatcher.set_device_substate("x");
                pageDispatcher.componentToRender(<ConnectionStatus child1={<DeviceRegistered />} />);
              }
            } else if (device_substate === "y") {
              if (pageState.device_substate !== device_substate) {
                pageDispatcher.set_device_substate("y");
                pageDispatcher.componentToRender(<ConnectionStatus child1={<RemoteUserConnecting />} />);
              }
            } else if (device_substate === "z") {
              if (pageState.device_substate !== device_substate) {
                pageDispatcher.set_device_substate("z");
                pageDispatcher.componentToRender(<ConnectionStatus child1={<RemoteUserConnected />} />);
              }
            } else if (device_substate === "a") {
              if (pageState.device_substate !== device_substate) {
                pageDispatcher.set_device_substate("a");
                pageDispatcher.componentToRender(
                  <ConnectionStatus
                    child1={<DefaultPage />}
                    child2={<OvmLiveVedio />}
                    child3={<WsImage1 />}
                  />
                );
              }
            } else if (device_substate === "b") {
              if (pageState.device_substate !== device_substate) {
                pageDispatcher.set_device_substate("b");
                pageDispatcher.componentToRender(<LoadSlide />);
              }
            } else if (device_substate === "c") {
              if (pageState.device_substate !== device_substate) {
                pageDispatcher.set_device_substate("c");
                pageDispatcher.componentToRender(<LoaderComponent />);
              }
            } else if (device_substate === "d") {
              if (pageState.device_substate !== device_substate) {
                pageDispatcher.set_device_substate("d");
                pageDispatcher.componentToRender(<LoaderComponent />);
              }
            } else if (device_substate === "f") {
              if (pageState.device_substate !== device_substate) {
                pageDispatcher.set_device_substate("f");
                pageDispatcher.componentToRender(<LoaderComponent />);
              }
            } else if (device_substate === "g") {
              if (pageState.device_substate !== device_substate) {
                pageDispatcher.set_device_substate("g");
                pageDispatcher.componentToRender(<LoaderComponent />);
              }
            } else if (device_substate === "e") {
              if (pageState.device_substate !== device_substate) {
                pageDispatcher.set_device_substate("e");
                pageDispatcher.componentToRender(<PlaceSlide />);
              }
            }
          } else if (res.data.device_task === "7" && res.data.device_status === "0") {
            pageDispatcher.componentToRender(<RegisteringDevice />);
          } else if (res.data.device_task === "503" && res.data.device_status === "0") {
            pageDispatcher.componentToRender(<Aborting />);
          } else if (res.data.device_task === "503" && res.data.device_status === "1") {
            pageDispatcher.set_timeInterval(3000);
            pageDispatcher.componentToRender(<Aborted />);
          } else if (res.data.device_task === "0" && res.data.device_status === "0") {
            pageDispatcher.componentToRender(<LoaderComponent />);
          } else if (res.data.device_task === "1" && res.data.device_status === "0") {
            pageDispatcher.componentToRender(<LoaderComponent />);
          }
           else if ( res.data.device_task === "2" || res.data.device_task === "3") {
            pageDispatcher.componentToRender(<RunningStatusMSGComponent runningComponent={"WSI"} />);
          } else if (res.data.device_task === "11" || res.data.device_task === "12" || res.data.device_task === "13") {
            pageDispatcher.componentToRender(<RunningStatusMSGComponent runningComponent={"PBS"} />);
          } else if (res.data.device_task === "107") {
            pageDispatcher.componentToRender(<RunningStatusMSGComponent runningComponent={"Maintenance Mode"} />);
          } else if (res.data.device_task === "21" || res.data.device_task === "22" || res.data.device_task === "23") {
            pageDispatcher.componentToRender(<RunningStatusMSGComponent runningComponent={"Grid Capture"} />);
          }
        }
      }),
  };
  const [pageState, dispatch] = useReducer(pageReducer, initialState);

  // console.log("remote-fps:", pageState.liveFps);

  useEffect(() => {
    if (pageState) {
      const interval = setInterval(() => {
        pageState.getDeviceTaskStatus(interval);
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [pageState.timeInterval]); // eslint-disable-line react-hooks/exhaustive-deps

  const pageDispatcher = {
    componentToRender: (component) => dispatch({ type: "set_componentToRender", payload: component }),
    setInfo: (data, status) => dispatch({ type: "set_data", payload: { data, status } }),
    setPosition: (data) => dispatch({ type: "set_position", payload: data }),
    set_timeInterval: (value) => dispatch({ type: "set_timeInterval", payload: value }),
    setModalVisible: (value) => dispatch({ type: "set_modalVisible", payload: value }),
    set_isDeviceError: (value) => dispatch({ type: "set_isDeviceError", payload: value }),
    set_errorMSG: (val) => dispatch({ type: "set_errorMSG", payload: val }),
    set_isCloudIdMsgErr: (value) => dispatch({ type: "set_isCloudIdMsgErr", payload: value }),
    set_device_substate: (value) => dispatch({ type: "set_device_substate", payload: value }),
    set_stream_status: (value) => dispatch({ type: "set_stream_status", payload: value }),
    set_live_fps: (value) => dispatch({ type: "set_live_fps", payload: value }),
  };

  return (
    <>
      <Navigation />
      <div className="container-fluid">
        <PageContext.Provider value={{ pageState, pageDispatcher }}>
          {(pageState.isDeviceError || pageState.isCloudIdMsgErr) && (
            <div
              className="container"
              style={{ marginTop: "14%" }}
            >
              <DeviceErrorAbort />
            </div>
          )}

          <NotificationContainer />
          {
            pageState && !pageState.isDeviceError && !pageState.isCloudIdMsgErr && (
              // <div className="container">
              <div className="stitchcardpage">
                {/* <div className="card-body"> */}
                {pageState.componentToRender}
                {/* </div> */}
              </div>
            )
            //  </div>
          }
        </PageContext.Provider>
      </div>
    </>
  );
};

export const PageContext = createContext(null);
