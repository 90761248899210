import React, { useContext } from "react";
import { BsGrid3X3 } from "react-icons/bs";
import { MdOutlineCancelPresentation } from "react-icons/md";
import { RxBox } from "react-icons/rx";
import { TbCircleLetterA } from "react-icons/tb";
import { PageContext } from "../../CaseViewer";
import GridHeight from "../updateSlide/GridHeight";
import GridWidth from "../updateSlide/GridWidth";
import ZoomoffSet from "./ZoomoffSet";

const GridAnnotation = () => {
  const { state, pageDispatcher } = useContext(PageContext);

  const selectedBoxOnClick = () => {
    if (state.selectedBoxVisibility) {
      console.log("selectedBoxOnClick", state.selectedBoxVisibility);
      pageDispatcher.set_selectedBoxVisibility(false);
    } else {
      console.log("selectedBoxOnClick", state.selectedBoxVisibility);
      pageDispatcher.set_selectedBoxVisibility(true);
    }
  };
  const selectedGridBoxOnClick = () => {
    if (state.selectedGridBoxVisibility) {
      console.log("selectedGridBoxOnClick", state.selectedGridBoxVisibility);
      pageDispatcher.set_selectedGridBoxVisibility(false);
    } else {
      console.log("selectedGridBoxOnClick", state.selectedGridBoxVisibility);
      pageDispatcher.set_selectedGridBoxVisibility(true);
    }
  };
  const selectedAnnotationBoxOnClick = () => {
    if (state.selectedAnnotationBoxVisibility) {
      console.log("selectedAnnotationBoxOnClick", state.selectedAnnotationBoxVisibility);
      pageDispatcher.set_selectedAnnotationBoxVisibility(false);
    } else {
      console.log("selectedAnnotationBoxOnClick", state.selectedAnnotationBoxVisibility);
      pageDispatcher.set_selectedAnnotationBoxVisibility(true);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center ">
        <p className="mt-3">Grid:</p>
        <button
          type="button"
          className={`btn ${!state.selectedGridBoxVisibility ? "btn-outline-secondary" : "btn-secondary"} btn-sm m-1`}
          id="selectedGrid"
          onClick={() => selectedGridBoxOnClick()}
          title="Grid"
        >
          <BsGrid3X3 />
        </button>
        <button
          type="button"
          className={`btn ${!state.selectedBoxVisibility ? "btn-outline-secondary" : "btn-secondary"} btn-sm m-1`}
          id="selectedBox"
          onClick={() => selectedBoxOnClick()}
          title="Box"
        >
          <RxBox />
        </button>
        <button
          type="button"
          className={`btn ${!state.selectedAnnotationBoxVisibility ? "btn-outline-secondary" : "btn-secondary"} btn-sm m-1`}
          id="selectedAnnotation"
          onClick={() => selectedAnnotationBoxOnClick()}
          title="Annotation Box"
        >
          <TbCircleLetterA />
        </button>
      </div>
      

      <div className="d-flex align-items-center">
        <GridWidth />
        <GridHeight />
      </div>
      <div className="d-flex align-items-center">
        <ZoomoffSet />
      </div>
    </>
  );
};

export default GridAnnotation;
