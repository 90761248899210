import {Oval} from "react-loader-spinner";
import { handleAbortState } from "../../Service";
import { NotificationContainer, NotificationManager } from 'react-notifications';


export const LoaderComponent = () => {
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
    };
    return (
        <div style={containerStyle}>
            <Oval type="TailSpin" color="#00BFFF" height={80} width={80} />
            <button style={{ maxWidth: '300px' }} type="button" className="btn btn-danger btn-block mt-5"
                    onClick={() => handleAbortState()
                        .then(res => {
                            if (res.data && res.data.msg) {
                                NotificationManager.success(res.data.msg, '', 2000);
                            }
                        })}
                >Abort</button>
        </div>
    )
}