import { createContext } from "react";
import axios from "axios";
import { environment } from "../../environment";
import { getToken } from ".././Utils/Common";
const baseURL = environment.MICALYS_POSTGRES_API;
const baseURLV2 = environment.MICALYS_API_V2;

export const pageReducer = (state, action) => {
  switch (action.type) {
    case "set_componentToRender":
      return {
        ...state,
        componentToRender: action.payload,
      };
    case "set_slot_status":
      return {
        ...state,
        slot_status: action.payload,
      };
    case "set_slot_data":
      return {
        ...state,
        slot_data: action.payload,
      };
    case "set_device_task":
      return {
        ...state,
        device_task: action.payload,
      };
    case "set_device_status":
      return {
        ...state,
        device_status: action.payload,
      };
    case "set_componentToRenderInImagePages":
      return {
        ...state,
        componentToRenderInImagePages: action.payload,
      };
    case "set_showAbortWithLoader":
      return {
        ...state,
        showAbortWithLoader: action.payload,
      };
    case "set_timeInterval":
      return {
        ...state,
        timeInterval: action.payload,
      };
    case "set_isDeviceError":
      return {
        ...state,
        isDeviceError: action.payload,
      };
    case "set_errorMSG":
      return {
        ...state,
        errText: action.payload,
      };
    case "set_current_active_slot_status":
      return {
        ...state,
        current_active_slot_status: action.payload,
      };
    case "set_running_module_name":
      return {
        ...state,
        runningModule: action.payload,
      };

    default:
      return state;
  }
};

export const handleInitialState = () => {
  return axios.get(baseURL + "get_device_state", { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const handleStartState = () => {
  return axios.get(baseURL + "1", { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const handleplaceSlideState = () => {
  return axios.get(baseURL + "2", { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const handleRectCoordinates = (obj) => {
  return axios.post(baseURL + "3", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const handleAbortState = () => {
  return axios.get(baseURL + "503", { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const handleAbortedState = () => {
  return axios.get(baseURL + "0", { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getCanvasImage = (name) => {
  return axios.get(baseURL + `get_ws_image/${name}`, { headers: { Authorization: `Bearer ${getToken()}` }, responseType: "arraybuffer" });
};

export const getCanvasData = () => {
  return axios.get(baseURL + `get_ws_qr_histo`, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const setSlotStatus_0_7 = (slot_no, obj) => {
  return axios.post(baseURL + `set_slot_status_0_7/${slot_no}`, obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const cancelSlot = (data) => {
  return axios.post(baseURLV2 + "slot_cancel_api", data, { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const getSlotData = (slot_no) => {
  return axios.get(baseURL + `get_slot_data/${slot_no}`, { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const getSubmittedSlotData = (slot_no) => {
  return axios.get(baseURL + `get_submitted_slot_data/${slot_no}`, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getLiveImage = async () => {
  return await axios.get(baseURL + "get_live_image", { headers: { Authorization: `Bearer ${getToken()}` }, responseType: "arraybuffer" });
};

export const addupdatescale = (obj, slide_token) => {
  return axios.post(baseURLV2 + "update_scale", obj, { headers: { Authorization: `Bearer ${slide_token}` } });
};

export const getGeneratedSignedURL = (data) => {
  return axios.post(baseURLV2 + "generate_import_slide_jwt", data, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const importSlideURL = (data, jwt, param) => {
  return axios.post(baseURLV2 + "import_slide?" + param, data, { headers: { Authorization: `Bearer ${jwt}` } });
};

export const updateReportingStatus = (obj) => {
  return axios.post(baseURLV2 + "setreportstatus", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const updateCaseReportingStatus = (obj) => {
  return axios.post(baseURLV2 + "update_reportstatus", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const setSlideTags = (obj) => {
  return axios.post(baseURLV2 + "set_slide_tags", obj, { headers: { Authorization: `Bearer ${getToken()}` }, responseType: "arraybuffer" });
};

export const setAssignedTo = (obj) => {
  return axios.post(baseURLV2 + "assign_case", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const setDeassignTo = (obj) => {
  return axios.post(baseURLV2 + "deassign_case", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getAllTags = () => {
  return axios.get(baseURLV2 + "get_all_tags", { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const addCase = (obj) => {
  return axios.post(baseURLV2 + "addCase", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getCaseData = (obj) => {
  return axios.post(baseURLV2 + "getcasedata", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const getSlideData = (data, case_jwt) => {
  return axios.post(baseURLV2 + "getSlidedata", data, { headers: { Authorization: `Bearer ${case_jwt}` } });
};

export const getCaseInfo = (obj) => {
  return axios.post(baseURLV2 + "get_case_info", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const getCaseFinding = (obj) => {
  return axios.post(baseURLV2 + "get_case_findings", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};
export const getCaseSignedURL = (data, case_jwt) => {
  return axios.post(baseURLV2 + "generate_import_caseslide_jwt", data, { headers: { Authorization: `Bearer ${case_jwt}` } });
};

export const updateCaseData = (data, case_jwt) => {
  return axios.post(baseURLV2 + "update_case_data", data, { headers: { Authorization: `Bearer ${case_jwt}` } });
};

export const uploadMacroscopicImg = (data, case_jwt) => {
  return axios.post(baseURLV2 + "upload_macroscopic_image", data, { headers: { Authorization: `Bearer ${case_jwt}` } });
};

export const getMacroscopicImg = (imgName, case_jwt) => {
  return axios.get(baseURLV2 + `get_macroscopic_image/${imgName}`, { headers: { Authorization: `Bearer ${case_jwt}` }, responseType: "arraybuffer" });
};

export const deleteMacroscopicImg = (imgName, case_token) => {
  return axios.delete(baseURLV2 + `delete_macroscopic_image/${imgName}`, { headers: { Authorization: `Bearer ${case_token}` } });
};

export const uploadMicroscopicImg = (formData, case_jwt) => {
  return axios.post(baseURLV2 + "upload_microscopic_image", formData, {
    headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${case_jwt}` },
  });
};

export const getMicroscopicImg = (imgName, case_jwt) => {
  return axios.get(baseURLV2 + `get_microscopic_image/${imgName}`, { headers: { Authorization: `Bearer ${case_jwt}` }, responseType: "arraybuffer" });
};

export const deleteMicroscopicImg = (imgName, case_jwt) => {
  return axios.delete(baseURLV2 + `delete_microscopic_image/${imgName}`, { headers: { Authorization: `Bearer ${case_jwt}` } });
};

export const uploadRequestForm = async (obj) => {
  return await axios.post(baseURL + "upload_request_form", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getRequestForm = (slide_token) => {
  return axios.get(baseURL + "get_requestform", {
    headers: { Authorization: `Bearer ${slide_token}` },
    responseType: "arraybuffer",
  });
};

export const importCaseURL = (data, jwt, param) => {
  return axios.post(baseURLV2 + "import_caseslide?" + param, data, { headers: { Authorization: `Bearer ${jwt}` } });
};

export const updateSlideData = (data, slide_jwt) => {
  return axios.post(baseURLV2 + "update_slide_data", data, { headers: { Authorization: `Bearer ${slide_jwt}` } });
};

export const setSyncStatus = (obj) => {
  return axios.post(baseURLV2 + "set_sync_status", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getSyncStatus = () => {
  return axios.post(baseURLV2 + "get_sync_status", {}, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const generateCaseFindingsPdf = (obj) => {
  return axios.post(baseURLV2 + "generate_case_findings_pdf", obj, { headers: { Authorization: `Bearer ${getToken()}` }, responseType: "arraybuffer" });
};
export const generateDiagnosisPdf = (token) => {
  return axios.post(baseURLV2 + "generate_diagnosis_pdf", {}, { headers: { Authorization: `Bearer ${token}` }, responseType: "arraybuffer" });
};

export const getannotated = (slide_token) => {
  // let obj = {
  //   slide_id: slide_id,
  // };
  return axios.get(baseURL + "getannotated", {
    headers: { Authorization: `Bearer ${slide_token}` },
    responseType: "arraybuffer",
  });
};

export const exportSlide = (slide_id, exportType) => {
  let obj = {
    // slide_id: slide_id.exportSlideId,
    slide_id: slide_id,
    export_type: exportType,
  };
  return axios.post(baseURL + "export_this_slide", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const loadSlideDiagnosis = (token) => {
  return axios.get(baseURLV2 + "load_slide_diagnosis", { headers: { Authorization: `Bearer ${token}` } });
};
export const diagnosisNextButton = (token, formData) => {
  return axios.post(baseURLV2 + "diagnosis_next_button", formData, {
    headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${token}` },
  });
};
export const diagnosisPreviousButton = (token, obj) => {
  return axios.post(baseURLV2 + "diagnosis_previous_button", obj, { headers: { Authorization: `Bearer ${token}` } });
};
export const sendNewXYZvalues = (token, obj) => {
  return axios.post(baseURLV2 + "capture_area_button", obj, { headers: { Authorization: `Bearer ${token}` } });
};
export const addAnnotation = (token, obj) => {
  return axios.post(baseURLV2 + "add_annotation", obj, { headers: { Authorization: `Bearer ${token}` } });
};

export const getAnnotationListData = (token) => {
  return axios.get(baseURLV2 + "get_annotation_list", { headers: { Authorization: `Bearer ${token}` } });
};

export const editAnnotionProfile = (token, obj) => {
  return axios.post(baseURLV2 + "edit_annotation", obj, { headers: { Authorization: `Bearer ${token}` } });
};

export const deleteAnnotation = (token, obj) => {
  return axios.post(baseURLV2 + "delete_annotation", obj, { headers: { Authorization: `Bearer ${token}` } });
};

export const updateSlideActiveDays = (obj) => {
  return axios.post(baseURLV2 + "update_slide_active_days", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const updateViewTracking = (obj, slide_jwt) => {
  return axios.post(baseURLV2 + "setViewTracking", obj, { headers: { Authorization: `Bearer ${slide_jwt}` } });
};

export const addMlSlide = (obj, slide_jwt) => {
  return axios.post(baseURLV2 + "add_ml_slide", obj, { headers: { Authorization: `Bearer ${slide_jwt}` } });
};

export const getMlJobList = (obj, slide_jwt) => {
  // console.log('slide_jwt 2',slide_jwt)
  // console.log('slide_jwt obj',obj)
  return axios.post(baseURLV2 + "get_ml_job_list", obj, { headers: { Authorization: `Bearer ${slide_jwt}` } });
};

export const deleteViewTracking = (slide_jwt) => {
  return axios.post(baseURLV2 + "deleteViewTracking", {}, { headers: { Authorization: `Bearer ${slide_jwt}` } });
};
export const getViewTracking = (slide_jwt) => {
  return axios.post(baseURLV2 + "getViewTracking", {}, { headers: { Authorization: `Bearer ${slide_jwt}` } });
};
export const addComment = (token, obj) => {
  return axios.post(baseURLV2 + "add_comment", obj, { headers: { Authorization: `Bearer ${token}` } });
};

export const getComment = (token, obj) => {
  return axios.post(baseURLV2 + "get_comment", obj, { headers: { Authorization: `Bearer ${token}` } });
};

export const deleteComment = (token, obj) => {
  return axios.post(baseURLV2 + "delete_comment", obj, { headers: { Authorization: `Bearer ${token}` } });
};

export const getCaselistBySearchKeywords = (obj) => {
  return axios.post(baseURLV2 + "get_caselist_by_search_keywords", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const addCaseForDifferentSlot = (obj) => {
  return axios.post(baseURLV2 + "add_case_for_different_slot", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};
// export const add_ml_slide =(obj,slide_jwt)=>{
//   return axios.post(baseURLV2 + "add_ml_slide",obj,{ headers: { Authorization: `Bearer ${slide_jwt}` } })
// }

export const handleAutoSlideCapture = (obj) => {
  return axios.post(baseURL + "2_auto_capture", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const addGrossImage = (formData, slide_token) => {
  // console.log('formData',formData)
  return axios.post(baseURLV2 + "add_gross_image", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${slide_token}`,
    },
  });
};

export const getGrossImageList = (slide_token) => {
  return axios.get(baseURLV2 + "get_gross_image_list", { headers: { Authorization: `Bearer ${slide_token}` } });
};
export const getGrossImage = (obj, slide_token) => {
  return axios.post(baseURLV2 + "get_gross_image", obj, { headers: { Authorization: `Bearer ${slide_token}` }, responseType: "arraybuffer" });
};

export const deleteGrossImage = (obj, slide_token) => {
  return axios.post(baseURLV2 + "delete_gross_image", obj, { headers: { Authorization: `Bearer ${slide_token}` } });
};

export const PageContext = createContext(null);
