import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { updateCaseData } from "../../../Service";
import { PageContext } from "../../CaseViewer";
import { CancelButton, SaveButton } from "../updateSlide/Buttons";

const PatientAge = () => {
  const { state, pageDispatcher } = useContext(PageContext);
  const { case_owner, patient_age, case_token } = state.case_info;

  const [isEditing, setIsEditing] = useState(false);
  const [patientAge, setPatientAge] = useState(patient_age);
  const [originalPatientAge, setOriginalPatientAge] = useState(patient_age);
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    setPatientAge(patient_age);
  }, [patient_age]);

  const handlePatientAgeChange = (event) => {
    setPatientAge(event.target.value);
  };

  const handleEditButtonClick = () => {
    setOriginalPatientAge(patientAge);
    setIsEditing(true);
  };

  const handleSaveButtonClick = async () => {
    const obj = {
      patient_age: patientAge,
    };
    try {
      await updateCaseData(obj, case_token);
      setIsEditing(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelButtonClick = () => {
    setPatientAge(originalPatientAge);
    setIsEditing(false);
  };

  return (
    <div className="mb-1">
      {isEditing ? (
        <div>
          <input
            type="number"
            value={patientAge}
            onChange={handlePatientAgeChange}
          />
          <Button
            variant="outline-primary"
            size="sm"
            onClick={handleSaveButtonClick}
            className="mx-1"
          >
            <SaveButton />
          </Button>
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={handleCancelButtonClick}
            className="mx-1"
          >
            <CancelButton/>
          </Button>
        </div>
      ) : (
        <div className="d-flex justify-content-start align-items-center">
          <span>Patient Age: {patientAge}</span>
          {case_owner ? (
            <sup>
              <MdOutlineModeEditOutline
                size={15}
                className={isHovered ? "edit-icon hovered" : "edit-icon"}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleEditButtonClick}
              />
            </sup>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default PatientAge;
