import { useLocation } from "react-router-dom";
import { Navigation } from "../shared/Navigation";
import { getCustomRoute } from "../Utils/Common";

export const RunningStatusMSGComponent = ({runningComponent='Maintenance mode'}) => {
  const location = useLocation();
  console.log("location:",location.pathname)

  return (
    <>
    <div className="parentContainer">
      <div className="boxStyle">
      {/* <Navigation /> */}
      {/* <h>Your Active module is {getCustomRoute()}</h> */}
      <h>Your Active module is {runningComponent}</h>
      <br/>
      {/* <h>Abort {getCustomRoute()} task</h> */}
      <h>Abort {runningComponent} task</h>
      </div>
      </div>
    </>
  );
};
